import React, {useEffect, useState} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { CredentialingAuditLogs, CredentialingTasks } from '../../components';
import { useLocation, useNavigate } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';

interface TabPanelProps {
	children?: React.ReactNode,
	index: number,
	value: number
}

function CustomTabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ pt: 3 }}>{children}</Box>
			)}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		'id': `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`
	};
}

export default function CredentialingPageLayout() {
	const { t } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();
	const [value, setValue] = useState(0);

	useEffect(()=> {
		setValue(location.pathname.split('/')[2] === 'tasks' ? 0 : 1);
	}, [value, location]);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
		if(newValue === 0){
			navigate('/credentialing/tasks');
		} else if( newValue === 1){
			navigate('/credentialing/audit-logs');
		}
	};

	return (
		<AutoSizer disableHeight>
			{({ width }: { width: number }) =>
			width ? (
			<Box style={{ width: `${width}px` }}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
						<Tab label={t('Tasks')} {...a11yProps(0)} />
						<Tab label={t('AuditLogs')} {...a11yProps(1)} />
					</Tabs>
				</Box>
				<CustomTabPanel value={value} index={0}>
					<CredentialingTasks />
				</CustomTabPanel>
				<CustomTabPanel value={value} index={1}>
					<CredentialingAuditLogs />
				</CustomTabPanel>
			</Box>
			) : (
				<div>Loading...</div>
			)
			}
		</AutoSizer>
	);
}