import React from 'react';
import { useApi, useToaster } from '@praos-health/ui';
import { useAuth } from '@praos-health/ui-security';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, IconButton } from '@mui/material';
import { AdvancedSearch, FlatScreening, LicenseVerificationTask, ListOrderBy, TaskType } from '@praos-health/screening-client';
import { CredentialingAuditLogTable } from './credentialing-auditLog-table';
import { downloadFile } from '../../../common/download';
import { useLocation } from 'react-router-dom';
import DownloadIcon from '@mui/icons-material/GetApp';
import { DataTableColumn, DataTablePagination } from '@praos-health/web/components/data-table';
import { AdvancedFiltersForm, initialAdvanceSearchState } from '../advanced-filters-form';
import { AppApi } from '../../../app-api';

export function CredentialingAuditLogs(): JSX.Element {
	const location = useLocation();
	const auth = useAuth();
	const api = useApi<AppApi>();
	const { t } = useTranslation();
	const toast = useToaster();
	const [isLoading, setIsLoading] = useState(false);
	const [applicantId, setApplicantId] = useState(location?.state?.applicantId || '');
	const [organizationId, setOrganizationId] = useState('all');
	const [advancedSearch, setAdvancedSearch] = useState(initialAdvanceSearchState());
	const [shouldDelaySearch, setShouldDelaySearch] = useState(false);
	const [listScreenings, setListScreenings] = useState<FlatScreening<LicenseVerificationTask>[]>([]);
	const [pagination, setPagination] = React.useState<DataTablePagination>(
		{ order: 'asc', orderBy: 'tasks.applicant.firstName', page: 0, rowsPerPage: 10 }
	);
	const [count, setCount] = React.useState<number>(0);

	const dateFormatter = new Intl.DateTimeFormat();

	useEffect(() => {
		async function loadScreeningData(): Promise<void> {
			if (auth.session) {
				try {
					const orderBy = (
						pagination.order === 'asc' ? pagination.orderBy : `!${pagination.orderBy}`
					) as ListOrderBy;

					setIsLoading(true);

					const screenings = await api.screeningService.listScreenings(auth.session?.auth, {
						isOpen: false,
						search: advancedSearch,
						flatten: true,
						applicantId,
						organization: (organizationId === 'praos' ? '' : organizationId !== 'all' ? organizationId : undefined),
						type: TaskType.LicenseVerification,
						orderBy: orderBy,
						limit: pagination.rowsPerPage,
						skip: pagination.page * pagination.rowsPerPage
					});

					setListScreenings(screenings.list as FlatScreening<LicenseVerificationTask>[]);
					setCount(screenings.count);
				} catch (err) {
					toast(err as Error, 'error');
				} finally {
					setIsLoading(false);
				}
			}
		}

		let timeout: NodeJS.Timeout | null = null;

		if (shouldDelaySearch) {
			timeout = setTimeout(() => {
				timeout = null;
				loadScreeningData();
			}, 500);

			return () => {
				if (timeout) {
					clearTimeout(timeout);
				}
			};
		}

		loadScreeningData();
	}, [advancedSearch, api, applicantId, auth, toast, organizationId, pagination, shouldDelaySearch]);

	const getLicenseState = (screening: FlatScreening<LicenseVerificationTask>) => screening.task.license ? screening.task.license.licenseBody : screening.task.applicant?.licenses?.[0]?.licenseBody ?? '';

	const columns: DataTableColumn<FlatScreening<LicenseVerificationTask>>[] = [
		{
			id: 'viewButton',
			title: t(''),
			align: 'left'
		},
		{
			id: 'accountID',
			title: t('AccountID'),
			orderBy: 'applicantId',
			align: 'left',
			getValue: (screening) => screening.applicantIntId ?? screening.applicantId ?? ''
		},
		{
			id: 'firstName',
			title: t('FirstName'),
			orderBy: 'tasks.applicant.firstName',
			align: 'left',
			getValue: (screening) => screening.task.applicant?.firstName ?? ''
		},
		{
			id: 'lastName',
			title: t('LastName'),
			orderBy: 'tasks.applicant.lastName',
			align: 'left',
			getValue: (screening) => screening.task.applicant?.lastName ?? ''
		},
		{
			id: 'licenseType',
			title: t('LicenseType'),
			orderBy: 'tasks.applicant.licenses.licenseType',
			align: 'left',
			getValue: (screening) => screening.task.applicant?.licenses?.[0]?.licenseType ?? ''
		},
		{
			id: 'licenseState',
			title: t('LicenseState'),
			orderBy: 'tasks.applicant.licenses.licenseBody',
			align: 'left',
			getValue: (screening) => getLicenseState(screening) as string
		},
		{
			id: 'taskStatus',
			title: t('TaskStatus'),
			orderBy: 'tasks.status',
			align: 'left',
			getValue: (screening) => screening.task.status || ''
		},
		{
			id: 'verifiedBy',
			title: t('Resolved By'),
			orderBy: 'tasks.closedBy',
			align: 'left',
			getValue: (screening) => screening.task.closedBy?.firstName ? (screening.task.closedBy?.firstName + ' ' + screening.task.closedBy?.lastName) : '-'
		},
		{
			id: 'verifiedAt',
			title: t('Resolved At'),
			orderBy: 'tasks.closedAt',
			align: 'left',
			getValue: (screening) => screening.task.closedAt ? dateFormatter.format(screening.task.closedAt) : ''
		},
		{
			id: 'resolutionNotes',
			title: t('ResolutionNotes'),
			orderBy: 'tasks.note',
			align: 'left',
			getValue: (screening) => screening.task.note ?? 'N/A'
		},
		{
			id: 'organization',
			title: t('Organization'),
			align: 'left',
			getValue: (screening) => screening.organization?.name ?? 'Praos Health'
		}
	];

	if (!auth.session?.organization) {
		columns.push(
			{
				id: 'actions',
				title: t('Actions'),
				align: 'left'
			}
		);
	}

	function handleSearch(organizationId: string, applicantId: string, search: AdvancedSearch, delay?: boolean): void {
		setOrganizationId(organizationId);
		setApplicantId(applicantId);
		setAdvancedSearch(search);
		setShouldDelaySearch(!!delay);
		setPagination({...pagination, page: 0});
	}

	function handleExport() {
		if(auth.session) {
			api.screeningService.listScreenings(auth.session.auth, {
				isOpen: false,
				flatten: true,
				applicantId,
				organization: (organizationId === 'praos' ? '' : organizationId !== 'all' ? organizationId : undefined),
				type: TaskType.LicenseVerification,
				csv: true
			}).then(res => {
				downloadFile(
					'credentialing audit-logs.csv',
					'text/csv;charset=utf-8;',
					res as string);
			}).catch(err => {
				toast(err as Error, 'error');
			});
		}
	}

	return (
		<Grid container spacing={2}>
			{/* Filters  */}
			<AdvancedFiltersForm
				organizationId={organizationId}
				applicantId={applicantId}
				advancedSearch={advancedSearch}
				onSearch={handleSearch}
				buttons= {
					<IconButton color="inherit" edge="end" onClick={handleExport} size="large">
						<DownloadIcon />
					</IconButton>
				}
			/>

			<Grid item xs={12}>
				<CredentialingAuditLogTable
					columns={columns}
					count={count}
					loading={isLoading}
					screeningData={listScreenings}
					pagination={pagination}
					onRequestData={(page) => { setPagination( page || { ...pagination }  ); }}
				/>
			</Grid>
		</Grid>
	);
}
