import { LicenseVerificationTask, isLicenseVerificationTask, parseLicenseVerificationTask } from "./license-verification/license-verification-task";
import { Task } from "./task";
import { VettyTask, isVettyTask, parseVettyTask } from "./vetty";

export type AnyTask = VettyTask | LicenseVerificationTask;

export enum TaskType {
	LicenseVerification = 'LicenseVerification',
	Vetty = 'Vetty'
}

export function parseTask<T extends Task = Task>(task: T): T {
	if (task.createdAt) {
		task.createdAt = new Date(task.createdAt);
	}

	if (task.updatedAt) {
		task.updatedAt = new Date(task.updatedAt);
	}

	if (task.closedAt) {
		task.closedAt = new Date(task.closedAt);
	}

	if (isLicenseVerificationTask(task)) {
		return parseLicenseVerificationTask(task) as T;
	}

	if (isVettyTask(task)) {
		return parseVettyTask(task) as T;
	}

	return task;
}