import { useEffect, useState } from 'react';
import { Backdrop, Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, IconButton, Tooltip, Typography, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAuth } from '@praos-health/ui-security';
import { useApi, useToaster } from '@praos-health/ui';
import { FlatScreening, LicenseVerificationTask } from '@praos-health/screening-client';
import CandidateInformation from '../candidate-information';
import LicenseInformation from '../license-information';
import { LicenseUrl, LicenseUrlService } from '@praos-health/briefcase-client';
import { grey, purple } from '@mui/material/colors';
import { AttachFile } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { AppApi } from '../../../app-api';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
	  padding: theme.spacing(2)
	},
	'& .MuiDialogActions-root': {
	  padding: theme.spacing(1)
	}
}));

interface ViewTaskModalProps {
	handleClose: () => void,
	modelOpen: boolean,
	taskId: string
}

const ViewTaskDialog = ({handleClose, taskId, modelOpen}: ViewTaskModalProps) => {
	const auth = useAuth();
	const toast = useToaster();
	const api = useApi<AppApi>();
	const [isLoading, setIsLoading] = useState(false);
	const [screeningDataObject, setScreeningDataObject] = useState<FlatScreening<LicenseVerificationTask> | null>(null);
	const [task, setTask] = useState<LicenseVerificationTask | null>(null);
	const [boardUrlDetails, setBoardUrlDetails] = useState<LicenseUrl | null>(null);

	const dateFormatter = new Intl.DateTimeFormat();

	function formatDate(date?: Date): string {
		return date ? dateFormatter.format(date) : '-';
	}

	function handleDownload(): void {
		api.recordService.download({ auth: auth.session?.auth, recordId: task?.psvFileId || '', openWindow: true });
	}

	useEffect(() => {
		async function fetchScreeningObject(): Promise<void> {
			if (auth.session && taskId) {
				const result = await api.screeningService.getTask<LicenseVerificationTask>(
					auth.session.auth,
					taskId
				);
				setScreeningDataObject(result);
				setTask(result?.task);
			}
		}

		setIsLoading(true);

	  fetchScreeningObject()
			.catch(error => {
				toast(error, 'error');
			})
			.finally(() => {
				setIsLoading(false);
			});

	}, [api, auth, setScreeningDataObject, setIsLoading, setTask, taskId, toast]);

	useEffect(() => {
		async function fetchBoardUrls(licenseType: string, licenseBody: string ) {
			const urlService = new LicenseUrlService(process.env.REACT_APP_API_URL || '');

			if (auth.session) {
				const result = (await urlService.lookupUrl(
					licenseType,
					{
						licenseBody: licenseBody,
						organization: auth.session.organization?._id,
						responseType: 'object'
					}
				)) as LicenseUrl;
				setBoardUrlDetails(result);
			}
		}

		if (task) {
			fetchBoardUrls(task?.applicant?.licenses?.[0]?.licenseType ?? '', task?.applicant?.licenses?.[0]?.licenseBody ?? '')
				.catch(err => {
					toast(err, 'error');
				});
		}
	}, [auth, task, toast]);

	return (
		<BootstrapDialog
			onClose={()=> {
				setScreeningDataObject(null);
				setBoardUrlDetails(null);
				handleClose();
			}}
			maxWidth={false}
			open={modelOpen}
			fullWidth={false}
		>
			<DialogTitle fontWeight='bold' sx={{ m: 0, p: 2 }} id="view-task-dialog">
			View Task
			</DialogTitle>
			<IconButton
				aria-label="close"
				onClick={()=> {
					setScreeningDataObject(null);
					setBoardUrlDetails(null);
					handleClose();
				}}
				sx={{
					position: 'absolute',
					right: 8,
					top: 8,
					color: (theme) => theme.palette.grey[500]
				}}
			>
				<CloseIcon />
			</IconButton>

			<DialogContent dividers>
				<Box sx={{ width: '750px', display: 'flex', gap: '15px', padding: '10px 10px 20px 10px', justifyContent: 'space-between'}}>
					{/* Candidate Information */}
					<CandidateInformation
						applicant={task?.applicant}
						applicantId={screeningDataObject?.applicantIntId || screeningDataObject?.applicantId}
						organization={screeningDataObject?.organization?.name}>
						<Grid container sx={{'backgroundColor': grey[100], '&:hover': {backgroundColor: grey[300]}, 'paddingY': '3px', 'paddingX': '8px', 'borderRadius': '7px'}}>
							<Grid item xs={5}>
								<Typography fontWeight='bold' variant='subtitle2'>Board URL(s):</Typography>
							</Grid>
							<Grid item xs={7}>
								{boardUrlDetails ? <Box sx={{display: 'flex', flexDirection: 'column'}}>
									<Tooltip title={boardUrlDetails.url} arrow>
										<Link to={boardUrlDetails.url} target='_blank' style={{textDecoration: 'none'}}>
											<Typography sx={{'&:hover': {
												textDecoration: 'underline'
											}, 'color': purple[800], 'textOverflow': 'ellipsis', 'overflow': 'hidden'}} variant='subtitle2'>{boardUrlDetails.url}</Typography>
										</Link>
									</Tooltip>
								</Box> :
							'-'}
							</Grid>
						</Grid>
						{task?.psvFileId && task?.psvFileId !== '' &&
					<Button variant="outlined" onClick={handleDownload} startIcon={<AttachFile />} sx={{width: 'fit-content', marginTop: '20px'}}>
						View Docs
					</Button>
						}
					</CandidateInformation>

					{/* License Information */}
					<LicenseInformation
						license={task?.applicant?.licenses?.[0]}
						updatedLicense={task?.license ?? null}
						nursys={screeningDataObject?.nursys}
						licenseIssueDate={null}
						licenseExpirationDate={null}
						licenseState={''}
						licenseNumber={''}
						updateLicenseStates={() => null}
						setIncompleteLicenseData={()=>(false)}
						setIsDirty={()=>(false)}
						profession='Nurse'
						modalType='view'
					>
						<Grid container sx={{'backgroundColor': grey[100], '&:hover': {backgroundColor: grey[300]}, 'paddingY': '3px', 'paddingX': '8px', 'borderRadius': '7px'}}>
							<Grid item xs={6}>
								<Typography fontWeight='bold' variant='subtitle2'>Task Status:</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography variant='subtitle2'>{task?.status ?? '-'}</Typography>
							</Grid>
						</Grid>
						<Grid container sx={{'backgroundColor': grey[100], '&:hover': {backgroundColor: grey[300]}, 'paddingY': '3px', 'paddingX': '8px', 'borderRadius': '7px'}}>
							<Grid item xs={6}>
								<Typography fontWeight='bold' variant='subtitle2'>Resolved by:</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography variant='subtitle2'>{task?.closedBy?.firstName ? (task?.closedBy?.firstName + ' ' + task?.closedBy?.lastName) : '-'}</Typography>
							</Grid>
						</Grid>
						<Grid container sx={{'backgroundColor': grey[100], '&:hover': {backgroundColor: grey[300]}, 'paddingY': '3px', 'paddingX': '8px', 'borderRadius': '7px'}}>
							<Grid item xs={6}>
								<Typography fontWeight='bold' variant='subtitle2'>Resolved at:</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography variant='subtitle2'>{formatDate(task?.closedAt)}</Typography>
							</Grid>
						</Grid>
						<Grid container sx={{'backgroundColor': grey[100], '&:hover': {backgroundColor: grey[300]}, 'paddingY': '3px', 'paddingX': '8px', 'borderRadius': '7px'}}>
							<Grid item xs={6}>
								<Typography fontWeight='bold' variant='subtitle2'>Resolution Notes:</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography variant='subtitle2'>{(task?.note) ?? '-'}</Typography>
							</Grid>
						</Grid>
					</LicenseInformation>
				</Box>
			</DialogContent>

			<Backdrop sx={(theme) => ({
				zIndex: theme.zIndex.drawer + 1,
				color: '#fff'
			})} open={isLoading}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</BootstrapDialog>
	);
};

export default ViewTaskDialog;