import React from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { LimitedBackdrop } from '@praos-health/web/components/limited-backdrop';
import { Pie } from '@nivo/pie';
import { InfoButtonPopper } from '@praos-health/web/components';
import { Color } from '../../common/color';
import { CredentialsStatistics } from '@praos-health/professional-client';
import { styled } from '@mui/material';
import { Title } from '@praos-health/web/components';
import { Toolbar } from '../shared-components';
import { TFunctionDetailedResult } from 'i18next';

interface Props {
	title: string,
	description: string,
	height: number,
	loading: boolean,
	data: LicenseBreakdownItem[]
}

const StyledMain = styled('div')(() => ({
	position: 'relative'
}));

export interface LicenseBreakdownItem {
	id: string,
	label: string,
	value: number,
	color: string
}

export function buildLicenseBreakdownItems(
	t: (v: string, args?: any) => string | TFunctionDetailedResult<string>,
	stats: CredentialsStatistics
): LicenseBreakdownItem[] {
	return [
		{
			id: 'usersWith1License',
			label: t('WithLicense').toString(),
			value: stats.usersWith1License,
			color: Color.Red
		},
		{
			id: 'usersWith2License',
			label: t('WithLicenses', { count: 2 }).toString(),
			value: stats.usersWith2License,
			color: Color.Yellow
		},
		{
			id: 'usersWith3License',
			label: t('WithLicenses', { count: 3 }).toString(),
			value: stats.usersWith3License,
			color: Color.Blue
		},
		{
			id: 'usersWith4License',
			label: t('WithLicenses', { count: 4 }).toString(),
			value: stats.usersWith4License,
			color: Color.Green
		},
		{
			id: 'usersWith5License',
			label: t('WithLicenses', { count: 5 }).toString(),
			value: stats.usersWith5License,
			color: Color.Purple
		},
		{
			id: 'usersWith5PlusLicense',
			label: t('WithLicenses', { count: '5+' }).toString(),
			value: stats.usersWith5PlusLicense,
			color: Color.Orange
		}
	];
}

export function LicenseBreakdownPieChart({
	title,
	description,
	data,
	height,
	loading
}: Props): JSX.Element {
	return (
		<StyledMain>
			{title && (
				<Toolbar>
					<Title>{title}</Title>
					{description && <InfoButtonPopper text={description} />}
				</Toolbar>
			)}
			<AutoSizer disableHeight>
				{({ width }: { width: number }) =>
					width ? (
						<div style={{ width: `${width}px` }}>
							<Pie
								height={height}
								width={width}
								data={data}
								margin={{
									top: 35,
									right: width < 450 ? 60 : 164,
									bottom: 80,
									left: width < 450 ? 60 : 164
								}}
								innerRadius={0.5}
								padAngle={0.7}
								cornerRadius={3}
								activeOuterRadiusOffset={8}
								borderWidth={1}
								colors={{ datum: 'data.color' }}
								borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
								enableArcLinkLabels={width >= 450}
								arcLinkLabelsSkipAngle={10}
								arcLinkLabelsTextColor="#333333"
								arcLinkLabelsThickness={2}
								arcLinkLabelsColor={{ from: 'color' }}
								arcLabelsSkipAngle={10}
								arcLinkLabel={({ data }) => data.label}
								arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
								animate={true}
								legends={[
									{
										anchor: 'bottom-left',
										direction: 'column',
										justify: false,
										translateX: width < 450 ? -40 : -130,
										translateY: 70,
										itemWidth: 100,
										itemHeight: 20,
										itemsSpacing: 0,
										symbolSize: 20,
										itemDirection: 'left-to-right'
									}
								]}
							/>
						</div>
					) : (
						<div>Loading...</div>
					)
				}
			</AutoSizer>
			<LimitedBackdrop open={loading} />
		</StyledMain>
	);
}
