import React from 'react';
import { useEffect, useState } from 'react';
import {
	DataTableWithDownloadIcon,
	RowType,
	TimeFrame,
	toCsv
} from '../data-table';
import { useTranslation } from 'react-i18next';
import { LimitedBackdrop } from '@praos-health/web/components/limited-backdrop';
import { UserInsightsStatistics } from '@praos-health/professional-client';
import { IconButton, Paper, styled } from '@mui/material';
import DownloadIcon from '@mui/icons-material/GetApp';
import { downloadFile } from '../../common/download';
import { DataTableColumn } from '@praos-health/web/components/data-table';

interface UserInsightsData {
	week: string,
	notActivated?: number,
	activatedOnly?: number,
	licenseVerified?: number,
	briefcaseCompleted?: number,
	skillsChecklistCompleted?: number | string,
	totalActive?: number,
	total?: number,
	timeFrame?: TimeFrame,
	type?: RowType
}

const NO_DATA = {
	thisWeek: 0,
	lastWeek: 0,
	lastWeekPartial: 0,
	thisMonth: 0,
	lastMonth: 0,
	lastMonthPartial: 0,
	allTime: 0
};

interface Props {
	data?: UserInsightsStatistics,
	organizationId?: string,
	loading: boolean
}

const Title = styled('h3')(({ theme }) => ({
	paddingLeft: theme.spacing(2)
}));

export function UserInsightsTable({ data, organizationId, loading }: Props) {
	const { t } = useTranslation();
	const [rows, setRows] = useState<UserInsightsData[]>([]);
	const columns: DataTableColumn<UserInsightsStatistics>[] = [
		{
			id: 'week',
			title: t('userInsightsTable.week'),
			orderBy: 'week',
			align: 'left'
		},
		{
			id: 'notActivated',
			title: t('userInsightsTable.notActivated'),
			description: t('userInsightsTable.notActivatedDescription'),
			orderBy: 'notActivated',
			align: 'left'
		},
		{
			id: 'activatedOnly',
			title: t('userInsightsTable.activatedOnly'),
			description: t('userInsightsTable.activatedOnlyDescription'),
			orderBy: 'activatedOnly',
			align: 'left'
		},
		{
			id: 'licenseVerified',
			title: t('userInsightsTable.licenseVerified'),
			description: t('userInsightsTable.licenseVerifiedDescription'),
			orderBy: 'licenseVerified',
			align: 'left'
		},
		{
			id: 'briefcaseCompleted',
			title: t('userInsightsTable.briefcaseCompleted'),
			description: t('userInsightsTable.briefcaseCompletedDescription'),
			orderBy: 'briefcaseCompleted',
			align: 'left'
		},
		{
			id: 'totalActive',
			title: t('userInsightsTable.totalActive'),
			description: t('userInsightsTable.totalActiveDescription'),
			orderBy: 'totalActive',
			align: 'left'
		},
		{
			id: 'total',
			title: t('userInsightsTable.total'),
			description: t('userInsightsTable.totalDescription'),
			orderBy: 'total',
			align: 'left'
		},
		{
			id: 'skillsChecklistCompleted',
			title: t('userInsightsTable.skillsChecklistCompleted'),
			description: t('userInsightsTable.skillsChecklistCompletedDescription'),
			orderBy: 'skillsChecklistCompleted',
			align: 'left'
		}
	];

	useEffect(() => {
		const created = data?.created || NO_DATA;
		const activated = data?.activated || NO_DATA;
		const licensed = data?.licensed || NO_DATA;
		const completed = data?.completed || NO_DATA;
		const skillsChecklisCompleted = data?.skillsChecklistCompleted || NO_DATA;

		const week1: UserInsightsData = {
			week: t('ThisWeek'),
			notActivated:
				created.thisWeek - (activated.thisWeek + licensed.thisWeek + completed.thisWeek),
			activatedOnly: activated.thisWeek,
			licenseVerified: licensed.thisWeek,
			briefcaseCompleted: completed.thisWeek,
			skillsChecklistCompleted: skillsChecklisCompleted.thisWeek === 0 ? '-' : skillsChecklisCompleted.thisWeek,
			totalActive: activated.thisWeek + licensed.thisWeek + completed.thisWeek,
			total: created.thisWeek,
			timeFrame: TimeFrame.thisWeek,
			type: RowType.userInsights
		};

		// week1.week = (<div>{ t('ThisWeek')} <IconButton	color="inherit" edge="end" onClick={() => handleExport(columns, [week1])} ><DownloadIcon />	</IconButton> </div>)

		const week2: UserInsightsData = {
			week: t('LastWeek'),
			notActivated:
				created.lastWeek - (activated.lastWeek + licensed.lastWeek + completed.lastWeek),
			activatedOnly: activated.lastWeek,
			licenseVerified: licensed.lastWeek,
			briefcaseCompleted: completed.lastWeek,
			skillsChecklistCompleted: skillsChecklisCompleted.lastWeek === 0 ? '-' : skillsChecklisCompleted.lastWeek,
			totalActive: activated.lastWeek + licensed.lastWeek + completed.lastWeek,
			total: created.lastWeek,
			timeFrame: TimeFrame.lastWeek,
			type: RowType.userInsights
		};

		const allTime: UserInsightsData = {
			week: t('AllTime'),
			notActivated:
				created.allTime - (activated.allTime + licensed.allTime + completed.allTime),
			activatedOnly: activated.allTime,
			licenseVerified: licensed.allTime,
			briefcaseCompleted: completed.allTime,
			skillsChecklistCompleted: skillsChecklisCompleted.allTime === 0 ? '-' : skillsChecklisCompleted.allTime,
			totalActive: activated.allTime + licensed.allTime + completed.allTime,
			total: created.allTime,
			timeFrame: TimeFrame.allTime,
			type: RowType.userInsights
		};

		setRows([week1, week2, allTime]);
	}, [t, data]);

	function handleExport() {
		downloadFile('user insights.csv', 'text/csv;charset=utf-8;', toCsv(columns, rows));
	}

	return (
		<Paper sx={{position: 'relative'}}>
			<Title>
				{t('userInsightsTable.title')}
				<IconButton size='large' edge='end' color='inherit' onClick={handleExport}>
					<DownloadIcon />
				</IconButton>
			</Title>
			<DataTableWithDownloadIcon id="week" columns={columns} rows={rows} organizationId={organizationId} />
			<LimitedBackdrop open={loading} />
		</Paper>
	);
}
