import React from 'react';
import { useApi, useToaster } from '@praos-health/ui';
import { useAuth } from '@praos-health/ui-security';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, Grid, InputLabel, Paper, Select, SelectChangeEvent } from '@mui/material';
import {
	buildSpecialtyPieChartItems,
	SpecialtyPieChart,
	SpecialtyPieChartItem,
	SpecialtyTable,
	SpecialtyTableRow,
	CompactLicenseSummary,
	CompactLicenseSummaryData,
	buildLicenseBreakdownItems,
	LicenseBreakdownItem,
	LicenseBreakdownPieChart,
	buildSpecialtyTableRows
} from '../../components';
import { StatisticsSummary, StatisticsSummaryData } from '@praos-health/web/components/statistics-summary';
import { Organization } from '@praos-health/organizations-client';
import { StatisticsType as ProfessionalStatisticsType, StatisticsOptions } from '@praos-health/professional-client';
import { AppApi } from '../../app-api';
import { StatisticsType as NursysStatisticsType } from '@praos-health/nursys-service-client';
export * from './credential-metrics-page-toolbar';

export function CredentialMetricsPage(): JSX.Element {
	const api = useApi<AppApi>();
	const auth = useAuth();
	const { t } = useTranslation();
	const toast = useToaster();
	const [isLoading, setIsLoading] = useState(false);
	const [organizationId, setOrganizationId] = useState('all');
	const [organizations, setOrganizations] = useState<Organization[]>([]);
	const [verifiedStatistics, setVerifiedStatistics] = useState<StatisticsSummaryData>({
		thisWeek: 0,
		lastWeek: 0,
		thisMonth: 0,
		lastMonth: 0
	});
	const [addedStatistics, setAddedStatistics] = useState<StatisticsSummaryData>({
		thisWeek: 0,
		lastWeek: 0,
		thisMonth: 0,
		lastMonth: 0
	});
	const [updatedStatistics, setUpdatedStatistics] = useState<StatisticsSummaryData>({
		thisWeek: 0,
		lastWeek: 0,
		thisMonth: 0,
		lastMonth: 0
	});
	const [licenseBreakdownStatistics, setLicenseBreakdownStatistics] = useState<
		LicenseBreakdownItem[]
	>([]);
	const [compactLicenseStatistics, setCompactLicenseStatistics] =
		useState<CompactLicenseSummaryData>({ count: 0, compactLicense: 0 });
	const [bySpecialtyRows, setBySpecialtyRows] = useState<SpecialtyTableRow[]>([]);
	const [bySpecialtyStatistics, setBySpecialtyStatistics] = useState<SpecialtyPieChartItem[]>([]);

	useEffect(() => {
		async function loadOrganizations(): Promise<void> {
			if (auth.session?.organization) {
				setOrganizations([
					{
						_id: auth.session.organization._id,
						name: auth.session.organization.name
					}
				]);
				return;
			}

			const result = await api.organizationService.list(auth.session?.auth || '', {
				select: { name: 1 },
				status: 'APPROVED'
			});

			setOrganizations(result.list);
		}

		loadOrganizations().catch(async (e) => {
			if (e instanceof Error && (e.name === 'UnauthorizedError' || e.message === 'Unauthorized')) {
				toast('Your session has expired', 'error');
				return await auth.signout();
			}
			toast(e, 'error');
		});
	}, [api, auth, toast]);

	useEffect(() => {
		async function loadStatistics(): Promise<void> {
			const options: StatisticsOptions = {
				useIsoWeek: true
			};

			if (organizationId === 'praos') {
				options.isMarketplace = true;
			} else if (organizationId !== 'all') {
				options.organization = organizationId;
			}

			const [result, credentialStats] = await Promise.all([
				api.professionalService.statistics(
					auth.session?.auth || '',
					ProfessionalStatisticsType.Credentials,
					options
				),
				api.nursysService.statistics(
					auth.session?.auth || '',
					NursysStatisticsType.Credentials,
					options.organization ?
						{ organization: options.organization } :
						((options.isMarketplace) ? { isMarketplaceOnly: true } : undefined)
				)
			]);
			setLicenseBreakdownStatistics(buildLicenseBreakdownItems(t, result));
			setCompactLicenseStatistics({
				count: result.userCount,
				compactLicense: result.usersWithCompactLicense
			});
			setVerifiedStatistics(credentialStats.verified);
			setAddedStatistics(credentialStats.added);
			setUpdatedStatistics(credentialStats.updated);
			setBySpecialtyStatistics(buildSpecialtyPieChartItems(result.bySpecialty));
			setBySpecialtyRows(buildSpecialtyTableRows(result.bySpecialty));
		}

		setIsLoading(true);

		loadStatistics()
			.catch(async (e) => {
				if (e instanceof Error && (e.name === 'UnauthorizedError' || e.message === 'Unauthorized')) {
					toast('Your session has expired', 'error');
					return await auth.signout();
				}
				toast(e, 'error');
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [api, auth, t, organizationId, toast]);

	const handleChange = async (e: SelectChangeEvent<string>) => {
		setOrganizationId(e.target.value as string);
	};

	return (
		<Grid container spacing={2}>
			{!auth.session?.organization && (
				<Grid item xs={12}>
					<Paper>
						<FormControl variant="standard" sx={(theme) => ({
							margin: theme.spacing(1),
							minWidth: 120
						})}>
							<InputLabel>{t('Organization')}</InputLabel>
							<Select
								variant="standard"
								native
								value={organizationId}
								onChange={handleChange}
								inputProps={{
									name: 'organization'
								}}
							>
								<option aria-label="None" value="all">
									{t('All')}
								</option>
								<option aria-label="PraosHealth" value="praos">
									Praos Health
								</option>
								{organizations.map((value) => {
									return (
										<option key={value._id} value={value._id}>
											{value.name}
										</option>
									);
								})}
							</Select>
						</FormControl>
					</Paper>
				</Grid>
			)}
			<Grid item xs={12} md={6}>
				<Paper>
					<StatisticsSummary
						title={t('LicensesVerified')}
						data={verifiedStatistics}
						loading={isLoading}
					/>
				</Paper>
			</Grid>
			<Grid item xs={12} md={6}>
				<Paper>
					<StatisticsSummary
						title={t('LicensesAdded')}
						data={addedStatistics}
						loading={isLoading}
					/>
				</Paper>
			</Grid>
			<Grid item xs={12} md={6}>
				<Paper>
					<StatisticsSummary
						title={t('LicensesUpdated')}
						data={updatedStatistics}
						loading={isLoading}
					/>
				</Paper>
			</Grid>
			<Grid item xs={12} md={6}></Grid>
			<Grid item xs={12} md={6}>
				<Paper>
					<LicenseBreakdownPieChart
						title={t('licenseBreakDownChart.title')}
						description={t('licenseBreakDownChart.description')}
						height={500}
						loading={isLoading}
						data={licenseBreakdownStatistics}
					/>
				</Paper>
			</Grid>
			<Grid item xs={12} md={6}>
				<Paper>
					<CompactLicenseSummary
						title={t('CompactLicense')}
						loading={isLoading}
						data={compactLicenseStatistics}
					/>
				</Paper>
			</Grid>
			<Grid item xs={12} md={6}>
				<Paper>
					<SpecialtyTable loading={isLoading} data={bySpecialtyRows} />
				</Paper>
			</Grid>
			<Grid item xs={12} md={6}>
				<Paper>
					<SpecialtyPieChart
						title={t('Specialties')}
						height={500}
						loading={isLoading}
						data={bySpecialtyStatistics}
					/>
				</Paper>
			</Grid>
		</Grid>
	);
}
