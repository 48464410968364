import { clone } from "@praos-health/core/utilities/object";
import { Organization } from "./organization";
import { SystemName } from "./system";
import { AnyTask, parseTask } from "./task-type";
import { Task } from "./task";

export interface Screening {
	_id?: string,
	organization?: Organization,
	system?: SystemName,
	applicantId?: any,
	applicantIntId?: number,
	tasks: AnyTask[],
	createdBy?: any,
	createdAt?: Date
	suspendedAt?: Date,
	deactivatedAt?: Date,
	updatedAt?: Date,
	nursys?: NursysInformation,
	csv?: string
}

export interface NursysInformation {
	isEnabled?: boolean,
	nextRunDate?: Date,
	lastRunDate?: Date
}

export type FlatScreening<T extends Task = Task> = Omit<Screening, 'tasks'> & { task: T };

export function parseScreening<T extends Task = Task>(screening: FlatScreening<T>): FlatScreening<T>;
export function parseScreening(screening: Screening): Screening;
export function parseScreening<T extends Task = Task>(screening: Screening | FlatScreening<T>): Screening | FlatScreening<T>;
export function parseScreening<T extends Task = Task>(screening: Screening | FlatScreening<T>): Screening | FlatScreening<T> {
	if (screening.createdAt) {
		screening.createdAt = new Date(screening.createdAt);
	}

	if (screening.updatedAt) {
		screening.updatedAt = new Date(screening.updatedAt);
	}

	if (screening.deactivatedAt) {
		screening.deactivatedAt = new Date(screening.deactivatedAt);
	}

	if (screening.suspendedAt) {
		screening.suspendedAt = new Date(screening.suspendedAt);
	}

	if (screening.nursys) {
		if (screening.nursys.nextRunDate) {
			screening.nursys.nextRunDate = new Date(screening.nursys.nextRunDate);
		}	
	}

	if ('tasks' in screening) {
		if (screening.tasks?.length) {
			for (let i = 0; i < screening.tasks.length; i++) {
				screening.tasks[i] = parseTask(screening.tasks[i]);
			}
		}	
	} else {
		screening.task = parseTask(screening.task);
	}

	return screening;
}

export function flatten<T extends Task = Task>(screening: Screening): FlatScreening<T>[];
export function flatten<T extends Task = Task>(screening: Screening, index: number): FlatScreening<T>;
export function flatten<T extends Task = Task>(screening: Screening, index?: number): FlatScreening<T>[] | FlatScreening<T> {
	const allTasks = screening.tasks as Task[];

	try {
		const tasks = (index !== undefined) ? screening.tasks as T[] : [screening.tasks[index] as T];
		const result: FlatScreening<T>[] = [];
		delete screening.tasks;

		for (const task of tasks) {
			const flat = clone(screening) as any as FlatScreening<T>;
			flat.task = clone<T>(task);

			result.push(flat);
		}

		return (index !== undefined) ? result : result[0];
	} catch (err) {
		throw err;			
	} finally {
		screening.tasks = allTasks;
	}
}