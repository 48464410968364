import { parseLicense, License, LicenseHistory, parseLicenseHistory, prepLicense } from "./license";
import { Certification, CertificationHistory, parseCertification, parseCertificationHistory, prepCertification } from "./certification";
import { parseAdditionalCertification, parseAdditionalCertificationHistory, AdditionalCertification, AdditionalCertificationHistory, prepAdditionalCertification } from "./additional-certification";
import { Education, EducationDocument, parseContinuingEducation, ContinuingEducation } from "./education";
import { WorkExperience, parseWorkExperience, prepWorkExperience } from "./work-experience";
import { Competency, parseCompetency } from "./competency";
import { Reference } from "./reference";
import { HealthDocument, parseHealthDocument } from "./health-document";
import { ActivityWork, parseActivityWork } from "./activity-work";
import { OrganizationMembership, parseOrganizationMembership } from "./organization-membership";
import { Facility } from "./facility";
import { BriefcaseSummary } from "./briefcase-summary";
import { isEmptyObject } from "@praos-health/core/utilities/object";

export type Briefcase = {
	nursys?: {
		isEnabled?: boolean,
		nextRunAt?: Date,
		verificationText?: string
	},

	currentStep?: number,
	consentedAt?: Date | true,
	completedAt?: Date,

	licensedAt?: Date,
	licenses?: License[],
	licenseHistory?: LicenseHistory[],
	specialties?: string[],
	certifications?: Certification[],
	certificationHistory?: CertificationHistory[],
	additionalCertifications?: AdditionalCertification[],
	additionalCertificationHistory?: AdditionalCertificationHistory[],

	facilities?: Facility[],

	w9?: string,
	references?: Reference[],
//	skillsChecklists?: SkillsChecklist[],

	educationLevel?: string,
	education?: Education[],
	educationDocuments?: EducationDocument[],
	continuingEducation?: ContinuingEducation[],
	workExperience?: WorkExperience,
	yearsOfExperience?: string,
	competencies?: Competency[],
	organizationMembership?: OrganizationMembership[],
	activityWork?: ActivityWork[],
	ehrSkills?: string[],

	healthDocuments?: HealthDocument[],
	personalStatement?: string,

	summary?: BriefcaseSummary
}

export enum BriefcaseField {
	ActivityWork = 'activitywork',
	AdditionalCertifications = 'additionalcertifications',
	Certifications = 'certifications',
	Competencies = 'competencies',
	ContinuingEducation = 'continuingeducation',
	Education = 'education',
	EducationDocuments = 'educationdocuments',
	Facilities = 'facilities',
	Licenses = 'licenses',
	OrganizationMembership = 'organizationmembership',
	References = 'references'
}

export type BriefcaseItem = AdditionalCertification | Certification | License;

export function prepBriefcaseItem(field: BriefcaseField, item: BriefcaseItem): BriefcaseItem {
	switch (field) {
		case BriefcaseField.AdditionalCertifications:
			prepAdditionalCertification(item);
			break;
		case BriefcaseField.Certifications:
			prepCertification(item);
			break;
		case BriefcaseField.Licenses:
			prepLicense(item);
			break;
		default:
			break;
	}

	return item;
}

export function prepBriefcase(item: Briefcase): Briefcase {
	if (item.licenses?.length) {
		for (let i = 0; i < item.licenses.length; i++) {
			if (isEmptyObject(item.licenses[i] = prepLicense(item.licenses[i]), true)) {
				item.licenses.splice(i, 1);
				i--;
			}
		}
	}

	if (item.certifications?.length) {
		for (let i = 0; i < item.certifications.length; i++) {
			if (isEmptyObject(item.certifications[i] = prepCertification(item.certifications[i]), true)) {
				item.certifications.splice(i, 1);
				i--;
			}
		}
	}

	if (item.additionalCertifications?.length) {
		for (let i = 0; i < item.additionalCertifications.length; i++) {
			if (isEmptyObject(item.additionalCertifications[i] = prepAdditionalCertification(item.additionalCertifications[i]), true)) {
				item.additionalCertifications.splice(i, 1);
				i--;
			}
		}
	}

	if (item.continuingEducation) {
		delete item.continuingEducation;
	}

	if (item.workExperience) {
		item.workExperience = prepWorkExperience(item.workExperience);

		if (isEmptyObject(item.workExperience)) {
			delete item.workExperience;
		}
	}

	if (item.activityWork) {
		delete item.activityWork;
	}

	if (item.competencies) {
		delete item.competencies;
	}

	if (item.facilities) {
		delete item.facilities;
	}

	if (item.references) {
		delete item.references;
	}

	if (item.organizationMembership) {
		delete item.organizationMembership;
	}

	if (item.educationDocuments) {
		delete item.educationDocuments;
	}

	if (item.education) {
		delete item.education;
	}

	return item;
}

export function parseBriefcase(json: any): Briefcase {
	if (json.nursys?.nextRunAt) {
		json.nursys.nextRunAt = new Date(json.nursys.nextRunAt);
	}

	if (json.completedAt) {
		json.completedAt = new Date(json.completedAt);
	}

	if (json.consentedAt) {
		json.consentedAt = new Date(json.consentedAt);
	}

	if (json.licensedAt) {
		json.licensedAt = new Date(json.licensedAt);
	}

	if (json.licenses?.length) {
		for (let i = 0; i < json.licenses.length; i++) {
			json.licenses[i] = parseLicense(json.licenses[i]);
		}
	}

	if (json.licenseHistory?.length) {
		for (let i = 0; i < json.licenseHistory.length; i++) {
			json.licenseHistory[i] = parseLicenseHistory(json.licenseHistory[i]);
		}
	}

	if (json.certifications?.length) {
		for (let i = 0; i < json.certifications.length; i++) {
			json.certifications[i] = parseCertification(json.certifications[i]);
		}
	}

	if (json.certificationHistory?.length) {
		for (let i = 0; i < json.certificationHistory.length; i++) {
			json.certificationHistory[i] = parseCertificationHistory(json.certificationHistory[i]);
		}
	}

	if (json.additionalCertifications?.length) {
		for (let i = 0; i < json.additionalCertifications.length; i++) {
			json.additionalCertifications[i] = parseAdditionalCertification(json.additionalCertifications[i]);
		}
	}

	if (json.additionalCertificationHistory?.length) {
		for (let i = 0; i < json.additionalCertificationHistory.length; i++) {
			json.additionalCertificationHistory[i] = parseAdditionalCertificationHistory(json.additionalCertificationHistory[i]);
		}
	}

	if (json.continuingEducation) {
		for (let i = 0; i < json.continuingEducation.length; i++) {
			json.continuingEducation[i] = parseContinuingEducation(json.continuingEducation[i]);
		}
	}

	if (json.workExperience) {
		json.workExperience = parseWorkExperience(json.workExperience);
	}

	if (json.competencies) {
		for (let i = 0; i < json.competencies.length; i++) {
			json.competencies[i] = parseCompetency(json.competencies[i]);
		}
	}

	if (json.healthDocuments) {
		for (let i = 0; i < json.healthDocuments.length; i++) {
			json.healthDocuments[i] = parseHealthDocument(json.healthDocuments[i]);
		}
	}

	if (json.activityWork) {
		for (let i = 0; i < json.activityWork.length; i++) {
			json.activityWork[i] = parseActivityWork(json.activityWork[i]);
		}
	}

	if (json.organizationMembership) {
		for (let i = 0; i < json.organizationMembership.length; i++) {
			json.organizationMembership[i] = parseOrganizationMembership(json.organizationMembership[i]);
		}
	}

	return json;
}