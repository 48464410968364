import { ReportCallback, onCLS, onFCP, onFID, onLCP, onTTFB } from 'web-vitals';

function reportWebVitals(onPerfEntry?: ReportCallback): void {
	if (onPerfEntry && onPerfEntry instanceof Function) {
		onCLS(onPerfEntry);
		onFID(onPerfEntry);
		onFCP(onPerfEntry);
		onLCP(onPerfEntry);
		onTTFB(onPerfEntry);
	}
}

export default reportWebVitals;
