import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import AutoSizer from 'react-virtualized-auto-sizer';
import DownloadIcon from '@mui/icons-material/GetApp';
import { IconButton } from '@mui/material';
import { Line, Serie } from '@nivo/line';

import { useApi, useToaster } from '@praos-health/ui';
import { useAuth } from '@praos-health/ui-security';
import {
	NewLeadStatistics,
	StatisticsType,
	StatisticsOptions
} from '@praos-health/professional-client';
import { addDays, beginningOfWeek, getUtc } from '@praos-health/core/utilities/date';
import { toCsv, sanitizeFilename } from '@praos-health/core/utilities/string';

import { DateRange, getRange } from '@praos-health/web/components';
import { LimitedBackdrop } from '@praos-health/web/components/limited-backdrop';
import { InfoButtonPopper } from '@praos-health/web/components';
import { buildTickValues } from '../../nivo/axes';
import { downloadFile } from '../../common/download';
import { Title } from '@praos-health/web/components';
import { ChartWrapper, StyledDateRangeSelect, Toolbar } from '../shared-components';
import { AppApi } from '../../app-api';

export interface NewLeadLineData {
	dateRange: string,
	created: number
}

interface Props {
	title?: string,
	description?: string,
	height: number,
	organization?: string,
	initialDateRange?: DateRange
}

function buildSeries(
	t: (v: string) => string,
	stats: NewLeadStatistics[],
	startDate?: Date,
	endDate?: Date
): Serie[] {
	const createdSerie: Serie = { id: t('Created'), data: [] };
	const formatter = new Intl.DateTimeFormat();

	if (!startDate && stats.length) {
		startDate = new Date(stats[0].date.year, stats[0].date.month - 1, stats[0].date.day);
	}

	if (!endDate) {
		endDate = new Date();
	}

	if (startDate) {
		let date = beginningOfWeek(startDate, false, true);
		let i = 0;

		while (date < endDate) {
			let created = 0;

			while (i < stats.length) {
				const curDate = new Date(
					stats[i].date.year,
					stats[i].date.month - 1,
					stats[i].date.day
				);

				if (addDays(7, date) <= curDate) {
					break;
				}

				if (curDate <= addDays(7, date)) {
					created = stats[i].created;
				}

				i++;
			}

			const dateRange = `${formatter.format(date)} - ${formatter.format(addDays(6, date))}`;

			createdSerie.data.push({ x: dateRange, y: created });

			date = addDays(7, date);
		}
	}

	return [createdSerie];
}

export function NewLeadLine({
	title,
	description,
	height,
	organization,
	initialDateRange
}: Props): JSX.Element {
	const { t } = useTranslation();
	const api = useApi<AppApi>();
	const auth = useAuth();
	const toast = useToaster();
	const [isLoading, setIsLoading] = useState(false);
	const [dateRange, setDateRange] = useState(initialDateRange || DateRange.Year);
	const [data, setData] = useState<Serie[]>([]);

	const ledgend = [
		{
			id: 'created',
			label: t('Created'),
			color: '#000000'
		}
	];

	useEffect(() => {
		setIsLoading(true);

		async function loadChart(): Promise<void> {
			const range = getRange(dateRange);

			range.start = beginningOfWeek(range.start, false, true);

			const options: StatisticsOptions = {
				startDate: getUtc(range.start),
				endDate: getUtc(range.end),
				useIsoWeek: true
			};

			if (organization === 'praos') {
				options.isMarketplace = true;
			} else if (organization !== 'all') {
				options.organization = organization;
			}

			const result = await api.professionalService.statistics(
				auth.session?.auth || '',
				StatisticsType.NewLeads,
				options
			);

			setData(buildSeries(t, result, range.start, range.end));
		}

		loadChart()
			.catch((e) => {
				toast(e, 'error');
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [api, auth, organization, t, dateRange, toast]);

	function handleDateRangeChange(value: DateRange) {
		setDateRange(value);
	}

	function handleExport() {
		const formatter = new Intl.DateTimeFormat();
		const range = getRange(dateRange);

		range.start = beginningOfWeek(range.start, false, true);

		downloadFile(
			sanitizeFilename(
				`new leads ${formatter.format(range.start)} to ${formatter.format(range.end)}.csv`
			),
			'text/csv;charset=utf-8;',
			[toCsv([t('Date'), t('NewLeads')])]
				.concat(
					data[0].data.map((i) => toCsv([(i.x || '').toString(), (i.y || 0).toString()]))
				)
				.join('\r\n')
		);
	}

	return (
		<div>
			{(title || description) && (
				<Toolbar>
					<Title>{title}</Title>
					{description && <InfoButtonPopper text={description} />}
					<IconButton color="inherit" edge="end" onClick={handleExport} size="large">
						<DownloadIcon />
					</IconButton>
					<StyledDateRangeSelect
						value={dateRange}
						onChange={handleDateRangeChange}
					/>
				</Toolbar>
			)}
			<ChartWrapper>
				<AutoSizer disableHeight>
					{({ width }: { width: number }) =>
					width ? (
						<div style={{ width: `${width}px` }}>
							<Line
								data={data}
								margin={{ top: 50, right: 50, bottom: 120, left: 70 }}
								height={height}
								width={width}
								xScale={{ type: 'point' }}
								yScale={{ type: 'linear', min: 0, max: 'auto', reverse: false }}
								yFormat=" >-.2f"
								colors={ledgend.map((i) => i.color)}
								axisBottom={{
									tickSize: 5,
									tickPadding: 5,
									tickRotation: -60,
									legendOffset: 36,
									legendPosition: 'middle',
									tickValues: buildTickValues(data?.[0]?.data, 'x', width, 35)
								}}
								axisLeft={{
									tickSize: 5,
									tickPadding: 5,
									tickRotation: 0,
									legendOffset: -40,
									legendPosition: 'middle'
								}}
								pointLabelYOffset={-12}
								useMesh={true}
							/>
						</div>
					) : (
						<div>Loading...</div>
					)
					}
				</AutoSizer>
				<LimitedBackdrop open={isLoading} />
			</ChartWrapper>
		</div>
	);
}
