import React from 'react';
import { useSpring, animated } from 'react-spring';
import { useTheme, useMotionConfig } from '@nivo/core';

const FunnelLabel = (data: any) => {
	const theme = useTheme();
	const { animate: shouldAnimate, config: motionConfig } = useMotionConfig();

	const animatedProps = useSpring({
		transform: `translate(${data.part.x}, ${data.part.y})`,
		color: data.part.labelColor,
		config: motionConfig,
		immediate: !shouldAnimate
	});

	return (
		<animated.g transform={animatedProps.transform}>
			<animated.text
				textAnchor="middle"
				dominantBaseline="central"
				style={{
					...theme.labels.text,
					fill: animatedProps.color,
					pointerEvents: 'none',
					fontWeight: 600
				}}>
				{data.part.data.label}: {data.part.data.value}
			</animated.text>
		</animated.g>
	);
};

export function FunnelLabels(props: any) {
	return props.parts.map((part: any) => <FunnelLabel key={part.data.id} part={part} />);
}