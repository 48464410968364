import { AnyObject } from "@praos-health/core/utilities/object";
import { RecordType } from "./record-type";
import { User } from "./user";
import { Organization } from "./organization";
import { sanitizeFilename } from "@praos-health/core/utilities/string";

export enum RecordPermissions {
	UserRead = 1,
	UserWrite = 2,
	OrganizationRead = 4,
	OrganizationWrite = 8,
	AnyOrganizationRead = 16,
	AnyOrganizationWrite = 32,
	Public = 64
}

export interface Record<T = AnyObject> {
	_id?: any,
	user?: User,
	organization?: Organization,
	createdBy?: User,
	createdAt?: Date,
	updatedAt?: Date,
	completedAt?: Date,
	deletedAt?: Date,
	deletedBy?: User,
	recordType?: RecordType,
	attachments?: { [identifier: string]: RecordAttachment },
	data?: T,
	permissions?: RecordPermissions,
	tags?: { [key: string]: string }
}

export interface RecordAttachment {
	fileName: string,
	thumbnails?: { [size: string]: FileThumbnail }
}

export interface FileThumbnail {
	fileName: string,
	url: string
}

export function formatUserFilename(recordName: string, user?: { firstName?: string, lastName?: string }): string {
	return sanitizeFilename(
		[user?.firstName, user?.lastName, recordName].filter(Boolean).join('_').toLocaleLowerCase()
	);
}