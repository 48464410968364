import React from 'react';
import { Typography, Box } from '@mui/material';
import { LimitedBackdrop } from '@praos-health/web/components/limited-backdrop';

interface JobApplicationsProps {
	value: string | number,
	loading?: boolean
}

const JobApplications: React.FC<JobApplicationsProps> = ({ value, loading }) => {
	return (
		<Box position="relative">
			<Typography variant="h3" component="p" align="center" sx={{ fontWeight: 400, my: 2 }}>
				{loading ? '\u00A0' : value}
			</Typography>
			<LimitedBackdrop open={!!loading} />
		</Box>
	);
};

export default JobApplications;