
import { AppBar, Backdrop, IconButton, styled } from '@mui/material';

const drawerWidth = 240;

export const StyledRoot = styled('div')(() => ({
	display: 'flex'
}));

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
	[theme.breakpoints.up('sm')]: {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: drawerWidth
	}
}));

export const StyledMenuButton = styled(IconButton)(({ theme }) => ({
	marginRight: theme.spacing(2),
	[theme.breakpoints.up('sm')]: {
		display: 'none'
	}
}));

export const StyledLogOutButton = styled(IconButton)(() => ({
	marginLeft: 'auto'
}));

export const StyledNavDrawer = styled('nav')(({ theme }) => ({
	[theme.breakpoints.up('sm')]: {
		width: drawerWidth,
		flexShrink: 0
	}
}));

export const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
	zIndex: theme.zIndex.drawer + 1,
	color: '#fff'
}));

export const StyledToolbar = styled('div')(({ theme }) => (theme.mixins.toolbar));

export const StyledContent = styled('main')(({ theme }) => ({
	flexGrow: 1,
	padding: theme.spacing(3)
}));
