import React from 'react';
import { Choropleth } from '@nivo/geo';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useTranslation } from 'react-i18next';
import FEATURES from '../../nivo/geo/us';
import { getStateName } from '../../common/usStates';
import { LimitedBackdrop } from '@praos-health/web/components/limited-backdrop';
import {
	IconButton,
	FormControl,
	Grid,
	InputLabel,
	Select,
	SelectChangeEvent,
	styled
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useApi, useToaster } from '@praos-health/ui';
import { InfoButtonPopper } from '@praos-health/web/components';
import { Specialty } from '@praos-health/briefcase-client';
import {
	ByStateStatistics,
	JobType,
	StatisticsType as JobStatisticsType,
	StatisticsOptions as JobStatisticsOptions
} from '@praos-health/jobs-client';
import { beginningOfWeek, getUtc } from '@praos-health/core/utilities/date';
import { useAuth } from '@praos-health/ui-security';
import { getBounds, scaleAndTranslate } from '../../nivo/geo';
import { DateRange, getRange } from '@praos-health/web/components';
import { DataTable } from '../data-table';
import DownloadIcon from '@mui/icons-material/GetApp';
import { toCsv, sanitizeFilename } from '@praos-health/core/utilities/string';
import { downloadFile } from '../../common/download';
import { Title } from '@praos-health/web/components/title';
import { StyledDateRangeSelect, Toolbar } from '../shared-components';
import { AppApi } from '../../app-api';

interface JobMapData {
	id: string,
	value: number,
	label?: string
}

interface Props {
	title?: string,
	description?: string,
	organization?: string,
	initialDateRange?: DateRange,
	height: number
}

const Main = styled('div')(() => ({
	position: 'relative'
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
	margin: theme.spacing(1),
	minWidth: 120
}));

const StyledFormControlContainer = styled('div')(() => ({
	float: 'right'
}));

export function JobApplicationMap({
	title,
	description,
	height,
	organization,
	initialDateRange
}: Props): JSX.Element {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);
	const [maxDomain, setMaxDomain] = useState(1000000);
	const [jobType, setJobType] = useState<JobType | 'all'>('all');
	const [specialties, setSpecialties] = useState<Specialty[]>([]);
	const [specialty, setSpecialty] = useState('');
	const [byState, setByState] = useState<ByStateStatistics[]>();
	const [data, setData] = useState<JobMapData[]>([]);
	const [dateRange, setDateRange] = useState(initialDateRange || DateRange.Year);
	const api = useApi<AppApi>();
	const auth = useAuth();
	const toast = useToaster();

	useEffect(() => {
		setIsLoading(true);

		async function loadSpecialties(): Promise<void> {
			const result = await api.specialtyService.list(auth.session?.auth || '');

			setSpecialties(result);
		}

		loadSpecialties()
			.catch((e) => {
				toast(e, 'error');
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [api, auth, toast]);

	useEffect(() => {
		const data: JobMapData[] = [];

		if (byState) {
			const jobData: { [state: string]: number } = {};

			if (jobType) {
				for (const item of byState) {
					let value = 0;

					switch (jobType) {
						case JobType.Contract:
							value = item.contract;
							break;
						case JobType.PerDiem:
							value = item.perDiem;
							break;
						case JobType.Permanent:
							value = item.permanent;
							break;
						case JobType.Travel:
							value = item.travel;
							break;
						default:
							value = item.count;
							break;
					}

					if (value > 0) {
						jobData[item.state] = value;
					}
				}
			}

			for (const state in jobData) {
				data.push({ id: state, value: jobData[state] });
			}
		}

		setMaxDomain(Math.max(...data.map((i) => i.value)));
		data.sort((a, b) => b.value - a.value);

		setData(data);
	}, [t, jobType, byState]);

	useEffect(() => {
		setIsLoading(true);

		async function loadChart(): Promise<void> {
			const range = getRange(dateRange);
			const jobOptions: JobStatisticsOptions = {
				startDate: getUtc(range.start),
				endDate: getUtc(range.end)
			};

			if (organization === 'praos') {
				jobOptions.isMarketplace = true;
			} else if (organization !== 'all') {
				jobOptions.organization = organization;
			}

			if (specialty) {
				jobOptions.specialty = specialty;
			}

			const result = await api.jobService.statistics(
				auth.session?.auth || '',
				JobStatisticsType.ApplicationsByState,
				jobOptions
			);

			setByState(result);
		}

		loadChart()
			.catch((e) => {
				toast(e, 'error');
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [api, auth, dateRange, organization, specialty, toast]);

	// async function handleJobTypeChange (e: React.ChangeEvent<{ name?: string; value: unknown }>) {
	// 	setJobType(e.target.value as JobType | 'all');
	// }

	// async function handleSpecialtyChange(e: React.ChangeEvent<{ name?: string; value: unknown }>) {
	// 	setSpecialty(e.target.value as string);
	// }

	const handleJobTypeChange = (e: SelectChangeEvent<JobType | 'all'>) => {
		setJobType(e.target.value as JobType | 'all');
	};

	const handleSpecialtyChange = (e: SelectChangeEvent<string>) => {
		setSpecialty(e.target.value as string);
	};

	function handleDateRangeChange(value: DateRange) {
		setDateRange(value);
	}

	function formatValue(v: number): string | number {
		if (v === Infinity || v === -Infinity) {
			return 0;
		}

		if (v > 1000000) {
			return `${Math.round(v / 1000000)}mm`;
		}

		if (v > 1000) {
			return `${Math.round(v / 1000)}k`;
		}

		return Math.round(v);
	}

	function handleExport() {
		const formatter = new Intl.DateTimeFormat();
		const range = getRange(dateRange);

		range.start = beginningOfWeek(range.start, false, true);

		downloadFile(
			sanitizeFilename(
				`jobs applications ${formatter.format(range.start)} to ${formatter.format(
					range.end
				)} ${t('JobType')} ${jobType || 'none'} ${t('Specialty')} ${
					specialty || 'none'
				}.csv`
			),
			'text/csv;charset=utf-8;',
			[toCsv([t('State'), t('JobApplications')])]
				.concat(
					data
						.map((i) => ({ ...i, id: getStateName(i.id) }))
						.map((i) => toCsv([i.id, i.value.toString()]))
				)
				.join('\r\n')
		);
	}

	return (
		<Main>
			{title && (
				<Toolbar>
					<Title>{title}</Title>
					{description && <InfoButtonPopper text={description} />}
					<IconButton color="inherit" edge="end" onClick={handleExport} size="large">
						<DownloadIcon />
					</IconButton>
					<StyledFormControlContainer>
						<StyledFormControl variant="standard">
							<InputLabel>{t('JobType')}</InputLabel>
							<Select
								variant="standard"
								native
								value={jobType}
								onChange={handleJobTypeChange}
								inputProps={{
									name: 'jobType'
								}}
							>
								<option key="all" value="all">
									{t('All')}
								</option>
								<option key={JobType.Contract} value={JobType.Contract.toString()}>
									{t('Contract')}
								</option>
								<option key={JobType.PerDiem} value={JobType.PerDiem.toString()}>
									{t('PerDiem')}
								</option>
								<option
									key={JobType.Permanent}
									value={JobType.Permanent.toString()}
								>
									{t('Permanent')}
								</option>
								<option key={JobType.Travel} value={JobType.Travel.toString()}>
									{t('Travel')}
								</option>
							</Select>
						</StyledFormControl>
						<StyledFormControl variant="standard">
							<InputLabel>{t('Specialty')}</InputLabel>
							<Select
								variant="standard"
								native
								value={specialty}
								onChange={handleSpecialtyChange}
								inputProps={{
									name: 'specialty'
								}}
							>
								<option aria-label="None" value="" />
								{specialties.map((value) => {
									return (
										<option key={value._id} value={value.name}>
											{value.name}
										</option>
									);
								})}
							</Select>
						</StyledFormControl>
						<StyledDateRangeSelect
							displayWeeks
							value={dateRange}
							onChange={handleDateRangeChange}
						/>
					</StyledFormControlContainer>
				</Toolbar>
			)}
			<Grid container spacing={2}>
				<Grid item xs={12} md={3}>
					<DataTable
						id="id"
						rows={data}
						columns={[
							{ id: 'id', title: t('State'), align: 'left' },
							{ id: 'value', title: t('Count'), align: 'left' }
						]}
					/>
				</Grid>
				<Grid item xs={12} md={9}>
					<AutoSizer disableHeight>
						{({ width }: { width: number }) => {
							width = width || 0;
							const [scale, translate] = scaleAndTranslate(
								width,
								height,
								getBounds(FEATURES)
							);

							return (
								<div style={{ width: `${width}px` }}>
									<Choropleth
										data={data}
										features={FEATURES}
										height={height}
										width={width}
										margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
										colors="blues"
										domain={[0, maxDomain]}
										unknownColor="#AAAEB0"
										label="properties.name"
										projectionScale={scale}
										projectionTranslation={translate}
										valueFormat={formatValue}
										tooltip={({ feature }) => {
											return !feature.data ? null : (
												<div
													style={{
														background: 'white',
														padding: '9px 12px',
														border: '1px solid #ccc',
														borderRadius: '25px',
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center',
														flexDirection: 'column',
														color: 'black'
													}}
												>
													{`${feature.label}: ${
														feature.data.label === undefined
															? feature.value
															: feature.data.label
													}`}
												</div>
											);
										}}
										legends={[
											{
												anchor: 'top-right',
												direction: 'column',
												justify: true,
												translateX: -40,
												translateY: 40,
												itemsSpacing: 0,
												itemWidth: 94,
												itemHeight: 18,
												itemDirection: 'left-to-right',
												itemTextColor: '#444444',
												itemOpacity: 0.85,
												symbolSize: 18,
												effects: [
													{
														on: 'hover',
														style: {
															itemTextColor: '#000000',
															itemOpacity: 1
														}
													}
												]
											}
										]}
										projectionRotation={[0, 0, 0]}
										graticuleLineColor="#dddddd"
										borderWidth={0.5}
										borderColor="#152538"
									/>
								</div>
							);
						}}
					</AutoSizer>
				</Grid>
			</Grid>
			<LimitedBackdrop open={isLoading} />
		</Main>
	);
}
