import React from 'react';
import { DataTable } from '../data-table';
import { useTranslation } from 'react-i18next';
import { LimitedBackdrop } from '@praos-health/web/components/limited-backdrop';
import { IconButton, Paper, styled } from '@mui/material';
import DownloadIcon from '@mui/icons-material/GetApp';
import { downloadFile } from '../../common/download';
import { useApi } from '@praos-health/ui';
import { useAuth } from '@praos-health/ui-security';
import { DataTableColumn } from '@praos-health/web/components/data-table';
import { AppApi } from '../../app-api';
import { StatisticsCsvDownloadOptions, StatisticsType } from '@praos-health/professional-client';
import { applyIfDefined } from '@praos-health/core/utilities/object';

export interface CsvDownload {
	fileName: string,
	csv: string
}

export interface RecruiterMetricsData {
	recruiter: string,
	email: string,
	count: number,
	activated: number,
	licensed: number,
	completed: number,
	applications: number,
	submissions: number,
	confirmations: number,
	department?: string
}

interface Props {
	title: string,
	data?: RecruiterMetricsData[],
	loading: boolean,
	organizationId?: string
}

const Title = styled('h3')(({ theme }) => ({
	paddingLeft: theme.spacing(2)
}));

export function RecruiterMetricsTable({ title, data, loading, organizationId }: Props) {
	const { t } = useTranslation();
	const api = useApi<AppApi>();
	const auth = useAuth();
	const columns: DataTableColumn<RecruiterMetricsData>[] = [
		{
			id: 'recruiter',
			title: t('Recruiter'),
			orderBy: 'recruiter',
			align: 'left'
		},
		{
			id: 'email',
			title: t('Email'),
			orderBy: 'email',
			align: 'left'
		},
		{
			id: 'activated',
			title: t('Activated'),
			description: t('ActivatedDescription'),
			orderBy: 'activated',
			align: 'left'
		},
		{
			id: 'licensed',
			title: t('Licensed'),
			description: t('LicensedDescription'),
			orderBy: 'licensed',
			align: 'left'
		},
		{
			id: 'completed',
			title: t('Completed'),
			description: t('CompletedDescription'),
			orderBy: 'completed',
			align: 'left'
		},
		{
			id: 'applications',
			title: t('Applications'),
			description: t('ApplicationsDescription'),
			orderBy: 'applications',
			align: 'left'
		},
		{
			id: 'submissions',
			title: t('Submissions'),
			description: t('SubmissionsDescription'),
			orderBy: 'submissions',
			align: 'left'
		},
		{
			id: 'confirmations',
			title: t('Confirmations'),
			description: t('ConfirmationsDescription'),
			orderBy: 'confirmations',
			align: 'left'
		}
	];

	function handleExport() {
		const options: StatisticsCsvDownloadOptions = { download: true };
		applyIfDefined(options, { organization: organizationId });
		api.professionalService.statistics(
			auth.session?.auth || '',
			StatisticsType.Recruiter,
			options
		).then((stats) => {
			downloadFile(stats.fileName, 'text/csv;charset=utf-8;', stats.csv);
		});
	}
	return (
		<Paper sx={{position: 'relative'}}>
			<Title>
				{title}
				<IconButton color="inherit" edge="end" onClick={handleExport} size="large">
					<DownloadIcon />
				</IconButton>
			</Title>
			<DataTable
				id='recruiters'
				columns={columns}
				rows={data || []}
				stickyHeader={true}
			/>
			<LimitedBackdrop open={loading}/>
		</Paper>
	);
}
