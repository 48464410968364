import React from 'react';
import CredentialingPageLayout from '../../layouts/credentialing-layout/navigation';
export * from './credentialing-page-toolbar';

export function CredentialingPage(): JSX.Element {
	return (
		<div>
			<CredentialingPageLayout/>
		</div>
	);
}
