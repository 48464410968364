import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@praos-health/ui-security';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';
import MenuIcon from '@mui/icons-material/Menu';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import { Backdrop, CircularProgress, Tooltip } from '@mui/material';
import { LogoutDialog } from '@praos-health/web/components';
import { Navigation } from './components';
import {
	StyledAppBar,
	StyledContent,
	StyledLogOutButton,
	StyledMenuButton,
	StyledNavDrawer,
	StyledRoot,
	StyledToolbar
} from './main-layout.styles';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
	CredentialMetricsPageToolbar,
	DashboardPageToolbar,
	JobMetricsPageToolbar,
	RecruiterMetricsPageToolbar,
	CredentialingPageToolbar,
	UserEngagementPageToolbar,
	UserInsightsPageToolbar
} from '../../pages';
import { BriefcaseStatsPageToolbar } from '../../pages/briefcase-stats-page';
import SessionDialogue from '@praos-health/web/components/session-timeout-dialog';
import { UserType, isClientUser } from '@praos-health/users-client';

function MainLayout() {
	const theme = useTheme();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [isMobileOpen, setIsMobileOpen] = useState(false);
	const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState(false);
	const [isLoading, setIsloading] = useState(false);
	const location = useLocation();
	const auth = useAuth();

	useEffect(()=>{
		if (isClientUser(auth?.session?.user?.userType as UserType) || !auth) {
			navigate('/login');
		}
	}, [auth, navigate]);

	function handleDrawerToggle() {
		setIsMobileOpen(!isMobileOpen);
	}

	function handleLogout() {
		setIsLogoutDialogOpen(true);
	}

	function handleCancelLogout() {
		setIsLogoutDialogOpen(false);
	}

	async function handleConfirmLogout() {
		setIsLogoutDialogOpen(false);
		setIsloading(true);

		try {
			await auth.signout();
		} catch (err) {
			console.log(err);
		} finally {
			window.location.reload();
		}
	}

	const container =
		window !== undefined ? () => window.document.body : undefined;

	let toolbar;
	switch (location.pathname) {
		case '/':
			toolbar = <DashboardPageToolbar />;
			break;
		case '/credentialmetrics':
			toolbar = <CredentialMetricsPageToolbar />;
			break;
		case '/jobmetrics':
			toolbar = <JobMetricsPageToolbar />;
			break;
		case '/userinsights':
			toolbar = <UserInsightsPageToolbar />;
			break;
		case '/userengagement':
			toolbar = <UserEngagementPageToolbar />;
			break;
		case '/briefcasestatistics':
			toolbar = <BriefcaseStatsPageToolbar />;
			break;
		case '/recruitermetrics':
			toolbar = <RecruiterMetricsPageToolbar />;
			break;
		case '/credentialing/tasks':
			toolbar = <CredentialingPageToolbar />;
			break;
		case '/credentialing/audit-logs':
			toolbar = <CredentialingPageToolbar />;
			break;
	}

	return (
		<StyledRoot>
			<SessionDialogue />
			<CssBaseline />
			<StyledAppBar position="fixed">
				<Toolbar>
					<StyledMenuButton
						color="inherit"
						aria-label="open drawer"
						edge="start"
						onClick={handleDrawerToggle}
						size="large"
					>
						<MenuIcon />
					</StyledMenuButton>
					{toolbar}
					<Tooltip title={t('LogOut')}>
						<StyledLogOutButton
							color="inherit"
							aria-label="logOut button"
							edge="start"
							onClick={handleLogout}
							size="large"
						>
							<ExitToAppIcon />
						</StyledLogOutButton>
					</Tooltip>
					<LogoutDialog
						open={isLogoutDialogOpen}
						onCancel={handleCancelLogout}
						onConfirm={handleConfirmLogout}
					/>
				</Toolbar>
			</StyledAppBar>
			<StyledNavDrawer aria-label="mailbox folders">
				{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
				<Hidden smUp implementation="css">
					<Drawer
						container={container}
						variant="temporary"
						anchor={theme.direction === 'rtl' ? 'right' : 'left'}
						open={isMobileOpen}
						onClose={handleDrawerToggle}
						sx={(theme) => ({
							paper: {
								textAlign: 'center',
								padding: `${theme.spacing(4)} 0 ${theme.spacing(4)} 0`
							}
						})}
						ModalProps={{
							keepMounted: true // Better open performance on mobile.
						}}
					>
						<Navigation childrendOnClick={handleDrawerToggle} />
					</Drawer>
				</Hidden>
				<Hidden smDown implementation="css">
					<Drawer
						sx={{
							'.MuiDrawer-paper': {
								width: `${240}px`
							}
						}}
						variant="permanent"
						open
					>
						<Navigation />
					</Drawer>
				</Hidden>
			</StyledNavDrawer>
			<StyledContent>
				<StyledToolbar />
				<Outlet />
			</StyledContent>
			<Backdrop
				sx={(theme) => ({
					zIndex: theme.zIndex.drawer + 1,
					color: '#fff'
				})}
				open={isLoading}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		</StyledRoot>
	);
}

export default MainLayout;
