import React from 'react';
import { useApi, useToaster } from '@praos-health/ui';
import { useAuth } from '@praos-health/ui-security';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { AdvancedSearch, FlatScreening, LicenseVerificationTask, ListOrderBy, TaskType } from '@praos-health/screening-client';
import { CredentialingTasksTable } from './credentialing-tasks-table';
import { AdvancedFiltersForm, initialAdvanceSearchState } from '../advanced-filters-form';
import { DataTableColumn, DataTablePagination } from '@praos-health/web/components/data-table';
import { AppApi } from '../../../app-api';

export function CredentialingTasks(): JSX.Element {
	const auth = useAuth();
	const api = useApi<AppApi>();
	const { t } = useTranslation();
	const toast = useToaster();
	const [isLoading, setIsLoading] = useState(false);
	const [applicantId, setApplicantId] = useState('');
	const [organizationId, setOrganizationId] = useState('all');
	const [advancedSearch, setAdvancedSearch] = useState(initialAdvanceSearchState());
	const [shouldDelaySearch, setShouldDelaySearch] = useState(false);
	const [listScreenings, setListScreenings] = useState<FlatScreening<LicenseVerificationTask>[]>([]);
	const [pagination, setPagination] = React.useState<DataTablePagination>(
		{ order: 'asc', orderBy: 'tasks.applicant.firstName', page: 0, rowsPerPage: 10 }
	);
	const [count, setCount] = React.useState(0);

	useEffect(() => {
		async function loadScreeningData(): Promise<void> {
			const orderBy = (
				pagination.order === 'asc' ? pagination.orderBy : `!${pagination.orderBy}`
			) as ListOrderBy;

			if (auth.session) {
				setIsLoading(true);

				try {
					const screenings = await api.screeningService.listScreenings(auth.session?.auth, {
						orderBy,
						limit: pagination.rowsPerPage,
						skip: pagination.page * pagination.rowsPerPage,
						search: advancedSearch,
						isOpen: true,
						applicantId,
						organization: (organizationId === 'praos' ? '' : organizationId !== 'all' ? organizationId : undefined),
						flatten: true,
						type: TaskType.LicenseVerification
					});

					setListScreenings(screenings.list as FlatScreening<LicenseVerificationTask>[]);
					setCount(screenings.count);
				} catch (err) {
					toast(err as Error, 'error');
				} finally {
					setIsLoading(false);
				}
			}
		}

		let timeout: NodeJS.Timeout | null = null;

		if (shouldDelaySearch) {
			timeout = setTimeout(() => {
				timeout = null;
				loadScreeningData();
			}, 500);

			return () => {
				if (timeout) {
					clearTimeout(timeout);
				}
			};
		}

		loadScreeningData();
	}, [api, auth, advancedSearch, applicantId, shouldDelaySearch, organizationId, pagination, toast]);

	const columns: DataTableColumn<FlatScreening<LicenseVerificationTask>>[] = [
		{
			id: 'verifyButton',
			title: '',
			align: 'center'
		},
		{
			id: 'accountID',
			title: t('AccountID'),
			orderBy: 'applicantId',
			align: 'left',
			getValue: (screening) => screening.applicantIntId ?? screening.applicantId ?? ''
		},
		{
			id: 'firstName',
			title: t('FirstName'),
			orderBy: 'tasks.applicant.firstName',
			align: 'left',
			getValue: (screening) => screening.task.applicant?.firstName ?? ''
		},
		{
			id: 'lastName',
			title: t('LastName'),
			orderBy: 'tasks.applicant.lastName',
			align: 'left',
			getValue: (screening) => screening.task.applicant?.lastName ?? ''
		},
		{
			id: 'licenseType',
			title: t('LicenseType'),
			orderBy: 'tasks.applicant.licenses.licenseType',
			align: 'left',
			getValue: (screening) => screening.task.applicant?.licenses?.[0]?.licenseType ?? ''
		},
		{
			id: 'licenseState',
			title: t('LicenseState'),
			orderBy: 'tasks.applicant.licenses.licenseBody',
			align: 'left',
			getValue: (screening) => screening.task.applicant?.licenses?.[0]?.licenseBody ?? ''
		},
		{
			id: 'organization',
			title: t('Organization'),
			align: 'left',
			getValue: (screening) => screening.organization?.name ?? 'Praos Health'
		},
		{
			id: 'actions',
			title: t('Actions'),
			align: 'left'
		}
	];

	function handleSearch(organizationId: string, applicantId: string, search: AdvancedSearch, delay?: boolean): void {
		setOrganizationId(organizationId);
		setApplicantId(applicantId);
		setAdvancedSearch(search);
		setShouldDelaySearch(!!delay);
		setPagination({...pagination, page: 0});
	}

	return (
		<Grid container spacing={2}>
			{/* Filters */}
			<AdvancedFiltersForm
				organizationId={organizationId}
				applicantId={applicantId}
				advancedSearch={advancedSearch}
				onSearch={handleSearch}
			/>

			<Grid item xs={12}>
				<CredentialingTasksTable
					columns={columns}
					loading={isLoading}
					screeningData={listScreenings}
					pagination={pagination}
					onRequestData={(page) => { setPagination( page || { ...pagination }  ); }}
					count={count}
				/>
			</Grid>
		</Grid>
	);
}
