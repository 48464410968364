import React from 'react';
import { useTranslation } from 'react-i18next';
import {
	Box,
	FormControl,
	Grid,
	InputLabel,
	Paper,
	Select,
	SelectChangeEvent
} from '@mui/material';
import { Title } from '@praos-health/web/components';
import { BriefcasePieChart, BriefcaseFunnelChart } from '../../components';
import { useEffect, useState } from 'react';
import { Organization } from '@praos-health/organizations-client';
import { useApi, useToaster } from '@praos-health/ui';
import { useAuth } from '@praos-health/ui-security';
import { AppApi } from '../../app-api';
export * from './briefcase-stats-page-toolbar';

export function BriefcaseStatsPage(): JSX.Element {
	const { t } = useTranslation();
	const api = useApi<AppApi>();
	const auth = useAuth();
	const toast = useToaster();
	const [organizationId, setOrganizationId] = useState('all');
	const [organizations, setOrganizations] = useState<Organization[]>([]);

	const handleChange = async (e: SelectChangeEvent<string>) => {
		setOrganizationId(e.target.value as string);
	};

	useEffect(() => {
		async function loadOrganizations(): Promise<void> {
			if (auth.session?.organization) {
				setOrganizations([
					{
						_id: auth.session.organization._id,
						name: auth.session.organization.name
					}
				]);
				return;
			}

			const result = await api.organizationService.list(auth.session?.auth || '', {
				select: { name: 1 },
				status: 'APPROVED'
			});

			setOrganizations(result.list);
		}

		loadOrganizations()
			.catch((e) => {
				toast(e, 'error');
			});
	}, [api, auth, toast]);

	return (
		<div>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Paper>
						<Grid container>
							<Grid item xs={12} md={9}>
								<Box ml={2} mt={1}>
									<Title>
										{t('BriefcaseStatisticsDescription')}
									</Title>
								</Box>
							</Grid>
							{!auth.session?.organization && (
								<Grid item xs={12} md={3}>
									<Box sx={(theme) => ({
										float: 'right',
										position: 'relative',
										padding: 2,
										[theme.breakpoints.down('md')]: {
											float: 'left'
										}
									})}>
										<FormControl
											variant="filled"
											sx={(theme) => ({
												margin: theme.spacing(1),
												minWidth: 120
											})}
										>
											<InputLabel id="demo-simple-select-label">
												{t('Organization')}
											</InputLabel>
											<Select
												variant="standard"
												native
												labelId="demo-simple-select-label"
												value={organizationId}
												onChange={handleChange}
												inputProps={{
													name: 'age',
													id: 'filled-age-native-simple'
												}}
											>
												<option aria-label="None" value="all">
													{t('All')}
												</option>
												<option aria-label="PraosHealth" value="praos">
													Praos Health
												</option>
												{organizations.map((value) => {
													return (
														<option key={value._id} value={value._id}>
															{value.name}
														</option>
													);
												})}
											</Select>
										</FormControl>
									</Box>
								</Grid>
							)}
						</Grid>
					</Paper>
				</Grid>
				<Grid item xs={12} md={6}>
					<Paper>
						<BriefcaseFunnelChart
							title={t('BriefcaseStatFunnelTitle')}
							description={t('BriefcaseStatFunnelDesc')}
							organization={organizationId}
							height={500}
						/>
					</Paper>
				</Grid>
				<Grid item xs={12} md={6}>
					<Paper>
						<BriefcasePieChart
							title={t('BriefcaseStatPieTitle')}
							description={t('BriefcaseStatPieDesc')}
							organization={organizationId}
							height={500}
						/>
					</Paper>
				</Grid>
			</Grid>
		</div>
	);
}
