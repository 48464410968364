import { User, parseNotification, parseOrganizationUser, prepOrganizationUser, Address, UserType, OrganizationUser, Notification  } from "./models";
import { ajax, ajaxKey } from "./ajax";
import { clone } from "@praos-health/core/utilities/object";

export type UserSelect = {
	'firstName'?: 1,
	'lastName'?: 1,
	'address'?: 1,
	'dateOfBirth'?: 1,
	'deactivatedAt'?: 1,
	'deactivatedBy'?: 1,
	'userType'?: 1,
	'email'?: 1,
	'facebookId'?: 1,
	'linkedInId'?: 1,
	'gender'?: 1,
	'emailCommunicationEnabled'?: 1,
	'phoneNumber'?: 1,
	'timezone'?: 1,
	'profilePicUrl'?: 1,
	'profilePicThumbUrl'?: 1,
	'languages'?: 1,
	'signupIpAddress'?: 1,
	'signupChannel'?: 1,

	// Organization User Fields
	'department'?: 1,
	'isSuperAdmin'?: 1,
	'jobTitle'?: 1,
	'organization'?: 1,
	'client'?: 1
};

export type AnonymousAddUserOptions = {
	item: User | OrganizationUser,
	select?: UserSelect,
	organizationId?: string,
	password?: string
}

export type AddUserOptions = {
	item: {
		userType?: UserType,
		firstName?: string,
		lastName?: string,
		dateOfBirth?: Date,
		gender?: 'Male' | 'Female' | 'Other',
		email?: string,
		phoneNumber?: string,
		address?: Address,
		languages?: string[],
		emailCommunicationEnabled?: boolean,
		departmentId?: string,
		client?: string,
		organization?: string | {
			name: string,
			facility: {
				type: string,
				employees: string
			},
			address1: string,
			address2?: string,
			city: string,
			state: string,
			zip: string
		},
	},
	select?: UserSelect,
	departmentId?: string,
	notes?: string
}

export type GetUserOptions = {
	select?: UserSelect
};

export type AnonymousGetOptions = {
	passwordResetToken?: string,
	verificationToken?: string
}

export type UpdateOptions = {
	item: {
		userType?: UserType,
		firstName?: string,
		lastName?: string,
		dateOfBirth?: Date,
		gender?: 'Male' | 'Female' | 'Other',
		email?: string,
		phoneNumber?: string,
		address?: Address,
		languages?: string[],
		emailCommunicationEnabled?: boolean,
		profilePicUrl?: null,
		jobTitle?: string,
		departmentId?: string
	},
	select?: UserSelect
}

export type VerifyOptions = {
	token: string,
	password?: string
}

export type VerifyWithDeviceOptions = VerifyOptions & {
	deviceType: 'IOS' | 'ANDROID',
	deviceId : string
	dviceToken: string,
	organization: string
}

export type ResendVerificationOptions = {
	email: string,
	organizationId?: string
}

export type LoginOptions = {
	email: string,
	password: string,
	coBrandedUrl?: string,
	userTypes?: UserType[]
}

export type LoginWithDeviceOptions = LoginOptions & {
	deviceType: 'IOS' | 'ANDROID',
	deviceId : string,
	dviceToken: string,
	organization?: string
}

export type ListUserOptions = {
	organizationId?: string,
	departmentId?: string,
	isAdmin?: boolean,
	isOrganizationUser?: boolean,
	isOrganizationAdmin?: boolean,
	isDeactivated?: boolean,
	orderBy?: 'intId' | '!intId' | 'name' | '!name' | 'phoneNumber' | '!phoneNumber' | 'email' | '!email',
	userType?: UserType | UserType[],
	limit?: number,
	skip?: number,
	search?: string | {
		firstName?: string,
		lastName?: string,
		email?: string,
		city?: string,
		state?: string,
		zip?: string
	},
	select?: UserSelect
}

export type ListUserResult = {
	list: (User | OrganizationUser)[],
	count: number
}

export type ListNotificationsOptions = {
	notificationType?: 'CRED_ALERTS' | 'JOB_ALERTS' | 'PROFESSIONAL_ALERTS' | 'LICENSE_UPDATES',
	limit?: number,
	skip?: number,
	markAsRead?: boolean
}

export type ListNotificationResult = {
	list: Notification[],
	count: number,
	unreadCount: number
}

export type EncryptionResult = {
	encrypotion: string
}

export type ProfessionalLocationOptions = {
	jobId: string,
	latitude: number,
	longitude: number
}

export type BullhornSsoOptions = {
	organizationId: string,
	ssoAuthToken: string,
	userId: string
}

export class UserService {
	constructor(protected apiUrl: string) {
	}

	async add(options: AnonymousAddUserOptions): Promise<User | OrganizationUser>;
	async add(auth: string, options: AddUserOptions): Promise<User | OrganizationUser>;
	async add(auth: any, options?: AddUserOptions | AnonymousAddUserOptions): Promise<User | OrganizationUser> {
		if (!(typeof auth === 'string')) {
			options = auth;
			auth = null;
		}

		options.item = prepOrganizationUser(clone<any>(options.item));

		let result = await ajax(`${this.apiUrl}/users`, 'POST', auth, options);

		if (result) {
			result = parseOrganizationUser(result);
		}

		return result;
	}

	async get(userId: string, options: AnonymousGetOptions): Promise<User | OrganizationUser>;
	async get(auth: string, options?: GetUserOptions): Promise<User | OrganizationUser>;
	async get(auth: string, userId: string, options?: GetUserOptions): Promise<User | OrganizationUser>;
	async get(auth: string, userId: any, options?: GetUserOptions): Promise<User | OrganizationUser> {
		let user: any;

		if (typeof userId === 'object' && (userId.shareToken || userId.verificationToken || userId.passwordResetToken)) {
			user = await ajax(`${this.apiUrl}/users/${auth}`, 'GET', null, null, userId);
		} else {
			if (typeof userId !== 'string') {
				options = userId;
				userId = 'session';
			}

			user = await ajax(`${this.apiUrl}/users/${userId}`, 'GET', auth, null, options);
		}

		return parseOrganizationUser(user);
	}

	async login(options: LoginOptions | LoginWithDeviceOptions): Promise<string> {
		return await ajax(`${this.apiUrl}/users/login`, 'POST', null, options);
	}

	async loginRedirect(auth: string): Promise<string> {
		return await ajax(`${this.apiUrl}/users/login-redirect`, 'GET', auth);
	}

	async logout(auth: string): Promise<void> {
		return await ajax(`${this.apiUrl}/users/logout`, 'POST', auth);
	}

	async deactivate(auth: string, userId: string, notes?: string): Promise<void> {
		await ajax(`${this.apiUrl}/users/${userId}/deactivate`, 'PUT', auth, notes ? { notes } : {});
	}

	async verify(userId: string, options: VerifyOptions | VerifyWithDeviceOptions): Promise<string> {
		return await ajax(`${this.apiUrl}/users/${userId}/verify`, 'POST', null, options);
	}

	async changePassword(auth: string, oldPassword: string, newPassword: string): Promise<void> {
		await ajax(`${this.apiUrl}/users/changepassword`, 'POST', auth, { oldPassword, newPassword });
	}

	async forgotPassword(email: string, panel?: string): Promise<void> {
		await ajax(`${this.apiUrl}/users/forgotpassword`, 'PUT', null, { email, panel });
	}

	async resetPassword(userId: string, token: string, password: string): Promise<void> {
		await ajax(`${this.apiUrl}/users/${userId}/resetpassword`, 'POST', null, { token, password });
	}

	async list(auth: string, options?: ListUserOptions): Promise<ListUserResult> {
		const result: any = await ajax(`${this.apiUrl}/users`, 'GET', auth, undefined, options);

		for (let i = 0; i < result.list.length; i++) {
			result.list[i] = parseOrganizationUser(result.list[i]);
		}

		return result;
	}

	async listExternal(xApiKey: string, options?: ListUserOptions): Promise<ListUserResult> {
		const result: any = await ajaxKey(`${this.apiUrl}/users/external`, 'GET', xApiKey, undefined, options);

		for (let i = 0; i < result.list.length; i++) {
			result.list[i] = parseOrganizationUser(result.list[i]);
		}

		return result;
	}

	async update(auth: string, userId: string, options: UpdateOptions): Promise<User> {
		options.item = prepOrganizationUser(clone<any>(options.item)) as any;

		const result = await ajax(`${this.apiUrl}/users/${userId}`, 'PUT', auth, options);

		return parseOrganizationUser(result);
	}

	async resendVerification(auth: string, userId: string): Promise<void>;
	async resendVerification(options: ResendVerificationOptions): Promise<void>;
	async resendVerification(authOrOptions: any, userId?: any): Promise<void> {
		if (typeof authOrOptions === "string") {
			await ajax(`${this.apiUrl}/users/resendverification`, 'PUT', authOrOptions, { userId });
		} else {
			await ajax(`${this.apiUrl}/users/resendverification`, 'PUT', null, authOrOptions);
		}
	}

	async listNotifications(auth: string, options?: ListNotificationsOptions): Promise<ListNotificationResult> {
		const result: any = await ajax(`${this.apiUrl}/users/notifications`, 'GET', auth, undefined, options);

		for (let i = 0; i < result.list.length; i++) {
			result.list[i] = parseNotification(result.list[i]);
		}

		return result;
	}

	async encryptText(auth: string, text: string): Promise<EncryptionResult> {
		return await ajax(`${this.apiUrl}/users/encrypt`, 'POST', auth, { text });
	}

	async addProfessionalLocation(auth: string, options: ProfessionalLocationOptions): Promise<void> {
		return await ajax(`${this.apiUrl}/users/professional-location`, 'POST', auth, options);
	}

	async bullhornSsoLogin(options: BullhornSsoOptions): Promise<string> {
		return await ajax(`${this.apiUrl}/users/bullhornsso`, 'POST', null, options);
	}
}