import React, { useEffect, useState } from 'react';
import { Bar, BarDatum, BarLayer } from '@nivo/bar';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useTranslation } from 'react-i18next';
import { useApi, useToaster } from '@praos-health/ui';
import { useAuth } from '@praos-health/ui-security';
import { StatisticsType, WeeklyStatistics, StatisticsOptions } from '@praos-health/jobs-client';
import { IconButton } from '@mui/material';
import { addDays, beginningOfWeek, getUtc } from '@praos-health/core/utilities/date';
import { DateRange, getRange } from '@praos-health/web/components';
import { LimitedBackdrop } from '@praos-health/web/components/limited-backdrop';
import { InfoButtonPopper } from '@praos-health/web/components';
import { buildTickValues } from '../../nivo/axes';
import DownloadIcon from '@mui/icons-material/GetApp';
import { toCsv, sanitizeFilename } from '@praos-health/core/utilities/string';
import { downloadFile } from '../../common/download';
import { Title } from '@praos-health/web/components';
import { ChartWrapper, Main, StyledDateRangeSelect, Toolbar } from '../shared-components';
import { AppApi } from '../../app-api';

export interface JobBarData extends BarDatum {
	dateRange: string,
	posted: number,
	applied: number,
	submitted: number,
	confirmed: number,
	cancelled: number
}

interface Props {
	title?: string,
	description?: string,
	height: number,
	organization?: string,
	initialDateRange?: DateRange
}

function buildBars(
	stats: WeeklyStatistics[],
	startDate?: Date,
	endDate?: Date,
	accumulate = false
): JobBarData[] {
	const result: JobBarData[] = [];
	const formatter = new Intl.DateTimeFormat();

	if (!startDate && stats.length) {
		startDate = new Date(stats[0].date.year, stats[0].date.month - 1, stats[0].date.day);
	}

	if (!endDate) {
		endDate = new Date();
	}

	if (startDate) {
		let date = beginningOfWeek(startDate, false, true);
		let i = 0;

		while (date < endDate) {
			const item: JobBarData = {
				dateRange: `${formatter.format(date)} - ${formatter.format(addDays(6, date))}`,
				cancelled:
					accumulate && result.length > 0 ? result[result.length - 1].cancelled : 0,
				submitted:
					accumulate && result.length > 0 ? result[result.length - 1].submitted : 0,
				confirmed:
					accumulate && result.length > 0 ? result[result.length - 1].confirmed : 0,
				applied: accumulate && result.length > 0 ? result[result.length - 1].applied : 0,
				posted: accumulate && result.length > 0 ? result[result.length - 1].posted : 0
			};

			while (i < stats.length) {
				const curDate = new Date(
					stats[i].date.year,
					stats[i].date.month - 1,
					stats[i].date.day
				);

				if (addDays(7, date) <= curDate) {
					break;
				}

				if (curDate <= addDays(7, date)) {
					item.cancelled = stats[i].cancelled;
					item.submitted = stats[i].submitted;
					item.confirmed = stats[i].confirmed;
					item.applied = stats[i].applied;
					item.posted = stats[i].posted;
				}

				i++;
			}

			result.push(item);
			date = addDays(7, date);
		}
	}

	return result;
}

export function JobBar({
	title,
	description,
	height,
	organization,
	initialDateRange
}: Props): JSX.Element {
	const { t } = useTranslation();
	const api = useApi<AppApi>();
	const auth = useAuth();
	const layers: BarLayer<JobBarData>[] = ['grid', 'axes', 'bars', 'markers', 'legends'];
	const [isLoading, setIsLoading] = useState(false);
	const [dateRange, setDateRange] = useState(initialDateRange || DateRange.Year);
	const [data, setData] = useState<JobBarData[]>([]);
	const toast = useToaster();

	const ledgend = [
		{
			id: 'posted',
			label: t('Posted'),
			color: '#a23A62'
		},
		{
			id: 'cancelled',
			label: t('Cancelled'),
			color: '#ff0000'
		},
		{
			id: 'applied',
			label: t('Applied'),
			color: '#4473c4'
		},
		{
			id: 'submitted',
			label: t('Submitted'),
			color: '#ffc000'
		},
		{
			id: 'confirmed',
			label: t('Confirmed'),
			color: '#70ad47'
		}
	];

	useEffect(() => {
		setIsLoading(true);

		async function loadChart(): Promise<void> {
			const range = getRange(dateRange);

			range.start = beginningOfWeek(range.start, false, true);

			const options: StatisticsOptions = {
				startDate: getUtc(range.start),
				endDate: getUtc(range.end),
				useIsoWeek: true
			};

			if (organization === 'praos') {
				options.isMarketplace = true;
			} else if (organization !== 'all') {
				options.organization = organization;
			}

			const result = await api.jobService.statistics(
				auth.session?.auth || '',
				StatisticsType.Weekly,
				options
			);

			setData(buildBars(result, range.start, range.end));
		}

		loadChart()
			.catch((e) => {
				toast(e, 'error');
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [api, auth, organization, dateRange, toast]);

	function handleDateRangeChange(value: DateRange) {
		setDateRange(value);
	}

	function handleExport() {
		const formatter = new Intl.DateTimeFormat();
		const range = getRange(dateRange);

		range.start = beginningOfWeek(range.start, false, true);

		downloadFile(
			sanitizeFilename(
				`jobs ${formatter.format(range.start)} to ${formatter.format(range.end)}.csv`
			),
			'text/csv;charset=utf-8;',
			[
				toCsv([
					t('Date'),
					t('Posted'),
					t('Cancelled'),
					t('Applied'),
					t('Submitted'),
					t('Confirmed')
				])
			]
				.concat(
					data.map((i) =>
						toCsv([
							i.dateRange,
							i.posted.toString(),
							i.cancelled.toString(),
							i.applied.toString(),
							i.submitted.toString(),
							i.confirmed.toString()
						])
					)
				)
				.join('\r\n')
		);
	}

	return (
		<Main>
			{(title || description) && (
				<Toolbar>
					<Title>{title}</Title>
					{description && <InfoButtonPopper text={description} />}
					<IconButton color="inherit" edge="end" onClick={handleExport} size="large">
						<DownloadIcon />
					</IconButton>
					<StyledDateRangeSelect
						value={dateRange}
						onChange={handleDateRangeChange}
					/>
				</Toolbar>
			)}
			<ChartWrapper>
				<AutoSizer disableHeight>
					{({ width }: { width: number }) =>
					width ? (
						<div style={{ width: `${width}px` }}>
							<Bar<JobBarData>
								axisBottom={{
									tickValues: buildTickValues(data, 'dateRange', width, 35),
									tickSize: 5,
									tickPadding: 5,
									tickRotation: -60
								}}
								data={data}
								keys={ledgend.map((i) => i.id)}
								indexBy="dateRange"
								groupMode="grouped"
								height={height}
								width={width}
								margin={{ top: 50, right: 50, bottom: 180, left: 60 }}
								padding={0.3}
								valueScale={{ type: 'linear' }}
								indexScale={{ type: 'band', round: true }}
								colors={ledgend.map((i) => i.color)}
								borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
								labelSkipWidth={12}
								labelSkipHeight={12}
								labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
								legends={[
									{
										data: ledgend,
										dataFrom: 'keys',
										anchor: 'bottom',
										direction: 'row',
										justify: false,
										translateX: 0,
										translateY: 170,
										itemsSpacing: 2,
										itemWidth: 80,
										itemHeight: 20,
										itemDirection: 'bottom-to-top',
										itemOpacity: 0.85,
										symbolSize: 20,
										padding: 0,
										effects: [
											{
												on: 'hover',
												style: {
													itemOpacity: 1
												}
											}
										]
									}
								]}
								animate={false}
								layers={layers}
							/>
						</div>
					) : (
						<div>Loading...</div>
					)
					}
				</AutoSizer>
				<LimitedBackdrop open={isLoading} />
			</ChartWrapper>

		</Main>
	);
}
