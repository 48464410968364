import React, { useEffect, useState } from 'react';

import AutoSizer from 'react-virtualized-auto-sizer';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/GetApp';
import { Bar, BarLayer } from '@nivo/bar';

import { useApi, useToaster } from '@praos-health/ui';
import { StatisticsType, StatisticsOptions } from '@praos-health/professional-client';
import { useAuth } from '@praos-health/ui-security';
import { beginningOfWeek, getUtc } from '@praos-health/core/utilities/date';

import { DateRange, getRange } from '@praos-health/web/components';
import { LimitedBackdrop } from '@praos-health/web/components/limited-backdrop';
import { InfoButtonPopper } from '@praos-health/web/components';
import { buildTickValues } from '../../nivo/axes';

import { UserActivationBarData, Props } from './index.types';
import { getLedgend, handleExport, buildBars } from './index.logics';
import { Title } from '@praos-health/web/components';
import { ChartWrapper, Main, StyledDateRangeSelect, Toolbar } from '../shared-components';
import { AppApi } from '../../app-api';

function UserActivationBar({
	title,
	description,
	height,
	organization,
	initialDateRange,
	downloadable
}: Props): JSX.Element {
	const { t } = useTranslation();
	const layers: BarLayer<UserActivationBarData>[] = [
		'grid',
		'axes',
		'bars',
		'markers',
		'legends'
	];
	const [isLoading, setIsLoading] = useState(false);
	const [dateRange, setDateRange] = useState(initialDateRange || DateRange.Year);
	const [data, setData] = useState<UserActivationBarData[]>([]);
	const api = useApi<AppApi>();
	const auth = useAuth();
	const toast = useToaster();

	const ledgend = getLedgend({ t });

	useEffect(() => {
		setIsLoading(true);

		async function loadChart(): Promise<void> {
			const range = getRange(dateRange);

			range.start = beginningOfWeek(range.start, false, true);

			const options: StatisticsOptions = {
				startDate: getUtc(range.start),
				endDate: getUtc(range.end),
				useIsoWeek: true,
				accumulate: true
			};

			if (organization === 'praos') {
				options.isMarketplace = true;
			} else if (organization !== 'all') {
				options.organization = organization;
			}

			const result = await api.professionalService.statistics(
				auth.session?.auth || '',
				StatisticsType.Archives,
				options
			);

			setData(buildBars(result, range.start, range.end, true));
		}

		loadChart()
			.catch((e) => {
				toast(e, 'error');
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [api, auth, organization, dateRange, toast]);

	function handleDateRangeChange(value: DateRange) {
		setDateRange(value);
	}

	return (
		<Main>
			{(title || description) && (
				<Toolbar>
					<Title>{title}</Title>
					{description && <InfoButtonPopper text={description} />}
					{downloadable && (
						<IconButton
							color="inherit"
							edge="end"
							onClick={() => handleExport({ dateRange, title, data, t })}
							size="large"
						>
							<DownloadIcon />
						</IconButton>
					)}
					<StyledDateRangeSelect
						value={dateRange}
						onChange={handleDateRangeChange}
					/>
				</Toolbar>
			)}
			<ChartWrapper>
				<AutoSizer disableHeight>
					{({ width }: { width: number }) =>
					width ? (
						<div style={{ width: `${width}px` }}>
							<Bar<UserActivationBarData>
								axisBottom={{
									tickSize: 5,
									tickPadding: 5,
									tickRotation: -60,
									tickValues: buildTickValues(data, 'dateRange', width, 35)
								}}
								data={data}
								keys={ledgend.map((i) => i.id)}
								indexBy="dateRange"
								groupMode="grouped"
								height={height}
								width={width}
								margin={{ top: 50, right: 50, bottom: 200, left: 60 }}
								padding={0.3}
								valueScale={{ type: 'linear' }}
								indexScale={{ type: 'band', round: true }}
								colors={ledgend.map((i) => i.color)}
								borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
								labelSkipWidth={12}
								labelSkipHeight={12}
								labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
								legends={[
									{
										data: ledgend,
										dataFrom: 'keys',
										anchor: 'bottom',
										direction: 'row',
										justify: false,
										translateX: 0,
										translateY: 170,
										itemsSpacing: 2,
										itemWidth: 80,
										itemHeight: 20,
										itemDirection: 'bottom-to-top',
										itemOpacity: 0.85,
										symbolSize: 20,
										padding: 0,
										effects: [
											{
												on: 'hover',
												style: {
													itemOpacity: 1
												}
											}
										]
									}
								]}
								animate={true}
								layers={layers}
							/>
						</div>
					) : (
						<div>Loading...</div>
					)
					}
				</AutoSizer>
				<LimitedBackdrop open={isLoading} />

			</ChartWrapper>
		</Main>
	);
}

export { UserActivationBar };
