import React from 'react';
import { Typography, Box } from '@mui/material';
import { LimitedBackdrop } from '@praos-health/web/components/limited-backdrop';

interface TimeToStartProps {
	value: string | number,
	loading?: boolean
}

const parseTime = (value: string | number): string => {
	const totalMilliseconds = typeof value === 'string' ? parseInt(value, 10) : value;
	const totalSeconds = Math.floor(totalMilliseconds / 1000);

	const seconds = totalSeconds % 60;
	const minutes = Math.floor(totalSeconds / 60) % 60;
	const hours = Math.floor(totalSeconds / 3600) % 24;
	const days = Math.floor(totalSeconds / 86400) % 7;
	const weeks = Math.floor(totalSeconds / 604800) % 4;
	const months = Math.floor(totalSeconds / 2592000) % 12;
	const years = Math.floor(totalSeconds / 31536000);

	const parts = [];
	if (years > 0) parts.push(`${years}Y`);
	if (months > 0) parts.push(`${months}M`);
	if (weeks > 0) parts.push(`${weeks}W`);
	if (days > 0) parts.push(`${days}D`);
	if (hours > 0) parts.push(`${hours}H`);
	if (minutes > 0) parts.push(`${minutes}m`);
	if (seconds > 0 || parts.length === 0) parts.push(`${seconds}s`);

	return parts.join(' ');
};

const TimeToStart: React.FC<TimeToStartProps> = ({ value, loading }) => {
	const formattedTime = parseTime(value);

	return (
		<Box position="relative">
			<Typography variant="h3" component="p" align="center" sx={{ fontWeight: 400, my: 2 }}>
				{loading ? '\u00A0' : formattedTime}
			</Typography>
			<LimitedBackdrop open={!!loading} />
		</Box>
	);
};

export default TimeToStart;