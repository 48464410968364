import { toCsv, sanitizeFilename } from '@praos-health/core/utilities/string';
import { ArchivedStatistics } from '@praos-health/professional-client';
import { addDays, beginningOfWeek } from '@praos-health/core/utilities/date';

import { getRange } from '@praos-health/web/components';
import { downloadFile } from '../../common/download';

import { UserActivationBarData, HandleExportProps, GetLedgendProps } from './index.types';

function buildBars(
	stats: ArchivedStatistics[],
	startDate?: Date,
	endDate?: Date,
	accumulate = false
): UserActivationBarData[] {
	const result: UserActivationBarData[] = [];
	const formatter = new Intl.DateTimeFormat();

	if (!startDate && stats.length) {
		startDate = new Date(stats[0].date.year, stats[0].date.month - 1, stats[0].date.day);
	}

	if (!endDate) {
		endDate = new Date();
	}

	if (startDate) {
		let date = beginningOfWeek(startDate, false, true);
		let i = 0;

		while (date < endDate) {
			const item: UserActivationBarData = {
				dateRange: `${formatter.format(date)} - ${formatter.format(addDays(6, date))}`,
				inactive: accumulate && result.length > 0 ? result[result.length - 1].inactive : 0,
				activated:
					accumulate && result.length > 0 ? result[result.length - 1].activated : 0,
				archived: accumulate && result.length > 0 ? result[result.length - 1].archived : 0
			};

			while (i < stats.length) {
				const curDate = new Date(
					stats[i].date.year,
					stats[i].date.month - 1,
					stats[i].date.day
				);

				if (addDays(7, date) <= curDate) {
					break;
				}

				if (curDate <= addDays(7, date)) {
					item.inactive = stats[i].inactive;
					item.activated = stats[i].activated;
					item.archived = stats[i].archived;
				}

				i++;
			}

			result.push(item);
			date = addDays(7, date);
		}
	}

	return result;
}

function getLedgend({ t }: GetLedgendProps) {
	return [
		{
			id: 'inactive',
			label: t('Inactive'),
			color: '#4473c4'
		},
		{
			id: 'activated',
			label: t('Activated'),
			color: '#ff0000'
		},
		{
			id: 'archived',
			label: t('Archived'),
			color: '#a5a5a5'
		}
	];
}

function handleExport({ dateRange, title, data, t }: HandleExportProps) {
	const formatter = new Intl.DateTimeFormat();
	const range = getRange(dateRange);

	range.start = beginningOfWeek(range.start, false, true);

	downloadFile(
		sanitizeFilename(
			`${title} ${formatter.format(range.start)} to ${formatter.format(range.end)}.csv`
		),
		'text/csv;charset=utf-8;',
		[toCsv([t('Date'), t('Inactive'), t('Activated'), t('Archived')])]
			.concat(
				data.map((i) =>
					toCsv([
						i.dateRange,
						i.inactive.toString(),
						i.activated.toString(),
						i.archived.toString()
					])
				)
			)
			.join('\r\n')
	);
}

export { getLedgend, handleExport, buildBars };
