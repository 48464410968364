import React from 'react';
import { DataTable, toCsv } from '../data-table';
import { useTranslation } from 'react-i18next';
import { LimitedBackdrop } from '@praos-health/web/components/limited-backdrop';
import { IconButton, Paper } from '@mui/material';
import DownloadIcon from '@mui/icons-material/GetApp';
import { downloadFile } from '../../common/download';
import { Title } from '@praos-health/web/components/title';
import { DataTableColumn } from '@praos-health/web/components/data-table';

export interface SpecialtyTableRow {
	specialty: string,
	count: number
}

interface Props {
	data: SpecialtyTableRow[],
	loading: boolean
}

export function buildSpecialtyTableRows(bySpecialty: { [specialty: string]: number }): SpecialtyTableRow[] {
	return Object.keys(bySpecialty)
		.map(i => { return { specialty: i, count: bySpecialty[i] }; })
		.sort((a, b) => b.count - a.count);
}

export function SpecialtyTable({ data, loading }: Props) {
	const { t } = useTranslation();
	const columns: DataTableColumn<SpecialtyTableRow>[] = [
		{
			id: 'specialty',
			title: t('Specialty'),
			orderBy: 'specialty',
			align: 'left'
		},
		{
			id: 'count',
			title: t('Count'),
			orderBy: 'count',
			align: 'left'
		}
	];

	function handleExport() {
		downloadFile('specialties.csv', 'text/csv;charset=utf-8;', toCsv(columns, data));
	}

	return (
		<Paper sx={{position: 'relative'}}>
			<Title>
				{t('Specialties')}
				<IconButton color="inherit" edge="end" onClick={handleExport} size="large">
					<DownloadIcon />
				</IconButton>
			</Title>
			<DataTable
				id='specialty'
				columns={columns}
				rows={data}
			/>
			<LimitedBackdrop open={loading}/>
		</Paper>
	);
}
