interface MsNavigator extends Navigator {
	msSaveBlob: (blob: any, defaultName: any) => boolean
}

export function downloadFile(fileName: string, fileType: string, data: string) {
	const blob = new Blob([data], { type: fileType });

	if ((navigator as MsNavigator).msSaveBlob) { // IE 10+
		(navigator as MsNavigator).msSaveBlob(blob, fileName);
	} else {
		const link = document.createElement('a');

		if (link.download !== undefined) { // feature detection
			// Browsers that support HTML5 download attribute
			const url = URL.createObjectURL(blob);
			link.setAttribute('href', url);
			link.setAttribute('download', fileName);
			link.style.visibility = 'hidden';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}
}