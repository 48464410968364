import { styled } from '@mui/material';
import { Link } from 'react-router-dom';

export const StyledNavigationHeader = styled('div')(({ theme }) => ({
	textAlign: 'center',
	padding: `${theme.spacing(4)} 0 ${theme.spacing(4)} 0`
}));

export const ListItemLink = styled(Link)(
	({ theme }) => ({
		'textDecoration': 'none',
		'color': 'inherit',
		'&.active': {
			'backgroundColor': theme.palette.grey[300],
			'& .MuiListItemIcon-root': {
				color: theme.palette.primary.main
			}
		}
	})
);