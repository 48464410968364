import React from 'react';
import { useEffect, useState } from 'react';
import {
	DataTableWithDownloadIcon,
	RowType,
	TimeFrame,
	toCsv
} from '../data-table';
import { addDays, beginningOfWeek } from '@praos-health/core/utilities/date';
import { useTranslation } from 'react-i18next';
import { LimitedBackdrop } from '@praos-health/web/components/limited-backdrop';
import { MetricsStatistics } from '@praos-health/jobs-client';
import { IconButton, Paper, styled } from '@mui/material';
import DownloadIcon from '@mui/icons-material/GetApp';
import { downloadFile } from '../../common/download';
import { DataTableColumn } from '@praos-health/web/components/data-table';

interface JobMetricData {
	week: string,
	created?: number | string,
	evergreen?: number | string,
	createdWithRate?: number | string,
	createdExclusive?: number | string,
	applications?: number,
	applicationsExclusive?: number,
	applicationsWithRate?: number,
	type?: RowType,
	timeFrame?: TimeFrame
}

const NO_DATA = { thisWeek: 0, lastWeek: 0, lastWeek2nd: 0, lastWeek3rd: 0 };

interface StatisticsTableData {
	thisWeek: number,
	lastWeek: number,
	lastWeek2nd: number,
	lastWeek3rd: number
}

interface Props {
	data?: MetricsStatistics,
	loading: boolean,
	organizationId: string
}

const StyledDescription = styled('p')(({ theme }) => ({
	color: '#333', // Choose a suitable color
	fontSize: '1rem', // Choose a suitable font-size
	paddingLeft: theme.spacing(2), // Align it with the title
	paddingTop: theme.spacing(1), // Optional: some space between the title and description
	paddingBottom: theme.spacing(1) // Optional: some space between the description and the table
}));

const Title = styled('h3')(({ theme }) => ({
	paddingLeft: theme.spacing(2)
}));

function getRows(data?: MetricsStatistics): JobMetricData[] {
	const formatter = new Intl.DateTimeFormat(undefined, {month: 'numeric', day: 'numeric'});
	const thisWeek = beginningOfWeek(true, true);
	const lastWeek = beginningOfWeek(addDays(-7), true, true);
	const lastWeek2nd = beginningOfWeek(addDays(-14), true, true);
	const lastWeek3rd = beginningOfWeek(addDays(-21), true, true);

	const created = data?.created?.['All'] || NO_DATA;
	const createdIndividual = data?.createdIndividual?.['All'] || NO_DATA;
	const evergreenIndividual = data?.evergreenIndividual?.['All'] || NO_DATA;
	const createdExclusive = data?.createdExclusive?.['All'] || NO_DATA;
	const createdExclusiveIndividual = data?.createdExclusiveIndividual?.['All'] || NO_DATA;
	const createdWithRate = data?.createdWithRate?.['All'] || NO_DATA;
	const createdWithRateIndividual = data?.createdWithRateIndividual?.['All'] || NO_DATA;
	const applications: StatisticsTableData = data?.applications?.['All'] || NO_DATA;
	const applicationsExclusive: StatisticsTableData = data?.applicationsExclusive?.['All'] || NO_DATA;
	const applicationsWithRate: StatisticsTableData = data?.applicationsWithRate?.['All'] || NO_DATA;

	const week1: JobMetricData = {
		week: `${formatter.format(thisWeek)} - ${formatter.format(addDays(6, thisWeek))}`,
		created: defineRowData(createdIndividual.thisWeek, created.thisWeek),
		evergreen: defineData(evergreenIndividual.thisWeek),
		createdWithRate: defineRowData(createdWithRateIndividual.thisWeek, createdWithRate.thisWeek),
		createdExclusive: defineRowData(createdExclusiveIndividual.thisWeek, createdExclusive.thisWeek),
		applications: applications.thisWeek,
		applicationsExclusive: applicationsExclusive.thisWeek,
		applicationsWithRate: applicationsWithRate.thisWeek,
		type: RowType.jobMetrics,
		timeFrame: TimeFrame.thisWeek
	};

	const week2: JobMetricData = {
		week: `${formatter.format(lastWeek)} - ${formatter.format(addDays(6, lastWeek))}`,
		created: defineRowData(createdIndividual.lastWeek, created.lastWeek),
		evergreen: defineData(evergreenIndividual.lastWeek),
		createdWithRate: defineRowData(createdWithRateIndividual.lastWeek, createdWithRate.lastWeek),
		createdExclusive: defineRowData(createdExclusiveIndividual.lastWeek, createdExclusive.lastWeek),
		applications: applications.lastWeek,
		applicationsExclusive: applicationsExclusive.lastWeek,
		applicationsWithRate: applicationsWithRate.lastWeek,
		type: RowType.jobMetrics,
		timeFrame: TimeFrame.lastWeek
	};

	const week3: JobMetricData = {
		week: `${formatter.format(lastWeek2nd)} - ${formatter.format(addDays(6, lastWeek2nd))}`,
		created: defineRowData(createdIndividual.lastWeek2nd, created.lastWeek2nd),
		evergreen: defineData(evergreenIndividual.lastWeek2nd),
		createdWithRate: defineRowData(createdWithRateIndividual.lastWeek2nd, createdWithRate.lastWeek2nd),
		createdExclusive: defineRowData(createdExclusiveIndividual.lastWeek2nd, createdExclusive.lastWeek2nd),
		applications: applications.lastWeek2nd,
		applicationsExclusive: applicationsExclusive.lastWeek2nd,
		applicationsWithRate: applicationsWithRate.lastWeek2nd,
		type: RowType.jobMetrics,
		timeFrame: TimeFrame.lastWeek2nd
	};

	const week4: JobMetricData = {
		week: `${formatter.format(lastWeek3rd)} - ${formatter.format(addDays(6, lastWeek3rd))}`,
		created: defineRowData(createdIndividual.lastWeek3rd, created.lastWeek3rd),
		evergreen: defineData(evergreenIndividual.lastWeek3rd),
		createdWithRate: defineRowData(createdWithRateIndividual?.lastWeek3rd, createdWithRate.lastWeek3rd),
		createdExclusive: defineRowData(createdExclusiveIndividual.lastWeek3rd, createdExclusive.lastWeek3rd),
		applications: applications.lastWeek3rd,
		applicationsExclusive: applicationsExclusive.lastWeek3rd,
		applicationsWithRate: applicationsWithRate.lastWeek3rd,
		type: RowType.jobMetrics,
		timeFrame: TimeFrame.lastWeek3rd
	};

	return [week1, week2, week3, week4];
}

function defineRowData(n: number, p: number): string | undefined {
	if(n===0 || p===0) return `${n || 0} (${p || 0})`;
	if (!n && !p) return;
	if (n && p) return `${n} (${p})`;
	if (n && !p) return `${n}`;
}

function defineData(item: any): string | number {
	return item === undefined ? 'No Data' : item;
}

export function JobMetricsTable({ data, loading, organizationId }: Props) {
	const { t } = useTranslation();
	const [rows, setRows] = useState<any[]>([]);
	const columns: DataTableColumn<MetricsStatistics>[] = [
		{
			id: 'week',
			title: t('weeklyJobMetrics.week'),
			orderBy: 'week',
			align: 'left'
		},
		{
			id: 'evergreen',
			title: t('weeklyJobMetrics.evergreen'),
			orderBy: 'evergreen',
			align: 'left'
		},
		{
			id: 'created',
			title: t('weeklyJobMetrics.created'),
			orderBy: 'created',
			align: 'left'
		},
		{
			id: 'createdWithRate',
			title: t('weeklyJobMetrics.createdWithRate'),
			orderBy: 'createdWithRate',
			align: 'left'
		},
		{
			id: 'createdExclusive',
			title: t('weeklyJobMetrics.createdExclusive'),
			orderBy: 'createdExclusive',
			align: 'left'
		},
		{
			id: 'applications',
			title: t('weeklyJobMetrics.applications'),
			orderBy: 'applications',
			align: 'left'
		},
		{
			id: 'applicationsExclusive',
			title: t('weeklyJobMetrics.applicationsExclusive'),
			orderBy: 'applicationsExclusive',
			align: 'left'
		},
		{
			id: 'applicationsWithRate',
			title: t('weeklyJobMetrics.applicationsWithRate'),
			orderBy: 'applicationsWithRate',
			align: 'left'
		}
	];


	useEffect(() => {
		setRows(getRows(data));
	}, [data]);

	function handleExport() {
		downloadFile('weekly job metrics.csv', 'text/csv;charset=utf-8;', toCsv(columns, rows));
	}

	return (
		<Paper sx={{position: 'relative'}}>
			<Title>
				{t('weeklyJobMetrics.title')}
				<IconButton color="inherit" edge="end" onClick={handleExport} size="large">
					<DownloadIcon />
				</IconButton>
			</Title>

			<DataTableWithDownloadIcon id="week" columns={columns} rows={rows} organizationId={organizationId} />
			<StyledDescription>
				<b>N</b> (<b>P</b>) - where <br/>
				<b>N</b> = Number of jobs <br/>
				<b>P</b> = Number of required professionals.
			</StyledDescription>
			<LimitedBackdrop open={loading} />
		</Paper>
	);
}


