import { AnyObject } from "@praos-health/core/utilities/object";
import { ajax } from "./ajax";
import { RecordError } from "./errors";
import { RecordPermissions, FileThumbnail, RecordType, Record, RecordHistory, RecordAttachment, User } from "./models";

export type TemporaryUploadOptions = TemporaryUploadRequest & { file: File | string };
export type UploadOptions = UploadRequestOptions & { file: File | string };

export type UploadRequestOptions = PermanentUploadRequest | TemporaryUploadRequest;

export type ListOrderBy = 'createdAt' | '!createdAt' | 'createdBy' | '!createdBy' | 'fileName' | '!fileName' | 'recordType' | '!recordType' | 'status' | '!status';

export type ListHistoryOrderBy = 'createdAt' | '!createdAt';

export type DownloadRequest = DownloadPermanentRequest | DownloadShareRequest | DownloadTempRequest;

export interface DownloadPermanentRequest {
	auth: string,
	recordId: string,
	attachmentType?: string,
	url?: string,
	openWindow?: boolean
}

export interface DownloadShareRequest {
	shareToken?: string,
	recordId: string,
	attachmentType?: string,
	url?: string,
	openWindow?: boolean
}

export interface DownloadTempRequest {
	accessToken: string,
	recordId: string,
	tempFileName: string,
	url?: string,
	openWindow?: boolean
}

export type DownloadMultipleRequest = DownloadMultipleShareRequest | DownloadMultipleByAuthRequest;

export interface DownloadMultipleShareRequest {
	fileName: string,
	generatePdf?: boolean,
	openWindow?: boolean,
	shareRecordIds?: string[],
	shareToken: string,
	signal?: AbortSignal | null,
	redirect?: boolean
}

export interface DownloadMultipleByAuthRequest {
	auth: string,
	fileName: string,
	generatePdf?: boolean,
	openWindow?: boolean,
	organizationId?: string,
	recordIds?: string[],
	recordTypes?: string[],
	recordTypeTags?: string[],
	redirect?: boolean,
	signal?: AbortSignal | null,
	userId?: string
}

export type ListRequest = ListByAuthRequest | ListByShareRequest;

export interface ListByAuthRequest {
	auth: string,
	organizationId?: string,
	userId?: string,
	isDeleted?: boolean,
	recordTypes?: string[],
	recordTypeTags?: string[],
	orderBy?: ListOrderBy,
	limit?: number,
	skip?: number	
}

export interface ListByShareRequest {
	shareToken: string,
	orderBy?: ListOrderBy,
	limit?: number,
	skip?: number	
}

export interface ListResponse<T> {
	list: T[],
	count: number
}

export interface ListHistoryRequest {
	auth: string,
	recordId: string,
	orderBy?: ListHistoryOrderBy,
	limit?: number,
	skip?: number	
}

export type RecordTypeListOrderBy = 'name' | '!name';

export interface RecordTypeListRequest {
	tags?: string[],
	organizationId?: string,
	isEnabled?: boolean,
	orderBy?: RecordTypeListOrderBy,
	limit?: number,
	skip?: number
}

export interface PermanentUploadRequest {
	recordId?: string,
	recordType: string,
	attachmentType: string,
	fileName: string,
	userId?: string,
	organizationId?: string,
	isPermanent: true,
	permissions: RecordPermissions,
	data?: AnyObject,
	tags?: { [key: string]: string },
	token?: string
}

export interface ShareRequest {
	auth: string,
	userId?: string,
	token?: string,
	organizationId?: string,
	recordTypes?: string[],
	recordTypeTags?: string[],
	expiresInDays?: number,
	permissions?: 'read' | 'write'
}

export interface TemporaryUploadRequest {
	isPermanent?: false,
	recordId?: string,
	fileName: string
}

export interface UploadRequest {
	recordId: string,
	tempFileName: string,
	url: string,
	fields: { [field: string]: string },
	downloadUrl: string
}

export interface UploadResponse {
	recordId: string,
	tempFileName: string,
	fileName: string,
	downloadUrl: string
}

export interface UpsertRequest {
	recordType: string,
	recordId?: string,
	userId?: string,
	organizationId?: string,
	permissions: RecordPermissions,
	attachments?: { [identifier: string]: UpsertAttachment | null },
	data?: AnyObject,
	tags?: { [key: string]: string },
	createdAt?: string
}

export interface UpsertResponse extends Record {
	updatedBy: User,
	attachments?: { [type: string]: UpsertResponseAttachment }
}

export interface UpsertResponseAttachment extends RecordAttachment {
	url: string
}

export interface UpsertAttachment {
	fileName: string,
	tempFileName: string
}

export type GetRequest = GetByAuthRequest | GetByShareRequest;

export interface GetByAuthRequest {
	auth: string,
	recordId: string
}

export interface GetByShareRequest {
	shareToken: string,
	recordId: string
}

export interface RecordUpdated {
	recordId: string,
	recordType: string,
	userId?: string,
	organizationId?: string,
	createdBy?: string,
	updatedBy?: string,
	permissions: RecordPermissions,
	data?: AnyObject,
	deletedAt?: Date,
	completedAt?: Date,
	attachments?: { [type: string]: UpsertResponseAttachment },
	tags?: { [key: string]: string }
}

export interface GetStatistics {
	recordType?: string,
	from?: Date,
	to?: Date,
	organization: string,
	useIsoWeek?: boolean,
	fields: (keyof Record)[]
}

export type Statatistics = {
	completedRecords?: {
		byUser: {
			_id: string,
			firstName: string,
			lastName: string,
			thisWeek: {
				completedAt: Date,
				organization: string
			}[],
			lastWeek: {
				completedAt: Date,
				organization: string
			}[],
			allTime: {
				completedAt: Date,
				organization: string
			}[],
			lastWeekPartial: {
				completedAt: Date,
				organization: string
			}[],
			thisMonth: {
				completedAt: Date,
				organization: string
			}[],
			lastMonth: {
				completedAt: Date,
				organization: string
			}[],
			lastMonthPartial: {
				completedAt: Date,
				organization: string
			}[],
			recordTypeId: string,
			recordTypeName: string
		}[]
	}
};

export class RecordService {
	constructor(protected apiUrl: string) {
	}

	async share({ auth, ...etc }: ShareRequest ): Promise<string> {
		const token = await ajax(`${this.apiUrl}/records/shares`, 'POST', auth, etc);

		return token;
	}

	async delete(auth: string, recordId: string, permanent?: boolean, userId?: string, recordType?: string): Promise<UpsertResponse> {
		const url = new URL([`${this.apiUrl}/records`, recordId].join('/'));

		if (permanent) {
			url.searchParams.append('permanent', 'true');
		}

		if (userId) {
			url.searchParams.append('userId', userId);
		}

		if (recordType) {
			url.searchParams.append('recordType', recordType);
		}

		return await ajax(
			url.toString(),
			'DELETE',
			auth
		);
	}

	download({ recordId, url, openWindow, ...etc }: DownloadRequest): string {
		let result: string;

		if (url) {
			result = url;
		} else {
			const urlParts = [recordId];

			if ('attachmentType' in etc && etc.attachmentType) {
				urlParts.push(etc.attachmentType);
			} else if ('tempFileName' in etc && etc.tempFileName) {
				urlParts.push(etc.tempFileName);
			}

			const url = new URL(`${this.apiUrl}/records/download/${urlParts.join('/')}`);

			if ('auth' in etc && etc.auth) {
				url.searchParams.append('auth', etc.auth);
			}

			if ('shareToken' in etc && etc.shareToken) {
				url.searchParams.append('shareToken', etc.shareToken);
			}

			if ('accessToken' in etc && etc.accessToken) {
				url.searchParams.append('accessToken', etc.accessToken);
			}

			result = url.toString();
		}

		if (result && openWindow) {
			window.open(result);
		}

		return result;
	}

	async downloadMultiple({ fileName, generatePdf, openWindow, redirect, signal, ...etc }: DownloadMultipleRequest): Promise<string> {
		let result: string;

		const url = new URL(`${this.apiUrl}/records/download`);

		url.searchParams.append('fileName', fileName);

		if (generatePdf) {
			url.searchParams.append('generatePdf', 'true');
		}

		if (openWindow || redirect === false) {
			url.searchParams.append('redirect', 'false');
		}

		if ('userId' in etc && etc.userId) {
			url.searchParams.append('userId', etc.userId);
		}

		if ('organizationId' in etc && etc.organizationId) {
			url.searchParams.append('organizationId', etc.organizationId);
		}

		if ('shareToken' in etc && etc.shareToken) {
			url.searchParams.append('shareToken', etc.shareToken);
		}

		if ('recordIds' in etc && etc.recordIds) {
			for (const id of etc.recordIds) {
				url.searchParams.append('recordIds', id);
			}
		}

		if ('shareRecordIds' in etc && etc.shareRecordIds) {
			for (const id of etc.shareRecordIds) {
				url.searchParams.append('shareRecordIds', id);
			}
		}

		if ('recordTypes' in etc && etc.recordTypes) {
			for (const type of etc.recordTypes) {
				url.searchParams.append('recordTypes', type);
			}
		}

		if ('recordTypeTags' in etc && etc.recordTypeTags) {
			for (const tag of etc.recordTypeTags) {
				url.searchParams.append('recordTypeTags', tag);
			}
		}

		result = await ajax(
			url.toString(),
			'GET',
			'auth' in etc ? etc.auth : undefined,
			undefined,
			undefined,
			signal
		);

		if (result && openWindow) {
			window.open(result);
		}

		return result;
	}

	async recordTypeGet(auth: string, id: string): Promise<RecordType> {
		return await ajax(`${this.apiUrl}/records/types/${id}`, 'GET', auth);
	}

	async recordTypeList(auth: string, request: RecordTypeListRequest): Promise<ListResponse<RecordType>> {
		const url = new URL(`${this.apiUrl}/records/types`);

		if (request.organizationId) {
			url.searchParams.append('organizationId', request.organizationId);
		}

		if (request.tags?.length) {
			for (const tag of request.tags) {
				url.searchParams.append('tags', tag);
			}
		}

		if (request.isEnabled !== undefined) {
			url.searchParams.append('isEnabled', request.isEnabled.toString());
		}

		if (request.orderBy) {
			url.searchParams.append('orderBy', request.orderBy);
		}

		if (request.limit) {
			url.searchParams.append('limit', request.limit.toString());
		}

		if (request.skip) {
			url.searchParams.append('skip', request.skip.toString());
		}

		return await ajax(url.toString(), 'GET', auth);
	}

	async get({ recordId, ...etc }: GetRequest): Promise<Record> {
		const url = new URL(`${this.apiUrl}/records/${recordId}`);
		let auth: string;

		if ('shareToken' in etc && etc.shareToken) {
			url.searchParams.append('shareToken', etc.shareToken);
		} else if ('auth' in etc && etc.auth) {
			auth = etc.auth;
		}

		return await ajax(url.toString(), 'GET', auth);
	}

	async list({ orderBy, limit, skip, ...etc }: ListRequest): Promise<ListResponse<Record>> {
		const url = new URL(`${this.apiUrl}/records`);

		if ('recordTypes' in etc && etc.recordTypes) {
			for (const type of etc.recordTypes) {
				url.searchParams.append('recordTypes', type);
			}
		}

		if ('recordTypeTags' in etc && etc.recordTypeTags) {
			for (const type of etc.recordTypeTags) {
				url.searchParams.append('recordTypeTags', type);
			}
		}

		if ('organizationId' in etc && etc.organizationId) {
			url.searchParams.append('organizationId', etc.organizationId);
		}

		if ('userId' in etc && etc.userId) {
			url.searchParams.append('userId', etc.userId);
		}

		if ('isDeleted' in etc && etc.isDeleted !== undefined) {
			url.searchParams.append('isDeleted', etc.isDeleted.toString());
		}

		if (orderBy) {
			url.searchParams.append('orderBy', orderBy);
		}

		if (limit) {
			url.searchParams.append('limit', limit.toString());
		}

		if (skip) {
			url.searchParams.append('skip', skip.toString());
		}

		return await ajax(url.toString(), 'GET', 'auth' in etc ? etc?.auth : undefined);
	}

	async listHistory({ auth, recordId, orderBy, limit, skip }: ListHistoryRequest): Promise<ListResponse<RecordHistory>> {
		const url = new URL(`${this.apiUrl}/records/${recordId}/history`);

		if (orderBy) {
			url.searchParams.append('orderBy', orderBy);
		}

		if (limit) {
			url.searchParams.append('limit', limit.toString());
		}

		if (skip) {
			url.searchParams.append('skip', skip.toString());
		}

		return await ajax(url.toString(), 'GET', auth);
	}

	async performUpload(request: UploadRequest, fileName: string, file: File | string): Promise<UploadResponse> {
		const formData = new FormData();
	
		for (const key in request.fields) {
			if (request.fields.hasOwnProperty(key)) {
				formData.append(key, request.fields[key]);
			}
		}

		if (typeof file === 'string') {
			const splitDataURI = file.split(',');
			const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
			const mimeString = splitDataURI[0].split(':')[1].split(';')[0];
			const ia = new Uint8Array(byteString.length);

			for (let i = 0; i < byteString.length; i++) {
				ia[i] = byteString.charCodeAt(i);
			}

			formData.append('file', new Blob([ia], { type: mimeString }), fileName);
		} else {
			formData.append('file', file as any);
		}

		const result = await ajax(request.url, 'POST', undefined, formData);

		if (result) {
			throw new RecordError("UploadError", result);
		}

		return {
			recordId: request.recordId,
			tempFileName: request.tempFileName,
			fileName,
			downloadUrl: request.downloadUrl
		};
	}

	async requestUpload(options: TemporaryUploadRequest): Promise<UploadRequest>;
	async requestUpload(options: PermanentUploadRequest): Promise<UploadRequest>;
	async requestUpload(auth: string, { recordId, ...options }: UploadRequestOptions): Promise<UploadRequest>;
	async requestUpload(auth: string | TemporaryUploadRequest | PermanentUploadRequest, options?: UploadRequestOptions): Promise<UploadRequest> {
		if (typeof auth === 'object') {
			options = auth;
			auth = undefined as string;
		}

		const { recordId, ...ajaxOptions } = options;

		return await ajax(
			[this.apiUrl, 'records', 'requestupload', recordId].filter(Boolean).join('/'),
			recordId ? 'PUT': 'POST',
			auth,
			ajaxOptions
		);
	}

	async upload(options: TemporaryUploadOptions): Promise<UploadResponse>;
	async upload(auth: string, options: UploadOptions): Promise<UploadResponse>;
	async upload(auth: string | TemporaryUploadOptions, options?: UploadOptions): Promise<UploadResponse> {
		if (typeof auth === 'object') {
			options = auth;
			auth = undefined as string;
		}

		if (typeof options.file !== 'string' && typeof window !== 'undefined') {
			if (options.file.name) {
				const lastDot = options.file.name.lastIndexOf('.');

				if (lastDot > -1) {
					options.fileName += options.file.name.substring(lastDot);
				}
			}

			try {
				// Check for google docs upload
				await options.file.slice(0, 1).arrayBuffer();
			} catch (error) {
				throw new RecordError('RecordError', 'Trouble uploading docs? Email them to support@praoshealth.com or via the contact form in the Help button.');
			}
		}

		const { file, ...requestOptions } = options;

		const request = await this.requestUpload(auth, requestOptions);

		return await this.performUpload(request, options.fileName, file);
	}

	async upsert(auth: string, { recordId, ...options }: UpsertRequest): Promise<UpsertResponse> {
		return await ajax(
			[`${this.apiUrl}/records`, recordId].filter(Boolean).join('/'),
			'PUT',
			auth,
			options
		);
	}	

	async getStatistics(auth: string, options: GetStatistics): Promise<Statatistics> {
		const url = new URL(`${this.apiUrl}/records/statistics`);
		for(let [name, value] of Object.entries(options) ?? []) {
			url.searchParams.append(name, value);
		}
		return await ajax(
			url.toString(),
			'GET',
			auth
		);
	} 
}