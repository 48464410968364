import React, { useEffect, useState } from 'react';
import { Bar, BarDatum, BarLayer } from '@nivo/bar';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useTranslation } from 'react-i18next';
import { BarTotalLabels } from '../../nivo/bar';
import { useApi, useToaster } from '@praos-health/ui';
import { useAuth } from '@praos-health/ui-security';
import {
	StatisticsType,
	CompletionStatistics,
	StatisticsOptions
} from '@praos-health/professional-client';
import { IconButton, Toolbar } from '@mui/material';
import { addDays, beginningOfWeek, getUtc } from '@praos-health/core/utilities/date';
import { DateRange, getRange } from '@praos-health/web/components';
import { LimitedBackdrop } from '@praos-health/web/components/limited-backdrop';
import { InfoButtonPopper } from '@praos-health/web/components';
import { buildTickValues } from '../../nivo/axes';
import DownloadIcon from '@mui/icons-material/GetApp';
import { toCsv, sanitizeFilename } from '@praos-health/core/utilities/string';
import { downloadFile } from '../../common/download';
import { Title } from '@praos-health/web/components';
import { ChartWrapper, Main, StyledDateRangeSelect } from '../shared-components';
import { AppApi } from '../../app-api';

export interface BriefcaseProgressionBarData extends BarDatum {
	dateRange: string,
	completed: number,
	licensed: number,
	activated: number,
	inactive: number,
	neverActivated: number
}

interface Props {
	title?: string,
	description?: string,
	height: number,
	hideInactive?: boolean,
	showTotals?: boolean,
	accumulate?: boolean,
	organization?: string,
	initialDateRange?: DateRange
}

function buildBars(
	stats: CompletionStatistics[],
	startDate?: Date,
	endDate?: Date,
	accumulate = false
): BriefcaseProgressionBarData[] {
	const result: BriefcaseProgressionBarData[] = [];
	const formatter = new Intl.DateTimeFormat();

	if (!startDate && stats.length) {
		startDate = new Date(stats[0].date.year, stats[0].date.month - 1, stats[0].date.day);
	}

	if (!endDate) {
		endDate = new Date();
	}

	if (startDate) {
		let date = beginningOfWeek(startDate, false, true);
		let i = 0;

		while (date < endDate) {
			const item: BriefcaseProgressionBarData = {
				dateRange: `${formatter.format(date)} - ${formatter.format(addDays(6, date))}`,
				neverActivated:
					accumulate && result.length > 0 ? result[result.length - 1].neverActivated : 0,
				inactive: accumulate && result.length > 0 ? result[result.length - 1].inactive : 0,
				activated:
					accumulate && result.length > 0 ? result[result.length - 1].activated : 0,
				licensed: accumulate && result.length > 0 ? result[result.length - 1].licensed : 0,
				completed:
					accumulate && result.length > 0 ? result[result.length - 1].completed : 0
			};

			while (i < stats.length) {
				const curDate = new Date(
					stats[i].date.year,
					stats[i].date.month - 1,
					stats[i].date.day
				);

				if (addDays(7, date) <= curDate) {
					break;
				}

				if (curDate <= addDays(7, date)) {
					item.neverActivated = stats[i].neverActivated;
					item.inactive = stats[i].inactive;
					item.activated = stats[i].activated;
					item.licensed = stats[i].licensed;
					item.completed = stats[i].completed;
				}

				i++;
			}

			result.push(item);
			date = addDays(7, date);
		}
	}

	return result;
}

export function BriefcaseProgressionBar({
	initialDateRange,
	hideInactive,
	showTotals,
	accumulate,
	organization,
	title,
	description,
	height
}: Props): JSX.Element {
	const { t } = useTranslation();
	const api = useApi<AppApi>();
	const auth = useAuth();
	const layers: BarLayer<BriefcaseProgressionBarData>[] = [
		'grid',
		'axes',
		'bars',
		'markers',
		'legends'
	];
	const [isLoading, setIsLoading] = useState(false);
	const [dateRange, setDateRange] = useState(initialDateRange || DateRange.Year);
	const [data, setData] = useState<BriefcaseProgressionBarData[]>([]);
	const toast = useToaster();

	const ledgend = [
		{
			id: 'completed',
			label: t('Completed'),
			color: '#70ad47'
		},
		{
			id: 'licensed',
			label: t('Licensed'),
			color: '#ffc000'
		},
		{
			id: 'activated',
			label: t('Activated'),
			color: '#ff0000'
		}
	];

	if (!hideInactive) {
		ledgend.push(
			{
				id: 'neverActivated',
				label: t('PreActivated'),
				color: '#4473c4'
			},
			{
				id: 'inactive',
				label: t('Inactive'),
				color: '#a23A62'
			}
		);
	}

	if (showTotals) {
		layers.push(BarTotalLabels({ style: { fill: 'rgb(51, 51, 51)' } }));
	}

	useEffect(() => {
		setIsLoading(true);

		async function loadChart(): Promise<void> {
			const range = getRange(dateRange);

			range.start = beginningOfWeek(range.start, false, true);

			const options: StatisticsOptions = {
				startDate: getUtc(range.start),
				endDate: getUtc(range.end),
				accumulate: accumulate,
				useIsoWeek: true
			};

			if (organization === 'praos') {
				options.isMarketplace = true;
			} else if (organization !== 'all') {
				options.organization = organization;
			}

			const result = await api.professionalService.statistics(
				auth.session?.auth || '',
				StatisticsType.Completions,
				options
			);

			setData(buildBars(result, range.start, range.end, accumulate));
		}

		loadChart()
			.catch(async (e) => {
				if (e instanceof Error && (e.name === 'UnauthorizedError' || e.message === 'Unauthorized')) {
					toast('Your session has expired', 'error');
					return await auth.signout();
				}
				toast(e, 'error');
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [api, auth, organization, accumulate, dateRange, toast]);

	function handleDateRangeChange(value: DateRange) {
		setDateRange(value);
	}

	function handleExport() {
		const formatter = new Intl.DateTimeFormat();
		const range = getRange(dateRange);

		range.start = beginningOfWeek(range.start, false, true);

		downloadFile(
			sanitizeFilename(
				`${title} ${formatter.format(range.start)} to ${formatter.format(range.end)}.csv`
			),
			'text/csv;charset=utf-8;',
			[
				toCsv(
					!hideInactive
						? [
							t('Date'),
							t('Completed'),
							t('Licensed'),
							t('Activated'),
							t('PreActivated'),
							t('Inactive')
						]
						: [t('Date'), t('Completed'), t('Licensed'), t('Activated')]
				)
			]
				.concat(
					data.map((i) =>
						toCsv(
							!hideInactive
								? [
									i.dateRange,
									i.completed.toString(),
									i.licensed.toString(),
									i.activated.toString(),
									i.inactive.toString(),
									i.neverActivated.toString()
								]
								: [
									i.dateRange,
									i.completed.toString(),
									i.licensed.toString(),
									i.activated.toString()
								]
						)
					)
				)
				.join('\r\n')
		);
	}

	return (
		<Main>
			{(title || description) && (
				<Toolbar>
					<Title>{title}</Title>
					{description && <InfoButtonPopper text={description} />}
					<IconButton color="inherit" edge="end" onClick={handleExport} size="large">
						<DownloadIcon />
					</IconButton>
					<StyledDateRangeSelect
						value={dateRange}
						onChange={handleDateRangeChange}
					/>
				</Toolbar>
			)}
			<ChartWrapper>
				<AutoSizer disableHeight>
					{({ width }: { width: number }) =>
						width ? (
							<div style={{ width: `${width}px` }}>
								<Bar
									axisBottom={{
										tickSize: 5,
										tickPadding: 5,
										tickRotation: -60,
										tickValues: buildTickValues(data, 'dateRange', width, 35)
									}}
									data={data}
									keys={ledgend.map((i) => i.id)}
									indexBy="dateRange"
									height={height}
									width={width}
									margin={{ top: 50, right: 50, bottom: 180, left: 60 }}
									padding={0.3}
									valueScale={{ type: 'linear' }}
									indexScale={{ type: 'band', round: true }}
									colors={ledgend.map((i) => i.color)}
									borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
									labelSkipWidth={12}
									labelSkipHeight={12}
									labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
									legends={[
										{
											data: ledgend,
											dataFrom: 'keys',
											anchor: 'bottom',
											direction: 'row',
											justify: false,
											translateX: 0,
											translateY: 170,
											itemsSpacing: 2,
											itemWidth: 80,
											itemHeight: 20,
											itemDirection: 'bottom-to-top',
											itemOpacity: 0.85,
											symbolSize: 20,
											padding: 0,
											effects: [
												{
													on: 'hover',
													style: {
														itemOpacity: 1
													}
												}
											]
										}
									]}
									animate={false}
									layers={layers}
								/>
							</div>
						) : (
							<div>Loading...</div>
						)
					}
				</AutoSizer>
				<LimitedBackdrop open={isLoading} />
			</ChartWrapper>

		</Main>
	);
}
