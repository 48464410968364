import React from 'react';
import { BarCustomLayer, BarCustomLayerProps, ComputedBarDatum } from '@nivo/bar';
import { CSSProperties } from 'react';

export interface BarTotalLabelProps {
	className?: string,
	style?: CSSProperties
}

export function BarTotalLabels<T>(props?: BarTotalLabelProps): BarCustomLayer<T> {
	return ({ bars, yScale }: BarCustomLayerProps<T>) => {
		if (!bars.length) {
			return null;
		}

		// space between top of stacked bars and total label
		const labelMargin = 20;

		const groups = bars.reduce<{ [x: string]: ComputedBarDatum<T>[] }>((groups, item) => {
			const group = (groups[item.x] || []);

			group.push(item);
			groups[item.x] = group;

			return groups;
		}, {});

		return <>
			{
				Object.getOwnPropertyNames(groups).map(x => {
					const items = groups[x];
					const total = items.reduce((v, item) => v + (item.data.value || 0), 0);
					const width = items[0].width;
					const key = `${items[0].data.indexValue}-total`;

					return (
						<g
							transform={`translate(${x}, ${yScale(total) - labelMargin})`}
							key={key}
						>
							<text
								// add any class to the label here
								className = {props?.className}
								x={width / 2}
								y={labelMargin / 2}
								textAnchor="middle"
								alignmentBaseline="central"
								// add any style to the label here
								style={props?.style}
							>
								{total}
							</text>
						</g>
					);
				})
			}
		</>;
	};
}