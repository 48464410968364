import React, { useEffect, useState } from 'react';
import { Box, Button, FormControl, Grid, IconButton, InputLabel, Paper, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { Close, KeyboardArrowDown } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@praos-health/ui-security';
import { Organization } from '@praos-health/organizations-client';
import { LicenseType, LicenseTypeService, LicenseBodyService, LicenseBody } from '@praos-health/briefcase-client';
import { useApi, useToaster } from '@praos-health/ui';
import { AdvancedSearch } from '@praos-health/screening-client';
import { AppApi } from '../../../app-api';

export function initialAdvanceSearchState(): AdvancedSearch {
	return {
		text: '',
		firstName: '',
		lastName: '',
		email: '',
		licenseBody:'',
		licenseNumber: '',
		licenseType: ''
	};
}

interface AdvancedFiltersFormProps {
	onSearch?: (organizationId: string, applicantId: string, search: AdvancedSearch, delay?: boolean) => void,
	organizationId: string,
	applicantId: string,
	advancedSearch: AdvancedSearch,
	buttons?: React.ReactNode
}

export function AdvancedFiltersForm({
	organizationId,
	applicantId,
	advancedSearch,
	onSearch,
	buttons
}: AdvancedFiltersFormProps) {
	const api = useApi<AppApi>();
	const toast = useToaster();
	const { t } = useTranslation();
	const auth = useAuth();
	const [shouldShowAdvanced, setShouldShowAdvanced] = useState(false);
	const [licenseTypes, setLicenseTypes] = useState<LicenseType[] | undefined>([]);
	const [licenseStates, setLicenseStates] = useState<LicenseBody[] | undefined>([]);
	const [organizations, setOrganizations] = useState<Organization[]>([]);
	const filterChips: [string, string][] = [];

	if (applicantId) {
		filterChips.push(['AccountID', applicantId]);
	}

	for (const key in advancedSearch) {
		if (Object.prototype.hasOwnProperty.call(advancedSearch, key) && key !== 'text') {
			const value = advancedSearch[key as keyof AdvancedSearch];

			if (value) {
				filterChips.push([key.charAt(0).toUpperCase() + key.slice(1), value]);
			}
		}
	}

	useEffect(() => {
		async function loadOrganizations(): Promise<void> {
			if (auth.session?.organization) {
				setOrganizations([
					{
						_id: auth.session.organization._id,
						name: auth.session.organization.name
					}
				]);
				return;
			}

			const result = await api.organizationService.list(auth.session?.auth || '', {
				select: { name: 1 },
				status: 'APPROVED'
			});

			setOrganizations(result.list);
		}

		loadOrganizations().catch(async (e) => {
			if (e instanceof Error && (e.name === 'UnauthorizedError' || e.message === 'Unauthorized')) {
				toast('Your session has expired', 'error');
				return await auth.signout();
			}
			toast(e, 'error');
		});
	}, [api, auth, toast]);

	useEffect(() => {
		// Don't load the license types or bodies unless the advanced search is shown
		if (!shouldShowAdvanced || licenseTypes?.length) {
			return;
		}

		async function fetchLicenseTypes(): Promise<LicenseType[] | undefined> {
			const licenseService = new LicenseTypeService(process.env.REACT_APP_API_URL || '');

			if (auth.session) {
				return await licenseService.list( auth.session?.auth);
			}
		}

		async function fetchLicenseStates(): Promise<LicenseBody[] | undefined> {
			const licenseStateService = new LicenseBodyService(process.env.REACT_APP_API_URL || '');

			if (auth.session) {
				return await licenseStateService.list(auth.session?.auth, 'Nurse');
			}
		}

		Promise.all([
			fetchLicenseTypes(),
			fetchLicenseStates()
		])
			.then((results) => {
				const [licenseTypes, licenseStates] = results;
				setLicenseTypes(licenseTypes);
				setLicenseStates(licenseStates);
			})
			.catch((error) => {
				console.error('Error fetching licenses', error);
			});
	}, [api, auth, licenseTypes, shouldShowAdvanced]);

	function resetFilters(): void {
		applicantId = '';
		advancedSearch = initialAdvanceSearchState();
		search();
	}

	function search(delay?: boolean): void {
		if (onSearch) {
			onSearch(organizationId, applicantId, advancedSearch, delay);
		}
	}

	function setAdvancedSearch(value: AdvancedSearch, delay?: boolean) {
		advancedSearch = value;
		search(delay);
	}

	function handleApplicantChanged(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
		e.preventDefault();
		applicantId = e.target.value;
		search(true);
	}

	function handleOrganizationChanged(e: SelectChangeEvent<string>): void {
		e.preventDefault();
		organizationId = e.target.value;
		search();
	}

	function onKeyPress(e: React.KeyboardEvent<HTMLElement>): void {
		e.preventDefault();
		search();
	}

	return (
		<Grid item xs={12}>
			<Paper sx={{padding: '10px 20px'}}>
				<Box sx={{display: 'flex', flexDirection: {lg: 'row', xs: 'column'},  justifyContent: 'space-between'}}>
					<TextField
						sx={(theme) => ({
							margin: theme.spacing(1),
							minWidth: 320
						})}
						value={advancedSearch.text}
						onKeyDown={(e) => { e.key === 'Enter' && onKeyPress(e); }}
						onChange={(e) => { setAdvancedSearch({ ...advancedSearch, text: e.target.value }, !!e.target.value); }}
						label="Search" variant="standard"
					/>

					<Box sx={{display: 'flex', alignItems: 'center', justifyContent: {lg: 'center', xs: 'space-between'}, marginRight: '30px', gap: '20px'}}>
						{!auth.session?.organization && (
							<FormControl variant="standard" sx={(theme) => ({
								margin: theme.spacing(1),
								minWidth: 320
							})}>
								<InputLabel>{t('Organization')}</InputLabel>
								<Select
									variant="standard"
									native
									value={organizationId}
									onChange={handleOrganizationChanged}
									inputProps={{ name: 'organization' }}
								>
									<option aria-label="None" value="all">
										{t('All')}
									</option>
									<option aria-label="PraosHealth" value='praos'>
									Praos Health
									</option>
									{organizations.map((value) => {
										return (
											<option key={value._id} value={value._id}>
												{value.name}
											</option>
										);
									})}
								</Select>
							</FormControl>
						)}
						<Box>
							<Button onClick={()=>setShouldShowAdvanced(!shouldShowAdvanced)} sx={{'&:hover': {
								textDecoration: 'underline'
							}}}>
								<Typography sx={{fontSize: '12px', cursor: 'pointer', color: '#3b275e'}}>Advanced Search {filterChips?.length > 0 && `(${filterChips.length})`}</Typography> <KeyboardArrowDown fontSize='small' sx={{ transform: `rotate(${shouldShowAdvanced ? 0 : 180 }deg)` }} />
							</Button>
							{buttons}
						</Box>
					</Box>
				</Box>

				{/* filters chips  */}
				{ !shouldShowAdvanced && filterChips?.length > 0 &&
					<Box sx={{display: 'flex', gap: '10px', marginY: '15px', alignItems: 'center'}}>
						{
							filterChips.map(([key, value]) => (
								<Box key={key} sx={{display: 'flex', gap: 1, border: '1px solid #3d255f', color: '#3d255f', paddingY: '5px', paddingX: '8px', borderRadius: '20px'}}>
									<Typography fontSize='small' fontWeight='bold'>{t(key)}:</Typography>
									<Typography fontSize='small'>{value}</Typography>
								</Box>
							))
						}
						<IconButton sx={{'backgroundColor': '#3d255f',
							'color': '#fff', 'width': '20px', 'height': '20px ',
							'&:hover': {
								backgroundColor: '#3d255f',
								color: '#fff'}
						}} onClick={() => resetFilters()} size="small">
							<Close fontSize='small' />
						</IconButton>
					</Box>
				}

				{shouldShowAdvanced &&
					<Grid item xs={12}>
						<Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: '0px 10px 15px 10px', gap: '10px'}}>
							<Grid container rowSpacing={0} columnSpacing={2}>
								<Grid item lg={4} md={6} xs={12}>
									<TextField
										sx={() => ({
											mt: 1,
											width: '100%'
										})}
										onKeyDown={(e) => { e.key === 'Enter' && onKeyPress(e); }}
										value={advancedSearch.firstName}
										onChange={(e) => { setAdvancedSearch({ ...advancedSearch, firstName: e.target.value }, !!e.target.value); }}
										label="First Name" variant="standard"
									/>
								</Grid>
								<Grid item lg={4} md={6} xs={12}>
									<TextField
										sx={() => ({
											mt: 1,
											width: '100%'
										})}
										value={advancedSearch.lastName}
										onKeyDown={(e) => { e.key === 'Enter' && onKeyPress(e); }}
										onChange={(e) => setAdvancedSearch({ ...advancedSearch, lastName: e.target.value }, !!e.target.value)}
										label="Last Name" variant="standard"
									/>
								</Grid>
								<Grid item lg={4} md={6} xs={12}>
									<TextField
										sx={() => ({
											mt: 1,
											width: '100%'
										})}
										type='email'
										value={advancedSearch.email}
										onKeyDown={(e) => { e.key === 'Enter' && onKeyPress(e); }}
										onChange={(e) => setAdvancedSearch({ ...advancedSearch, email: e.target.value }, !!e.target.value)}
										label="Email" variant="standard"
									/>
								</Grid>
								<Grid item lg={4} md={6} xs={12}>
									<TextField
										sx={() => ({
											mt: 1,
											width: '100%'
										})}
										onKeyDown={(e) => { e.key === 'Enter' && onKeyPress(e); }}
										value={advancedSearch.licenseNumber}
										onChange={(e) => setAdvancedSearch({ ...advancedSearch, licenseNumber: e.target.value }, !!e.target.value)}
										label="License Number" variant="standard"
									/>
								</Grid>
								<Grid item lg={4} md={6} xs={12}>
									<FormControl variant="standard" sx={{ mt: 1, width: '100%' }}>
										<InputLabel>License Type</InputLabel>
										<Select
											onKeyDown={(e) => { e.key === 'Enter' && onKeyPress(e); }}
											native value={advancedSearch.licenseType} onChange={(e) => setAdvancedSearch({ ...advancedSearch, licenseType: e.target.value })} label="Grouping">
											<option aria-label="None" value="" />
											<optgroup label="Allied Health">
												{licenseTypes?.filter((type)=>type?.profession === 'Allied Health').map((item, index) =>
													<option key={index} value={item.abbr}>{item.abbr + '-' + item.name}</option>
												)}
											</optgroup>
											<optgroup label="Nurse">
												{licenseTypes?.filter((type)=>type?.profession === 'Nurse').map((item, index) =>
													<option key={index} value={item.abbr}>{item.abbr + '-' + item.name}</option>
												)}
											</optgroup>
										</Select>
									</FormControl>
								</Grid>
								<Grid item lg={4} md={6} xs={12}>
									<FormControl variant="standard" sx={{ mt: 1, width: '100%' }}>
										<InputLabel>License State</InputLabel>
										<Select
											onKeyDown={(e) => { e.key === 'Enter' && onKeyPress(e); }}
											native value={advancedSearch.licenseBody} onChange={(e) => setAdvancedSearch({ ...advancedSearch, licenseBody: e.target.value })} label="Grouping">
											<option aria-label="None" value="" />
											{licenseStates?.map((state, index) =>
												<option key={index} value={state.name}>{state.name}</option>
											)}
										</Select>
									</FormControl>
								</Grid>
								<Grid item lg={4} md={6} xs={12}>
									<TextField
										sx={() => ({
											mt: 1,
											width: '100%'
										})}
										value={applicantId}
										onKeyDown={(e) => { e.key === 'Enter' && onKeyPress(e); }}
										onChange={handleApplicantChanged}
										label="Account ID" variant="standard"
									/>
								</Grid>
								<Grid item lg={4} md={6} xs={12}>
									{/* <TextField
											sx={(theme) => ({
													mt: 1,
													width: '100%'
											})}
											defaultValue={searchParams}
											onChange={(e)=>setSearchParams(e.target.value)}
											label="City" variant="standard"
									/> */}
								</Grid>
								<Grid item lg={4} md={6} xs={12}>
									{/* <FormControl variant="standard" sx={{ mt: 1, width: "100%" }}>
												<InputLabel>Certification</InputLabel>
												<Select
														labelId="demo-simple-select-filled-label"
														value={''}
														onChange={handleChange}
												>
												<MenuItem value="">
														<em>None</em>
												</MenuItem>
												<MenuItem value={20}>CPR</MenuItem>
												<MenuItem value={20}>BLS</MenuItem>
												<MenuItem value={30}>ALS/ACLS</MenuItem>
												<MenuItem value={10}>PALS</MenuItem>
												<MenuItem value={20}>PICC</MenuItem>
												<MenuItem value={30}>TNCC</MenuItem>
												<MenuItem value={10}>ENPC</MenuItem>
												<MenuItem value={20}>STABLE</MenuItem>
												<MenuItem value={30}>NRC</MenuItem>
												<MenuItem value={30}>Others</MenuItem>
												</Select>
										</FormControl> */}
								</Grid>
								<Grid item lg={4} md={6} xs={12}>
									{/* <FormControl variant="standard" sx={{ mt: 1, width: "100%" }}>
												<InputLabel id="demo-simple-select-filled-label">State</InputLabel>
												<Select
														labelId="demo-simple-select-filled-label"
														id="demo-simple-select-filled"
														value={''}
														onChange={handleChange}
												>
												<MenuItem value="">
														<em>None</em>
												</MenuItem>
												<MenuItem value={10}>CPR</MenuItem>
												<MenuItem value={20}>BLS</MenuItem>
												<MenuItem value={30}>Others</MenuItem>
												</Select>
										</FormControl> */}
								</Grid>
								<Grid item lg={4} md={6} xs={12}>
									{/* <FormControl variant="standard" sx={{ mt: 1, width: "100%" }}>
												<InputLabel id="demo-simple-select-filled-label">Prof. Certificate</InputLabel>
												<Select
														labelId="demo-simple-select-filled-label"
														id="demo-simple-select-filled"
														value={''}
														onChange={handleChange}
												>
												<MenuItem value="">
														<em>None</em>
												</MenuItem>
												<MenuItem value={10}>CPR</MenuItem>
												<MenuItem value={20}>BLS</MenuItem>
												<MenuItem value={30}>ALS/ACLS</MenuItem>
												<MenuItem value={30}>Others</MenuItem>
												</Select>
										</FormControl> */}
								</Grid>
								<Grid item lg={4} md={6} xs={12}>
									{/* <TextField
												sx={(theme) => ({
														mt: 1,
														width: '100%'
												})}
												type='number'
												defaultValue={searchParams}
												onChange={(e)=>setSearchParams(e.target.value)}
												label="Zipcode" variant="standard"
										/>
										<FormControl variant="standard" sx={{ mt: 1, width: "100%" }}>
												<InputLabel id="demo-simple-select-filled-label">Specialty</InputLabel>
												<Select
														labelId="demo-simple-select-filled-label"
														id="demo-simple-select-filled"
														value={''}
														onChange={handleChange}
												>
												<MenuItem value="">
														<em>None</em>
												</MenuItem>
												<MenuItem value={10}>CPR</MenuItem>
												<MenuItem value={20}>BLS</MenuItem>
												<MenuItem value={30}>ALS/ACLS</MenuItem>
												<MenuItem value={30}>Others</MenuItem>
												</Select>
										</FormControl> */}
								</Grid>
							</Grid>
							<Box sx={{display: 'flex', justifyContent: 'flex-end', gap: '5px'}}>
								<Button onClick={resetFilters} variant="outlined" sx={{width: 'fit-content', marginTop: '20px', borderRadius: '50px'}}>
									Reset
								</Button>
								<Button onKeyDown={(e) => onKeyPress(e)} onClick={() => { search(), setShouldShowAdvanced(false); }} variant="contained" sx={{width: 'fit-content', marginTop: '20px', borderRadius: '50px'}}>
									Search
								</Button>
							</Box>
						</Box>
					</Grid>
				}
			</Paper>
		</Grid>
	);
}