import React, { useCallback, useEffect, useState } from 'react';
import { Backdrop, Box, Button, ButtonProps, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, OutlinedInput, Tooltip, Typography, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { grey, blue, purple } from '@mui/material/colors';
import UploadIcon from '@mui/icons-material/Upload';
import { FlatScreening, LicenseVerificationLicense, LicenseVerificationTask } from '@praos-health/screening-client';
import { useAuth } from '@praos-health/ui-security';
import { useApi, useToaster } from '@praos-health/ui';
import { RecordService, UploadResponse } from '@praos-health/records-client';
import { LicenseUrl } from '@praos-health/briefcase-client';
import { Link } from 'react-router-dom';
import { InfoOutlined } from '@mui/icons-material';
import { Dayjs } from 'dayjs';
import CandidateInformation from '../candidate-information';
import LicenseInformation from '../license-information';
import { AppApi } from '../../../app-api';
import { LicenseUpdateType } from '../credentialing-data-table';
import classes from './verify-task-dialog.module.css';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import EditOffIcon from '@mui/icons-material/EditOff';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
		padding: theme.spacing(2)
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(1)
	}
}));

const VisuallyHiddenInput = styled('input')({
	clip: 'rect(0 0 0 0)',
	clipPath: 'inset(50%)',
	height: 1,
	overflow: 'hidden',
	position: 'absolute',
	bottom: 0,
	left: 0,
	whiteSpace: 'nowrap',
	width: 1
});

const ColorButton = styled(Button)<ButtonProps>(() => ({
	'color': grey[300],
	'backgroundColor': '#cd5c5c',
	'&:hover': {
		color: grey[300],
		backgroundColor: '#cd5c5c'
	}
}));

interface VerifyTaskModalProps {
	handleClose: () => void,
	handleVerify: () => void,
	handleUnableVerify: () => void,
	modelOpen: boolean,
	taskId: any,
	licenseIssueDate: Dayjs | null | undefined,
	licenseExpirationDate: Dayjs | null | undefined,
	licenseState: string | undefined,
	licenseNumber: string | undefined,
	setIsDirty: (value: boolean) => void,
	uploadedFile: UploadResponse | null,
	setUploadedFile: (value: UploadResponse | null) => void,
	incompleteLicenseData: boolean,
	setIncompleteLicenseData: (value: boolean) => void,
	updateLicenseStates: (license: LicenseVerificationLicense | undefined, updateType?: LicenseUpdateType) => void
}

function VerifyTaskDialog({
	handleClose,
	handleVerify,
	handleUnableVerify,
	taskId,
	licenseIssueDate,
	licenseExpirationDate,
	licenseState,
	licenseNumber,
	setIsDirty,
	incompleteLicenseData,
	setIncompleteLicenseData,
	uploadedFile,
	setUploadedFile,
	updateLicenseStates,
	modelOpen
}: VerifyTaskModalProps) {
	const auth = useAuth();
	const api = useApi<AppApi>();
	const toast = useToaster();
	const [isLoading, setIsLoading] = useState(false);
	const [isFileUploading, setIsFileUploading] = useState(false);
	const [selectedFile, setSelectedFile] = useState<File | null>(null);
	const [screeningDataObject, setScreeningDataObject] = useState<FlatScreening<LicenseVerificationTask> | null>(null);
	const [profession, setProfession] = useState<string | undefined>('');
	const [task, setTask] = useState<LicenseVerificationTask | null>(null);

	const [boardUrlDetails, setBoardUrlDetails] = useState<LicenseUrl | null>(null);
	const [canEditBoardUrl, setCanEditBoardUrl] = useState<boolean>(false);
	const [updatedBoardUrl, setUpdatedBoardUrl] = useState<string | null>(null);
	const [isUpdatingdUrl, setIsUpdatingUrl] = useState<boolean>(false);

	const fetchScreeningObject = useCallback(async () => {
		if (auth.session && taskId) {
			setIsLoading(true);
			try {
				const result = await api.screeningService.getTask<LicenseVerificationTask>(
					auth.session.auth,
					taskId
				);
				setScreeningDataObject(result);
				setTask(result?.task);
			} catch (error: any) {
				toast(error, 'error');
			} finally {
				setIsLoading(false);
			}
		}
	}, [api, auth, taskId, toast]);

	const fetchBoardUrls = useCallback(async (licenseType: string, licenseBody: string) => {
		try {
			if (auth.session) {
				const result = (await api.urlService.lookupUrl(
					licenseType,
					{
						licenseBody: licenseBody,
						organization: auth.session.organization?._id,
						responseType: 'object'
					}
				)) as LicenseUrl;
				setBoardUrlDetails(result);
				setUpdatedBoardUrl(result.url);
			}
		} catch (error: any) {
			toast(error, 'error');
		}
	}, [api, auth, toast]);

	const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files; // Get files array

		if (files) {
			const file = files[0];

			if (file) {
				setSelectedFile(file);

				try {
					await handleUpload(file);
				} catch (e) {
					toast(e as Error, 'error');
				} finally {
					setIsFileUploading(false);
				}
			}
		}
	};

	const handleUpload = async (file: File) => {
		if (!file) {
			return;
		}
		if (file.size / 1e+6 > 10) {
			setSelectedFile(null);
			return toast('File too large. Please upload a file under 10MB.');
		} else {
			const service = new RecordService(process.env.REACT_APP_API_URL || '');

			if (auth.session) {
				setIsFileUploading(true);
				const fileUpload = await service.upload(
					{
						file,
						fileName: 'License PSV'
					}
				);
				setUploadedFile(fileUpload);
				setIsFileUploading(false);
				return fileUpload;
			}
		}
	};

	function truncateUrl(url: string, maxLength = 20) {
		if (url.length <= maxLength) {
			return url;
		}

		const truncatedUrl = url.substring(0, maxLength - 3);
		return `${truncatedUrl}...`;
	}

	const handleDialogClose = () => {
		setSelectedFile(null);
		setBoardUrlDetails(null);
		setUpdatedBoardUrl(null);
		setCanEditBoardUrl(false);
		setScreeningDataObject(null);
		handleClose();
	};

	const onUpdateBoardUrl = async () => {
		if (!updatedBoardUrl || !boardUrlDetails) return;

		setIsUpdatingUrl(true);
		try {
			const updatedLicenseDetails = await api.urlService.updateLicenseUrl(
				auth.session?.auth || '',
				boardUrlDetails._id || '',
				updatedBoardUrl
			);
			setBoardUrlDetails(updatedLicenseDetails);
		} catch (err: any) {
			toast(err.message, 'error');
		}
		setIsUpdatingUrl(false);
		setCanEditBoardUrl(false);
	};

	useEffect(() => {
		fetchScreeningObject();
		setUploadedFile(null);
	}, [taskId, fetchScreeningObject, setUploadedFile]);

	useEffect(() => {
		const license = task?.applicant?.licenses?.[0];
		if (task) {
			fetchBoardUrls(license?.licenseType ?? '', license?.licenseBody ?? '');
		}

		setProfession(task?.applicant?.profession);
		updateLicenseStates(license);
	}, [task, fetchBoardUrls, updateLicenseStates]);

	return (
		<BootstrapDialog
			onClose={handleDialogClose}
			maxWidth={false}
			aria-labelledby="customized-dialog-title"
			open={modelOpen}
			fullWidth={false}
		>
			<DialogTitle fontWeight='bold' sx={{ m: 0, p: 2 }} id="verify-task-dialog">
				Verify Task
			</DialogTitle>
			<IconButton
				aria-label="close"
				onClick={handleDialogClose}
				sx={{
					position: 'absolute',
					right: 8,
					top: 8,
					color: (theme) => theme.palette.grey[500]
				}}
			>
				<CloseIcon />
			</IconButton>

			<DialogContent dividers>
				<Box sx={{ width: '750px', display: 'flex', gap: '15px', padding: '10px 10px 20px 10px', justifyContent: 'space-between' }}>
					{/* Candidate Information */}
					<CandidateInformation
						applicant={task?.applicant}
						applicantId={screeningDataObject?.applicantIntId || screeningDataObject?.applicantId}
						organization={screeningDataObject?.organization?.name}
					/>

					{/* License Information */}
					<LicenseInformation
						license={task?.applicant?.licenses?.[0]}
						nursys={screeningDataObject?.nursys}
						licenseIssueDate={licenseIssueDate}
						licenseExpirationDate={licenseExpirationDate}
						licenseState={licenseState}
						licenseNumber={licenseNumber}
						setIsDirty={(value) => setIsDirty(value)}
						profession={profession}
						setIncompleteLicenseData={(value) => setIncompleteLicenseData(value)}
						updateLicenseStates={updateLicenseStates}
						modalType='verify'
					/>
				</Box>
				<Box
					sx={{
						width: '750px',
						display: 'flex',
						gap: '15px',
						flexDirection: 'column'
					}}
				>
					<Typography variant='h6' fontWeight='bold' textAlign='center'>
						Instructions for License Upload
					</Typography>
					<Box sx={{ display: 'flex', gap: '15px', width: '100%' }}>
						<Box
							sx={{
								display: 'flex',
								gap: '15px',
								flexDirection: 'column',
								backgroundColor: blue[50],
								padding: 2,
								borderRadius: '10px',
								width: '100%'
							}}
						>
							<Typography variant='subtitle2'>
								1: Visit the board URL to retrieve license document
							</Typography>
							<Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
								<Typography fontWeight='bold' variant='subtitle2'>
									Board URL(s):
								</Typography>
								{
									canEditBoardUrl ?
										(
											<OutlinedInput
												value={updatedBoardUrl}
												onChange={(event) => setUpdatedBoardUrl(event.target.value)}
												sx={{ height: '30px' }}
												endAdornment={
													<InputAdornment position="end">
														<IconButton edge="end">
															<ClearIcon
																onClick={() => setUpdatedBoardUrl('')}
																className={classes.updateIcon} />
														</IconButton>
													</InputAdornment>
												}
											/>
										) :
										(
											boardUrlDetails ? (
												<Box sx={{ display: 'flex', flexDirection: 'column' }}>
													<Tooltip title={boardUrlDetails.url} arrow>
														<Link
															to={boardUrlDetails.url}
															target='_blank'
															style={{ textDecoration: 'none' }}
														>
															<Typography
																sx={{
																	'&:hover': {
																		textDecoration: 'underline'
																	},
																	'color': purple[800]
																}}
																variant='subtitle2'
															>
																{truncateUrl(boardUrlDetails.url, 30)}
															</Typography>
														</Link>
													</Tooltip>
												</Box>
											) :
												(
													'-'
												)
										)
								}
								{
									canEditBoardUrl ?
										(
											isUpdatingdUrl ?
												(
													<Box sx={{ display: 'flex' }}>
														<CircularProgress size={20} />
													</Box>
												) :
												(
													<>
														<CheckIcon
															onClick={onUpdateBoardUrl}
															className={classes.updateIcon}
															color='success' />
														<EditOffIcon
															onClick={() => {
																setCanEditBoardUrl(false);
																setUpdatedBoardUrl(boardUrlDetails?.url || '');
															}}
															className={classes.updateIcon}
															color='error' />
													</>
												)
										) :
										(
											<EditIcon
												onClick={() => setCanEditBoardUrl(true)}
												className={classes.updateIcon} />
										)
								}
							</Box>
						</Box>

						<Box
							sx={{
								display: 'flex',
								gap: '15px',
								flexDirection: 'column',
								alignItems: 'center',
								backgroundColor: blue[50],
								padding: 2,
								borderRadius: '10px',
								width: '100%'
							}}
						>
							<Box
								sx={{
									display: 'flex',
									gap: '15px',
									flexDirection: 'column',
									justifyContent: 'center',
									alignItems: 'center'
								}}
							>
								<Typography variant='subtitle2'>
									2: Upload the license document here
								</Typography>
								<Box sx={{ position: 'relative' }}>
									<Button
										sx={{ width: 'fit-content' }}
										component='label'
										variant='contained'
										startIcon={<UploadIcon />}
									>
										{isFileUploading ? 'File Uploading...' : 'Upload file'}
										<VisuallyHiddenInput
											type='file'
											accept='image/*,.pdf,.doc,.docx,.xls,.xlsx,.page,.gif,.rtf'
											id='file'
											multiple
											onChange={handleFileChange}
										/>
									</Button>
									<Box
										sx={{
											position: 'absolute',
											right: -8,
											top: -8,
											zIndex: 50
										}}
									>
										<Tooltip
											placement='top-end'
											title='Please upload the license document in pdf, doc, docx, jpeg, page, txt, gif, rtf or png format. The file must be smaller than 10MB'
											arrow
										>
											<InfoOutlined
												fontSize='small'
												color='primary'
												sx={{ backgroundColor: '#fff', borderRadius: '10px' }}
											/>
										</Tooltip>
									</Box>
								</Box>
							</Box>
							{selectedFile && !isFileUploading && (
								<Box sx={{ display: 'flex', alignItems: 'center', ml: '5px' }}>
									<Link to={uploadedFile?.downloadUrl || ''} target='_blank'>
										<Typography color={grey[600]}>
											{selectedFile.name.length > 24
												? selectedFile.name.slice(0, 25) + '...'
												: selectedFile.name}
										</Typography>
									</Link>
									<IconButton
										onClick={() => {
											setSelectedFile(null);
										}}
									>
										<CloseIcon fontSize='small' />
									</IconButton>
								</Box>
							)}
						</Box>
					</Box>
				</Box>
			</DialogContent>

			<DialogActions>
				<Button
					disabled={!uploadedFile || incompleteLicenseData}
					variant='contained'
					color='primary'
					sx={{ borderRadius: '50px' }}
					onClick={() => {
						setSelectedFile(null);
						handleVerify();
					}}
				>
					Complete Verification
				</Button>
				<ColorButton
					disabled={incompleteLicenseData}
					variant='contained'
					onClick={() => {
						setSelectedFile(null);
						handleUnableVerify();
					}}
					sx={{ borderRadius: '50px' }}
				>
					Unable to Verify
				</ColorButton>
			</DialogActions>

			<Backdrop
				sx={(theme) => ({
					zIndex: theme.zIndex.drawer + 1,
					color: '#fff'
				})}
				open={isLoading}
			>
				<CircularProgress color='inherit' />
			</Backdrop>
		</BootstrapDialog>
	);
}

export default React.memo(VerifyTaskDialog);