import React, { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, IconButton, TextareaAutosize, Typography, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CloseLicenseVerificationOptions, FlatScreening } from '@praos-health/screening-client';
import { useAuth } from '@praos-health/ui-security';
import { useApi, useToaster } from '@praos-health/ui';
import { Dayjs } from 'dayjs';
import { UploadResponse } from '@praos-health/records-client';
import { AppApi } from '../../../app-api';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
		padding: theme.spacing(2)
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(1)
	}
}));

interface ConfirmCloseLicenseDialogProps {
	handleClose: () => void,
	optionalMessage?: boolean,
	dialogMessage?: string,
	modelOpen: boolean,
	buttonColor?: 'primary' | 'inherit' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
	buttonText?: string,
	selectedTask?: FlatScreening,
	isVerify?: boolean,
	licenseIssueDate: Dayjs | null | undefined,
	licenseExpirationDate: Dayjs | null | undefined,
	licenseState: string | undefined,
	licenseNumber: string | undefined,
	uploadedFile: UploadResponse | null,
	isDirty: boolean
}

const ConfirmCloseLicenseDialog = ({
	handleClose,
	optionalMessage,
	dialogMessage = '',
	buttonColor = 'primary',
	buttonText = 'Confirm',
	modelOpen,
	selectedTask,
	isVerify = true,
	licenseIssueDate,
	licenseExpirationDate,
	licenseState,
	licenseNumber,
	uploadedFile,
	isDirty = false
}: ConfirmCloseLicenseDialogProps) => {
	const auth = useAuth();
	const api = useApi<AppApi>();
	const [isLoading, setIsLoading] = useState(false);
	const toast = useToaster();
	const [additionalNotes, setAdditionalNotes] = useState('');

	const handleSubmit = async () => {
		try {
			if (auth.session){
				setIsLoading(true);
				const license = {
					licenseBody: licenseState,
					licenseNumber: licenseNumber,
					issueDate: new Date(String(licenseIssueDate)),
					expirationDate: new Date(String(licenseExpirationDate))
				};
				const note = additionalNotes ?? 'Could Not Verify';

				let apiPayload: CloseLicenseVerificationOptions;

				if (isVerify) {
					apiPayload = {
						verified: true
					};
				} else {
					apiPayload = {
						verified: false,
						note: note
					};
				}

				if (isDirty) {
					apiPayload.license = license;
				}

				if (uploadedFile) {
					apiPayload.recordId = uploadedFile.recordId;
					apiPayload.tempFileName = uploadedFile.tempFileName;
				}

				// Make the API call
				await api.screeningService.closeLicenseVerification(
					auth.session?.auth,
					selectedTask?.task?.id,
					apiPayload
				);
			}
		} catch (error: any) {
			toast(error, 'error');
		} finally {
			setIsLoading(false);
			setAdditionalNotes('');
			handleClose();
		}
	};

	return (
		<BootstrapDialog
			onClose={handleClose}
			aria-labelledby="customized-dialog-title"
			open={modelOpen}
		>
			<IconButton
				aria-label="close"
				onClick={() => {
					setAdditionalNotes('');
					setIsLoading(false);
					handleClose();
				}}
				sx={{
					position: 'absolute',
					right: 8,
					top: 8,
					color: (theme) => theme.palette.grey[500]
				}}
			>
				<CloseIcon />
			</IconButton>

			<DialogContent>
				{ optionalMessage ? (
					<Box sx={{ width: '400px', display: 'flex', gap: '5px', flexDirection: 'column', justifyContent: 'center'}}>
						<Typography fontWeight='bold' variant='h6'>Unable to Verify?</Typography>
						<Typography variant='subtitle2'>{dialogMessage}</Typography>
						<TextareaAutosize aria-label="minimum height" minRows={5} value={additionalNotes} onChange={(e)=>setAdditionalNotes(e.target.value)} placeholder="Additional notes (this field is optional)" />
					</Box>
				) : (
					<Box sx={{ width: '350px', display: 'flex'}}>
						<Typography fontWeight='bold' variant='subtitle2' sx={{width: '80%'}}>{dialogMessage}</Typography>
					</Box>
				)}
			</DialogContent>

			<DialogActions>
				<Button disabled={isLoading} variant="contained" color={buttonColor} sx={{borderRadius: '50px'}} onClick={handleSubmit}>
					{isLoading ? 'Submitting' : buttonText}
				</Button>
			</DialogActions>
		</BootstrapDialog>
	);
};

export default ConfirmCloseLicenseDialog;