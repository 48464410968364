import { ApplicationsByWeekStatistics, Job, parseJob, JobDurationUnit, JobLocation, JobRate, JobType, TotalStatistics, JobAuditTrail, parseAuditTrail, DnrType, parseDnrOrganization, DnrOrganization, WeeklyStatistics, MetricsStatistics, ByStateStatistics, ApplicationsByStateStatistics, RecruiterStatistics, jobToApplicationV1, jobToV1, RateFrequency, JobApplication } from "./models";
import { clone, toJson } from "@praos-health/core/utilities/object";
import { replaceAll } from '@praos-health/core/utilities/string'
import { getUtc, midnight } from "@praos-health/core/utilities/date";
import { JobError } from "./job-error";
import { DailyStatistics, DailyStatisticType, parseDailyStatistic } from "./models/daily-statistics";
import { JobSettings } from "./models/job-settings";
import { AutocompleteOptions } from "./models/autocomplete";

export type PostJobOptions = {
	type: JobType,
	thirdPartyId?: string,
	title: string,
	description?: string,
	keywords?: string[],
	licenseType: string,
	specialties?: string[],
	location: JobLocation,
	certification?: string,
	additionalCertifications?: string[],
	minimumEducation?: string,
	minimumExperience?: string | number,	
	rate?: JobRate,
	startDate?: Date,
	timezone?: number,
	timezoneText?: string,
	duration?: number,
	durationUnit: JobDurationUnit,
	sendOrientation: boolean,
	totalRequirement: number,
	clientId?: string,
	departmentId?: string,
	isMarketplace?: boolean,
	isIrp?: boolean,
	isTelehealth?: boolean,
	isEvergreen?: boolean,
	isSilent?: boolean,
	isExclusive?: boolean,
	autoRePost?: boolean,
	recurrentDates?: Date[]
}

export type UpdateJobOptions = {
	title?: string,
	description?: string,
	keywords?: string[],
	minimumEducation?: string,
	minimumExperience?: string | number,
	rate?: JobRate,
	startDate?: number,
	timezone?: number,
	timezoneText?: string,
	duration?: number,
	durationUnit: 'Minutes' | 'Hours' | 'Days' | 'Weeks' | 'Months' | 'Full-time' | 'Part-time',
	sendOrientation?: boolean,
	totalRequirement?: number,
	departmentId?: string,
	isMarketplace?: boolean,
	isIrp?: boolean,
	isTelehealth?: boolean,
	isEvergreen?: boolean,
	isExclusive?: boolean,
	autoRePost?: boolean,
	location?: JobLocation,
	specialties?: string[]
}

export type AddDnrOptions = {
	organization?: string,
	dnrType: DnrType
}

export type GetJobOptions = {
	status?: 'OPEN' | 'APPLIED' | 'ACTIVE' | 'UPCOMING' | 'SCHEDULED' | 'IN_PROGRESS' | 'EXCEPTION' | 'APPROVED' | 'PAST' | 'HISTORY',
	limit?: number,
	skip?: number,
};

export type ListJobSelect = 'oneAppPerJob' | 'jobsOnly' | 'latLong';

export type ListJobsOptions = {
	/**
	 * @deprecated Use select instead.
	*/
	jobsOnly?: boolean,

	/**
	 * @deprecated Use select instead.
	*/
	oneAppPerJob?: boolean,
	organizationId?: string,
	organizationPathName?: string,
	sortBy?: 'createdAt' | 'startDate' | 'rate' | 'distance' | 'isExclusive',
	limit?: number,
	skip?: number,
	thirdPartyId?: string,
	startDate?: Date | 'ASAP',
	startDateTo?: Date,
	timezone?: number,
	favorite?: boolean,
	distance?: number,
	owner?: string,
	status?: 'OPEN' | 'APPLIED' | 'ACTIVE' | 'UPCOMING' | 'SCHEDULED' | 'IN_PROGRESS' | 'EXPIRED' | 'EXCEPTION' | 'APPROVED' | 'HISTORY' | 'PAST' | 'REJECTED' | 'CANCELLED_BY_ORG'| 'CANCELLED_BY_PROF' | 'ALL',
	filterHistoryBy?: 'APPLICATIONS' | 'COMPLETED' | 'OTHER',
	select?: ListJobSelect,
	search?: string,
	candidateSearch?: string,
	type?: JobType,
	shiftTime?: 'AM' | 'PM',
	rateFrequency?: RateFrequency.Hourly | RateFrequency.Daily | RateFrequency.Weekly | RateFrequency.Monthly | RateFrequency.Yearly,
	rate?: number,
	cities?: CityPreference[] | string,
	specialties?: Specialty[] | string,
	licenseType?: LicenseType[] | string,
	order?: 'ASC' | 'DESC',
	isExclusive?: boolean,
	ignoreMatching?: boolean
};

export type Specialty = {
	profession?: string,
	name?: string
};

export type LicenseType = {
    profession?: string,
	abbr?: string
};

export type CityPreference = {
	city?: string,
	state?: string
};

export enum OrganizationRating {
	Competent = 1,
	Skilled = 2,
	Professional = 3,
	ProfessionalDnr = 4,
	ClinicalDnr = 5
};

export enum StatisticsType {
	ApplicationsByState = 'applicationsbystate',
	ApplicationsByWeek = 'applicationsbyweek',
	ByState = 'bystate',
	Metrics = 'metrics',
	DownloadMetrics = 'downloadmetrics',
	Recruiter = 'recruiter',
	Totals = 'totals',
	Weekly = 'weekly',
	Placements = 'placements'
}

export type StatisticsOptions = {
	isMarketplace?: boolean,
	organization?: string,
	date?: Date,
	startDate?: Date,
	endDate?: Date,
	useIsoWeek?: boolean,
	accumulate?: boolean,
	specialty?: string,
	timeFrame?: string
}

export type ReviewOrganizationOptions = {
	rating?: number,
	review?: string
}

export type ReviewApplicantOptions = {
	isOriented?: boolean,
	badge?: boolean,
	rating?: OrganizationRating,
	review?: string
}

export type ListJobsResult = {
	list: Job[],
	count: number
}

export type ListAuditResult = {
	list: JobAuditTrail[],
	count: number
}

export type ApproveOptions = {
	timesheet?: {
		clockIn: boolean,
		adjustedTimestamp: Date | null
	}[]
}

export type SubmitOptions = {
	availableDate?: Date,
	requestedTimeOff?: {
    fromDate: Date,
    toDate: Date
	}
}

export type ConfirmOptions = {
	startDate: Date,
	endDate?: Date,
	totalContractHrs?: number
}

export type UpdateOptions = {
	startDate?: Date,
	endDate?: Date,
	duration?: number,
	durationUnit: 'Minutes' | 'Hours' | 'Days' | 'Weeks' | 'Months' | 'Full-time' | 'Part-time',
	jobType?: JobType,
	respect?: true
}

export type DownloadMetrics = {
	applicationCsv: string;
	jobMetricsCsv: string;
}

export interface JobApplyResult extends Job {
	message?: string
}

export type OrganizationJobCountResponse = {
  id: string,
  logoURL?: string,
  name?: string,
  coBrandedUrl?: string,
  coBrandedUrl2?: string,
  jobCount?: number
}

type Professional = {
	firstName?: string,
	lastName?: string,
	email?: string,
	phoneNumber?: string,
	profession?: string,
	briefcase?: {
		licenses?: {
			licenseType: string
		}[],
        specialties: string[],
        yearsOfExperience: string
	},
	jobs?: {
		workStates?: string[] | null,
		workCities?: {
			city?: string,
			state?: string,
			coordinates?: number[]
		}[] | null,
		workDistance?: number,
		preferredShift?: "AM" | "PM" | "Mids"
	}
}

export type ThirdPartyApplyOptions = {
	item: Professional,
	organizationId?: string,
	jobId?: string,
	recruiter?: string,
	referredBy?: string,
	fileUrl?: string
}

export type PlacementMetircs = {
	confirmedUsersCount: {
		count: number,
		csv?: string
	},
	recruiterApplicationsCount: {
		count: number,
		csv?: string
	},
	totalJobApplicantsCount: {
		count: number,
		csv?: string
	},
	submittedProfessionalsCount: {
		count: number,
		csv?: string
	},
	submittedOrAppliedAndPlacedCount: {
		count: number,
		csv?: string
	},
	submittedOrAppliedCount: {
		count: number,
		csv?: string
	},
	timeToStartAverage: {
		count: number,
		csv?: string
	}
}

export function numToExperience(years: number): string {
	if (!years || years <= 1) {
		return 'Less than 1 Year';
	}

	if (years <=2) {
		return '1 to 2 Years';
	}

	if (years <=3) {
		return '2 to 3 Years';
	}

	if (years <=5) {
		return '3 to 5 Years';
	}

	if (years <=10) {
		return '5 to 10 Years';
	}

	return 'More than 10 Years';
}

interface ConflictingJobApplication extends JobApplication {
	errors: Error[]
}

interface UpdateApplicationResponse {
	count: number,
	applications: ConflictingJobApplication[]
}

export class JobService {
	constructor(protected apiUrl: string) {
	}

	async autocomplete(options: AutocompleteOptions): Promise<string[]> {

		if(options.query && typeof options.query !== 'string') {
			options.query = JSON.stringify(options.query)
		}
		
		return await this.ajax(`${this.apiUrl}/jobs/autocomplete`, 'GET', undefined, undefined, options)
	}

	async multiple(auth: string, file: number): Promise<JobSettings> {
		return await this.ajax(`${this.apiUrl}/jobs/multiple`, 'POST', auth, file, false, 'text/csv');
	}

	async bulk(auth: string, file: number): Promise<JobSettings> {
		return await this.ajax(`${this.apiUrl}/jobs/bulk`, 'POST', auth, file, false, 'text/csv');
	}

	async post(auth: string, options: PostJobOptions): Promise<Job> {
		if (!options.additionalCertifications?.length) {
			delete options.additionalCertifications;
		}
		
		if (!options.certification) {
			delete options.certification;
		}

		if (!options.clientId) {
			delete options.clientId;
		}
	
		
		if (!options.departmentId) {
			delete options.departmentId;
		}
		
		if (!options.description) {
			delete options.description
		}
		
		if (!options.keywords?.length) {
			delete options.keywords;
		}

		if (!options.specialties?.length) {
			delete options.specialties;
		}

		if (!options.thirdPartyId) {
			delete options.thirdPartyId;
		}

		if (typeof options.minimumExperience == 'number') {
			options.minimumExperience = numToExperience(options.minimumExperience);
		}

		return parseJob(await this.ajax(`${this.apiUrl}/jobs`, 'POST', auth, options));
	}

	async apply(auth: string, jobId: string, userId?: string): Promise<JobApplyResult> {
		return parseJob(await this.ajax(`${this.apiUrl}/jobs/${jobId}/applications`, 'POST', auth, userId ? { applicantId: userId } : {}));
	}

	async favorite(auth: string, jobId: string): Promise<Job> {
		return parseJob(await this.ajax(`${this.apiUrl}/jobs/${jobId}/favorite`, 'PUT', auth));
	}

	async delegate(auth: string, jobId: string, userId: string): Promise<Job> {
		return parseJob(await this.ajax(`${this.apiUrl}/jobs/${jobId}/delegate/${userId}`, 'PUT', auth));
	}

	async withdraw(auth: string, jobId: string): Promise<Job> {
		return parseJob(await this.ajax(`${this.apiUrl}/jobs/${jobId}/withdraw`, 'PUT', auth));
	}

	async submit(auth: string, jobId: string, applicantId: string, options?: SubmitOptions): Promise<Job> {
		const data: any = clone(options) || {};

		if (data.availableDate) {
			data.availableDate = midnight(new Date(data.availableDate), true);
		}

		if (data.requestedTimeOff) {
			if (data.requestedTimeOff.fromDate) {
				data.requestedTimeOff.fromDate = midnight(new Date(data.requestedTimeOff.fromDate), true);
			}

			if (data.requestedTimeOff.toDate) {
				data.requestedTimeOff.toDate = midnight(new Date(data.requestedTimeOff.toDate), true);
			}	
		}

		return parseJob(await this.ajax(`${this.apiUrl}/jobs/${jobId}/applications/${applicantId}/submit`, 'PUT', auth, data));
	}

	async confirm(auth: string, jobId: string, applicantId: string, options?: ConfirmOptions): Promise<Job> {
		const data: any = clone(options) || {};

		if (data.startDate) {
			data.startDate = midnight(new Date(data.startDate), true);
		}

		if (data.endDate) {
			data.endDate = midnight(new Date(data.endDate), true);
		}
		
		return parseJob(await this.ajax(`${this.apiUrl}/jobs/${jobId}/applications/${applicantId}/confirm`, 'PUT', auth, data));
	}

	async cancel(auth: string, jobId: string, applicantId?: string, cancelledByApplicant?: boolean): Promise<Job> {
		if (applicantId) {
			return parseJob(await this.ajax(`${this.apiUrl}/jobs/${jobId}/applications/${applicantId}/cancel`, 'PUT', auth, { cancelledByApplicant: !!cancelledByApplicant }));
		}

		return parseJob(await this.ajax(`${this.apiUrl}/jobs/${jobId}/cancel`, 'PUT', auth));
	}

	async fulfill(auth: string, jobId: string): Promise<Job> {
		return parseJob(await this.ajax(`${this.apiUrl}/jobs/${jobId}/fulfill`, 'PUT', auth));
	}

	async reject(auth: string, jobId: string, applicantId: string): Promise<Job> {
		return parseJob(await this.ajax(`${this.apiUrl}/jobs/${jobId}/applications/${applicantId}/reject`, 'PUT', auth));
	}

	async assign(auth: string, jobId: string, applicantId: string, supervisorId: string): Promise<Job> {
		return parseJob(await this.ajax(`${this.apiUrl}/jobs/${jobId}/applications/${applicantId}/assign/${supervisorId}`, 'PUT', auth));
	}

	async enroute(auth: string, jobId: string): Promise<Job> {
		return parseJob(await this.ajax(`${this.apiUrl}/jobs/${jobId}/enroute`, 'PUT', auth));
	}

	async clockIn(auth: string, jobId: string, notes?: string): Promise<Job> {
		let body: any = {};

		if (notes) {
			body.notes = notes;
		}

		return parseJob(await this.ajax(`${this.apiUrl}/jobs/${jobId}/clockin`, 'PUT', auth, body));
	}

	async clockOut(auth: string, jobId: string, isComplete?: boolean, notes?: string): Promise<Job> {
		const body: any = { isComplete: !!isComplete };

		if (notes) {
			body.notes = notes;
		}

		return parseJob(await this.ajax(`${this.apiUrl}/jobs/${jobId}/clockout`, 'PUT', auth, body));
	}

	async approve(auth: string, jobId: string, applicantId: string, options?: ApproveOptions): Promise<Job> {
		return parseJob(await this.ajax(`${this.apiUrl}/jobs/${jobId}/applications/${applicantId}/approve`, 'PUT', auth, options || {}));
	}

	async shareDocuments(auth: string, jobId: string, documents: string[]): Promise<Job> {
		return parseJob(await this.ajax(`${this.apiUrl}/jobs/${jobId}/sharedocuments`, 'PUT', auth, { documents }));
	}

	async update(auth: string, jobId: string, options: UpdateJobOptions): Promise<Job> {
		if (typeof options.minimumExperience == 'number') {
			options.minimumExperience = numToExperience(options.minimumExperience);
		}

		return parseJob(await this.ajax(`${this.apiUrl}/jobs/${jobId}`, 'PUT', auth, options));
	}

	async addDnr(auth: string, userId: string, options: AddDnrOptions): Promise<DnrOrganization> {
		return parseDnrOrganization(await this.ajax(`${this.apiUrl}/jobs/dnr/${userId}`, 'POST', auth, options));
	}

	async deleteDnr(auth: string, userId: string, organization?: string): Promise<void> {
		return await this.ajax(`${this.apiUrl}/jobs/dnr/${userId}`, 'DELETE', auth, organization ? { organization } : {});
	}

	async review(auth: string, jobId: string, options?: ReviewOrganizationOptions): Promise<Job>;
	async review(auth: string, jobId: string, applicantId?: string, options?: ReviewApplicantOptions): Promise<Job>;
	async review(auth: string, jobId: string, applicantId?: any, options?: ReviewOrganizationOptions | ReviewApplicantOptions): Promise<Job> {
		if (typeof applicantId == 'object') {
			options = applicantId;
			applicantId = undefined;
		}

		const url = applicantId ? `${this.apiUrl}/jobs/${jobId}/applications/${applicantId}/review` : `${this.apiUrl}/jobs/${jobId}/review`

		return parseJob(await this.ajax(url, 'PUT', auth, options));
	}

	async get(jobId: string, options?: GetJobOptions): Promise<Job>;
	async get(auth: string, jobId: string, applicantId?: string): Promise<Job>;
	async get(auth: string, jobId: string, options?: GetJobOptions): Promise<Job>;
	async get(auth: string, jobId: string | GetJobOptions, options?: GetJobOptions | string): Promise<Job> {
		let job: Job;
		let applicantId: string;

		if (typeof jobId !== 'string') {
			options = jobId;
			jobId = auth;
			auth = undefined;
		} else if (typeof options === 'string') {
			applicantId = options;
			options = undefined;
		}

		if (applicantId) {
			job = await this.ajax(`${this.apiUrl}/jobs/${jobId}/applications/${applicantId}`, 'GET', auth);
		} else {
			job = await this.ajax(`${this.apiUrl}/jobs/${jobId}`, 'GET', auth, undefined, options);
		}

		return parseJob(job);
	}

	async list(auth: string, options?: ListJobsOptions): Promise<ListJobsResult> {
		const result: any = await this.ajax(`${this.apiUrl}/jobs`, 'GET', auth, undefined, options);

		if (!result || !result.list) {
			return { list: [], count: 0 }
		}

		for (let i = 0; i < result.list.length; i++) {
			result.list[i] = parseJob(result.list[i]);
		}

		return result;
	}

	async listV1(auth: string, options: ListJobsOptions, endTimeBufferMinutes: number = 0, userId: string = '', userType: string = ''): Promise<ListJobsResult> {
		const result: any = await this.ajax(`${this.apiUrl}/jobs`, 'GET', auth, undefined, options);

		if (!result || !result.list) {
			return { list: [], count: 0 }
		}

		for (let i = 0; i < result.list.length; i++) {
			result.list[i] = parseJob(result.list[i]);
			if (options.status === 'APPLIED') {
				result.list[i] = jobToApplicationV1(result.list[i]);
			} else {
				result.list[i] = jobToV1(result.list[i], endTimeBufferMinutes, userId, userType, (result.list[i].applications ? result.list[i].applications[0] : undefined))
			}
		}

		return result;
	}

	async listExternal(xApiKey: string, options?: ListJobsOptions): Promise<ListJobsResult> {

		if (options.cities && typeof options.cities !== 'string') {
			options.cities = JSON.stringify(options.cities)
		}
			
		const result: any = await this.ajaxKey(`${this.apiUrl}/jobs/external`, 'GET', xApiKey, undefined, options);

		for (let i = 0; i < result.list.length; i++) {
			result.list[i] = parseJob(result.list[i]);
		}

		return result;
	}

	async listOrganizationsJobCount(xApiKey: string, options?: ListJobsOptions): Promise<OrganizationJobCountResponse[]> {

        return await this.ajaxKey(`${this.apiUrl}/organizations-job-count/list`, 'GET', xApiKey, undefined, options);

	}

	async listAudit(auth: string, jobId: string, userId?: string) : Promise<ListAuditResult[]> {
		const result: any = await this.ajax(`${this.apiUrl}/jobs/${jobId}/audit`, 'GET', auth, undefined, { userId: userId });

		for (let i = 0; i < result.list.length; i++) {
			result.list[i] = parseAuditTrail(result.list[i]);
		}

		return result;
	}

	async dailyStatistics(auth: string, statistic: DailyStatisticType, startDate: Date, endDate: Date, timezone?: number, organizationId?: string): Promise<DailyStatistics[]> {
		const options: any = { startDate: startDate.valueOf(), endDate: endDate.valueOf() };

		if (timezone) {
			options.timezone = timezone;
		}

		if (organizationId) {
			options.organizationId = organizationId;
		}

		const result: any = await this.ajax(`${this.apiUrl}/jobs/dailystatistics/${statistic}`, 'GET', auth, undefined, options);

		for (let i = 0; i < result.length; i++) {
			result[i] = parseDailyStatistic(result[i]);
		}

		return result;
	}

	async reports(auth: string, type: string, options: any): Promise<Job[]> {
		if (options.startDate instanceof Date) {
			options.startDate = options.startDate.valueOf();
		}

		if (options.endDate instanceof Date) {
			options.endDate = options.endDate.valueOf();
		}

		const items = await this.ajax(`${this.apiUrl}/jobs/reports/${type}`, 'GET', auth, undefined, options);

		for (let i = 0; i < items.length; i++) {
			items[i] = parseJob(items[i]);
		}

		return items;
	}
	async statistics(auth: string, statistics: StatisticsType.Placements, options?: StatisticsOptions): Promise<PlacementMetircs>
	async statistics(auth: string, statistics: StatisticsType.ApplicationsByState, options?: StatisticsOptions): Promise<ApplicationsByStateStatistics[]>;
	async statistics(auth: string, statistics: StatisticsType.ApplicationsByWeek, options?: StatisticsOptions): Promise<ApplicationsByWeekStatistics[]>;
	async statistics(auth: string, statistics: StatisticsType.ByState, options?: StatisticsOptions): Promise<ByStateStatistics[]>;
	async statistics(auth: string, statistics: StatisticsType.Metrics, options?: StatisticsOptions): Promise<MetricsStatistics>;
	async statistics(auth: string, statistics: StatisticsType.Recruiter, options?: StatisticsOptions): Promise<RecruiterStatistics[]>;
	async statistics(auth: string, statistics: StatisticsType.Totals, options?: StatisticsOptions): Promise<TotalStatistics>;
	async statistics(auth: string, statistics: StatisticsType.Weekly, options?: StatisticsOptions): Promise<WeeklyStatistics[]>;
	async statistics(auth: string, statistics: StatisticsType.DownloadMetrics, options?: StatisticsOptions): Promise<DownloadMetrics>;
	async statistics(auth: string, statistics: StatisticsType, options?: StatisticsOptions): Promise<ApplicationsByWeekStatistics[] | ApplicationsByStateStatistics[] | RecruiterStatistics[] | ByStateStatistics[] | MetricsStatistics | TotalStatistics | WeeklyStatistics[] | DownloadMetrics | PlacementMetircs> {
		return await this.ajax(`${this.apiUrl}/jobs/statistics/${statistics}`, 'GET', auth, undefined, options || {} );
	}

	async settings(auth: string): Promise<JobSettings> {
		return await this.ajax(`${this.apiUrl}/jobs/settings`, 'GET', auth);
	}

	async thirdpartyApply(options: ThirdPartyApplyOptions): Promise<any> {
		if (options.item.phoneNumber) {
			options.item.phoneNumber = replaceAll(options.item.phoneNumber.replace(/\D/g,''), "-", "");
		}
		return await this.ajax(`${this.apiUrl}/job/thirdparty-apply`, 'POST', null, options);
	}

	async updateApplications(auth: string, jobId: string, options?: UpdateOptions): Promise<Job | UpdateApplicationResponse> {
		const data: any = clone(options) || {};

		if (data.startDate) {
			data.startDate = options.jobType === JobType.PerDiem ? new Date(data.startDate) : midnight(new Date(data.startDate), true);
		}

		if (data.endDate) {
			data.endDate = options.jobType === JobType.PerDiem ? new Date(data.endDate) : midnight(new Date(data.endDate), true);
		}

		delete data.jobType;
		const res = await this.ajax(`${this.apiUrl}/jobs/${jobId}/applications`, 'PUT', auth, data);

		if(res.count) {
			return res;
		} else {
			return parseJob(res);
		}
	}

	private async ajax(url: RequestInfo, method: string, auth?: string, body?: any, qs?: any, contentType?: string): Promise<any> {
		if (qs) {
			url += '?' + Object.keys(qs).reduce((i, j) => { i.push(`${j}=${encodeURIComponent(toJson(qs[j]))}`); return i; }, []).join('&');
		}

		if (body && !contentType) {
			body = JSON.stringify(toJson(body));
		}

		const response: Response = await fetch(
			url,
			{
				method: method,
				mode: 'cors',
				cache: 'no-cache',
				credentials: 'same-origin',
				headers: Object.assign(
					{
						'Content-Type': contentType || 'application/json',
					},
					auth ? { 'Authorization': auth } : {}
				),
				redirect: 'manual',
				referrerPolicy: 'no-referrer',
				body
			}
		);

		return await this.handleResponse(response, !(contentType && contentType !== 'application/json'));			
	}

    private async ajaxKey(url: RequestInfo, method: string, xApiKey: string, body?: any, qs?: any, contentType?: string): Promise<any> {
		if (qs) {
			url += '?' + Object.keys(qs).reduce((i, j) => { i.push(`${j}=${encodeURIComponent(toJson(qs[j]))}`); return i; }, []).join('&');
		}

		if (body && !contentType) {
			body = JSON.stringify(toJson(body));
		}

		const response: Response = await fetch(
			url,
			{
				method: method,
				mode: 'cors',
				cache: 'no-cache',
				credentials: 'same-origin',
				headers: {
					'Content-Type': contentType || 'application/json',
					'X-API-key': xApiKey
				},
				redirect: 'manual',
				referrerPolicy: 'no-referrer',
				body
			}
		);

		return await this.handleResponse(response, !(contentType && contentType !== 'application/json'));			
	}

	private async handleResponse(response: Response, jsonType: boolean): Promise<any> {
		if (!jsonType){
			return await response.text()	
		}

		const json = await response.json();

		if (response.ok) {
			return json;
		}

		const location = response.headers.get('location');
		const type = (location || !json?.type) ? 'JobError' : json.type;
		const err = new JobError(type, json?.message || response.statusText, response.status, location);

		if (json.stack) {
			err.stack = json.stack;
		}

		throw err;
	}

}