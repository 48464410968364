import { ajax } from "./ajax";

export interface GetLicenseUrlOptions {
	licenseBody?: string,
	organization?: string,
	responseType?: 'string' | 'object'
}
export interface LicenseUrl {
	_id: any,
	licenseType: string,
	licenseBody?: string,
	url: string,
	isNational: boolean,
	isDefault: boolean,
	organization?: any
}

export class LicenseUrlService {
	constructor(protected apiUrl: string) {
	}

	async lookupUrl(licenseType: string, options?: GetLicenseUrlOptions): Promise<LicenseUrl | string> {
		const { licenseBody, organization, responseType } = options;
		return await ajax(`${this.apiUrl}/briefcase/licenseurls`, 'GET', undefined, undefined, { licenseType, licenseBody, organization, responseType });
	}

	async updateLicenseUrl(auth: string, id: string, url: string): Promise <LicenseUrl> {
		return await ajax(`${this.apiUrl}/briefcase/licenseurls/${id}`, 'PUT', auth, { url });
	}
}