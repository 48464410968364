import React from 'react';
import { LimitedBackdrop } from '@praos-health/web/components/limited-backdrop';
import { Paper } from '@mui/material';
import CredentialingDataTable from '../credentialing-data-table';
import { DataTableColumn, DataTablePagination } from '@praos-health/web/components/data-table';
import { FlatScreening, LicenseVerificationTask } from '@praos-health/screening-client';

interface Props {
	columns: DataTableColumn<FlatScreening<LicenseVerificationTask>>[],
	loading: boolean,
	screeningData?: any,
	pagination: DataTablePagination,
	onRequestData: (pagination?: DataTablePagination) => void,
	count: number
}

export function CredentialingAuditLogTable({ columns, loading, screeningData, count, pagination, onRequestData }: Props) {
	return (
		<Paper sx={{position: 'relative'}}>
			<CredentialingDataTable
				columns={columns}
				screeningData = {screeningData}
				pagination={pagination}
				onRequestData={onRequestData}
				count={count}
			/>
			<LimitedBackdrop open={loading}/>
		</Paper>
	);
}
