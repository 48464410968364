import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';

export enum DateRangeOption {
	YearToDate = 'yearToDate',
	Previous12Months = 'previous12Months',
	LastYear = 'lastYear',
	MonthToDate = 'monthToDate',
	LastMonth = 'lastMonth',
	QuarterToDate = 'quarterToDate',
	LastQuarter = 'lastQuarter',
	Previous30Days = 'previous30Days',
	Custom = 'custom'
}

const dateRangeOptions = [
	{ value: DateRangeOption.YearToDate, label: 'Year to Date' },
	{ value: DateRangeOption.QuarterToDate, label: 'Quarter to Date' },
	{ value: DateRangeOption.MonthToDate, label: 'Month to Date' },
	{ value: DateRangeOption.LastYear, label: 'Last Year' },
	{ value: DateRangeOption.LastQuarter, label: 'Last Quarter' },
	{ value: DateRangeOption.LastMonth, label: 'Last Month' },
	{ value: DateRangeOption.Previous12Months, label: 'Previous 12 months' },
	{ value: DateRangeOption.Previous30Days, label: 'Previous 30 days' },
	{ value: DateRangeOption.Custom, label: 'Custom' }
];

interface DateRangeSelectProps {
	value: DateRangeOption,
	onChange: (value: DateRangeOption) => void
}

export const DateRangeSelect: React.FC<DateRangeSelectProps> = ({ value, onChange }) => {
	const handleChange = (event: SelectChangeEvent<DateRangeOption>) => {
		onChange(event.target.value as DateRangeOption);
	};

	return (
		<FormControl fullWidth>
			<InputLabel id="date-range-select-label">Date Range</InputLabel>
			<Select
				labelId="date-range-select-label"
				id="date-range-select"
				value={value}
				label="Date Range"
				onChange={handleChange}
			>
				{dateRangeOptions.map((option) => (
					<MenuItem key={option.value} value={option.value}>
						{option.label}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};