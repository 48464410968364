import React from 'react';
import { useTranslation } from 'react-i18next';
import { LimitedBackdrop } from '@praos-health/web/components/limited-backdrop';
import { InfoButtonPopper } from '@praos-health/web/components';
import { styled } from '@mui/material';
import { Title } from '@praos-health/web/components';

interface Props {
	title: string,
	description?: string,
	loading?: boolean,
	data: CompactLicenseSummaryData
}

export interface CompactLicenseSummaryData {
	count: number,
	compactLicense: number
}

const Main = styled('div')(({ theme }) => ({
	position: 'relative',
	padding: theme.spacing(2)
}));

const Section = styled('div')(() => ({
	display: 'flex',
	maxWidth: '500px',
	justifyContent: 'center'
}));

const SectionItem = styled('div')(() => ({
	flex: '1 1 0'
}));

const Value = styled('div')(() => ({
	whiteSpace: 'nowrap',
	textAlign: 'center',
	fontWeight: 'bold',
	fontSize: '48px'
}));

const SubTitle = styled('div')(({ theme }) => ({
	textAlign: 'center',
	color: theme.palette.text.disabled
}));

const PctValue = styled('div')(() => ({
	flex: '1 1 0',
	fontSize: '36px',
	textAlign: 'center'
}));

export function CompactLicenseSummary({ title, description, loading, data } : Props): JSX.Element {
	const { t } = useTranslation();

	return (
		<Main>
			<Title>{title}</Title>
			{ description && <InfoButtonPopper text={description} /> }
			<Section>
				<SectionItem>
					<Value>{data?.compactLicense}</Value>
					<SubTitle>{t('AllTime')}</SubTitle>
				</SectionItem>
			</Section>
			<Section>
				<PctValue>
					{ data?.count ? (data?.compactLicense / data?.count * 100).toFixed(2) : 0}%
				</PctValue>
			</Section>
			<LimitedBackdrop open={!!loading} />
		</Main>
	);
}