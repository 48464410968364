import HomeIcon from '@mui/icons-material/Home';
import DashboardIcon from '@mui/icons-material/Dashboard';

const MENU = [
	{
		title: 'Dashboard',
		Icon: HomeIcon,
		href: '/'
	},
	{
		title: 'CredentialMetrics',
		Icon: DashboardIcon,
		href: '/credentialmetrics'
	},
	{
		title: 'JobMetrics',
		Icon: DashboardIcon,
		href: '/jobmetrics'
	},
	{
		title: 'UserInsights',
		Icon: DashboardIcon,
		href: '/userinsights'
	},
	{
		title: 'UserEngagement',
		Icon: DashboardIcon,
		href: '/userengagement'
	},
	{
		title: 'BriefcaseStatistics',
		Icon: DashboardIcon,
		href: '/briefcasestatistics'
	},
	{
		title: 'RecruiterMetrics',
		Icon: DashboardIcon,
		href: '/recruitermetrics'
	},
	{
		title: 'Credentialing',
		Icon: DashboardIcon,
		href: '/credentialing/tasks'
	}
];

export { MENU as menu };