/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useAuth } from '@praos-health/ui-security';
import { Box, Grid, Paper, SelectChangeEvent } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useTranslation } from 'react-i18next';
import { useApi, useToaster } from '@praos-health/ui';
import { Organization } from '@praos-health/organizations-client';
import { AppApi } from '../../app-api';
import MetricDisplay from '../../components/metric-display';
import PlacementMetric from '../../components/placement-metric';
import { FilterHeader } from '../../components/filter-header';
import { StatisticsOptions, StatisticsType as ProfessionalStatisticsType } from '@praos-health/professional-client';
import { StatisticsType as JobStatisticsType } from '@praos-health/jobs-client';
import BriefcaseCompletedMetric from '../../components/briefcase-completed';
import PlacementRatioMetric from '../../components/placement-ratio';
import { DateRangeOption } from '../../components/date-range-select';
import JobApplications from '../../components/applications-number';
import RecruiterApplications from '../../components/recruiter-applications';
import AccountsCreatedMetrics from '../../components/registrations';
import TimeToStart from '../../components/time-to-start';
import JobSubmissions from '../../components/job-submissions';
export * from './dashboard-page-toolbar';

export function DashboardPage() {
	const api = useApi<AppApi>();
	const auth = useAuth();
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const toast = useToaster();
	const [organizationId, setOrganizationId] = useState('all');
	const [organizations, setOrganizations] = useState<Organization[]>([]);
	const [dateRange, setDateRange] = useState<DateRangeOption>(DateRangeOption.Previous30Days);
	const [timePeriod, setTimePeriod] = useState(() => {
		const endDate = new Date();
		endDate.setUTCHours(23, 59, 59, 999);
		const startDate = new Date(endDate);
		startDate.setUTCDate(startDate.getUTCDate() - 29);
		startDate.setUTCHours(0, 0, 0, 0);
		return { startDate, endDate };
	});

	const [placementData, setPlacementData] = useState<number>(0);
	const [submissionToPlacementRatio, setSubmissionToPlacementRatio] = useState<number | string>(0);
	const [briefcaseCompleted, setBriefcaseCompleted] = useState<number>(0);
	const [jobApplications, setJobApplications] = useState<number>(0);
	const [dateError, setDateError] = useState<string | null>(null);
	const [recruiterApplications, setRecruiterApplicaitons] = useState<number>(0);
	const [accountsCreatedCount, setAccountsCratedCount] = useState<number | string>(0);
	const [averageTimeToStart, setAverageTimeToStart] = useState<number>(0);
	const [jobSubmissions, setJobSubmissions] = useState<number>(0);


	const handleOrganizationChange = (e: SelectChangeEvent<string>) => {
		setOrganizationId(e.target.value as string);
	};

	const calculateDateRange = (range: DateRangeOption): { startDate: Date, endDate: Date } => {
		let endDate = new Date();
		endDate.setUTCHours(23, 59, 59, 999);
		let startDate = new Date(endDate);

		switch (range) {
			case DateRangeOption.YearToDate:
				startDate = new Date(Date.UTC(endDate.getUTCFullYear(), 0, 1));
				break;
			case DateRangeOption.Previous12Months:
				startDate.setUTCFullYear(startDate.getUTCFullYear() - 1);
				break;
			case DateRangeOption.LastYear:
				startDate = new Date(Date.UTC(endDate.getUTCFullYear() - 1, 0, 1));
				endDate = new Date(Date.UTC(endDate.getUTCFullYear() - 1, 11, 31, 23, 59, 59, 999));
				break;
			case DateRangeOption.MonthToDate:
				startDate = new Date(Date.UTC(endDate.getUTCFullYear(), endDate.getUTCMonth(), 1));
				break;
			case DateRangeOption.LastMonth:
				startDate = new Date(Date.UTC(endDate.getUTCFullYear(), endDate.getUTCMonth() - 1, 1));
				endDate = new Date(Date.UTC(endDate.getUTCFullYear(), endDate.getUTCMonth(), 0, 23, 59, 59, 999));
				break;
			case DateRangeOption.QuarterToDate:
				startDate = new Date(Date.UTC(endDate.getUTCFullYear(), Math.floor(endDate.getUTCMonth() / 3) * 3, 1));
				break;
			case DateRangeOption.LastQuarter:
				const currentQuarter = Math.floor(endDate.getUTCMonth() / 3);
				startDate = new Date(Date.UTC(endDate.getUTCFullYear(), (currentQuarter - 1) * 3, 1));
				endDate = new Date(Date.UTC(endDate.getUTCFullYear(), currentQuarter * 3, 0, 23, 59, 59, 999));
				break;
			case DateRangeOption.Previous30Days:
				startDate.setUTCDate(startDate.getUTCDate() - 29);
				startDate.setUTCHours(0, 0, 0, 0);
				break;
			default:
				return timePeriod;
		}

		startDate.setUTCHours(0, 0, 0, 0);
		return { startDate, endDate };
	};

	const handleDateRangeChange = (newRange: DateRangeOption) => {
		setDateRange(newRange);
		if (newRange !== DateRangeOption.Custom) {
			const newTimePeriod = calculateDateRange(newRange);
			setTimePeriod(newTimePeriod);
		}
	};

	const handleDateError = (error: string | null) => {
		setDateError(error);
		if (error) {
			toast(error, 'error');
			setIsLoading(false);
			setPlacementData(0);
			setSubmissionToPlacementRatio(0);
			setBriefcaseCompleted(0);
			setJobApplications(0);
			setAverageTimeToStart(0);
		}
	};
	const handleDateChange = (newValue: Date | null, type: 'startDate' | 'endDate') => {
		if (newValue) {
			const utcDate = new Date(newValue);

			if (type === 'startDate') {
				utcDate.setUTCHours(0, 0, 0, 0);
			} else if (type === 'endDate') {
				utcDate.setUTCHours(23, 59, 59, 999);
			}

			setTimePeriod(prev => ({ ...prev, [type]: utcDate }));
		}
	};

	useEffect(() => {
		async function loadOrganizations(): Promise<void> {
			if (auth.session?.organization) {
				setOrganizations([
					{
						_id: auth.session.organization._id,
						name: auth.session.organization.name
					}
				]);
				return;
			}

			const result = await api.organizationService.list(auth.session?.auth || '', {
				select: { name: 1 },
				status: 'APPROVED'
			});

			setOrganizations(result.list);
		}

		loadOrganizations()
			.catch(async (e) => {
				if (e instanceof Error && (e.name === 'UnauthorizedError' || e.message === 'Unauthorized')) {
					toast('Your session has expired', 'error');
					return await auth.signout();
				}
				toast(e, 'error');
			});
	}, [api, auth, toast]);

	useEffect(() => {
		if (dateError) {
			setIsLoading(false);
			setPlacementData(0);
			setSubmissionToPlacementRatio(0);
			setBriefcaseCompleted(0);
			setJobApplications(0);
			setAccountsCratedCount(0);
			setAverageTimeToStart(0);
		}
	}, [dateError]);

	useEffect(() => {
		if (dateError) {
			setIsLoading(false);
			return;
		}
		async function loadMetrics(): Promise<void> {
			if (dateError) {
				throw new Error(dateError);
			}
			setIsLoading(true);
			if(!auth.session) throw new Error('No Auth provided');
			const options: StatisticsOptions = {
				useIsoWeek: true,
				startDate: timePeriod.startDate,
				endDate: timePeriod.endDate
			};

			if (organizationId === 'praos') {
				options.isMarketplace = true;
			} else if (organizationId !== 'all') {
				options.organization = organizationId;
			}

			const professionalMetrics = await api.professionalService.statistics(
				auth.session?.auth || '',
				ProfessionalStatisticsType.UserInsights,
				options
			);

			const placements = await api.jobService.statistics(auth.session?.auth, JobStatisticsType.Placements, options);

			if(placements) {
				const confirmedUsersCount = placements.confirmedUsersCount.count ?? 0;
				const totalJobApplicantsCount = placements.totalJobApplicantsCount.count ?? 0;
				const recruiterApplicationsCount = placements.recruiterApplicationsCount.count ?? 0;
				const jobSubmissionsCount = placements.submittedProfessionalsCount.count ?? 0;
				const submittedOrAppliedAndPlacedCount = placements.submittedOrAppliedAndPlacedCount.count ?? 0;
				const submittedOrAppliedCount = placements.submittedOrAppliedCount.count ?? 0;
				const averageTimeToStartCount = placements.timeToStartAverage.count ?? 0;
				setAverageTimeToStart(averageTimeToStartCount);
				setPlacementData(confirmedUsersCount);
				setSubmissionToPlacementRatio(`${submittedOrAppliedAndPlacedCount}/${submittedOrAppliedCount}`);
				totalJobApplicantsCount > 0 ? setJobApplications(totalJobApplicantsCount) : setJobApplications(0);
				recruiterApplicationsCount > 0 ? setRecruiterApplicaitons(recruiterApplicationsCount) : setRecruiterApplicaitons(0);
				jobSubmissionsCount > 0 ? setJobSubmissions(jobSubmissionsCount) : setJobSubmissions(0);
			}
			setBriefcaseCompleted(professionalMetrics.completed.timeRange);
			setAccountsCratedCount(professionalMetrics.created.timeRange);
		}
		loadMetrics()
			.catch((e) => {
				toast(e, 'error');
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [api, auth, toast, organizationId, timePeriod, dateError]);

	const hasOrganizationSelect = !auth.session?.organization;

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<Box sx={{ pb: 7 }}>
				<FilterHeader
					showOrganizationSelect={hasOrganizationSelect}
					organizationId={organizationId}
					organizations={organizations}
					timePeriod={timePeriod}
					onOrganizationChange={handleOrganizationChange}
					onDateChange={handleDateChange}
					onDateRangeChange={handleDateRangeChange}
					dateRange={dateRange}
					onError={handleDateError}
				/>

				<Grid container spacing={2}>
					<Grid item xs={12} md={6}>
						<Paper>
							<MetricDisplay
								title={t('placementMetrics.SelfApplicationsTitle')}
								description={t('placementMetrics.SelfApplicationsDescription')}
								loading={isLoading}
								timePeriod={timePeriod}
							>
								<JobApplications value={jobApplications} loading={isLoading}/>
							</MetricDisplay>
						</Paper>
					</Grid>
					<Grid item xs={12} md={6}>
						<Paper>
							<MetricDisplay
								title={t('placementMetrics.RecruiterApplications')}
								description={t('placementMetrics.RecruiterApplicationsDescription')}
								loading={isLoading}
								timePeriod={timePeriod}
							>
								<RecruiterApplications value={recruiterApplications} loading={isLoading}/>
							</MetricDisplay>
						</Paper>
					</Grid>
					<Grid item xs={12} md={6}>
						<Paper>
							<MetricDisplay
								title={t('placementMetrics.JobSubmissions')}
								description={t('placementMetrics.JobSubmissionsDescription')}
								loading={isLoading}
								timePeriod={timePeriod}
							>
								<JobSubmissions value={jobSubmissions} loading={isLoading}/>
							</MetricDisplay>
						</Paper>
					</Grid>
					<Grid item xs={12} md={6}>
						<Paper>
							<MetricDisplay
								title={t('placementMetrics.placementTitle')}
								description={t('placementMetrics.placementDescription')}
								loading={isLoading}
								timePeriod={timePeriod}
							>
								<PlacementMetric value={placementData} loading={isLoading}/>
							</MetricDisplay>
						</Paper>
					</Grid>
					<Grid item xs={12} md={6}>
						<Paper>
							<MetricDisplay
								title={t('placementMetrics.SubmissionToPlacement')}
								description={t('placementMetrics.SubmissionToPlacementDescription')}
								loading={isLoading}
								timePeriod={timePeriod}
							>
								<PlacementRatioMetric value={submissionToPlacementRatio} loading={isLoading}/>
							</MetricDisplay>
						</Paper>
					</Grid>
					<Grid item xs={12} md={6}>
						<Paper>
							<MetricDisplay
								title={t('briefcaseMetrics.CompletedBriefcases')}
								description={t('briefcaseMetrics.CompletedBriefcasesDescription')}
								loading={isLoading}
								timePeriod={timePeriod}
							>
								<BriefcaseCompletedMetric value={briefcaseCompleted} loading={isLoading}/>
							</MetricDisplay>
						</Paper>
					</Grid>
					<Grid item xs={12} md={6}>
						<Paper>
							<MetricDisplay
								title={t('AccountsCreatedTitle')}
								description={t('AccountsCreatedDescriptions')}
								loading={isLoading}
								timePeriod={timePeriod}
							>
								<AccountsCreatedMetrics value={accountsCreatedCount} loading={isLoading}/>
							</MetricDisplay>
						</Paper>
					</Grid>
					<Grid item xs={12} md={6}>
						<Paper>
							<MetricDisplay
								title={t('placementMetrics.TimeToStart')}
								description={t('placementMetrics.TimeToStartDescription')}
								loading={isLoading}
								timePeriod={timePeriod}
							>
								<TimeToStart value={averageTimeToStart} loading={isLoading}/>
							</MetricDisplay>
						</Paper>
					</Grid>
				</Grid>
			</Box>
		</LocalizationProvider>
	  );
}