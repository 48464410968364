import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { ToastProvider, Toaster, ApiProvider, ApiAuthProvider } from '@praos-health/ui';
import { JobService } from '@praos-health/jobs-client';
import { UserService } from '@praos-health/users-client';
import { LicenseUrlService, SpecialtyService } from '@praos-health/briefcase-client';
import { ProfessionalService } from '@praos-health/professional-client';
import { OrganizationService } from '@praos-health/organizations-client';
import { PrivateRoute, ProvideAuth } from '@praos-health/ui-security';
import { NursysService } from '@praos-health/nursys-service-client';

import {
	createTheme,
	ThemeProvider,
	StyledEngineProvider
} from '@mui/material/styles';
import { enUS } from '@mui/material/locale';

import {
	UserEngagementPage,
	UserInsightsPage,
	DashboardPage,
	CredentialMetricsPage,
	JobMetricsPage,
	RecruiterMetricsPage,
	CredentialingPage,
	BriefcaseStatsPage
} from './pages';
import { ForgotPage, LoginPage } from '@praos-health/web/pages';
import {  MainLayout } from './layouts';
import {AuthLayout } from '@praos-health/web/layouts';
import { Spinner, WebToaster } from '@praos-health/web/components';
import './App.css';
import { AppApi } from './app-api';
import { RecordService } from '@praos-health/records-client';
import { ScreeningService } from '@praos-health/screening-client';

const API_URL = process.env.REACT_APP_API_URL || '';

export default function App() {
	const theme = createTheme(
		{
			palette: {
				primary: { main: '#3d255f' }
			}
		},
		enUS
	);

	const jobService = new JobService(API_URL);
	const userService = new UserService(API_URL);
	const professionalService = new ProfessionalService(API_URL);
	const organizationService = new OrganizationService(API_URL);
	const nursysService = new NursysService(API_URL);
	const screeningService = new ScreeningService(API_URL);
	const specialtyService = new SpecialtyService(API_URL);
	const recordService = new RecordService(API_URL);
	const urlService = new LicenseUrlService(API_URL);
	const toaster = new Toaster();

	return (
		<Suspense fallback={<Spinner/>}>
			<ToastProvider toaster={toaster}>
				<StyledEngineProvider injectFirst>
					<ThemeProvider theme={theme}>
						<WebToaster
							toaster={toaster}
							autoHideDuration={5000}
							toastVariant="filled"
							anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
						/>
						<ProvideAuth auth={ApiAuthProvider(userService)}>
							<ApiProvider<AppApi>
								api={{
									jobService,
									nursysService,
									organizationService,
									professionalService,
									recordService,
									screeningService,
									specialtyService,
									userService,
									urlService
								}}
							>
								<Router>
									<Routes>
										<Route element={<AuthLayout/>}>
											<Route path="/login" element={<LoginPage/>}/>
											<Route path="/forgot" element={<ForgotPage/>}/>
										</Route>
										<Route element={<PrivateRoute />}>
											<Route path="/" element={<MainLayout />}>
												<Route path="/" element={<DashboardPage />}/>
												<Route path="/credentialmetrics" element={<CredentialMetricsPage />}/>
												<Route path="/jobmetrics" element={<JobMetricsPage />}/>
												<Route path="/userinsights" element={<UserInsightsPage />} />
												<Route path="/userengagement" element={<UserEngagementPage />}/>
												<Route path="/briefcasestatistics" element={<BriefcaseStatsPage />}/>
												<Route path="/recruitermetrics" element={<RecruiterMetricsPage />}/>
												<Route path={'/credentialing/:tabId'} element={<CredentialingPage />}/>
											</Route>
										</Route>
									</Routes>
								</Router>
							</ApiProvider>
						</ProvideAuth>
					</ThemeProvider>
				</StyledEngineProvider>
			</ToastProvider>
		</Suspense>
	);
}
