import { IconButton, Table, TableBody, TableContainer, styled } from '@mui/material';
import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { toCsv as arrayToCsv } from '@praos-health/core/utilities/string';
import React from 'react';
import { downloadFile } from '../../common/download';
import { useApi, useToaster } from '@praos-health/ui';
import { useAuth } from '@praos-health/ui-security';
import { StatisticsType as JobStatisticsType } from '@praos-health/jobs-client';
import { StatisticsCsvDownloadOptions, StatisticsType } from '@praos-health/professional-client';
import DownloadIcon from '@mui/icons-material/Download';
import { InfoButtonPopper } from '@praos-health/web';
import { DataTableColumn, DataTableHeadProps, Order } from '@praos-health/web/components/data-table';
import { AppApi } from '../../app-api';

export interface DownloadMetrics {
	applicationCsv: string,
	jobMetricsCsv: string
}

export enum RowType {
	userInsights = 'USER_INSIGHTS',
	jobMetrics = 'JOB_METRICS'
}

export enum TimeFrame {
	thisWeek = 'THIS_WEEK',
	lastWeek = 'LAST_WEEK',
	thisMonth = 'THIS_MONTH',
	lastMonth = 'LAST_MONTH',
	allTime = 'ALL_TIME',
	lastWeek2nd = 'LAST_WEEK_2ND',
	lastWeek3rd = 'LAST_WEEK_3RD'
}

export function toCsv<T>(columns: DataTableColumn<T>[], data: any[]): string {
	const result: string[] = [arrayToCsv(columns.map((i) => i.title))];

	for (const item of data) {
		result.push(arrayToCsv(columns.map((i) => item[i.id])));
	}

	return result.join('\r\n');
}

interface Props<T> {
	id: string,
	rows: any[],
	minWidth?: number,
	organizationId?: string,
	columns?: DataTableColumn<T>[],
	stickyHeader?: boolean
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator<T extends keyof any>(
	order: Order,
	orderBy: T
): (a: { [key in T]: number | string }, b: { [key in T]: number | string }) => number {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number): T[] {
	const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

const StyledRoot = styled('div')(() => ({
	display: 'flex',
	height: '100%'
}));

const StyledTableContainer = styled(TableContainer)<{ sticky: boolean }>(({ sticky }) => ({
	flex: '1 0 auto',
	width: '0',
	overflow: 'auto',
	maxHeight: sticky ? 'calc(100vh - 200px)' : 'none' // Apply max height only if sticky
}));

const StyledTable = styled(Table)(() => ({
	//			tableLayout: 'fixed',
	'& .MuiTableCell-head': {
		textTransform: 'capitalize',
		fontWeight: 'bold',
		whiteSpace: 'nowrap'
	}
}));

const StyledTableHead = styled(TableHead)<{ sticky: boolean }>(({ sticky }) => ({
	position: sticky ? 'sticky' : 'static',
	top: 0,
	backgroundColor: 'white',
	zIndex: sticky ? 1 : 'auto'
}));

function DataTableHead<T>({
	columns,
	order,
	orderBy,
	onRequestData,
	stickyHeader
}: DataTableHeadProps<T> & { stickyHeader?: boolean }): JSX.Element {
	const createSortHandler = (property: string) => () => {
		onRequestData(property);
	};

	return (
		<StyledTableHead sticky={!!stickyHeader}>
			<TableRow>
				{columns.map((column) => (
					<TableCell
						key={column.id}
						align="left"
						sortDirection={orderBy === column.id ? order : false}
					>
						{column.orderBy ? (
							<TableSortLabel
								active={orderBy === column.id}
								direction={orderBy === column.id ? order : 'asc'}
								onClick={createSortHandler(column.id)}
							>
								{column.title}
							</TableSortLabel>
						) : (
							column.title
						)}
						{column.description && (
							<InfoButtonPopper text={column.description} />
						)}
					</TableCell>
				))}
			</TableRow>
		</StyledTableHead>
	);
}

export function DataTable<T>({ columns, rows, minWidth, stickyHeader = false }: Props<T>): JSX.Element {
	let internalColumns: DataTableColumn<T>[] = [
		{
			id: '',
			title: '',
			align: 'inherit',
			description: ''
		}
	];

	if (!columns) {
		if (rows.length) {
			internalColumns = Object.keys(rows[0]).map((key) => {
				return {
					id: String(key),
					title: rows[0][key],
					description: rows[0].description,
					align: 'inherit'
				};
			});
		}
	} else {
		internalColumns = columns;
	}

	const [order, setOrder] = React.useState<Order>('asc');
	const [orderBy, setOrderBy] = React.useState<string>('');

	function handleRequestSort(property?: string) {
		if (property) {
			const isAsc = orderBy === property && order === 'asc';
			setOrder(isAsc ? 'desc' : 'asc');
			setOrderBy(property);
		}
	}

	return (
		<StyledRoot>
			<StyledTableContainer sticky={stickyHeader}>
				<StyledTable stickyHeader={stickyHeader} style={{ minWidth }}>
					<DataTableHead<T>
						columns={internalColumns}
						order={order}
						orderBy={orderBy}
						onRequestData={handleRequestSort}
						stickyHeader={stickyHeader}
					/>
					<TableBody>
						{stableSort(rows, getComparator(order, orderBy)).map((row, index) => (
							<TableRow key={index}>
								{internalColumns.map((col, i) => (
									<TableCell
										align={col.align ? col.align : 'inherit'}
										key={`${row.id}_${i}`}
									>
										{row[col.id]}
									</TableCell>
								))}
							</TableRow>
						))}
					</TableBody>
				</StyledTable>
			</StyledTableContainer>
		</StyledRoot>
	);
}

export function DataTableWithDownloadIcon<T>({ columns, id, rows, organizationId = '', minWidth, stickyHeader = false }: Props<T>): JSX.Element {
	const api = useApi<AppApi>();
	const auth = useAuth();
	const toast = useToaster();

	let internalColumns: DataTableColumn<T>[] = [
		{
			id: '',
			title: '',
			align: 'inherit'
		}
	];

	if (!columns) {
		if (rows.length) {
			internalColumns = Object.keys(rows[0]).map((key) => {
				return {
					id: String(key),
					title: rows[0][key],
					align: 'inherit',
					enableSort: false
				};
			});
		}
	} else {
		internalColumns = columns;
	}

	const [order, setOrder] = React.useState<Order>('asc');
	const [orderBy, setOrderBy] = React.useState<string>('');

	function handleRequestSort(property?: string) {
		if (property) {
			const isAsc = orderBy === property && order === 'asc';
			setOrder(isAsc ? 'desc' : 'asc');
			setOrderBy(property);
		}
	}

	async function handleExport(row: any) {
		try {
			const options: StatisticsCsvDownloadOptions = {
				useIsoWeek: true,
				timeFrame: row[0].timeFrame,
				organization: organizationId === 'praos' || organizationId === 'all' ? '' : organizationId,
				download: true
			};

			if(organizationId === 'praos') {
				Object.assign(options, { isMarketplace: true });
			}

			if (row[0].type === RowType.userInsights) {
				const response: { csv: string } = await api.professionalService.statistics(
					auth.session?.auth || '',
					StatisticsType.UserInsightsDownload,
					options
				);
				if (response) {
					downloadFile(
						`user insights ${row[0].week}.csv`,
						'text/csv;charset=utf-8;',
						response.csv
					);
				}
			} else if (row[0].type === RowType.jobMetrics) {
				const response: DownloadMetrics = await api.jobService.statistics(
					auth.session?.auth || '',
					JobStatisticsType.DownloadMetrics,
					options
				);

				if (response) {
					downloadFile(
						`Application Metrics ${row[0].week}.csv`,
						'text/csv;charset=utf-8;',
						response.applicationCsv
					);

					downloadFile(
						`Job Metrics ${row[0].week}.csv`,
						'text/csv;charset=utf-8;',
						response.jobMetricsCsv
					);
				}
			}
		} catch (error) {
			if (error instanceof Error && (error.name === 'UnauthorizedError' || error.message === 'Unauthorized')) {
				toast('Your session has expired', 'error');
				return await auth.signout();
			}
			toast(String(error), 'error');
		}
	}

	function conditionalDownload(
		col: DataTableColumn<T>,
		i: number,
		row: any
	) {
		if (id === 'week' && i === 0) {
			return (
				<IconButton color="inherit" edge="end" onClick={() => handleExport([row])}>
					<DownloadIcon />{' '}
				</IconButton>
			);
		}
	}

	return (
		<StyledRoot>
			<StyledTableContainer sticky={stickyHeader}>
				<StyledTable stickyHeader={stickyHeader} style={{ minWidth }}>
					<DataTableHead<T>
						columns={internalColumns}
						order={order}
						orderBy={orderBy}
						onRequestData={handleRequestSort}
						stickyHeader={stickyHeader}
					/>
					<TableBody>
						{stableSort(rows, getComparator(order, orderBy)).map((row) => (
							<TableRow key={row[id]}>
								{internalColumns.map((col, i) => (
									<TableCell
										align={col.align ? col.align : 'inherit'}
										key={`${row.id}_${i}`}
									>
										{row[col.id]} {conditionalDownload(col, i, row)}{' '}
									</TableCell>
								))}
							</TableRow>
						))}
					</TableBody>
				</StyledTable>
			</StyledTableContainer>
		</StyledRoot>
	);
}
