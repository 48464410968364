import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Organization } from '@praos-health/organizations-client';

interface OrganizationSelectProps {
	organizationId: string,
	organizations: Organization[],
	onChange: (event: SelectChangeEvent<string>) => void
}

export const OrganizationSelect: React.FC<OrganizationSelectProps> = ({
	organizationId,
	organizations,
	onChange
}) => {
	const { t } = useTranslation();

	return (
		<FormControl fullWidth>
			<InputLabel id="organization-select-label">{t('Organization')}</InputLabel>
			<Select
				labelId="organization-select-label"
				id="organization-select"
				value={organizationId}
				label={t('Organization')}
				onChange={onChange}
			>
				<MenuItem value="all">{t('All')}</MenuItem>
				<MenuItem value="praos">Praos Health</MenuItem>
				{organizations.map((org) => (
					<MenuItem key={org._id} value={org._id}>
						{org.name}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};