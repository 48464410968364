import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { LicenseVerificationApplicant } from '@praos-health/screening-client';

interface CandidateInformationProps {
	applicant?: LicenseVerificationApplicant,
	applicantId?: string | number,
	organization?: string,
	children?: React.ReactNode
}

export default function CandidateInformation ({ children, applicant, applicantId, organization }: CandidateInformationProps) {
	const dateFormatter = new Intl.DateTimeFormat();
	const gmtDateFormatter = new Intl.DateTimeFormat(undefined, { timeZone: 'GMT' });

	function formatDate(date?: Date, useGmt?: boolean): string {
		return date ? (useGmt ? gmtDateFormatter : dateFormatter).format(date) : '-';
	}

	return (
		<Box width='50%' sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
			<Typography variant='h6' fontWeight='bold'>Candidate Information</Typography>
			<Box sx={{display: 'flex', flexDirection: 'column', gap: '3px'}}>
				<Grid container sx={{'backgroundColor': grey[100], '&:hover': {backgroundColor: grey[300]}, 'paddingY': '3px', 'paddingX': '8px', 'borderRadius': '7px'}}>
					<Grid item xs={5} >
						<Typography fontWeight='bold' variant='subtitle2'>First Name:</Typography>
					</Grid>
					<Grid item xs={7} >
						<Typography variant='subtitle2'>{applicant?.firstName ?? '-'}</Typography>
					</Grid>
				</Grid>
				<Grid container sx={{'backgroundColor': grey[100], '&:hover': {backgroundColor: grey[300]}, 'paddingY': '3px', 'paddingX': '8px', 'borderRadius': '7px'}}>
					<Grid item xs={5}>
						<Typography fontWeight='bold' variant='subtitle2'>Last Name:</Typography>
					</Grid>
					<Grid item xs={7}>
						<Typography variant='subtitle2'>{applicant?.lastName ?? '-'}</Typography>
					</Grid>
				</Grid>
				<Grid container sx={{'backgroundColor': grey[100], '&:hover': {backgroundColor: grey[300]}, 'paddingY': '3px', 'paddingX': '8px', 'borderRadius': '7px'}}>
					<Grid item xs={5}>
						<Typography fontWeight='bold' variant='subtitle2'>Phone Number:</Typography>
					</Grid>
					<Grid item xs={7}>
						<Typography variant='subtitle2'>{applicant?.phoneNumber ?? '-'}</Typography>
					</Grid>
				</Grid>
				<Grid container sx={{'backgroundColor': grey[100], '&:hover': {backgroundColor: grey[300]}, 'paddingY': '3px', 'paddingX': '8px', 'borderRadius': '7px'}}>
					<Grid item xs={5}>
						<Typography fontWeight='bold' variant='subtitle2'>Email:</Typography>
					</Grid>
					<Grid item xs={7}>
						<Typography variant='subtitle2'>{applicant?.email ?? '-'}</Typography>
					</Grid>
				</Grid>
				<Grid container sx={{'backgroundColor': grey[100], '&:hover': {backgroundColor: grey[300]}, 'paddingY': '3px', 'paddingX': '8px', 'borderRadius': '7px'}}>
					<Grid item xs={5}>
						<Typography fontWeight='bold' variant='subtitle2'>Home State:</Typography>
					</Grid>
					<Grid item xs={7}>
						<Typography variant='subtitle2'>{applicant?.address?.state ?? '-'}</Typography>
					</Grid>
				</Grid>
				<Grid container sx={{'backgroundColor': grey[100], '&:hover': {backgroundColor: grey[300]}, 'paddingY': '3px', 'paddingX': '8px', 'borderRadius': '7px'}}>
					<Grid item xs={5}>
						<Typography fontWeight='bold' variant='subtitle2'>Date of Birth:</Typography>
					</Grid>
					<Grid item xs={7}>
						<Typography variant='subtitle2'>{formatDate(applicant?.dateOfBirth, true)}</Typography>
					</Grid>
				</Grid>
				<Grid container sx={{'backgroundColor': grey[100], '&:hover': {backgroundColor: grey[300]}, 'paddingY': '3px', 'paddingX': '8px', 'borderRadius': '7px'}}>
					<Grid item xs={5}>
						<Typography fontWeight='bold' variant='subtitle2'>SSN:</Typography>
					</Grid>
					<Grid item xs={7}>
						<Typography variant='subtitle2'>{applicant?.ssn ?? '-'}</Typography>
					</Grid>
				</Grid>
				<Grid container sx={{'backgroundColor': grey[100], '&:hover': {backgroundColor: grey[300]}, 'paddingY': '3px', 'paddingX': '8px', 'borderRadius': '7px'}}>
					<Grid item xs={5}>
						<Typography fontWeight='bold' variant='subtitle2'>Organization Name:</Typography>
					</Grid>
					<Grid item xs={7}>
						<Typography variant='subtitle2'>{organization ?? '-'}</Typography>
					</Grid>
				</Grid>
				<Grid container sx={{'backgroundColor': grey[100], '&:hover': {backgroundColor: grey[300]}, 'paddingY': '3px', 'paddingX': '8px', 'borderRadius': '7px'}}>
					<Grid item xs={5}>
						<Typography fontWeight='bold' variant='subtitle2'>Account ID:</Typography>
					</Grid>
					<Grid item xs={7}>
						<Typography variant='subtitle2'>{applicantId ?? '-'}</Typography>
					</Grid>
				</Grid>
				{children}
			</Box>
		</Box>
	);
}