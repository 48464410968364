import React, { useEffect } from 'react';
import { Grid, Paper, SelectChangeEvent } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Organization } from '@praos-health/organizations-client';
import { OrganizationSelect } from '../organization-select';
import { DateRangeOption, DateRangeSelect } from '../date-range-select';

interface FilterHeaderProps {
	showOrganizationSelect: boolean,
	organizationId: string,
	organizations: Organization[],
	timePeriod: {
		startDate: Date,
		endDate: Date
	},
	onOrganizationChange: (e: SelectChangeEvent<string>) => void,
	onDateChange: (newValue: Date | null, type: 'startDate' | 'endDate') => void,
	onDateRangeChange: (newValue: DateRangeOption) => void,
	dateRange: DateRangeOption,
	onError: (error: string | null) => void
}

export const FilterHeader: React.FC<FilterHeaderProps> = ({
	showOrganizationSelect,
	organizationId,
	organizations,
	timePeriod,
	onOrganizationChange,
	onDateChange,
	onDateRangeChange,
	dateRange,
	onError
}) => {

	const isCustomDateRange = dateRange === DateRangeOption.Custom;

	const convertToLocalDate = (date: Date) => {
		return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
	};

	const validateDates = (startDate: Date, endDate: Date) => {
		const now = new Date();
		if (startDate > now) {
			return 'Start date must be in the past';
		}
		if (startDate > endDate) {
			return 'Start date must be before (or the same as) the end date';
		}
		return null;
	};
	const handleLocalDateChange = (newValue: Date | null, type: 'startDate' | 'endDate') => {
		if (newValue) {
			const utcDate = new Date(newValue.getTime() - newValue.getTimezoneOffset() * 60000);

			const newStartDate = type === 'startDate' ? utcDate : timePeriod.startDate;
			const newEndDate = type === 'endDate' ? utcDate : timePeriod.endDate;

			const error = validateDates(newStartDate, newEndDate);
			onError(error);

			if (!error) {
				onDateChange(utcDate, type);
			}
		} else {
			onError(null);
			onDateChange(null, type);
		}
	};

	useEffect(() => {
		if (isCustomDateRange) {
			const error = validateDates(timePeriod.startDate, timePeriod.endDate);
			onError(error);
		} else {
			onError(null);
		}
	}, [isCustomDateRange, timePeriod, onError]);


	const datePickerProps = {
		slotProps: {
			textField: {
				fullWidth: true,
				disabled: !isCustomDateRange
			},
			inputAdornment: {
				sx: {
					visibility: isCustomDateRange ? 'visible' : 'hidden'
				}
			}
		}
	};

	return (
		<Paper
			elevation={3}
			sx={{
				top: 0,
				zIndex: 1100,
				backgroundColor: 'background.paper',
				marginBottom: 2,
				padding: 2
			}}
		>
			<Grid container spacing={2} alignItems="center">
				{showOrganizationSelect ? (
<>
	<Grid item xs={12} md={3}>
		<OrganizationSelect
			organizationId={organizationId}
			organizations={organizations}
			onChange={onOrganizationChange}
		/>
	</Grid>
	<Grid item xs={12} md={3}>
		<DateRangeSelect value={dateRange} onChange={onDateRangeChange} />
	</Grid>
	<Grid item xs={12} md={3}>
		<DatePicker
			label="From Date"
			value={convertToLocalDate(timePeriod.startDate)}
			onChange={(newValue) => handleLocalDateChange(newValue, 'startDate')}
			{...datePickerProps}
		/>
	</Grid>
	<Grid item xs={12} md={3}>
		<DatePicker
			label="Thru Date"
			value={convertToLocalDate(timePeriod.endDate)}
			onChange={(newValue) => handleLocalDateChange(newValue, 'endDate')}
			{...datePickerProps}
		/>
	</Grid>
</>
) : (
<>
	<Grid item xs={12} md={4}>
		<DateRangeSelect value={dateRange} onChange={onDateRangeChange} />
	</Grid>
	<Grid item xs={12} md={4}>
		<DatePicker
			label="From Date"
			value={convertToLocalDate(timePeriod.startDate)}
			onChange={(newValue) => handleLocalDateChange(newValue, 'startDate')}
			{...datePickerProps}
		/>
	</Grid>
	<Grid item xs={12} md={4}>
		<DatePicker
			label="Thru Date"
			value={convertToLocalDate(timePeriod.endDate)}
			onChange={(newValue) => handleLocalDateChange(newValue, 'endDate')}
			{...datePickerProps}
		/>
	</Grid>
</>
)}
			</Grid>
		</Paper>
	);
};