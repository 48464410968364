import React from 'react';
import { useApi, useToaster } from '@praos-health/ui';
import { useAuth } from '@praos-health/ui-security';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, Grid, InputLabel, Paper, Select, SelectChangeEvent } from '@mui/material';
import { Organization } from '@praos-health/organizations-client';
import {
	StatisticsType as ProfessionalStatisticsType,
	StatisticsOptions
} from '@praos-health/professional-client';
import { StatisticsType as JobStatisticsType } from '@praos-health/jobs-client';
import { RecruiterMetricsData, RecruiterMetricsTable } from '../../components';
import { AppApi } from '../../app-api';
export * from './recruiter-metrics-page-toolbar';

export function RecruiterMetricsPage(): JSX.Element {
	const api = useApi<AppApi>();
	const auth = useAuth();
	const { t } = useTranslation();
	const toast = useToaster();
	const [isLoading, setIsLoading] = useState(false);
	const [organizationId, setOrganizationId] = useState('all');
	const [organizations, setOrganizations] = useState<Organization[]>([]);
	const [recruiterStatistics, setRecruiterStatistics] = useState<RecruiterMetricsData[]>([]);

	useEffect(() => {
		async function loadOrganizations(): Promise<void> {
			if (auth.session?.organization) {
				setOrganizations([
					{
						_id: auth.session.organization._id,
						name: auth.session.organization.name
					}
				]);
				return;
			}

			const result = await api.organizationService.list(auth.session?.auth || '', {
				select: { name: 1 },
				status: 'APPROVED'
			});

			setOrganizations(result.list);
		}

		loadOrganizations().catch(async (e) => {
			if (e instanceof Error && (e.name === 'UnauthorizedError' || e.message === 'Unauthorized')) {
				toast('Your session has expired', 'error');
				return await auth.signout();
			}
			toast(e, 'error');
		});
	}, [api, auth, toast]);

	useEffect(() => {
		async function loadStatistics(): Promise<void> {
			const options: StatisticsOptions = {
				useIsoWeek: true
			};

			if (organizationId !== 'all') {
				options.organization = organizationId;
			}

			const [professionalStats, jobStats] = await Promise.all([
				api.professionalService.statistics(
					auth.session?.auth || '',
					ProfessionalStatisticsType.Recruiter,
					options
				),
				api.jobService.statistics(
					auth.session?.auth || '',
					JobStatisticsType.Recruiter,
					options
				)
			]);

			const data: RecruiterMetricsData[] = professionalStats.map((i:any) => {
				return {
					recruiter: [i.recruiter.firstName, i.recruiter.lastName]
						.filter(Boolean)
						.join(' '),
					department: i.recruiter.department,
					email: i.recruiter.email,
					count: i.count,
					activated: i.activated,
					licensed: i.licensed,
					completed: i.completed,
					applications: 0,
					submissions: 0,
					confirmations: 0
				};
			});

			for (const item of jobStats) {
				let found = false;

				for (const item2 of data) {
					if (item.recruiter.email === item2.email) {
						item2.applications = item.applications;
						item2.submissions = item.submissions;
						item2.confirmations = item.confirmations;
						found = true;
						break;
					}
				}

				if (!found) {
					data.push({
						recruiter: [item.recruiter.firstName, item.recruiter.lastName]
							.filter(Boolean)
							.join(' '),
						email: item.recruiter.email,
						count: 0,
						activated: 0,
						licensed: 0,
						completed: 0,
						applications: item.applications,
						submissions: item.submissions,
						confirmations: item.confirmations
					});
				}
			}

			setRecruiterStatistics(data);
		}

		setIsLoading(true);

		loadStatistics()
			.catch((e) => {
				toast(e, 'error');
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [api, auth, organizationId, toast]);

	const handleChange = async (e: SelectChangeEvent<string>) => {
		setOrganizationId(e.target.value as string);
	};

	return (
		<Grid container spacing={2}>
			{!auth.session?.organization && (
				<Grid item xs={12}>
					<Paper>
						<FormControl variant="standard" sx={(theme) => ({
							margin: theme.spacing(1),
							minWidth: 120
						})}>
							<InputLabel>{t('Organization')}</InputLabel>
							<Select
								variant="standard"
								native
								value={organizationId}
								onChange={handleChange}
								inputProps={{
									name: 'organization'
								}}
							>
								<option aria-label="None" value="all">
									{t('All')}
								</option>
								{organizations.map((value) => {
									return (
										<option key={value._id} value={value._id}>
											{value.name}
										</option>
									);
								})}
							</Select>
						</FormControl>
					</Paper>
				</Grid>
			)}
			<Grid item xs={12}>
				<RecruiterMetricsTable
					title={t('Recruiters')}
					data={recruiterStatistics}
					loading={isLoading}
					organizationId={organizationId}
				/>
			</Grid>
		</Grid>
	);
}
