import { AnyObject } from '@praos-health/core/utilities/object';
import { Organization } from './organization';
import { RecordPermissions } from './record';

export enum StandardRecordType {
	ActivityWork = 'Activity Work',
	AdditionalCertification = 'Additional Certification',
	AdditionalCertificationBack = 'Additional Certification Back',
	AdditionalDocument = 'Additional Document',
	BackgroundScreening = 'Background Screening',
	Certification = 'Certification',
	CertificationCoursework = 'Certification Coursework',
	Competency = 'Competency',
	ContinuingEducation = 'Continuing Education',
	ContinuingEducationCoursework = 'Continuing Education Coursework',
	DriversLicense = 'Drivers License',
	HealthDocument = 'Health Document',
	HealthScreen = 'Health Screen',
	Education = 'Education',
	EducationDocument = 'Education Document',
	EhrSkills = 'EHR and Computer Skills',
	LiabilityInsurance = 'Liability Insurance',
	License = 'License',
	ProfilePicture = 'Profile Picture',
	PSV_License = 'PSV License',
	OrganizationBanner = 'Organization Banner',
	OrganizationImage = 'Organization Image',
	OrganizationLogo = 'Organization Logo',
	OrganizationMembership = 'Organization Membership',
	Reference = 'Reference',
	Resume = 'Resume',
	SkillsChecklist = 'Skills Checklist',
	TaskPsvFile = 'Task PSV File',
	WorkHistory = 'Work History',
	Vaccination = 'Vaccination',
	VehicleInsurance = 'Vehicle Insurance'
}

export enum StandardAttachmentType {
	Back = 'back',
	Banner = 'banner',
	Certificate = 'certificate',
	Certification = 'certification',
	Checklist = 'checklist',
	Coursework = 'coursework',
	Diploma = 'diploma',
	Document = 'document',
	Image = 'image',
	Resume = 'resume',
	Front = 'front',
	License = 'license',
	Logo = 'logo',
	Verification = 'verification'
}

export enum FileRequirement {
	NotAllowed = 0,
	Optional = 1,
	ForCompletion = 2,
	Mandatory = 3
}

export interface RecordType {
	_id: any,
	name?: string,
	tags?: string[],
	organization?: Organization,
	editor?: string,
	settings?: AnyObject,
	jsonSchema?: string,
	fileRequirement?: { [ type: string ]: FileRequirement },
	isEnabled?: false,
	allowedPermissions?: RecordPermissions
}

export function getDefaultAttachmentType(recordType: StandardRecordType, isSecondary?: boolean): StandardAttachmentType {
	switch (recordType) {
		case StandardRecordType.ActivityWork:
			return StandardAttachmentType.Document;
		case StandardRecordType.AdditionalCertification:
			if (isSecondary) {
				return StandardAttachmentType.Back
			} else {
				return StandardAttachmentType.Front
			}
		case StandardRecordType.AdditionalDocument:
			return StandardAttachmentType.Document;
		case StandardRecordType.BackgroundScreening:
			return StandardAttachmentType.Document;
		case StandardRecordType.Certification:
			return StandardAttachmentType.Certificate;
		case StandardRecordType.ContinuingEducation:
			return StandardAttachmentType.Certificate;
		case StandardRecordType.Competency:
			return StandardAttachmentType.Document;
		case StandardRecordType.CertificationCoursework:
			return StandardAttachmentType.Coursework;
		case StandardRecordType.DriversLicense:
			return StandardAttachmentType.License;
		case StandardRecordType.Education:
			return StandardAttachmentType.Diploma;
		case StandardRecordType.EducationDocument:
			return StandardAttachmentType.Document;
		case StandardRecordType.HealthDocument:
			return StandardAttachmentType.Document;
		case StandardRecordType.HealthScreen:
			return StandardAttachmentType.Document;
		case StandardRecordType.License:
			return StandardAttachmentType.License;
		case StandardRecordType.OrganizationBanner:
			return StandardAttachmentType.Banner;
		case StandardRecordType.OrganizationImage:
			return StandardAttachmentType.Image;
		case StandardRecordType.OrganizationLogo:
			return StandardAttachmentType.Logo;
		case StandardRecordType.PSV_License:
			return StandardAttachmentType.Verification;
		case StandardRecordType.ProfilePicture:
			return StandardAttachmentType.Image;
		case StandardRecordType.Resume:
			return StandardAttachmentType.Resume;
		case StandardRecordType.SkillsChecklist:
			return StandardAttachmentType.Checklist;
		case StandardRecordType.TaskPsvFile:
			return StandardAttachmentType.Verification;
		case StandardRecordType.LiabilityInsurance:
			return StandardAttachmentType.Document;
		case StandardRecordType.Vaccination:
			return StandardAttachmentType.Document;
		case StandardRecordType.VehicleInsurance:
			return StandardAttachmentType.Document;
	}
}