import { request } from "@praos-health/http";
import { toJson } from "@praos-health/core/utilities/object";
import { toArray } from "@praos-health/core/utilities/array";
import { midnight } from "@praos-health/core/utilities/date";
import { FlatScreening, LicenseVerificationApplicant, LicenseVerificationTask, Organization, Screening, SystemName, Task, VettyApplicant, VettyPackage, parseScreening } from "./models";
import { AnyTask, TaskType } from "./models/task-type";

export interface AddVettyTaskOptions {
	applicant: VettyApplicant,
	packageId: string,
	system: SystemName,
	screeningId?: string,
	optionalChecks?: {
		name: string,
		code?: string,
		items?: string[]
	}[]
}

export interface AddLicenseVerificationOptions {
	applicant: LicenseVerificationApplicant,
	screeningId?: string,
	system?: SystemName,
	organization?: string
}

export interface CloseLicenseVerificationOptions {
	verified: boolean,
	note?: string,
	license?: LicenseDetails,
	recordId?: string,
	tempFileName?: string,
	shouldSkipWorkflowTrigger?: boolean
}

export interface LicenseDetails {
	licenseBody?: string,
	issueDate?: Date,
	expirationDate?: Date,
	licenseNumber?: string
}

export interface FileUpdateOptions {
	fileId: string,
	taskType: string
}

export type ListOrderBy =
	'applicantId' | '!applicantId' |
	'tasks.applicant.firstName' | '!tasks.applicant.firstName' |
	'tasks.applicant.lastName' | '!tasks.applicant.lastName' |
	'tasks.applicant.licenses.licenseType' | '!tasks.applicant.licenses.licenseType' |
	'tasks.applicant.licenses.licenseBody' | '!tasks.applicant.licenses.licenseBody' |
	'tasks.closedBy' | '!tasks.closedBy' |
	'tasks.closedAt' | '!tasks.closedAt' |
	'tasks.note' | '!tasks.note' |
	'tasks.createdAt' | '!tasks.createdAt' | 'tasks.status' | '!tasks.status';

export interface ListScreeningsOptions {
	limit?: number,
	skip?: number,
	orderBy?: ListOrderBy,
	system?: SystemName,
	applicantId?: string | string[],
	isOpen?: boolean,
	type?: TaskType,
	organization?: string,
	search?: string | AdvancedSearch
}

export type FlatListScreeningsOptions = ListScreeningsOptions & {
	flatten: true
}

export type NonFlatListScreeningsOptions = ListScreeningsOptions & {
	flatten?: false
}

export interface AdvancedSearch {
	text?: string,
	firstName?: string,
	lastName?: string,
	email?: string,
	licenseBody?: string,
	licenseNumber?: string,
	licenseType?: string
}

export interface ListResponse<T> {
	list: T[],
	count: number
}

export class ScreeningService {
	constructor(protected apiUrl: string) {}

	async getOrganization(auth: string, organizationId: string): Promise<Organization> {
		return (await request<Organization>(`${this.apiUrl}/screening/organization/${organizationId}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: auth
			}
		})).body;
	}

	async getPackages(auth: string): Promise<VettyPackage[]> {
		return (await request<VettyPackage[]>(`${this.apiUrl}/screening/vetty/packages`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: auth
			}
		})).body;
	}

	async addVettyTask(auth: string, options: AddVettyTaskOptions): Promise<FlatScreening> {
		const result = (await request<FlatScreening>(`${this.apiUrl}/screening/vetty`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: auth
			},
			body: JSON.stringify(toJson(options))
		})).body;

		return parseScreening(result);
	}

	async addLicenseVerification(auth: string, options: AddLicenseVerificationOptions): Promise<FlatScreening> {
		const result = (await request<FlatScreening>(`${this.apiUrl}/screening/licenseverification`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: auth
			},
			body: JSON.stringify(toJson(options))
		})).body;

		return parseScreening(result);
	}

	async closeLicenseVerification(auth: string, task: string | LicenseVerificationTask, options: CloseLicenseVerificationOptions): Promise<void> {
		if (options.license?.issueDate) {
			options.license.issueDate = midnight(options.license.issueDate, true);
		}

		if (options.license?.expirationDate) {
			options.license.expirationDate = midnight(options.license.expirationDate, true);			
		}

		await request<Screening>(`${this.apiUrl}/screening/licenseverification/${typeof task === 'string' ? task : task.id}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				Authorization: auth
			},
			body: JSON.stringify(toJson(options))
		});
	}

	async removeLicenseVerificationFile(auth: string, task: string | LicenseVerificationTask): Promise<void> {
		await request<Screening>(`${this.apiUrl}/screening/licenseverification/${typeof task === 'string' ? task : task.id}/file`, {
			method: 'DELETE',
			headers: {
				Authorization: auth
			}
		});
	}

	async getScreening(auth: string, id: string ): Promise<Screening> {
		const result = (await request<Screening>(`${this.apiUrl}/screening/${id}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: auth
			}
		})).body;

		return parseScreening(result);
	}

	async getTask<T extends Task = Task>(auth: string, id: string ): Promise<FlatScreening<T>> {
		const result = (await request<FlatScreening<T>>(`${this.apiUrl}/screening/${id}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: auth
			}
		})).body;

		return parseScreening(result);
	}

	async listScreenings(auth: string, options?: NonFlatListScreeningsOptions): Promise<ListResponse<Screening>>;
	async listScreenings(auth: string, options:  FlatListScreeningsOptions): Promise<ListResponse<FlatScreening>>;
	async listScreenings(auth: string, options?: ListScreeningsOptions & { flatten?: boolean, csv?: boolean }): Promise<ListResponse<Screening | FlatScreening> | string>
	async listScreenings(auth: string, options?: ListScreeningsOptions & { flatten?: boolean, csv?: boolean }): Promise<ListResponse<Screening | FlatScreening> | string> {
		const url = new URL(`${this.apiUrl}/screening`);
		const headers = {
				'Content-Type': 'application/json',
				Authorization: auth
			}
		if (options) {
			if (options.applicantId) {
				url.searchParams.append('applicantId', toArray(options.applicantId).join(","));
			}
			
			if (options.system) {
				url.searchParams.append('system', options.system);
			}

			if (options.skip !== undefined) {
				url.searchParams.append('skip', options.skip.toString());
			}

			if (options.limit) {
				url.searchParams.append('limit', options.limit.toString());
			}

			if (options.type) {
				url.searchParams.append('type', options.type);
			}

			if (options.organization !== undefined) {
				url.searchParams.append('organization', options.organization);
			}

			if (options.orderBy) {
				url.searchParams.append('orderBy', options.orderBy);
			}

			if (options.search) {
				url.searchParams.append(
					'search',
					typeof options.search === 'string' ? options.search : JSON.stringify(options.search)
				);
			}

			if (options.flatten !== undefined) {
				url.searchParams.append('flatten', options.flatten.toString());
			}

			if (options.isOpen !== undefined) {
				url.searchParams.append('isOpen', options.isOpen.toString());
			}

			if(options.csv !== undefined) {
				Object.assign(headers, { Accept: 'text/csv' })
			}
		}

		const result = (await request<ListResponse<Screening | FlatScreening>>(url.toString(), {
			method: 'GET',
			headers 
		})).body;

		for (let i = 0; i < result?.list?.length; i++) {
			result.list[i] = parseScreening(result.list[i]);
		}

		return result;
	}

	async updateStatuses(): Promise<void> {
		await request<Screening>(`${this.apiUrl}/screening/vetty/scheduler`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json'
			}
		});
	}

	async updateTaskFile(auth: string, task: string | AnyTask, options: FileUpdateOptions): Promise<void> {
		await request<Screening>(`${this.apiUrl}/screening/task/${typeof task === 'string' ? task : task.id}/file`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				Authorization: auth
			},
			body: JSON.stringify(toJson(options))
		});
	}
}
