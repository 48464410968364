import { geoMercator, geoPath, GeoPermissibleObjects } from 'd3-geo';

export function getBounds(features: GeoPermissibleObjects[]): [[number, number], [number, number]] {
	const projection = geoMercator()
		.scale(1)
		.translate([0, 0]);

	// Create a path generator.
	const path = geoPath().projection(projection);

	const result: [[number, number], [number, number]] = path.bounds(features[0]);

	for (let i = 1; i < features.length; i++) {
		const curResult = path.bounds(features[i]);

		result[0][0] = Math.min(result[0][0], curResult[0][0]);
		result[0][1] = Math.min(result[0][1], curResult[0][1]);
		result[1][0] = Math.max(result[1][0], curResult[1][0]);
		result[1][1] = Math.max(result[1][1], curResult[1][1]);
	}

	return result;
}

export function scaleAndTranslate(width: number, height: number, bounds: [[number, number], [number, number]]): [number, [number, number]] {
	const scale = .95 / Math.max((bounds[1][0] - bounds[0][0]) / width, (bounds[1][1] - bounds[0][1]) / height);

	return [
		scale,
		// XXX we need to multiply by the width and height because nivo multiply this translation: https://github.com/plouc/nivo/blob/master/packages/geo/src/hooks.js#L57
		[
			(width - scale * (bounds[1][0] + bounds[0][0])) / 2 / width,
			(height - scale * (bounds[1][1] + bounds[0][1])) / 2 / height
		]
	];
}