import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAuth } from '@praos-health/ui-security';

import { Avatar, Divider, List, ListItemButton, ListItemIcon } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';

import { Props } from './navigation.types';
import { menu } from './navigation.constants';
import { StyledNavigationHeader } from './navigation.styles';

function Navigation({ childrendOnClick }: Props): JSX.Element {
	const { t } = useTranslation();
	const auth = useAuth();
	const location = useLocation();
	let { pathname } = location;
	const lastSlash = pathname.lastIndexOf('/');

	if (lastSlash) {
		pathname = pathname.substring(0, lastSlash);
	}

	return (
		<div>
			<StyledNavigationHeader>
				<Avatar src={auth?.session?.user.profilePicUrl} sx={(theme) => ({
					margin: `0 auto ${theme.spacing(1)} auto`,
					width: theme.spacing(10),
					height: theme.spacing(10)
				})}/>
				<div>{`${auth?.session?.user.firstName} ${auth?.session?.user.lastName}`}</div>
			</StyledNavigationHeader>
			<Divider />
			<List sx={{paddingTop: 0}}>
				{menu.map((item, index) => (
					<ListItemButton
						key={`menu-item-${index}`}
						onClick={childrendOnClick}
						component={NavLink}
						to={item.href}
						sx={(theme) => {
							return {
								backgroundColor: (item.href.startsWith(`${pathname}/`) || item.href === pathname) ? theme.palette.grey[300] : undefined
							};
						}}
					>

						<ListItemIcon>{<item.Icon />}</ListItemIcon>
						<ListItemText primary={t(item.title)} />
					</ListItemButton>
				))}
			</List>
		</div>
	);
}

export default Navigation;
