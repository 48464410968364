import { User, parseUser } from "./user";
import { JobTimesheet, parseJobTimesheet } from "./job-timesheet";

export enum JobApplicationStatus {
	Accepted = 'ACCEPTED',
	Rejected = 'REJECTED',
	Withdrawn = 'WITHDRAWN',
	Expired = 'EXPIRED',
	Ignored = 'IGNORED',
	Cancelled = 'CANCELLED',
	Pending = 'PENDING',
	Submitted = 'SUBMITTED',

	Enroute = 'ENROUTE',
	Started = 'STARTED',
	Completed = 'COMPLETED'
}

export type OrganizationReview = {
	rating?: number,
	review?: string,
	reviewedAt?: Date,
	reviewedByUser?: User
}

export type JobApplication = {
	user: User,
	status: JobApplicationStatus,
	appliedAt: Date,
	withdrawnAt: Date,
	expiredAt: Date,
	updateAt?: Date,
	confirmedAt?: Date
	confirmedByUser?: User,
	rejectedAt?: Date,
	rejectedByUser?: User,
	ignoredAt?: Date,
	cancelledAt?: Date,
	cancelledByUser?: User,
	cancelledByApplicant?: boolean
	enrouteAt?: Date,
	approvedAt?: Date,
	approvedByUser?: User,
	submittedAt?: Date,
	submittedByUser?: User,
	completedAt?: Date,
	startDate?: Date,
	startTime?: Date,
	endDate?: Date,
	endTime?: Date,
	timesheet?: JobTimesheet,
	supervisorUser?: User,
	organizationReview?: OrganizationReview,
	sharedDocuments?: string[];
}

export function parseJobApplication(json: any): JobApplication {
	const result: JobApplication = json;

	if (json.appliedAt) {
		result.appliedAt = new Date(json.appliedAt);
	}

	if (json.withdrawnAt) {
		result.withdrawnAt = new Date(json.withdrawnAt);
	}

	if (json.updateAt) {
		result.updateAt = new Date(json.updateAt);
	}

	if (json.submittedAt) {
		result.submittedAt = new Date(json.submittedAt);
	}

	if (json.submittedByUser) {
		result.submittedByUser = parseUser(json.submittedByUser);
	}

	if (json.confirmedAt) {
		result.confirmedAt = new Date(json.confirmedAt);
	}

	if (json.confirmedByUser) {
		result.confirmedByUser = parseUser(json.confirmedByUser);
	}

	if (json.rejectedAt) {
		result.rejectedAt = new Date(json.rejectedAt);
	}

	if (json.rejectedByUser) {
		result.rejectedByUser = parseUser(json.rejectedByUser);
	}

	if (json.cancelledAt) {
		result.cancelledAt = new Date(json.cancelledAt);
	}

	if (json.cancelledByUser) {
		result.cancelledByUser = parseUser(json.cancelledByUser);
	}

	if (json.approvedAt) {
		result.approvedAt = new Date(json.approvedAt);
	}

	if (json.approvedByUser) {
		result.approvedByUser = parseUser(json.approvedByUser);
	}

	if (json.startDate) {
		result.startDate = new Date(json.startDate);
	}

	if(json.startTime) {
		result.startTime = new Date(json.startTime);
	}

	if (json.endDate) {
		result.endDate = new Date(json.endDate);
	}

	if(json.endTime) {
		result.endTime = new Date(json.endTime)
	}

	if (json.completedAt) {
		result.completedAt = new Date(json.completedAt);
	}

	if (json.timesheet instanceof Array) {
		for (let i = 0; i < json.timesheet.length; i++) {
			json.timesheet[i] = parseJobTimesheet(json.timesheet[i]);
		}
	}

	if (json.supervisorUser) {
		result.supervisorUser = parseUser(json.supervisorUser);
	}

	return result;
}