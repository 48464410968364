import React from 'react';
import { FormControl, Grid, InputLabel, Paper, Select, SelectChangeEvent } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	BriefcaseProgressionBar,
	BriefcaseProgressionLine,
	NewLeadLine,
	UserActivationBar
} from '../../components';
import { StatisticsSummary, StatisticsSummaryData } from '@praos-health/web/components/statistics-summary';
import { Organization } from '@praos-health/organizations-client';
import { StatisticsType, StatisticsOptions } from '@praos-health/professional-client';
import { useAuth } from '@praos-health/ui-security';
import { useApi, useToaster } from '@praos-health/ui';
import { UserInsightsTable } from '../../components';
import { AppApi } from '../../app-api';
export * from './user-insights-page-toolbar';

export function UserInsightsPage(): JSX.Element {
	const { t } = useTranslation();
	const [isStatsLoading, setIsStatsLoading] = useState(false);
	const auth = useAuth();
	const toast = useToaster();
	const api = useApi<AppApi>();
	const [organizationId, setOrganizationId] = useState('all');
	const [organizations, setOrganizations] = useState<Organization[]>([]);
	const [signupStatistics, setSignupStatistics] = useState<StatisticsSummaryData>({
		thisWeek: 0,
		lastWeek: 0,
		thisMonth: 0,
		lastMonth: 0,
		allTime: 0
	});
	const [archivalsStatistics, setArchivalsStatistics] = useState<StatisticsSummaryData>({
		thisWeek: 0,
		lastWeek: 0,
		thisMonth: 0,
		lastMonth: 0,
		allTime: 0
	});
	const [activationStatistics, setActivationStatistics] = useState<StatisticsSummaryData>({
		thisWeek: 0,
		lastWeek: 0,
		thisMonth: 0,
		lastMonth: 0,
		allTime: 0
	});
	const [licensedStatistics, setLicensedStatistics] = useState<StatisticsSummaryData>({
		thisWeek: 0,
		lastWeek: 0,
		thisMonth: 0,
		lastMonth: 0,
		allTime: 0
	});
	const [completionStatistics, setcompletionStatistics] = useState<StatisticsSummaryData>({
		thisWeek: 0,
		lastWeek: 0,
		thisMonth: 0,
		lastMonth: 0,
		allTime: 0
	});
	const [tableData, setTableData] = useState<any>({});

	useEffect(() => {
		async function loadOrganizations(): Promise<void> {
			if (auth.session?.organization) {
				setOrganizations([
					{
						_id: auth.session.organization._id,
						name: auth.session.organization.name
					}
				]);
				return;
			}
			const result = await api.organizationService.list(auth.session?.auth || '', {
				select: { name: 1 },
				status: 'APPROVED'
			});

			setOrganizations(result.list);
		}

		loadOrganizations()
			.catch(async (e) => {
				if (e instanceof Error && (e.name === 'UnauthorizedError' || e.message === 'Unauthorized')) {
					toast('Your session has expired', 'error');
					return await auth.signout();
				}
				toast(e, 'error');
			});
	}, [api, auth, toast]);

	useEffect(() => {
		async function loadStatistics(): Promise<void> {
			const options: StatisticsOptions = {
				useIsoWeek: true
			};

			if (organizationId === 'praos') {
				options.isMarketplace = true;
			} else if (organizationId !== 'all') {
				options.organization = organizationId;
			}

			const result = await api.professionalService.statistics(
				auth.session?.auth || '',
				StatisticsType.UserInsights,
				options
			);

			setTableData(result);
			setSignupStatistics(result.created);
			setArchivalsStatistics(result.archived);
			setActivationStatistics(result.activated);
			setLicensedStatistics(result.licensed);
			setcompletionStatistics(result.completed);
		}

		setIsStatsLoading(true);

		loadStatistics()
			.catch((e) => {
				toast(e, 'error');
			})
			.finally(() => {
				setIsStatsLoading(false);
			});
	}, [api, auth, organizationId, toast]);

	const handleChange = async (e: SelectChangeEvent<string>) => {
		setOrganizationId(e.target.value as string);
	};

	return (
		<div>
			<Grid container spacing={2}>
				{!auth.session?.organization && (
					<Grid item xs={12}>
						<Paper>
							<FormControl variant="standard" sx={(theme) => ({
								margin: theme.spacing(1),
								minWidth: 120
							})}>
								<InputLabel>{t('Organization')}</InputLabel>
								<Select
									variant="standard"
									native
									value={organizationId}
									onChange={handleChange}
									inputProps={{
										name: 'organization'
									}}
								>
									<option aria-label="None" value="all">
										{t('All')}
									</option>
									<option aria-label="PraosHealth" value="praos">
										Praos Health
									</option>
									{organizations.map((value) => {
										return (
											<option key={value._id} value={value._id}>
												{value.name}
											</option>
										);
									})}
								</Select>
							</FormControl>
						</Paper>
					</Grid>
				)}
				<Grid item xs={12} md={12}>
					<UserInsightsTable data={tableData} loading={isStatsLoading} organizationId={organizationId}  />
				</Grid>
				<Grid item xs={12} md={6}>
					<Paper>
						<StatisticsSummary
							title={t('Signups')}
							description={t('SignupsDescription')}
							data={signupStatistics}
							loading={isStatsLoading}
						/>
					</Paper>
				</Grid>
				<Grid item xs={12} md={6}>
					<Paper>
						<StatisticsSummary
							title={t('Archivals')}
							description={t('ArchivalsDescription')}
							data={archivalsStatistics}
							loading={isStatsLoading}
						/>
					</Paper>
				</Grid>
				<Grid item xs={12} md={6}>
					<Paper>
						<StatisticsSummary
							title={t('ActivationsOnly')}
							description={t('ActivationsOnlyDescription')}
							data={activationStatistics}
							loading={isStatsLoading}
						/>
					</Paper>
				</Grid>
				<Grid item xs={12} md={6}>
					<Paper>
						<StatisticsSummary
							title={t('Licensed')}
							description={t('LicensedDescription')}
							data={licensedStatistics}
							loading={isStatsLoading}
						/>
					</Paper>
				</Grid>
				<Grid item xs={12} md={6}>
					<Paper>
						<StatisticsSummary
							title={t('BriefcaseCompletions')}
							description={t('BriefcaseCompletionsDescription')}
							data={completionStatistics}
							loading={isStatsLoading}
						/>
					</Paper>
				</Grid>
				<Grid item xs={12}>
					<Paper>
						<NewLeadLine
							title={t('NewLeads')}
							description={t('NewLeadsDescription')}
							organization={organizationId}
							height={500}
						/>
					</Paper>
				</Grid>
				<Grid item xs={12}>
					<Paper>
						<BriefcaseProgressionBar
							title={t('LeadFunnel')}
							description={t('LeadFunnelDescription')}
							organization={organizationId}
							height={500}
						/>
					</Paper>
				</Grid>
				<Grid item xs={12}>
					<Paper>
						<BriefcaseProgressionBar
							title={t('CumulativeActiveUserStatistics')}
							description={t('CumulativeActiveUserStatisticsDescription')}
							organization={organizationId}
							accumulate
							height={500}
							showTotals
							hideInactive
						/>
					</Paper>
				</Grid>
				<Grid item xs={12}>
					<Paper>
						<BriefcaseProgressionLine
							title={t('FunnelConversions')}
							description={t('FunnelConversionsDescription')}
							organization={organizationId}
							height={500}
							usePercentage
						/>
					</Paper>
				</Grid>
				<Grid item xs={12}>
					<Paper>
						<UserActivationBar
							title={t('CumulativeActivationTrend')}
							description={t('CumulativeActivationTrendDescription')}
							organization={organizationId}
							height={500}
							downloadable
						/>
					</Paper>
				</Grid>
			</Grid>
		</div>
	);
}
