import { clone } from "@praos-health/core/utilities/object";
import { Professional, Affiliation, ArchivedStatistics, ByStateStatistics, NewLeadStatistics, BriefcaseField, BriefcaseItem, License, AdditionalCertification, Certification, TotalStatistics, User, CompletionStatistics, UserInsightsStatistics, UserEngagementStatistics, CredentialsStatistics, RecruiterStatistics } from "./models";
import { parseActivityWork } from "./models/activity-work";
import { prepBriefcaseItem } from "./models/briefcase";
import { parseAdditionalCertification } from "./models/additional-certification";
import { parseCertification } from "./models/certification";
import { parseCompetency } from "./models/competency";
import { parseContinuingEducation } from "./models/education";
import { parseLicense } from "./models/license";
import { parseOrganizationMembership } from "./models/organization-membership";
import { parseProfessional, prepProfessional } from "./models/professional";
import { AffiliationClient, parseAffiliation } from "./models/affiliation";
import { ConsentForm, parseConsentForm } from "./models/consent-form";
import { ajax, ajaxKey } from "./ajax";
import { prepUser } from "./models/user";
import { BriefcaseStatistics } from "./models/statistics/briefcase-statistics";
import { NursysResult } from "./models/nursys-result";

export type ProfessionalSelect = {
	'intId'?: 1,
	'activatedAt'?: 1,
	'affiliations'?: 1,
	'affiliations.notes'?: 1,
	'affiliations.clients'?: 1,
	'affiliations.consentedAt'?: 1,
	'createdAt'?: 1,
	'firstName'?: 1,
	'lastName'?: 1,
	'address'?: 1,
	'dateOfBirth'?: 1,
	'deactivatedAt'?: 1,
	'deactivatedBy'?: 1,
	'deactivationNotes'?: 1,
	'email'?: 1,
	'emailVerifiedAt'?:1,
	'facebookId'?: 1,
	'linkedInId'?: 1,
	'gender'?: 1,
	'emailCommunicationEnabled'?: 1,
	'phoneNumber'?: 1,
	'ssn'?: 1,
	'timezone'?: 1,
	'profilePicFileId'?: 1,
	'profilePicUrl'?: 1,
	'profilePicThumbUrl'?: 1,
	'signupIpAddress'?: 1,
	'signupChannel'?: 1,
	'languages'?: 1,
	'briefcase.activityWork'?: 1,
	'briefcase.additionalCertifications'?: 1,
	'briefcase.additionalCertificationHistory'?: 1,
	'briefcase.additionalDocuments'?: 1,
	'briefcase.certifications'?: 1,
	'briefcase.certificationHistory'?: 1,
	'briefcase.completedAt'?: 1,
	'briefcase.competencies'?: 1,
	'briefcase.consentedAt'?: 1,
	'briefcase.continuingEducation'?: 1,
	'briefcase.currentStep'?: 1,
	'briefcase.driversLicense'?: 1,
	'briefcase.education'?: 1,
	'briefcase.educationDocuments'?: 1,
	'briefcase.educationLevel'?: 1,
	'briefcase.ehrSkills'?: 1,
	'briefcase.facilities'?: 1,
	'briefcase.healthDocuments'?: 1,
	'briefcase.liabilityInsurance'?: 1,
	'briefcase.licensedAt'?: 1,
	'briefcase.licenses'?: 1,
	'briefcase.licenseHistory'?: 1,
	'briefcase.nursys'?: 1,
	'briefcase.organizationMembership'?: 1,
	'briefcase.personalStatement'?: 1,
	'briefcase.references'?: 1,
	'briefcase.specialties'?: 1,
	'briefcase.summary'?: 1,
	'briefcase.vehicleInsurance'?: 1,
	'briefcase.w9'?: 1,
	'briefcase.workExperience'?: 1,
	'briefcase.yearsOfExperience'?: 1,
	'emergencyContact'?: 1,
	'isMarketplace'?: 1,
	'jobs.cancelledJobs'?: 1,
	'jobs.confirmedJobs'?: 1,
	'jobs.competentReviews'?: 1,
	'jobs.dnrOrganizations'?: 1,
	'jobs.professionalReviews'?: 1,
	'jobs.skilledReviews'?: 1,
	'jobs.badgedReviews'?: 1,
	'jobs.newJobEmail'?: 1,
	'jobs.newJobSms'?: 1,
	'jobs.workCities'?: 1,
	'jobs.workStates'?: 1,
	'jobs.workDistance'?: 1,
	'jobs.availiableDate'?: 1,
	'jobs.requestedTimeOff'?: 1,
	'jobs.preferredShift'?: 1,
	'jobs.preferredSchedule'?: 1,
	'profession'?: 1,
	'suspendedAt'?: 1
};

export type VerifyOptions = {
	token: string,
	organizationId?: string,
	recruiterId?: string,
	isMarketplace?: boolean,
	password?: string,
	phoneNumber?: string
}

export type VerifyWithDeviceOptions = VerifyOptions & {
	deviceType: 'IOS' | 'ANDROID',
	deviceId : string
	dviceToken : string
}

export type AnonymousAddProfessionalOptions = {
	item: Professional,
	select?: ProfessionalSelect,
	organizationId?: string,
	password?: string
}

export type AddProfessionalOptions = {
	item: Professional,
	select?: ProfessionalSelect,
	departmentId?: string,
	thirdPartyId?: string,
	recruiter?: User | string,
	notes?: string,
	isIrp?: boolean
}

export type AddExternalProfessionalOptions = {
	item: Professional,
	organizationId?: string,
	jobId?: string,
	recruiter?: string,
	password?: string,
	referredBy?: string,
	fileName?: string
}

export type GetProfessionalOptions = {
	select?: ProfessionalSelect | 'overview' | 'full'
};

export type AnonymousGetProfessionalOptions = {
	shareToken: string
} | {
	verificationToken: string
} | {
	passwordResetToken: string
}

export type UpdateOptions = {
	item: Professional,
	select?: ProfessionalSelect
}

export type AddMarketplaceAffiliationOptions = {
	isMarketplace: true,
	notes?: string
}

export type AddAffiliationOptions = {
	organizationId?: string,
	isIrp?: boolean,
	thirdPartyId?: string,
	recruiter?: User | string,
	departmentId?: string,
	notes?: string
}

export type UpdateAffiliationOptions = {
	thirdPartyId?: string,
	recruiter?: User | string,
	departmentId?: string,
	suspend?: boolean,
	notes?: string,
	isIrp?: boolean
}

export type ListProfessionalsOptions = {
	profession?: string,
	organizationId?: string,
	departmentId?: string,
	recruiterId?: string,
	isIrp?: boolean,
	isMarketplace?: boolean,
	isDeactivated?: boolean,
	orderBy?: 'intId' | '!intId' | 'status' | '!status' | 'name' | '!name' | 'phoneNumber' | '!phoneNumber' | 'email' | '!email' | 'licenseType' | 'licenseType!',
	limit?: number,
	skip?: number,
	status?: 'NOT_ACTIVE' | 'ACTIVATED' | 'LICENSED' | 'COMPLETED' | 'ISSUES' | 'ACTIVE',
	statusCounts?: boolean,
	search?: string | {
		text?: string,
		firstName?: string,
		lastName?: string,
		email?: string,
		city?: string,
		state?: string,
		zip?: string,
		licenseBody?: string,
		licenseNumber?: string,
		licenseType?: string,
		specialty?: string | string[],
		certification?: string,
		additionalCertification?: string
	},
	isSuspended?: boolean,
	select?: ProfessionalSelect
}

export type ExistsProfessionalsOptions = {
	entityId?: string,
	system?: string,
	organizationId?: string
}

export type SyncExternalProfessionalsOptions = {
	entityName?: string,
	entityId?: string,
	system?: string,
	organizationId?: string
}

export type SyncProfessionalOptions = {
	'profile'?: 1,
	'briefcase.licenses'?: 1,
	'briefcase.specialties'?: 1,
	'briefcase.certifications'?: 1,
	'briefcase.additionalCertifications'?: 1,
	'briefcase.education'?: 1,
	'briefcase.yearsOfExperience'?: 1,
	'briefcase.workExperience'?: 1,
	'briefcase.references'?: 1
}

export enum StatisticsType {
	Archives = 'archives',
	Briefcase = 'briefcase',
	ByState = 'bystate',
	Completions = 'completions',
	Credentials = 'credentials',
	NewLeads = 'newleads',
	Recruiter = 'recruiter',
	Totals = 'totals',
	UserEngagement = 'userengagement',
	UserInsights = 'userinsights',
	UserInsightsDownload = 'userinsightsdownload'
}

export enum TimeFrame {
	thisWeek = 'THIS_WEEK',
	lastWeek = 'LAST_WEEK',
	thisMonth = 'THIS_MONTH',
	lastMonth = 'LAST_MONTH',
	allTime = 'ALL_TIME',
}

export type StatisticsOptions = (StatisticsDataOptions | StatisticsCsvDownloadOptions);

export interface StatisticsDataOptions {
	isMarketplace?: boolean,
	organization?: string,
	date?: Date,
	startDate?: Date,
	endDate?: Date,
	useIsoWeek?: boolean,
	accumulate?: boolean,
	specialty?: string,
	timeFrame?: TimeFrame,
	download?: false
}

export interface StatisticsCsvDownloadOptions {
	isMarketplace?: boolean,
	organization?: string,
	date?: Date,
	startDate?: Date,
	endDate?: Date,
	useIsoWeek?: boolean,
	accumulate?: boolean,
	specialty?: string,
	timeFrame?: TimeFrame,
	download: true
}

export type ShareBriefcaseAccessLevel = 'overview' | 'full';

export type ShareBriefcaseResult = {
	url: string
}

export type CsvDownload = {
	csv: string, 
	fileName: string
}

export type ListProfessionalsResult = {
	list: Professional[],
	count: number,
	leadCount?: number,
	registeredCount?: number,
	licensedCount?: number,
	completedCount?: number,
	issuesCount?: number,
	activeCount?: number
}

export type ProfessionalExistsResult = {
	exists?: boolean	
}

export type ContactMeOptions = {
  preferredShift?: string,
  hourlyRate?: string,
  contactHours?: string,
  message?: string,
  organization?: string
}

export type InviteCandidateOptions = {
	firstName?: string,
	lastName?: string,
	email?: string
}

export type personInfo = {
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string
}
export type ReferPayload = {
    info: personInfo,
    referral: personInfo,
    recruiterId?: string,
    organizationId: string,
    jobId: string
}
export type TextMeAppOptions = {
	number: number
}

export class ProfessionalService {
	constructor(protected apiUrl: string, public cacheEnabled = true) {
	}

	async verify(userId: string, options: VerifyOptions | VerifyWithDeviceOptions): Promise<string> {
		if (options.phoneNumber) {
			options.phoneNumber = options.phoneNumber.replace(/\D/g,'');

			if (!options.phoneNumber) {
				delete options.phoneNumber;
			}
		}

		return await ajax(`${this.apiUrl}/professional/${userId}/verify`, 'POST', null, options);
	}

	async add(options: AnonymousAddProfessionalOptions): Promise<Professional>;
	async add(auth: string, options: AddProfessionalOptions): Promise<Professional>;
	async add(auth: any, options?: AddProfessionalOptions | AnonymousAddProfessionalOptions): Promise<Professional> {
		if (!(typeof auth === 'string')) {
			options = auth;
			auth = null;
		}

		options.item = prepProfessional(clone<any>(options.item));

		let result = await ajax(`${this.apiUrl}/professional`, 'POST', auth, options);

		if (result) {
			result = parseProfessional(result);
		}

		return result;
	}

	async addExternal(options: AddExternalProfessionalOptions): Promise<Professional> {
		options.item = prepProfessional(clone<any>(options.item));

		let result = await ajax(`${this.apiUrl}/professional/external`, 'POST', null, options);

		if (result) {
			result = parseProfessional(result);
		}

		return result;
	}

	async referCandidate(xApiKey: string, payload: ReferPayload): Promise<boolean> {
		return await ajaxKey(`${this.apiUrl}/professional/refer-candidate`, 'POST', xApiKey, payload);
	}

	async get(userId: string, options: AnonymousGetProfessionalOptions): Promise<Professional>;
	async get(auth: string, options?: GetProfessionalOptions): Promise<Professional>;
	async get(auth: string, userId: string, options?: GetProfessionalOptions): Promise<Professional>;
	async get(auth: string, userId?: any, options?: GetProfessionalOptions): Promise<Professional> {
		let user: any;

		if (typeof userId === 'object' && (userId.shareToken || userId.verificationToken || userId.passwordResetToken)) {
			user = await ajax(`${this.apiUrl}/professional/${auth}`, 'GET', null, null, userId);
		} else {
			if (typeof userId !== 'string') {
				options = userId;
				userId = 'session';
			}

			user = await ajax(`${this.apiUrl}/professional/${userId}`, 'GET', auth, null, options);
		}

		return parseProfessional(user);
	}

	async list(auth: string, options?: ListProfessionalsOptions): Promise<ListProfessionalsResult> {
		const result: any = await ajax(`${this.apiUrl}/professional`, 'GET', auth, undefined, options);

		for (let i = 0; i < result.list.length; i++) {
			result.list[i] = parseProfessional(result.list[i]);
		}

		return result;
	}

	async syncFromExternal(options?: SyncExternalProfessionalsOptions): Promise<void> {
		return await ajax(`${this.apiUrl}/professional/external/sync`, 'PUT', undefined, options);
	}

	async update(auth: string, userId: string, options: UpdateOptions): Promise<Professional> {
		options.item = prepProfessional(clone<any>(options.item));

		const result = await ajax(`${this.apiUrl}/professional/${userId}`, 'PUT', auth, options);

		return parseProfessional(result);
	}

	async addBriefcaseItem(auth: string, userId: string, field: BriefcaseField.AdditionalCertifications, item: AdditionalCertification): Promise<AdditionalCertification>;
	async addBriefcaseItem(auth: string, userId: string, field: BriefcaseField.Certifications, item: Certification): Promise<Certification>;
	async addBriefcaseItem(auth: string, userId: string, field: BriefcaseField.Licenses, item: License): Promise<License>;
	async addBriefcaseItem<T extends BriefcaseItem = BriefcaseItem>(auth: string, userId: string, field: BriefcaseField, item: T): Promise<T>;
	async addBriefcaseItem(auth: string, userId: string, field: BriefcaseField, item: BriefcaseItem): Promise<BriefcaseItem> {
		const body = clone<any>(item);

		body._field = field;

		prepBriefcaseItem(field, body);

		const result = await ajax(`${this.apiUrl}/professional/${userId}/briefcase/${field}`, 'POST', auth, body);

		switch (field) {
			case BriefcaseField.ActivityWork:
				return parseActivityWork(result);
			case BriefcaseField.AdditionalCertifications:
				return parseAdditionalCertification(result);
			case BriefcaseField.Certifications:
				return parseCertification(result);
			case BriefcaseField.Competencies:
				return parseCompetency(result);
			case BriefcaseField.ContinuingEducation:
				return parseContinuingEducation(result);
			case BriefcaseField.Licenses:
				return parseLicense(result);
			case BriefcaseField.OrganizationMembership:
				return parseOrganizationMembership(result);
			default:
				return result;
		}
	}

	async updateBriefcaseItem(auth: string, userId: string, field: BriefcaseField.AdditionalCertifications, item: AdditionalCertification): Promise<AdditionalCertification>;
	async updateBriefcaseItem(auth: string, userId: string, field: BriefcaseField.Certifications, item: Certification): Promise<Certification>;
	async updateBriefcaseItem(auth: string, userId: string, field: BriefcaseField.Licenses, item: License): Promise<License>;
	async updateBriefcaseItem<T extends BriefcaseItem = BriefcaseItem>(auth: string, userId: string, field: BriefcaseField, item: T): Promise<T>;
	async updateBriefcaseItem(auth: string, userId: string, field: BriefcaseField, item: BriefcaseItem): Promise<BriefcaseItem> {
		const body = clone<any>(item);

		body._field = field;

		prepBriefcaseItem(field, body);

		const result = await ajax(`${this.apiUrl}/professional/${userId}/briefcase/${field}/${item.id}`, 'PUT', auth, body);

		switch (field) {
			case BriefcaseField.ActivityWork:
				return parseActivityWork(result);
			case BriefcaseField.AdditionalCertifications:
				return parseAdditionalCertification(result);
			case BriefcaseField.Certifications:
				return parseCertification(result);
			case BriefcaseField.Competencies:
				return parseCompetency(result);
			case BriefcaseField.ContinuingEducation:
				return parseContinuingEducation(result);
			case BriefcaseField.Licenses:
				return parseLicense(result);
			case BriefcaseField.OrganizationMembership:
				return parseOrganizationMembership(result);
			default:
				return result;
		}
	}

	async deleteBriefcaseItem(auth: string, userId: string, field: BriefcaseField, id: string): Promise<void> {
		await ajax(`${this.apiUrl}/professional/${userId}/briefcase/${field}/${id}`, 'DELETE', auth);
	}

	async addAffiliation(auth: string, userId: string, options: AddAffiliationOptions | AddMarketplaceAffiliationOptions): Promise<Affiliation> {
		if (typeof (options as AddAffiliationOptions).recruiter === 'object') {
			(options as AddAffiliationOptions).recruiter = prepUser((options as any).recruiter);
		}

		let result = await ajax(`${this.apiUrl}/professional/${userId}/affiliations`, 'POST', auth, options);

		if (result) {
			result = parseAffiliation(result);
		}

		return result;
	}

	async rejectAffiliation(auth: string, userId: string, organizationId: string, notes?: string): Promise<Affiliation> {
		const options: any = {};

		if (notes) {
			options.notes = notes;
		}

		let result = await ajax(`${this.apiUrl}/professional/${userId}/affiliations/${organizationId}/reject`, 'POST', auth, options);

		if (result) {
			result = parseAffiliation(result);
		}

		return result;
	}

	async updateAffiliation(auth: string, userId: string, organizationId: string, options: UpdateAffiliationOptions): Promise<Affiliation> {
		if (typeof options.recruiter === 'object') {
			options.recruiter = prepUser(options.recruiter);
		}

		let result = await ajax(`${this.apiUrl}/professional/${userId}/affiliations/${organizationId}`, 'PUT', auth, options);

		if (result) {
			result = parseAffiliation(result);
		}

		return result;
	}

	async addClient(auth: string, userId: string, organizationId: string, clientId: string): Promise<AffiliationClient> {
		let result = await ajax(`${this.apiUrl}/professional/${userId}/affiliations/${organizationId}/clients/${clientId}`, 'POST', auth);

		return result;
	}

	async deleteClient(auth: string, userId: string, organizationId: string, clientId: string): Promise<void> {
		await ajax(`${this.apiUrl}/professional/${userId}/affiliations/${organizationId}/clients/${clientId}`, 'DELETE', auth);
	}

	async suspend(auth: string, userId: string, notes?: string): Promise<void> {
		await ajax(`${this.apiUrl}/professional/${userId}/suspend`, 'PUT', auth, notes ? { notes } : {});
	}

	async verifyLicense(auth: string, userId: string, licenseId: string, notes?: string): Promise<License> {
		let result = await ajax(`${this.apiUrl}/professional/${userId}/briefcase/licenses/${licenseId}/verify`, 'PUT', auth, notes ? { notes } : {});
		
		if (result) {
			result = parseLicense(result);
		}

		return result;
	}

	async getConsent(auth: string, userId: string): Promise<ConsentForm> {
		const result = await ajax(`${this.apiUrl}/professional/${userId}/briefcase/consent`, 'GET', auth);

		return parseConsentForm(result);
	}

	async statistics(auth: string, statistics: StatisticsType.Archives, options?: StatisticsDataOptions): Promise<ArchivedStatistics[]>;
	async statistics(auth: string, statistics: StatisticsType.Archives, options?: StatisticsCsvDownloadOptions): Promise<CsvDownload>;
	async statistics(auth: string, statistics: StatisticsType.ByState, options?: StatisticsDataOptions): Promise<ByStateStatistics[]>;
	async statistics(auth: string, statistics: StatisticsType.ByState, options?: StatisticsCsvDownloadOptions): Promise<CsvDownload>;
	async statistics(auth: string, statistics: StatisticsType.Completions, options?: StatisticsDataOptions): Promise<CompletionStatistics[]>;
	async statistics(auth: string, statistics: StatisticsType.Completions, options?: StatisticsCsvDownloadOptions): Promise<CsvDownload>;
	async statistics(auth: string, statistics: StatisticsType.Credentials, options?: StatisticsDataOptions): Promise<CredentialsStatistics>;
	async statistics(auth: string, statistics: StatisticsType.Credentials, options?: StatisticsCsvDownloadOptions): Promise<CsvDownload>;
	async statistics(auth: string, statistics: StatisticsType.Totals, options?: StatisticsDataOptions): Promise<TotalStatistics>;
	async statistics(auth: string, statistics: StatisticsType.Totals, options?: StatisticsCsvDownloadOptions): Promise<CsvDownload>;
	async statistics(auth: string, statistics: StatisticsType.NewLeads, options?: StatisticsDataOptions): Promise<NewLeadStatistics[]>;
	async statistics(auth: string, statistics: StatisticsType.NewLeads, options?: StatisticsCsvDownloadOptions): Promise<CsvDownload>;
	async statistics(auth: string, statistics: StatisticsType.Recruiter, options?: StatisticsDataOptions): Promise<RecruiterStatistics[]>;
	async statistics(auth: string, statistics: StatisticsType.Recruiter, options?: StatisticsCsvDownloadOptions): Promise<CsvDownload>;
	async statistics(auth: string, statistics: StatisticsType.UserEngagement, options?: StatisticsDataOptions): Promise<UserEngagementStatistics>;
	async statistics(auth: string, statistics: StatisticsType.UserEngagement, options?: StatisticsCsvDownloadOptions): Promise<CsvDownload>;
	async statistics(auth: string, statistics: StatisticsType.UserInsights, options?: StatisticsDataOptions): Promise<UserInsightsStatistics>;
	async statistics(auth: string, statistics: StatisticsType.UserInsights, options?: StatisticsCsvDownloadOptions): Promise<CsvDownload>;
	async statistics(auth: string, statistics: StatisticsType.Briefcase, options?: StatisticsDataOptions): Promise<BriefcaseStatistics>;
	async statistics(auth: string, statistics: StatisticsType.Briefcase, options?: StatisticsCsvDownloadOptions): Promise<CsvDownload>;
	async statistics(auth: string, statistics: StatisticsType.UserInsightsDownload, options?: StatisticsCsvDownloadOptions): Promise<CsvDownload>
	async statistics(auth: string, statistics: StatisticsType, options?: StatisticsOptions): Promise<TotalStatistics | BriefcaseStatistics | CredentialsStatistics | RecruiterStatistics[] | UserEngagementStatistics | CompletionStatistics[] | ByStateStatistics[] | ArchivedStatistics[] | NewLeadStatistics[] | UserEngagementStatistics | UserInsightsStatistics | CsvDownload> {
		return await ajax(`${this.apiUrl}/professional/statistics/${statistics}`, 'GET', auth, undefined, options || {} );
	}

	async shareBriefcase(auth: string, access: ShareBriefcaseAccessLevel): Promise<ShareBriefcaseResult> {
		return await ajax(`${this.apiUrl}/professional/briefcase/share/${access}`, 'POST', auth);
	}

	async sync(auth: string, userId: string, options: SyncProfessionalOptions): Promise<void> {
		return await ajax(`${this.apiUrl}/professional/${userId}/sync`, 'PUT', auth, options);
	}

	async contactMe(userId: string, options?: ContactMeOptions): Promise<void> {
		const result = await ajax(`${this.apiUrl}/professional/${userId}/contactme`, 'POST', null, options || {});
	}

	async inviteCandidate(auth: string, inviteCandidate: InviteCandidateOptions): Promise<Boolean> {
		return await ajax(`${this.apiUrl}/professional/invite-candidate`, 'POST', auth, inviteCandidate);
	}

	async textMeApp(auth: string, options: TextMeAppOptions): Promise<{statusCode: number, message: string, data: object }> {
		return await ajax(`${this.apiUrl}/professional/web/sms`, 'GET', auth, undefined, options);
	}

	async nursysResult(auth: string, userId: string, result: NursysResult): Promise<void> {
		return await ajax(`${this.apiUrl}/professional/${userId}/nursysresult`, 'PUT', auth, result);
	}
}