import React from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useApi, useToaster } from '@praos-health/ui';
import { useAuth } from '@praos-health/ui-security';
import {
	BriefcaseStatistics,
	StatisticsOptions,
	StatisticsType
} from '@praos-health/professional-client';
import { Fragment, useEffect, useState } from 'react';
import { LimitedBackdrop } from '@praos-health/web/components/limited-backdrop';
import { Pie } from '@nivo/pie';
import { InfoButtonPopper } from '@praos-health/web/components';
import { Color } from '../../common/color';
import { Title } from '@praos-health/web/components';
import { ChartWrapper, Main } from '../shared-components';
import { Toolbar } from '@mui/material';

interface Props {
	title: string,
	description: string,
	height: number,
	organization?: string
}

interface PieDataItem {
	id: string,
	label: string,
	value: number,
	color: string
}

function buildBars(stats: BriefcaseStatistics): PieDataItem[] {
	const result: PieDataItem[] = [];

	if (stats.checklistsMissing) {
		const checklists: PieDataItem = {
			id: 'checklists',
			label: 'Checklists',
			value: stats.checklistsMissing,
			color: Color.Red
		};
		result.push(checklists);
	}
	if (stats.referencesMissing) {
		const references: PieDataItem = {
			id: 'references',
			label: 'References',
			value: stats.referencesMissing,
			color: Color.Yellow
		};
		result.push(references);
	}
	if (stats.resumeAndChecklistMissing) {
		const resumeAndChecklist: PieDataItem = {
			id: 'resumeAndChecklist',
			label: 'Resume & Checklist',
			value: stats.resumeAndChecklistMissing,
			color: Color.Blue
		};
		result.push(resumeAndChecklist);
	}
	if (stats.resumeAndReferencesMissing) {
		const resumeAndReferences: PieDataItem = {
			id: 'resumeAndReferences',
			label: 'Resume & References',
			value: stats.resumeAndReferencesMissing,
			color: Color.Green
		};
		result.push(resumeAndReferences);
	}
	if (stats.checklistAndReferencesMissing) {
		const checklistAndReferences: PieDataItem = {
			id: 'checklistAndReferences',
			label: 'Checklist & References',
			value: stats.checklistAndReferencesMissing,
			color: Color.Purple
		};
		result.push(checklistAndReferences);
	}
	if (stats.everythingMissing) {
		const everything: PieDataItem = {
			id: 'everything',
			label: 'Resume, References & checklists',
			value: stats.everythingMissing,
			color: Color.Orange
		};
		result.push(everything);
	}

	return result;
}

export function BriefcasePieChart({
	title,
	description,
	height,
	organization
}: Props): JSX.Element {
	const api = useApi();
	const auth = useAuth();
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState<PieDataItem[]>([]);
	const toast = useToaster();

	useEffect(() => {
		setIsLoading(true);

		async function loadChart(): Promise<void> {
			const options: StatisticsOptions = {};

			if (organization === 'praos') {
				options.isMarketplace = true;
			} else if (organization !== 'all') {
				options.organization = organization;
			}

			const result = await api.professionalService.statistics(
				auth.session?.auth || '',
				StatisticsType.Briefcase,
				options
			);

			setData(buildBars(result));
		}

		loadChart()
			.catch(async (e) => {
				if (e instanceof Error && (e.name === 'UnauthorizedError' || e.message === 'Unauthorized')) {
					toast('Your session has expired', 'error');
					return await auth.signout();
				}
				toast(e, 'error');
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [organization, toast, auth, api]);

	return (
		<Main>
			{title && (
				<Toolbar>
					<Title>{title}</Title>
					{description && <InfoButtonPopper text={description} />}
				</Toolbar>
			)}
			<ChartWrapper>
				<AutoSizer disableHeight>
					{({ width }: { width: number }) =>
						width ? (
							<div style={{ width: `${width}px` }}>
								<Pie
									height={height}
									width={width}
									data={data}
									margin={{
										top: 35,
										right: width < 450 ? 60 : 164,
										bottom: 80,
										left: width < 450 ? 60 : 164
									}}
									innerRadius={0.5}
									padAngle={0.7}
									cornerRadius={3}
									activeOuterRadiusOffset={8}
									borderWidth={1}
									colors={{ datum: 'data.color' }}
									borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
									enableArcLinkLabels={width >= 450}
									arcLinkLabelsSkipAngle={10}
									arcLinkLabelsTextColor="#333333"
									arcLinkLabelsThickness={2}
									arcLinkLabelsColor={{ from: 'color' }}
									arcLabelsSkipAngle={10}
									arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
									arcLinkLabelComponent={(d: any) => {
										let first = '';
										let last = '';

										if (d.datum.label?.includes('&')) {
											first = d.datum.label.substr(0, d.datum.label.indexOf('&'));
											last = d.datum.label.substr(
												d.datum.label.indexOf('&'),
												d.datum.label.length
											);
										} else {
											first = d.datum.label;
										}
										return (
											<Fragment>
												<tspan x="0" dy="-0.6em">
													{first}
												</tspan>
												{last !== '' && (
													<tspan x="0" dy="1.2em">
														{last}
													</tspan>
												)}
											</Fragment>
										);
									}}
									animate={true}
									defs={[
										{
											id: 'dots',
											type: 'patternDots',
											background: 'inherit',
											color: 'rgba(255, 255, 255, 0.3)',
											size: 4,
											padding: 1,
											stagger: true
										}
									]}
									legends={[
										{
											anchor: 'bottom-left',
											direction: 'column',
											justify: false,
											translateX: width < 450 ? -40 : -130,
											translateY: 70,
											itemWidth: 100,
											itemHeight: 20,
											itemsSpacing: 0,
											symbolSize: 20,
											itemDirection: 'left-to-right'
										}
									]}
								/>
							</div>
						) : (
							<div>Loading...</div>
						)
					}
				</AutoSizer>
				<LimitedBackdrop open={isLoading} />
			</ChartWrapper>
		</Main>
	);
}
