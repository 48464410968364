export interface VettyApplicant {
	id: any;
	firstName: string;
	lastName: string;
	email: string;
	dateOfBirth?: Date;
	phoneNumber: string;
	jobStartDate?: Date;
	address?: {
		address1: string;
		address2?: string;
		city: string;
		zip: string;
		state: string;
		country: 'USA';
	};
	licenses?: {
		licenseType: string;
		licenseBody?: string;
		expirationDate?: Date;
		licenseNumber?: string;
		fileUrl?: string;
		documentIds?: string[];
	}[];
	driversLicense?: {
		number?: string;
		state?: string;
		expirationDate?: Date;
	};
	facilities?: {
		name?: string;
		title?: string;
		startDate?: Date;
		endDate?: Date;
	}[];
	references?: {
		firstName: string;
		lastName: string;
		phoneNumber?: string;
		email?: string;
		title: string;
	}[];
}

export function professionalToApplicant(professional: any, jobStartDate?: Date): VettyApplicant {
	const applicant: VettyApplicant = {
		id: professional._id.toString(),
		email: professional.email,
		firstName: professional.firstName,
		lastName: professional.lastName,
		phoneNumber: professional.phoneNumber
	};

	if (professional.dateOfBirth) {
		applicant.dateOfBirth = professional.dateOfBirth;
	}

	if (jobStartDate) {
		applicant.jobStartDate = jobStartDate;
	}

	if (professional.address) {
		applicant.address = {
			address1: professional.address.address1,
			address2: professional.address.address2,
			city: professional.address.city,
			state: professional.address.state,
			zip: professional.address.zip,
			country: 'USA'
		};
	}

	if (professional.briefcase?.driversLicense) {
		applicant.driversLicense = {
			number: professional.briefcase.driversLicense.number,
			state: professional.briefcase.driversLicense.state,
			expirationDate: professional.briefcase.driversLicense.expirationDate
		};
	}

	if (professional.briefcase?.references?.length) {
		applicant.references = [];

		for (const reference of professional.briefcase.references) {
			applicant.references.push({
				firstName: reference.firstName,
				lastName: reference.lastName,
				phoneNumber: reference.phoneNumber,
				email: reference.email,
				title: reference.title,
			});
		}
	}

	if (professional.briefcase?.licenses?.length) {
		applicant.licenses = [];

		for (const license of professional.briefcase.licenses) {
			applicant.licenses.push({
				licenseType: license.licenseType,
				licenseNumber: license.licenseNumber,
				expirationDate: license.expirationDate,
				licenseBody: license.licenseBody
			});
		}
	}

	return applicant;
}
