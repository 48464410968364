import { Box, FormControl, Grid, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { grey, purple, red } from '@mui/material/colors';
import {
	NursysInformation,
	LicenseVerificationLicense
} from '@praos-health/screening-client';
import React, { useEffect, useState } from 'react';
import { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LicenseBody, LicenseBodyService, LicenseType, LicenseTypeService } from '@praos-health/briefcase-client';
import { useAuth } from '@praos-health/ui-security';
import { Link } from 'react-router-dom';
import { UserType, isAdminUser } from '@praos-health/users-client';
import { LicenseUpdateType } from '../credentialing-data-table';

interface LicenseInformationProps {
	license?: LicenseVerificationLicense,
	updatedLicense?: any,
	nursys?: NursysInformation,
	licenseIssueDate: Dayjs | null | undefined,
	licenseExpirationDate: Dayjs | null | undefined,
	licenseState: string | undefined,
	licenseNumber: string | undefined,
	setIsDirty: (value: boolean) => void,
	modalType: string,
	profession: string | undefined,
	children?: React.ReactNode,
	setIncompleteLicenseData: (value: boolean) => void,
	updateLicenseStates: (license: LicenseVerificationLicense | undefined, type?: LicenseUpdateType) => void
}

function LicenseInformation({
	children,
	license,
	updatedLicense,
	nursys,
	licenseIssueDate,
	licenseExpirationDate,
	licenseState,
	licenseNumber,
	modalType = 'verify',
	profession,
	setIncompleteLicenseData,
	updateLicenseStates,
	setIsDirty
}: LicenseInformationProps) {
	const auth = useAuth();
	const [licenseStates, setLicenseStates] = useState<LicenseBody[] | undefined>([]);
	const [licenseTypes, setLicenseTypes] = useState<LicenseType[] | undefined>([]);
	const [isUserLicenseDetailsRequired, setIsUserLicenseDetailsRequired] = useState<boolean>(false);

	const handleSetIncompleteLicenseData = () => {
		if(isUserLicenseDetailsRequired && ((licenseState === '' || licenseState === undefined) || (licenseNumber === '' || licenseNumber === undefined))){
			setIncompleteLicenseData(true);
		}else{
			setIncompleteLicenseData(false);
		}
	};
	handleSetIncompleteLicenseData();

	const handleChangeState = (event: SelectChangeEvent) => {
		updateLicenseStates({ licenseType: license?.licenseType || '', licenseBody: event.target.value }, 'body');
		setIsDirty(true);
	};
	const dateFormatter = new Intl.DateTimeFormat();
	const gmtDateFormatter = new Intl.DateTimeFormat(undefined, {
		timeZone: 'GMT'
	});

	function formatDate(date?: Date, useGmt?: boolean): string {
		return date
      ? (useGmt ? gmtDateFormatter : dateFormatter).format(date)
      : '-';
	}

	useEffect(() => {
		async function fetchLicenseTypes(): Promise<LicenseType[] | undefined> {
			const licenseTypeService = new LicenseTypeService(process.env.REACT_APP_API_URL || '');

			if (auth.session) {
				return await licenseTypeService.list(auth.session?.auth, profession);
			}
		}

		async function fetchLicenseStates(): Promise<LicenseBody[] | undefined> {
			const licenseStateService = new LicenseBodyService(process.env.REACT_APP_API_URL || '');

			if (auth.session) {
				return await licenseStateService.list(auth.session?.auth, profession);
			}
		}

		Promise.all([
			fetchLicenseTypes(),
			fetchLicenseStates()
		])
			.then((results) => {
				const [licenseTypes, licenseStates] = results;
				setLicenseTypes(licenseTypes);
				setLicenseStates(licenseStates);
			})
			.catch((error) => {
				console.error('Error fetching licenses', error);
			});
	}, [auth, profession]);

	useEffect(() => {
		setIsUserLicenseDetailsRequired(licenseTypes?.filter((type) => type.abbr === license?.licenseType)[0]?.detailsRequired ?? false);
	}, [license, licenseTypes, setIsUserLicenseDetailsRequired]);

	const customStyles = {
		datePicker: {
			'width': '100%',
			'& .MuiInputLabel-root.Mui-focused': { color: '#979797' }, //styles the label
			'& .MuiOutlinedInput-root': {
				'&:hover > fieldset': { borderColor: '#C7C8CD' },
				'& .MuiButtonBase-root-MuiIconButton-root': {
					padding: '1px 8px'
				},
				'& .MuiSvgIcon-root': {
					width: '0.7em',
					height: '0.7em'
				},
				'height': '24px',
				'fontSize': '14px',
				'borderRadius': '4px'
			}
		}
	};

	return (
		<Box
			width="50%"
			sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
		>
			<Typography variant="h6" fontWeight="bold">
        License Information
			</Typography>
			<Box sx={{ display: 'flex', flexDirection: 'column', gap: '3px' }}>
				<Grid
					container
					sx={{
						'backgroundColor': grey[100],
						'&:hover': { backgroundColor: grey[300] },
						'paddingY': '3px',
						'paddingX': '8px',
						'borderRadius': '7px'
					}}
				>
					<Grid item xs={6}>
						<Typography fontWeight="bold" variant="subtitle2">
              License Type:
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography variant="subtitle2">
							{license?.licenseType ?? '-'}
						</Typography>
					</Grid>
				</Grid>
				<Grid
					container
					sx={{
						'backgroundColor': grey[100],
						'&:hover': { backgroundColor: grey[300] },
						'paddingY': '3px',
						'paddingX': '8px',
						'borderRadius': '7px'
					}}
				>
					<Grid item xs={6}>
						<Typography fontWeight="bold" variant="subtitle2">
              License State:
						</Typography>
					</Grid>
					<Grid item xs={6}>
						{modalType === 'view' || !isAdminUser(auth.session?.user.userType as UserType) ?
              <Typography variant='subtitle2'>{updatedLicense ? updatedLicense.licenseBody : license?.licenseBody ?? '-'}</Typography>
            :
							<>
								<FormControl variant="standard" fullWidth>
									<Select
										sx={{fontSize: '14px', padding: 0}}
										value={licenseState} onChange={handleChangeState}>
										<MenuItem value=""><em>None</em></MenuItem>
										{licenseStates?.map((state, index) =>
											<MenuItem key={index} value={state.name}>{state.name}</MenuItem>
										)}
									</Select>
								</FormControl>
								{isUserLicenseDetailsRequired && (licenseState === '' || licenseState === undefined) &&
									<Typography sx={{color: red[600], fontSize: '12px'}}>* This field is required</Typography>
								}
							</>
						}
					</Grid>
				</Grid>
				<Grid
					container
					sx={{
						'backgroundColor': grey[100],
						'&:hover': { backgroundColor: grey[300] },
						'paddingY': '3px',
						'paddingX': '8px',
						'borderRadius': '7px'
					}}
				>
					<Grid item xs={6}>
						<Typography fontWeight="bold" variant="subtitle2">
              License Number:
						</Typography>
					</Grid>
					<Grid item xs={6}>
						{modalType === 'view' || !isAdminUser(auth.session?.user.userType as UserType) ?
						<Typography variant='subtitle2'>{updatedLicense ? updatedLicense.licenseNumber : license?.licenseNumber ?? '-'}</Typography>
            :
						<>
							<FormControl variant="standard" fullWidth>
								<input type="text" className="licenseNumber" value={licenseNumber}  onChange={(e)=>updateLicenseStates({ licenseType: license?.licenseType || '', licenseNumber: e.target.value }, 'number')}/>
							</FormControl>
							{isUserLicenseDetailsRequired && (licenseNumber === '' || licenseNumber === undefined) &&
								<Typography sx={{color: red[600], fontSize: '12px'}}>* This field is required</Typography>
							}
						</>
						}
					</Grid>
				</Grid>
				<Grid
					container
					sx={{
						'backgroundColor': grey[100],
						'&:hover': { backgroundColor: grey[300] },
						'paddingY': '3px',
						'paddingX': '8px',
						'borderRadius': '7px'
					}}
				>
					<Grid item xs={6}>
						<Typography fontWeight="bold" variant="subtitle2">
              License Issue Date:
						</Typography>
					</Grid>
					<Grid item xs={6}>
		  {modalType === 'view' || !isAdminUser(auth.session?.user.userType as UserType) ?
            <Typography variant='subtitle2'>{formatDate(updatedLicense ? updatedLicense.issueDate : license?.issueDate, true)}</Typography>
			:
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<DatePicker
						sx={customStyles.datePicker}
						value={licenseIssueDate}
						onChange={(newValue) => {
							updateLicenseStates({ licenseType: license?.licenseType || '', issueDate: newValue?.toDate() }, 'issueDate');
							setIsDirty(true);
						}}
					/>
				</LocalizationProvider>
						}
					</Grid>
				</Grid>
				<Grid
					container
					sx={{
						'backgroundColor': grey[100],
						'&:hover': { backgroundColor: grey[300] },
						'paddingY': '3px',
						'paddingX': '8px',
						'borderRadius': '7px'
					}}
				>
					<Grid item xs={6}>
						<Typography fontWeight="bold" variant="subtitle2">
              License Expiration Date:
						</Typography>
					</Grid>
					<Grid item xs={6}>
		  {modalType === 'view' || !isAdminUser(auth.session?.user.userType as UserType) ?
            <Typography variant='subtitle2'>{formatDate(updatedLicense ? updatedLicense.expirationDate : license?.expirationDate, true)}</Typography>
			:
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<DatePicker
						sx={customStyles.datePicker}
						value={licenseExpirationDate}
						onChange={(newValue) => {
							updateLicenseStates({ licenseType: license?.licenseType || '', expirationDate: newValue?.toDate() }, 'expirationDate');
							setIsDirty(true);
						}}
					/>
				</LocalizationProvider>
						}
					</Grid>
				</Grid>
				<Grid
					container
					sx={{
						'backgroundColor': grey[100],
						'&:hover': { backgroundColor: grey[300] },
						'paddingY': '3px',
						'paddingX': '8px',
						'borderRadius': '7px'
					}}
				>
					<Grid item xs={6}>
						<Typography fontWeight="bold" variant="subtitle2">
              Last Verified Date:
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography variant="subtitle2">
							{formatDate(license?.verifiedAt)}
						</Typography>
					</Grid>
				</Grid>
				<Grid
					container
					sx={{
						'backgroundColor': grey[100],
						'&:hover': { backgroundColor: grey[300] },
						'paddingY': '3px',
						'paddingX': '8px',
						'borderRadius': '7px'
					}}
				>
					<Grid item xs={6}>
						<Typography fontWeight="bold" variant="subtitle2">
              Next Run Date:
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography variant="subtitle2">
							{formatDate(nursys?.nextRunDate)}
						</Typography>
					</Grid>
				</Grid>
				<Grid
					container
					sx={{
						'backgroundColor': grey[100],
						'&:hover': { backgroundColor: grey[300] },
						'paddingY': '3px',
						'paddingX': '8px',
						'borderRadius': '7px'
					}}
				>
					<Grid item xs={6}>
						<Typography fontWeight="bold" variant="subtitle2">
                Diploma/Certificate:
						</Typography>
					</Grid>
					<Grid item xs={6}>
						{license?.fileUrl ?
							<Link to={license?.fileUrl || ''} target="_blank" style={{textDecoration: 'none'}}>
								<Typography color={purple[800]} variant="subtitle2">
									View Doc
								</Typography>
							</Link>
							: '-'
						}
					</Grid>
				</Grid>
				<Grid
					container
					sx={{
						'backgroundColor': grey[100],
						'&:hover': { backgroundColor: grey[300] },
						'paddingY': '3px',
						'paddingX': '8px',
						'borderRadius': '7px'
					}}
				>
					<Grid item xs={6}>
						<Typography fontWeight="bold" variant="subtitle2">
              Status:
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography variant="subtitle2">
							{nursys?.isEnabled ? 'Nursys Enabled' : 'Nursys Disabled'}
						</Typography>
					</Grid>
				</Grid>
				{children}
			</Box>
		</Box>
	);
}

export default React.memo(LicenseInformation);