export function buildTickValues(data: any[], property: string, width: number, widthPerTick: number): (string | Date | number)[] {
	const result : (string | Date | number)[] = [];

	if (data?.length) {
		let  skip = 1;

		while ((data.length / (skip + 1) * widthPerTick) > width) {
			skip++;
		}

		for (let i = 0; i < data.length; i+=skip) {
			result.push(data[i][property]);
		}
	}

	return result;
}
