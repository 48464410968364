import React, { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import { Line, Serie } from '@nivo/line';
import { useApi, useToaster } from '@praos-health/ui';
import { useAuth } from '@praos-health/ui-security';
import { useTranslation } from 'react-i18next';
import AutoSizer from 'react-virtualized-auto-sizer';
import { DateRange, getRange } from '@praos-health/web/components';
import { LimitedBackdrop } from '@praos-health/web/components/limited-backdrop';
import {
	ApplicationsByWeekStatistics,
	StatisticsType,
	StatisticsOptions
} from '@praos-health/jobs-client';
import { addDays, beginningOfWeek, getUtc } from '@praos-health/core/utilities/date';
import { InfoButtonPopper } from '@praos-health/web/components';
import { buildTickValues } from '../../nivo/axes';
import DownloadIcon from '@mui/icons-material/GetApp';
import { toCsv, sanitizeFilename } from '@praos-health/core/utilities/string';
import { downloadFile } from '../../common/download';
import { Title } from '@praos-health/web/components';
import { ChartWrapper, Main, StyledDateRangeSelect, Toolbar } from '../shared-components';
import { AppApi } from '../../app-api';

export interface JobApplicationLineData {
	dateRange: string,
	created: number
}

interface JobApplicationLineExportableData {
	dateRange: string,
	applications: number,
	uniqueJobs: number,
	uniqueApplicants: number
}

interface Props {
	title?: string,
	description?: string,
	height: number,
	organization?: string,
	initialDateRange?: DateRange
}

function buildSeries(
	t: (v: string) => string,
	stats: ApplicationsByWeekStatistics[],
	startDate?: Date,
	endDate?: Date
): Serie[] {
	const applicationsSerie: Serie = { id: t('Applications'), data: [] };
	const jobsSerie: Serie = { id: t('UniqueJobs'), data: [] };
	const professionalsSerie: Serie = { id: t('UniqueApplicants'), data: [] };
	const formatter = new Intl.DateTimeFormat();

	if (!startDate && stats.length) {
		startDate = new Date(stats[0].date.year, stats[0].date.month - 1, stats[0].date.day);
	}

	if (!endDate) {
		endDate = new Date();
	}

	if (startDate) {
		let date = beginningOfWeek(startDate, false, true);
		let i = 0;

		while (date < endDate) {
			let applications = 0;
			let jobs = 0;
			let professionals = 0;

			while (i < stats.length) {
				const curDate = new Date(
					stats[i].date.year,
					stats[i].date.month - 1,
					stats[i].date.day
				);

				if (addDays(7, date) <= curDate) {
					break;
				}

				if (curDate <= addDays(7, date)) {
					applications = stats[i].applications;
					jobs = stats[i].jobs;
					professionals = stats[i].professionals;
				}

				i++;
			}

			const dateRange = `${formatter.format(date)} - ${formatter.format(addDays(6, date))}`;

			applicationsSerie.data.push({ x: dateRange, y: applications });
			jobsSerie.data.push({ x: dateRange, y: jobs });
			professionalsSerie.data.push({ x: dateRange, y: professionals });

			date = addDays(7, date);
		}
	}

	return [applicationsSerie, jobsSerie, professionalsSerie];
}

export function JobApplicationLine({
	title,
	description,
	height,
	organization,
	initialDateRange
}: Props): JSX.Element {
	const { t } = useTranslation();
	const api = useApi<AppApi>();
	const auth = useAuth();
	const toast = useToaster();
	const [isLoading, setIsLoading] = useState(false);
	const [dateRange, setDateRange] = useState(initialDateRange || DateRange.Year);
	const [data, setData] = useState<Serie[]>([]);
	const ledgend = [
		{
			id: 'applications',
			label: t('Applications'),
			color: 'blue'
		},
		{
			id: 'jobs',
			label: t('UniqueJobs'),
			color: 'orange'
		},
		{
			id: 'professionals',
			label: t('UniqueApplicants'),
			color: 'gray'
		}
	];

	useEffect(() => {
		setIsLoading(true);

		async function loadChart(): Promise<void> {
			const range = getRange(dateRange);

			range.start = beginningOfWeek(range.start, false, true);

			const options: StatisticsOptions = {
				startDate: getUtc(range.start),
				endDate: getUtc(range.end),
				useIsoWeek: true
			};

			if (organization === 'praos') {
				options.isMarketplace = true;
			} else if (organization !== 'all') {
				options.organization = organization;
			}

			const result = await api.jobService.statistics(
				auth.session?.auth || '',
				StatisticsType.ApplicationsByWeek,
				options
			);

			setData(buildSeries(t, result, range.start, range.end));
		}

		loadChart()
			.catch((e) => {
				toast(e, 'error');
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [api, auth, organization, t, dateRange, toast]);

	function handleDateRangeChange(value: DateRange) {
		setDateRange(value);
	}

	function generateExportableData() {
		const exportableData: JobApplicationLineExportableData[] = [];

		data.forEach((cate) => {
			cate.data.forEach((value, index) => {
				if (exportableData.length < index + 1)
					exportableData.push({
						dateRange: value.x?.toString() || '',
						applications: 0,
						uniqueJobs: 0,
						uniqueApplicants: 0
					});
				else exportableData[index].dateRange = value.x?.toString() || '';
				if (cate.id === t('Applications'))
					exportableData[index].applications = parseInt((value.y || '').toString()) || 0;
				else if (cate.id === t('UniqueJobs'))
					exportableData[index].uniqueJobs = parseInt((value.y || '').toString()) || 0;
				else if (cate.id === t('UniqueApplicants'))
					exportableData[index].uniqueApplicants =
						parseInt((value.y || '').toString()) || 0;
			});
		});

		return exportableData;
	}

	function handleExport() {
		const formatter = new Intl.DateTimeFormat();
		const range = getRange(dateRange);
		const exportableData = generateExportableData();

		range.start = beginningOfWeek(range.start, false, true);

		downloadFile(
			sanitizeFilename(
				`jobs application ${formatter.format(range.start)} to ${formatter.format(
					range.end
				)}.csv`
			),
			'text/csv;charset=utf-8;',
			[toCsv([t('Date'), t('Applications'), t('Unique Jobs'), t('Unique Applicants')])]
				.concat(
					exportableData.map((i) =>
						toCsv([
							i.dateRange,
							i.applications.toString(),
							i.uniqueJobs.toString(),
							i.uniqueApplicants.toString()
						])
					)
				)
				.join('\r\n')
		);
	}

	return (
		<Main>
			{(title || description) && (
				<Toolbar>
					<Title>{title}</Title>
					{description && <InfoButtonPopper text={description} />}
					<IconButton color="inherit" edge="end" onClick={handleExport} size="large">
						<DownloadIcon />
					</IconButton>
					<StyledDateRangeSelect
						value={dateRange}
						onChange={handleDateRangeChange}
					/>
				</Toolbar>
			)}
			<ChartWrapper>
				<AutoSizer disableHeight>
					{({ width }: { width: number }) =>
						width ? (
							<div style={{ width: `${width}px` }}>
								<Line
									data={data}
									margin={{ top: 10, right: 50, bottom: 180, left: 70 }}
									height={height}
									width={width}
									xScale={{ type: 'point' }}
									yScale={{ type: 'linear', min: 0, max: 'auto', reverse: false }}
									colors={ledgend.map((i) => i.color)}
									enableSlices="x"
									axisBottom={{
										tickSize: 5,
										tickPadding: 5,
										tickRotation: -60,
										tickValues: buildTickValues(data?.[0]?.data, 'x', width, 35),
										legendOffset: 36,
										legendPosition: 'middle'
									}}
									axisLeft={{
										tickSize: 5,
										tickPadding: 5,
										tickRotation: 0,
										legendOffset: -40,
										legendPosition: 'middle'
									}}
									pointLabelYOffset={-12}
									useMesh={true}
									legends={[
										{
											data: ledgend,
											anchor: 'bottom',
											direction: 'row',
											justify: false,
											translateX: 0,
											translateY: 170,
											itemsSpacing: 2,
											itemWidth: 80,
											itemHeight: 20,
											itemDirection: 'bottom-to-top',
											itemOpacity: 0.85,
											symbolSize: 20,
											padding: 0,
											effects: [
												{
													on: 'hover',
													style: {
														itemOpacity: 1
													}
												}
											]
										}
									]}
								/>
							</div>
						) : (
							<div>Loading...</div>
						)
					}
				</AutoSizer>
				<LimitedBackdrop open={isLoading} />
			</ChartWrapper>
		</Main>
	);
}
