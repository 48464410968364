import React from 'react';
import { Choropleth } from '@nivo/geo';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useTranslation } from 'react-i18next';
import { getBounds, scaleAndTranslate } from '../../nivo/geo';
import FEATURES from '../../nivo/geo/us';
import { getStateName } from '../../common/usStates';
import { LimitedBackdrop } from '@praos-health/web/components/limited-backdrop';
import { IconButton, FormControl, InputLabel, Select, SelectChangeEvent, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { useApi, useToaster } from '@praos-health/ui';
import { InfoButtonPopper } from '@praos-health/web/components';
import { Specialty } from '@praos-health/briefcase-client';
import {
	ByStateStatistics as JobByStateStatistics,
	JobType,
	StatisticsType as JobStatisticsType,
	StatisticsOptions as JobStatisticsOptions
} from '@praos-health/jobs-client';
import {
	ByStateStatistics as ProfessionalByStateStatistics,
	StatisticsType as ProfessionalStatisticsType,
	StatisticsOptions as ProfessionalStatisticsOptions
} from '@praos-health/professional-client';
import { useAuth } from '@praos-health/ui-security';
import DownloadIcon from '@mui/icons-material/GetApp';
import { toCsv, sanitizeFilename } from '@praos-health/core/utilities/string';
import { downloadFile } from '../../common/download';
import { Title } from '@praos-health/web/components';
import { ChartWrapper, Main, Toolbar } from '../shared-components';
import { AppApi } from '../../app-api';

const blendColors = [
	'#5FA3CF',
	'#739FC2',
	'#869BB5',
	'#9A96A8',
	'#AE929B',
	'#C18E8D',
	'#D58A80',
	'#E88573',
	'#FC8166'
];

interface JobMapData {
	id: string,
	value: number,
	label?: string
}

interface ByStateStatistics {
	jobs: JobByStateStatistics[],
	professionals: ProfessionalByStateStatistics[]
}

interface Props {
	title?: string,
	description?: string,
	organization?: string,
	height: number
}

enum LocationType {
	Home = 'home',
	License = 'license',
	Preference = 'preference'
}

const StyledFormControl = styled(FormControl)(({ theme }) => ({
	margin: theme.spacing(1),
	minWidth: 120
}));

const StyledFormControlContainer = styled('div')(() => ({
	float: 'right'
}));

function toRatio(x: number, tolerance = 0.0001): string {
	if (x === 0) return '0';
	if (x < 0) x = -x;
	let num = 1,
		den = 1;

	function iterate() {
		const r = num / den;
		if (Math.abs((r - x) / x) < tolerance) return;

		if (r < x) num++;
		else den++;
		iterate();
	}

	iterate();
	return `${num}:${den}`;
}

export function JobMap({ title, description, height, organization }: Props): JSX.Element {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);
	const [maxDomain, setMaxDomain] = useState(1000000);
	const [jobType, setJobType] = useState<JobType | 'all' | ''>('all');
	const [location, setLocation] = useState<LocationType | ''>('');
	const [specialties, setSpecialties] = useState<Specialty[]>([]);
	const [specialty, setSpecialty] = useState('');
	const [byState, setByState] = useState<ByStateStatistics>();
	const [colors, setColors] = useState<string | string[]>('blues');
	const [data, setData] = useState<JobMapData[]>([]);
	const api = useApi<AppApi>();
	const auth = useAuth();
	const toast = useToaster();

	useEffect(() => {
		setIsLoading(true);

		async function loadSpecialties(): Promise<void> {
			const result = await api.specialtyService.list(auth.session?.auth || '');

			setSpecialties(result);
		}

		loadSpecialties()
			.catch((e) => {
				toast(e, 'error');
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [api, auth, toast]);

	useEffect(() => {
		const data: JobMapData[] = [];

		if (byState) {
			const jobData: { [state: string]: number } = {};
			const professionalData: { [state: string]: number } = {};

			if (jobType) {
				for (const item of byState.jobs) {
					let value = 0;

					switch (jobType) {
						case JobType.Contract:
							value = item.contract;
							break;
						case JobType.PerDiem:
							value = item.perDiem;
							break;
						case JobType.Permanent:
							value = item.permanent;
							break;
						case JobType.Travel:
							value = item.travel;
							break;
						default:
							value = item.count;
							break;
					}

					if (value > 0) {
						jobData[item.state] = value;
					}
				}
			}

			if (location) {
				for (const item of byState.professionals) {
					let value = 0;

					switch (location) {
						case LocationType.License:
							value = item.license.licensed;
							break;
						case LocationType.Preference:
							value = item.preference.licensed;
							break;
						default:
							value = item.home.licensed;
							break;
					}

					if (value > 0) {
						professionalData[item.state] = value;
					}
				}
			}

			for (const state in jobData) {
				const value = jobData[state];

				if (location) {
					const pValue = professionalData[state];

					data.push({
						id: state,
						value: pValue ? Math.min(pValue / value, 1) : 0,
						label: `${pValue ? pValue : 0} ${t('Professional(s)')} / ${value} ${t(
							'Job(s)'
						)}`
					});
				} else {
					data.push({ id: state, value });
				}
			}

			for (const state in professionalData) {
				const value = professionalData[state];

				if (jobType) {
					if (!jobData.hasOwnProperty(state)) {
						data.push({
							id: state,
							value: 1,
							label: `${value} ${t('Professional(s)')} / 0 ${t('Job(s)')}`
						});
					}
				} else {
					data.push({ id: state, value });
				}
			}
		}

		setMaxDomain(Math.max(...data.map((i) => i.value)));
		setData(data);
	}, [t, jobType, location, byState]);

	useEffect(() => {
		setIsLoading(true);

		async function loadChart(): Promise<void> {
			const jobOptions: JobStatisticsOptions = {};
			const professionalOptions: ProfessionalStatisticsOptions = {};

			if (organization === 'praos') {
				jobOptions.isMarketplace = true;
				professionalOptions.isMarketplace = true;
			} else if (organization !== 'all') {
				jobOptions.organization = organization;
				professionalOptions.organization = organization;
			}

			if (specialty) {
				jobOptions.specialty = specialty;
				professionalOptions.specialty = specialty;
			}

			const [jobResult, professionalResult] = await Promise.all([
				api.jobService.statistics(
					auth.session?.auth || '',
					JobStatisticsType.ByState,
					jobOptions
				),
				api.professionalService.statistics(
					auth.session?.auth || '',
					ProfessionalStatisticsType.ByState,
					professionalOptions
				)
			]);

			setByState({ jobs: jobResult, professionals: professionalResult });
		}

		loadChart()
			.catch((e) => {
				toast(e, 'error');
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [api, auth, organization, specialty, toast]);

	async function handleJobTypeChange(e: SelectChangeEvent<'all' | JobType>) {
		setColors(e.target.value && location ? blendColors : e.target.value ? 'blues' : 'reds');
		setJobType(e.target.value as JobType | 'all' | '');
	}

	async function handleLocationChange(e: SelectChangeEvent<LocationType>) {
		setColors(jobType && e.target.value ? blendColors : e.target.value ? 'reds' : 'blues');
		setLocation(e.target.value as LocationType | '');
	}

	async function handleSpecialtyChange(e: SelectChangeEvent<string>) {
		setSpecialty(e.target.value as string);
	}

	function formatValue(v: number): string | number {
		if ((!jobType && !location) || v === Infinity || v === -Infinity) {
			return 0;
		}

		if (!jobType || !location) {
			if (v > 1000000) {
				return `${Math.round(v / 1000000)}mm`;
			}

			if (v > 1000) {
				return `${Math.round(v / 1000)}k`;
			}

			return Math.round(v);
		}

		return toRatio(v);
	}

	function handleExport() {
		downloadFile(
			sanitizeFilename(
				`open jobs-${t('JobType')} ${jobType || 'none'}-${t('Professionals')} ${
					location || 'none'
				}-${t('Specialty')} ${specialty || 'none'}.csv`
			),
			'text/csv;charset=utf-8;',
			[toCsv([t('State'), t('OpenJobs')])]
				.concat(
					data
						.map((i) => ({ ...i, id: getStateName(i.id) }))
						.map((i) => toCsv([i.id, i.value.toString()]))
				)
				.join('\r\n')
		);
	}

	return (
		<Main>
			{title && (
				<Toolbar>
					<Title>{title}</Title>
					{description && <InfoButtonPopper text={description} />}
					<IconButton color="inherit" edge="end" onClick={handleExport} size="large">
						<DownloadIcon />
					</IconButton>
					<StyledFormControlContainer>
						<StyledFormControl variant="standard">
							<InputLabel>{t('JobType')}</InputLabel>
							<Select
								variant="standard"
								native
								value={jobType}
								onChange={handleJobTypeChange}
								inputProps={{
									name: 'jobType'
								}}
							>
								<option aria-label="None" value="" />
								<option key="all" value="all">
									{t('All')}
								</option>
								<option key={JobType.Contract} value={JobType.Contract.toString()}>
									{t('Contract')}
								</option>
								<option key={JobType.PerDiem} value={JobType.PerDiem.toString()}>
									{t('PerDiem')}
								</option>
								<option
									key={JobType.Permanent}
									value={JobType.Permanent.toString()}
								>
									{t('Permanent')}
								</option>
								<option key={JobType.Travel} value={JobType.Travel.toString()}>
									{t('Travel')}
								</option>
							</Select>
						</StyledFormControl>
						<StyledFormControl variant="standard">
							<InputLabel>{t('Professionals')}</InputLabel>
							<Select
								variant="standard"
								native
								value={location}
								onChange={handleLocationChange}
								inputProps={{
									name: 'location'
								}}
							>
								<option aria-label="None" value="" />
								<option key={LocationType.Home} value={LocationType.Home}>
									{t('HomeAddress')}
								</option>
								<option key={LocationType.License} value={LocationType.License}>
									{t('License')}
								</option>
								<option
									key={LocationType.Preference}
									value={LocationType.Preference}
								>
									{t('Preference')}
								</option>
							</Select>
						</StyledFormControl>
						<StyledFormControl variant="standard">
							<InputLabel>{t('Specialty')}</InputLabel>
							<Select
								variant="standard"
								native
								value={specialty}
								onChange={handleSpecialtyChange}
								inputProps={{
									name: 'specialty'
								}}
							>
								<option aria-label="None" value="" />
								{specialties.map((value) => {
									return (
										<option key={value._id} value={value.name}>
											{value.name}
										</option>
									);
								})}
							</Select>
						</StyledFormControl>
					</StyledFormControlContainer>
				</Toolbar>
			)}
			<ChartWrapper>
				<AutoSizer disableHeight>
					{({ width }: { width: number }) => {
						width = width || 0;
						const [scale, translate] = scaleAndTranslate(
							width,
							height,
							getBounds(FEATURES)
						);

						return (
							<div style={{ width: `${width}px` }}>
								<Choropleth
									data={data}
									features={FEATURES}
									height={height}
									width={width}
									margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
									colors={colors}
									domain={[0, maxDomain]}
									unknownColor="#AAAEB0"
									label="properties.name"
									valueFormat={formatValue}
									projectionScale={scale}
									projectionTranslation={translate}
									tooltip={({ feature }) => {
										return !feature.data ? null : (
										<div
											style={{
												background: 'white',
												padding: '9px 12px',
												border: '1px solid #ccc',
												borderRadius: '25px',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												flexDirection: 'column',
												color: 'black'
											}}
										>
											{`${feature.label}: ${
												feature.data.label === undefined
													? feature.value
													: feature.data.label
											}`}
										</div>
									);
									}}
									legends={[
										{
											anchor: 'top-right',
											direction: 'column',
											justify: true,
											translateX: -40,
											translateY: 40,
											itemsSpacing: 0,
											itemWidth: 94,
											itemHeight: 18,
											itemDirection: 'left-to-right',
											itemTextColor: '#444444',
											itemOpacity: 0.85,
											symbolSize: 18,
											effects: [
												{
													on: 'hover',
													style: {
														itemTextColor: '#000000',
														itemOpacity: 1
													}
												}
											]
										}
									]}
									projectionRotation={[0, 0, 0]}
									graticuleLineColor="#dddddd"
									borderWidth={0.5}
									borderColor="#152538"
								/>
							</div>
						);
					}}
				</AutoSizer>
				<LimitedBackdrop open={isLoading} />
			</ChartWrapper>

		</Main>
	);
}
