import React, { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextareaAutosize, Typography, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAuth } from '@praos-health/ui-security';
import { useApi, useToaster } from '@praos-health/ui';
import { FlatScreening, LicenseVerificationTask } from '@praos-health/screening-client';
import { AppApi } from '../../../app-api';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
		padding: theme.spacing(2)
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(1)
	}
}));

export interface AdminAction {
	message: string,
	buttonColor: 'primary' | 'inherit' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
	hasNotes?: boolean,
	buttonText: string,
	handler: (api: AppApi, auth: string, ticket: FlatScreening<LicenseVerificationTask>, notes?: string) => Promise<void>
}

export interface AdminActionDialogProps extends AdminAction {
	ticket: FlatScreening<LicenseVerificationTask>,
	handleClose: (cancel?: boolean) => void
}

const ConfirmAdminActionDialog = ({
	handler,
	message = '',
	hasNotes,
	buttonColor = 'primary',
	buttonText = 'Confirm',
	ticket,
	handleClose
}: AdminActionDialogProps) => {
	const auth = useAuth();
	const api = useApi<AppApi>();
	const [isLoading, setIsLoading] = useState(false);
	const toast = useToaster();
	const [additionalNotes, setAdditionalNotes] = useState('');

	const handleSubmit = async () => {
		try {
			setIsLoading(true);
			await handler(api, auth.session?.auth || '', ticket, additionalNotes);
			handleClose(true);
		} catch (error: any) {
			toast(error, 'error');
			handleClose(false);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<BootstrapDialog
			onClose={() => { handleClose(); }}
			open={true}
		>
			<DialogTitle fontWeight='bold' sx={{ m: 0, p: 2 }} id="customized-dialog-title">
			</DialogTitle>
			<IconButton
				aria-label="close"
				onClick={() => {
					setAdditionalNotes('');
					setIsLoading(false);
					handleClose();
				}}
				sx={{
					position: 'absolute',
					right: 8,
					top: 8,
					color: (theme) => theme.palette.grey[500]
				}}
			>
				<CloseIcon />
			</IconButton>

			<DialogContent>
				{ hasNotes !== false ? (
					<Box sx={{ width: '400px', display: 'flex', gap: '5px', flexDirection: 'column', justifyContent: 'center'}}>
						<Typography fontWeight='bold' textAlign='center' variant='subtitle1'>{message}</Typography>
						<Typography fontWeight='bold' textAlign='center' variant='subtitle2'>Additional Notes:</Typography>
						<TextareaAutosize aria-label="minimum height" minRows={5} value={additionalNotes} onChange={(e)=>setAdditionalNotes(e.target.value)} placeholder="Additional notes (this field is optional)" />
					</Box>) : (
					<Box sx={{ width: '350px', display: 'flex', justifyContent: 'center'}}>
						<Typography fontWeight='bold' variant='subtitle2'>{message}</Typography>
					</Box>
				)}
			</DialogContent>

			<DialogActions>
				<Button disabled={isLoading} variant="contained" color={buttonColor} sx={{borderRadius: '50px'}} onClick={handleSubmit}>
					{isLoading ? 'Submitting' : buttonText}
				</Button>
			</DialogActions>
		</BootstrapDialog>
	);
};

export default ConfirmAdminActionDialog;