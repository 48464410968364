import React from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useApi, useToaster } from '@praos-health/ui';
import { useAuth } from '@praos-health/ui-security';
import { StatisticsType, StatisticsOptions } from '@praos-health/professional-client';
import { useEffect, useState } from 'react';
import { Grid, Toolbar } from '@mui/material';
import { LimitedBackdrop } from '@praos-health/web/components/limited-backdrop';
import { Funnel } from '@nivo/funnel';
import { InfoButtonPopper } from '@praos-health/web/components';
import { FunnelLabels } from './funnel-label';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Color } from '../../common/color';
import { useTranslation } from 'react-i18next';
import { Title } from '@praos-health/web/components';
import { ChartWrapper, Main } from '../shared-components';
import { AppApi } from '../../app-api';

const stepColors = [Color.Red, Color.Yellow, Color.Blue, Color.Green];

interface Props {
	title: string,
	description: string,
	height: number,
	organization?: string
}

interface FunnelDataItem {
	id: string,
	label: string,
	value: number,
	color: string
}

export function BriefcaseFunnelChart({
	title,
	description,
	height,
	organization
}: Props): JSX.Element {
	const api = useApi<AppApi>();
	const auth = useAuth();
	const layers: any[] = ['separators', 'parts', FunnelLabels, 'annotations'];
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState<FunnelDataItem[]>([]);
	const toast = useToaster();
	const { t } = useTranslation();

	useEffect(() => {
		setIsLoading(true);
		async function loadChart(): Promise<void> {
			const options: StatisticsOptions = {};

			if (organization === 'praos') {
				options.isMarketplace = true;
			} else if (organization !== 'all') {
				options.organization = organization;
			}

			const stats = await api.professionalService.statistics(
				auth.session?.auth || '',
				StatisticsType.Briefcase,
				options
			);

			const activatedData: FunnelDataItem = {
				id: 'activated',
				label: t('Activated'),
				value: stats.count,
				color: stepColors[0]
			};

			const profileData: FunnelDataItem = {
				id: 'profile',
				label: t('Profile'),
				value: stats.profile,
				color: stepColors[1]
			};

			const licenseData: FunnelDataItem = {
				id: 'license',
				label: t('Licensed'),
				value: stats.licensed,
				color: stepColors[2]
			};

			const checklistData: FunnelDataItem = {
				id: 'checklist',
				label: t('Checklist'),
				value:
					stats.referencesMissing +
					stats.resumeMissing +
					stats.resumeAndReferencesMissing,
				color: stepColors[3]
			};

			setData([activatedData, profileData, licenseData, checklistData]);
		}

		loadChart()
			.catch(async (e) => {
				if (e instanceof Error && (e.name === 'UnauthorizedError' || e.message === 'Unauthorized')) {
					toast('Your session has expired', 'error');
					return await auth.signout();
				}
				toast(e, 'error');
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [auth, api, t, organization, toast]);

	return (
		<Main>
			{title && (
				<Toolbar>
					<Title>{title}</Title>
					{description && <InfoButtonPopper text={description} />}
				</Toolbar>
			)}
			<ChartWrapper>
				<AutoSizer disableHeight>
					{({ width }: { width: number }) =>
						width ? (
							<div style={{ width: `${width}px` }}>
								{data.length > 0 ? (
									<Grid container>
										<Grid item>
											<Funnel
												height={height - 65}
												width={width}
												data={data}
												margin={{ top: 40, right: 50, bottom: 50, left: 50 }}
												/*valueFormat=">-.4s"*/
												colors={stepColors}
												borderWidth={20}
												labelColor={{
													from: 'color',
													modifiers: [['darker', 3]]
												}}
												currentPartSizeExtension={10}
												currentBorderWidth={40}
												/*motionConfig="wobbly"*/
												layers={layers}
											/>
										</Grid>
										<Grid
											container
											spacing={4}
											sx={{ paddingBottom: (theme) => theme.spacing(2) }}
											direction="row"
											justifyContent="center"
											alignItems="center"
										>
											{data.map((datum: any, index: any) => {
												return (
													<Grid item key={datum.id}>
														<Grid item xs={12}>
															{datum.label}
														</Grid>
														<Grid
															item
															xs={12}
															style={{ textAlign: 'center' }}
														>
															<FiberManualRecordIcon
																style={{ color: stepColors[index] }}
															/>
														</Grid>
													</Grid>
												);
											})}
										</Grid>
									</Grid>
								) : (
									<div style={{ height: height, width: width }}></div>
								)}
							</div>
						) : (
							<div>Loading...</div>
						)
					}
				</AutoSizer>
				<LimitedBackdrop open={isLoading} />
			</ChartWrapper>
		</Main>
	);
}
