import { Box, Button, IconButton, Paper, Table, TableBody, TableContainer, Tooltip, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useAuth } from '@praos-health/ui-security';
import ConfirmAdminActionDialog, { AdminAction, AdminActionDialogProps } from '../confirm-admin-action-dialog';
import { Link, useLocation } from 'react-router-dom';
import { BusinessCenter, CalendarMonth, Delete, Lock, LockOpen, PersonAdd, PersonRemove, ReplayOutlined } from '@mui/icons-material';
import ViewTaskDialog from '../view-task-dialog';
import ConfirmCloseLicenseDialog from '../confirm-close-license-dialog';
import { FlatScreening, LicenseVerificationLicense, LicenseVerificationTask, TaskType } from '@praos-health/screening-client';
import dayjs, { Dayjs } from 'dayjs';
import TablePagination from '@praos-health/web/components/table-pagination';
import { DataTableColumn, DataTableHeadProps, DataTablePagination } from '@praos-health/web/components/data-table';
import VerifyTasksDialog from '../verify-task-dialog';
import { UploadResponse } from '@praos-health/records-client';
import { AppApi } from '../../../app-api';
import { getUtc } from '@praos-health/core/utilities/date';

interface Props {
	columns: DataTableColumn<FlatScreening<LicenseVerificationTask>>[],
	screeningData?: FlatScreening<LicenseVerificationTask>[],
	pagination: DataTablePagination,
	onRequestData: (value?: DataTablePagination) => void,
	count: number
}

export type LicenseUpdateType = 'issueDate' | 'expirationDate' | 'body' | 'number';

const enum AdminActionType {
	NurseReject = 'nurseReject',
	NurseSuspend = 'nurseSuspend',
	NurseActive = 'nurseActive',
	NursysDisable = 'nursysDisable',
	NursysEnable = 'nursysEnable'
}

const adminActions: { [action in AdminActionType]: AdminAction } = {
	nurseReject: {
		message: 'Are you sure you want to reject this nurse?',
		buttonColor: 'error',
		buttonText: 'Reject',
		handler: (api: AppApi, auth: string, ticket: FlatScreening<LicenseVerificationTask>, notes?: string) => {
			return api.userService.deactivate(
				auth,
				ticket.applicantId,
				notes ?? ''
			);
		}
	},
	nurseSuspend: {
		message: 'Are you sure you want to suspend this nurse?',
		buttonColor: 'warning',
		buttonText: 'Suspend',
		handler: (api: AppApi, auth: string, ticket: FlatScreening<LicenseVerificationTask>, notes?: string) => {
			return api.professionalService.suspend(
				auth,
				ticket.applicantId,
				notes ?? ''
			);
		}
	},
	nurseActive: {
		message: 'Are you sure you want to make this nurse active?',
		buttonColor: 'success',
		buttonText: 'Active',
		handler: async (api: AppApi, auth: string, ticket: FlatScreening<LicenseVerificationTask>, notes?: string) => {
			await api.professionalService.verifyLicense(
				auth,
				ticket.applicantId,
				(ticket.task.applicant?.licenses && ticket.task.applicant?.licenses[0]?.id) ?? '',
				notes ?? ''
			);
		}
	},
	nursysDisable: {
		message: 'Are you sure you want to disable this nurse for nursys api check?',
		buttonColor: 'error',
		buttonText: 'Disable',
		handler: async (api: AppApi, auth: string, ticket: FlatScreening<LicenseVerificationTask>) => {
			await api.professionalService.update(
				auth,
				ticket.applicantId,
				{
					item: {
						briefcase: {
							nursys: { isEnabled: false }
						}
					},
					select: { 'briefcase.nursys': 1 }
				}
			);
		}
	},
	nursysEnable: {
		message: 'Are you sure you want to enable this nurse for nursys api check?',
		buttonColor: 'success',
		buttonText: 'Enable',
		hasNotes: false,
		handler: async (api: AppApi, auth: string, ticket: FlatScreening<LicenseVerificationTask>) => {
			await api.professionalService.update(
				auth,
				ticket.applicantId,
				{
					item: {
						briefcase: {
							nursys: { isEnabled: true }
						}
					},
					select: { 'briefcase.nursys': 1 }
				}
			);
		}
	}
};

const CredentialingDataTable = ({ columns, screeningData, pagination, onRequestData, count }: Props): JSX.Element => {
	const location = useLocation();
	const auth = useAuth();
	const [isOpen, setIsOpen] = React.useState(false);
	const [isOpenViewTask, setIsOpenViewTask] = useState(false);
	const [isOpenSubmit, setIsOpenSubmit] = useState(false);
	const [isOpenReason, setIsOpenReason] = useState(false);
	const [adminAction, setAdminAction] = useState<AdminActionDialogProps | null>(null);
	const [selectedScreeningObject, setSelectedScreeningObject] = useState<FlatScreening<LicenseVerificationTask> | undefined>(undefined);
	const [isDirty, setIsDirty] = useState(false);
	const [licenseIssueDate, setLicenseIssueDate] = useState<Dayjs | null>(null);
	const [licenseExpirationDate, setLicenseExpirationDate] = useState<Dayjs | null>(null);
	const [licenseState, setLicenseState] = useState<string | undefined>('');
	const [licenseNumber, setLicenseNumber] = useState<string | undefined>('');
	const [isIncompleteLicenseData, setIsIncompleteLicenseData] = useState<boolean>(false);
	const [uploadedFile, setUploadedFile] = useState<UploadResponse | null>(null);

	function DataTableHead({
		columns,
		order,
		orderBy,
		onRequestData
	}: DataTableHeadProps<FlatScreening<LicenseVerificationTask>>): JSX.Element {
		const createSortHandler = (newOrderBy?: string) => () => {
			onRequestData(newOrderBy);
		};

		return (
			<TableHead>
				<TableRow>
					{columns.map((column) => (
						<TableCell
							sx={(column.id === 'verifyButton' || column.id === 'viewButton') ? {
								left: 0,
								position: 'sticky',
								zIndex: 2,
								backgroundColor: '#fff'
							} : {
								fontWeight: 'bold',
								zIndex: 1
							}}
							key={column.id}
							align="left"
							sortDirection={orderBy === column.orderBy ? order : false}
						>
							{column.orderBy ? (
								<TableSortLabel
									active={orderBy === column.orderBy}
									direction={orderBy === column.orderBy ? order : 'asc'}
									onClick={createSortHandler(column.orderBy)}
								>
									{column.title}
								</TableSortLabel>
							) : (column.id === 'verifyButton' || column.id === 'viewButton') ?
								<Button onClick={() => onRequestData()}>
									<ReplayOutlined fontSize='small' />
								</Button>
								: (
									column.title
								)}
						</TableCell>
					))}
				</TableRow>
			</TableHead>
		);
	}

	function handleClickOpen(): void {
		setIsOpen(true);
	}

	function handleOpenViewTask(): void {
		setIsOpenViewTask(true);
	}

	function handleClose(): void {
		setIsOpen(false);
		setSelectedScreeningObject(undefined);
		setIsDirty(false);
		setIsOpenViewTask(false);
	}

	function handleVerify(): void {
		setIsOpen(false);
		setIsOpenSubmit(true);
	}

	function handleUnableVerify(): void {
		setIsOpen(false);
		setIsOpenReason(true);
	}

	function handleCloseLicense(): void {
		onRequestData();
		setIsDirty(false);
		setIsOpenReason(false);
		setIsOpenSubmit(false);
	}

	function handleCloseAction(success?: boolean): void {
		if (success === true) {
			onRequestData();
		}

		setAdminAction(null);
	}

	function handleRequestSort(orderBy?: string) {
		const newPagination = { ...pagination };

		if (orderBy) {
			const isAsc = (pagination.orderBy !== orderBy || pagination.order === 'desc');

			newPagination.order = (isAsc ? 'asc' : 'desc');
			newPagination.orderBy = orderBy;
		}

		onRequestData(newPagination);
	}

	function conditionalActionButtons(
		col: DataTableColumn<FlatScreening<LicenseVerificationTask>>,
		i: number,
		index: number,
		screening: FlatScreening<LicenseVerificationTask>
	) {
		const actionButtonStyleProps = {
			paddingY: '6px',
			paddingX: '12px',
			marginBottom: 0,
			fontSize: '14px',
			fontWeight: 400,
			width: '40px',
			height: '35px',
			lineHeight: 1.42857143,
			whiteSpace: 'nowrap',
			verticalAlign: 'middle',
			cursor: 'pointer',
			borderRadius: '4px',
			border: '1px solid #ccc'
		};

		if (col.id === 'verifyButton') {
			return (
				<Button variant="contained" color='primary' size="small" sx={{ borderRadius: '30px' }} onClick={async () => {
					setSelectedScreeningObject(screening);
					handleClickOpen();
				}}>
					Verify
				</Button>
			);
		} else if (col.id === 'viewButton') {
			return (
				<Button variant="contained" color='primary' size="small" sx={{ borderRadius: '30px' }} onClick={() => {
					setSelectedScreeningObject(screening);
					handleOpenViewTask();
				}}>
					View
				</Button>
			);
		} else if (col.id === 'actions') {
			return (
				<Box sx={{ display: 'flex', gap: '3px' }}>
					{!auth.session?.organization &&
						<>
							{screening.system === 'Praos' && screening.task.type === TaskType.LicenseVerification &&
								<>
									<Tooltip title='Reject' arrow>
										<IconButton sx={{
											...actionButtonStyleProps, 'backgroundColor': '#ff4d4d',
											'color': '#fff',
											'&:hover': {
												backgroundColor: '#ff4d4d',
												color: '#fff'
											}
										}} onClick={() => {
											setSelectedScreeningObject(screening);
											setAdminAction(
												Object.assign(
													{ ticket: screening, handleClose: handleCloseAction },
													adminActions['nurseReject']
												)
											);
										}}>
											<Delete fontSize='small' />
										</IconButton>
									</Tooltip>
									{screening.suspendedAt ?
										<Tooltip title='Active' arrow>
											<IconButton sx={{
												...actionButtonStyleProps, 'backgroundColor': '#2e7d32',
												'color': '#fff',
												'&:hover': {
													backgroundColor: '#2e7d32',
													color: '#fff'
												}
											}} onClick={() => {
												setSelectedScreeningObject(screening);
												setAdminAction(
													Object.assign(
														{ ticket: screening, handleClose: handleCloseAction },
														adminActions['nurseActive']
													)
												);
											}}>
												<LockOpen fontSize='small' />
											</IconButton>
										</Tooltip>
										:
										<Tooltip title='Suspend' arrow>
											<IconButton sx={{
												...actionButtonStyleProps, 'backgroundColor': '#3cc',
												'color': '#fff',
												'&:hover': {
													backgroundColor: '#3cc',
													color: '#fff'
												}
											}} onClick={() => {
												setSelectedScreeningObject(screening);
												setAdminAction(
													Object.assign(
														{ ticket: screening, handleClose: handleCloseAction },
														adminActions['nurseSuspend']
													)
												);
											}}>
												<Lock fontSize='small' />
											</IconButton>
										</Tooltip>
									}
								</>
							}
							{screening.nursys?.isEnabled ?
								<Tooltip title='Nursys Disable' arrow>
									<IconButton sx={{
										...actionButtonStyleProps, 'backgroundColor': '#ff1919',
										'color': '#fff',
										'&:hover': {
											backgroundColor: '#ff1919',
											color: '#fff'
										}
									}} onClick={() => {
										setSelectedScreeningObject(screening);
										setAdminAction(
											Object.assign(
												{ ticket: screening, handleClose: handleCloseAction },
												adminActions['nursysDisable']
											)
										);
									}}>
										<PersonRemove fontSize='small' />
									</IconButton>
								</Tooltip>
								:
								<Tooltip title='Nursys Enable' arrow>
									<IconButton sx={{
										...actionButtonStyleProps, 'backgroundColor': '#4c4cff',
										'color': '#fff',
										'&:hover': {
											backgroundColor: '#4c4cff',
											color: '#fff'
										}
									}} onClick={() => {
										setSelectedScreeningObject(screening);
										setAdminAction(
											Object.assign(
												{ ticket: screening, handleClose: handleCloseAction },
												adminActions['nursysEnable']
											)
										);
									}}>
										<PersonAdd fontSize='small' />
									</IconButton>
								</Tooltip>
							}
							{screening.system === 'Praos' && screening.task.type === TaskType.LicenseVerification &&
								<Tooltip title='Briefcase' arrow>
									<Link to={`${process.env.REACT_APP_ADMIN_PANEL_URL}#/page/login?auth=${auth?.session?.auth}&applicantId=${screening.applicantId}`} target='_blank'>
										<IconButton sx={{
											...actionButtonStyleProps, 'backgroundColor': '#3d255f',
											'color': '#fff',
											'&:hover': {
												backgroundColor: '#3d255f',
												color: '#fff'
											}
										}} >
											<BusinessCenter fontSize='small' />
										</IconButton>
									</Link>
								</Tooltip>
							}
						</>
					}
					{location.pathname !== '/credentialing/audit-logs' &&
						<Tooltip title='Audit Logs' arrow>
							<Link to={'/credentialing/audit-logs'} state={{ applicantId: (screening.applicantIntId ?? screening.applicantId) }}>
								<IconButton sx={{
									...actionButtonStyleProps, 'backgroundColor': '#333',
									'color': '#fff',
									'&:hover': {
										backgroundColor: '#333',
										color: '#fff'
									}
								}}>
									<CalendarMonth fontSize='small' />
								</IconButton>
							</Link>
						</Tooltip>
					}
				</Box>
			);
		}
	}

	const updateLicenseStates = useCallback((license: LicenseVerificationLicense | undefined, updateType?: LicenseUpdateType) => {
		switch (updateType) {
			case 'issueDate':
				setLicenseIssueDate(license?.issueDate ? dayjs(getUtc(license.issueDate)) : null);
				break;
			case 'expirationDate':
				setLicenseExpirationDate(license?.expirationDate ? dayjs(getUtc(license.expirationDate)) : null);
				break;
			case 'body':
				setLicenseState(license?.licenseBody ?? '');
				break;
			case 'number':
				setLicenseNumber(license?.licenseNumber ?? '');
				break;
			default:
				setLicenseIssueDate(license?.issueDate ? dayjs(getUtc(license.issueDate)) : null);
				setLicenseExpirationDate(license?.expirationDate ? dayjs(getUtc(license.expirationDate)) : null);
				setLicenseState(license?.licenseBody ?? '');
				setLicenseNumber(license?.licenseNumber ?? '');
		}
	}, []);

	return (
		<Paper sx={{ width: '100%', overflow: 'hidden' }}>
			<TableContainer sx={{ maxHeight: 500, whiteSpace: 'nowrap' }}>
				<Table stickyHeader aria-label="sticky table">
					<DataTableHead
						columns={columns}
						order={pagination.order}
						orderBy={pagination.orderBy}
						onRequestData={handleRequestSort}
					/>
					<TableBody>
						{screeningData && screeningData.map((row, index) => (
							<TableRow hover key={row._id}>
								{columns.map((col, i) => (
									<TableCell
										align={col.align ? col.align : 'inherit'}
										key={`${row._id}_${i}`}
										sx={i === 0 ? {
											left: 0,
											position: 'sticky',
											zIndex: 1,
											backgroundColor: '#fff'
										} : {}}
									>
										{col.getValue ? col.getValue(row) : ''} {conditionalActionButtons(col, i, index, row)}{' '}
									</TableCell>
								))}
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				count={count}
				pagination={pagination}
				onRequestData={onRequestData}
			/>
			<VerifyTasksDialog
				handleClose={handleClose}
				handleVerify={handleVerify}
				taskId={selectedScreeningObject?.task.id}
				handleUnableVerify={handleUnableVerify}
				modelOpen={isOpen}
				licenseIssueDate={licenseIssueDate}
				licenseExpirationDate={licenseExpirationDate}
				licenseState={licenseState}
				licenseNumber={licenseNumber}
				setIsDirty={(value) => setIsDirty(value)}
				uploadedFile={uploadedFile}
				setUploadedFile={setUploadedFile}
				incompleteLicenseData={isIncompleteLicenseData}
				setIncompleteLicenseData={(value) => setIsIncompleteLicenseData(value)}
				updateLicenseStates={updateLicenseStates}
			/>
			<ViewTaskDialog
				handleClose={handleClose}
				modelOpen={isOpenViewTask}
				taskId={selectedScreeningObject?.task.id}
			/>
			<ConfirmCloseLicenseDialog
				handleClose={handleCloseLicense}
				optionalMessage={false}
				dialogMessage={`I confirm that candidate's license is verified on ${new Date(Date.now()).toLocaleDateString('en-US')}`}
				modelOpen={isOpenSubmit}
				selectedTask={selectedScreeningObject}
				isVerify={true}
				licenseState={licenseState}
				licenseNumber={licenseNumber}
				licenseIssueDate={licenseIssueDate}
				licenseExpirationDate={licenseExpirationDate}
				uploadedFile={uploadedFile}
				isDirty={isDirty}
			/>
			<ConfirmCloseLicenseDialog
				handleClose={handleCloseLicense}
				optionalMessage={true}
				dialogMessage='Enter reason below:'
				modelOpen={isOpenReason}
				selectedTask={selectedScreeningObject}
				isVerify={false}
				licenseState={licenseState}
				licenseNumber={licenseNumber}
				licenseIssueDate={licenseIssueDate}
				licenseExpirationDate={licenseExpirationDate}
				uploadedFile={uploadedFile}
				isDirty={isDirty}
			/>

			{/* Actions */}
			{adminAction && <ConfirmAdminActionDialog {...adminAction} />}
		</Paper>
	);
};

export default CredentialingDataTable;