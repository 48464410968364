import React, { useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { LimitedBackdrop } from '@praos-health/web/components/limited-backdrop';
import { Pie } from '@nivo/pie';
import { InfoButtonPopper } from '@praos-health/web/components';
import { Color } from '../../common/color';
import { replaceAll } from '@praos-health/core/utilities/string';
import { useEffect } from 'react';
import { LegendProps } from '@nivo/legends';
import { styled } from '@mui/material';
import { Title } from '@praos-health/web/components';
import { Toolbar } from '../shared-components';

const colors = [
	Color.Red,
	Color.Yellow,
	Color.Blue,
	Color.Green,
	Color.Purple,
	Color.Orange,
	Color.Gray,
	Color.Pink,
	Color.BlueGreen,
	Color.LightBlue
];

interface Props {
	title: string,
	description?: string,
	height: number,
	loading: boolean,
	data: SpecialtyPieChartItem[]
}

const Main = styled('div')(() => ({
	position: 'relative'
}));

export interface SpecialtyPieChartItem {
	id: string,
	label: string,
	value: number,
	color: string
}

export function buildSpecialtyPieChartItems(bySpecialty: {
	[specialty: string]: number
}): SpecialtyPieChartItem[] {
	const result = Object.keys(bySpecialty)
		.map((specialty) => {
			return {
				id: replaceAll(specialty, ' ', '-').toLowerCase(),
				label: specialty,
				value: bySpecialty[specialty],
				color: ''
			};
		})
		.sort((a, b) => b.value - a.value)
		.map((item, i, arr) => {
			if (arr.length <= colors.length || i < colors.length - 1) {
				item.color = colors[i];
			}

			return item;
		});

	return result.slice(0, colors.length - (result.length > colors.length ? 1 : 0)).concat(
		result.length > colors.length
			? result.reduce(
				(prev, item, i) => {
					if (i > colors.length - 2) {
						prev.value += item.value;
					}
					return prev;
				},
				{
					id: 'specialty-other',
					label: 'Other',
					value: 0,
					color: colors[colors.length - 1]
				}
			)
		: []
	);
}

export function SpecialtyPieChart({
	title,
	description,
	data,
	height,
	loading
}: Props): JSX.Element {
	const [legends, setLegends] = useState<LegendProps[]>();

	useEffect(() => {
		const itemsPerRow = 5;
		const items: LegendProps[] = [];

		for (let i = 0; i < data.length; i += itemsPerRow) {
			items.push({
				anchor: 'bottom-left',
				direction: 'column',
				justify: false,
				translateX: -50 + Math.floor(i / itemsPerRow) * 190, //(width < 450 ? -40 : -130),
				translateY: 90,
				itemWidth: 150,
				itemHeight: 20,
				itemsSpacing: 0,
				data: data.slice(i, (i + 1) * itemsPerRow),
				symbolSize: 20,
				itemDirection: 'left-to-right'
			});
		}

		setLegends(items);
	}, [data]);

	return (
		<Main>
			{title && (
				<Toolbar>
					<Title>{title}</Title>
					{description && <InfoButtonPopper text={description} />}
				</Toolbar>
			)}
			<AutoSizer disableHeight>
				{({ width }: { width: number }) =>
					width ? (
						<div style={{ width: `${width}px` }}>
							<Pie
								height={height}
								width={width}
								data={data}
								margin={{
									top: 35,
									right: width < 450 ? 60 : 180,
									bottom: width < 450 ? 105 : 50,
									left: width < 450 ? 60 : 180
								}}
								innerRadius={0.5}
								padAngle={0.7}
								cornerRadius={3}
								activeOuterRadiusOffset={8}
								borderWidth={1}
								colors={{ datum: 'data.color' }}
								borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
								enableArcLinkLabels={width >= 450}
								arcLinkLabelsSkipAngle={10}
								arcLinkLabelsTextColor="#333333"
								arcLinkLabelsThickness={2}
								arcLinkLabelsColor={{ from: 'color' }}
								arcLabelsSkipAngle={10}
								arcLinkLabel={({ data }) => data.label}
								arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
								animate={true}
								legends={width >= 450 ? undefined : legends}
							/>
						</div>
					) : (
						<div>Loading...</div>
					)
				}
			</AutoSizer>
			<LimitedBackdrop open={loading} />
		</Main>
	);
}
