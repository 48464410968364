import React from 'react';
import { FormControl, Grid, InputLabel, Paper, Select, SelectChangeEvent } from '@mui/material';
import { useApi, useToaster } from '@praos-health/ui';
import { useAuth } from '@praos-health/ui-security';
import { useEffect, useState } from 'react';
import { JobBar } from '../../components/job-bar';
import { Organization } from '@praos-health/organizations-client';
import { StatisticsType, StatisticsOptions } from '@praos-health/jobs-client';
import { useTranslation } from 'react-i18next';
import {
	JobApplicationLine,
	JobApplicationMap,
	JobMap
} from '../../components';
import { StatisticsSummary, StatisticsSummaryData, addAllStatistics } from '@praos-health/web/components/statistics-summary';
import { JobMetricsTable } from '../../components';
import { AppApi } from '../../app-api';
export * from './job-metrics-page-toolbar';

function formatTime(v: number): string {
	// Convert milliseconds to seconds
	v = v / 1000;

	if (Math.abs(v) < 60) {
		return `${Math.round(v)} s`;
	}

	// Convert to minutes
	v = v / 60;

	if (Math.abs(v) < 60) {
		return `${Math.round(v)} m`;
	}

	// Convert to hours
	v = v / 60;

	if (Math.abs(v) < 24) {
		return `${Math.round(v)} h`;
	}

	// Convert to days
	v = v / 24;

	return `${Math.round(v)} d`;
}

export function JobMetricsPage() {
	const { t } = useTranslation();
	const [isStatsLoading, setIsStatsLoading] = useState(false);
	const auth = useAuth();
	const toast = useToaster();
	const api = useApi<AppApi>();
	const [organizationId, setOrganizationId] = useState('all');
	const [organizations, setOrganizations] = useState<Organization[]>([]);
	const [postedStatistics, setPostedStatistics] = useState(
		{} as { [category: string]: StatisticsSummaryData }
	);
	const [cancelledStatistics, setCancelledStatistics] = useState(
		{} as { [category: string]: StatisticsSummaryData }
	);
	const [timeTillCancelledStatistics, setTimeTillCancelledStatistics] = useState(
		{} as { [category: string]: StatisticsSummaryData }
	);
	const [applicationsStatistics, setApplicationsStatistics] = useState(
		{} as { [category: string]: StatisticsSummaryData }
	);
	const [confirmedStatistics, setConfirmedStatistics] = useState(
		{} as { [category: string]: StatisticsSummaryData }
	);
	const [cancelledShiftsByClinicianStatistics, setCancelledShiftsByClinicianStatistics] =
		useState({} as { [category: string]: StatisticsSummaryData });
	const [cancelledShiftsByOrgStatistics, setCancelledShiftsByOrgStatistics] = useState(
		{} as { [category: string]: StatisticsSummaryData }
	);
	const [cancellationNoticeByClinicianStatistics, setCancellationNoticeByClinicianStatistics] =
		useState({} as { [category: string]: StatisticsSummaryData });
	const [cancellationNoticeByOrgStatistics, setCancellationNoticeByOrgStatistics] = useState(
		{} as { [category: string]: StatisticsSummaryData }
	);
	const [timeToFirstApplyStatistics, setTimeToFirstApplyStatistics] = useState(
		{} as { [category: string]: StatisticsSummaryData }
	);
	const [timeToApplyStatistics, setTimeToApplyStatistics] = useState(
		{} as { [category: string]: StatisticsSummaryData }
	);
	const [timeToConfirmStatistics, setTimeToConfirmStatistics] = useState(
		{} as { [category: string]: StatisticsSummaryData }
	);
	const [applicationRateStatistics, setApplicationRateStatistics] = useState(
		{} as { [category: string]: StatisticsSummaryData }
	);
	const [confirmationRateStatistics, setConfirmationRateStatistics] = useState(
		{} as { [category: string]: StatisticsSummaryData }
	);

	const [tableData, setTableData] = useState<any>({});

	useEffect(() => {
		async function loadOrganizations(): Promise<void> {
			if (auth.session?.organization) {
				setOrganizations([
					{
						_id: auth.session.organization._id,
						name: auth.session.organization.name
					}
				]);
				return;
			}

			const result = await api.organizationService.list(auth.session?.auth || '', {
				select: { name: 1 },
				status: 'APPROVED'
			});

			setOrganizations(result.list);
		}

		loadOrganizations()
			.catch(async (e) => {
				if (e instanceof Error && (e.name === 'UnauthorizedError' || e.message === 'Unauthorized')) {
					toast('Your session has expired', 'error');
					return await auth.signout();
				}
				toast(e, 'error');
			});
	}, [api, auth, toast]);

	useEffect(() => {
		async function loadStatistics(): Promise<void> {
			const options: StatisticsOptions = {
				useIsoWeek: true
			};

			if (organizationId === 'praos') {
				options.isMarketplace = true;
			} else if (organizationId !== 'all') {
				options.organization = organizationId;
			}

			const result = await api.jobService.statistics(
				auth.session?.auth || '',
				StatisticsType.Metrics,
				options
			);

			addAllStatistics(result.created);
			addAllStatistics(result.createdIndividual);
			addAllStatistics(result.evergreen);
			addAllStatistics(result.evergreenIndividual);
			addAllStatistics(result.createdWithRate);
			addAllStatistics(result.createdWithRateIndividual);
			addAllStatistics(result.createdExclusive);
			addAllStatistics(result.createdExclusiveIndividual);
			addAllStatistics(result.cancelled);
			addAllStatistics(result.cancelledIndividual);
			addAllStatistics(result.timeTillCancelledMs, result.cancelled);
			addAllStatistics(result.applications);
			addAllStatistics(result.applicationsWithRate);
			addAllStatistics(result.applicationsExclusive);
			addAllStatistics(result.confirmed);
			addAllStatistics(result.cancelledShiftsByNurse);
			addAllStatistics(result.cancelledShiftsByOrg);
			addAllStatistics(result.cancellationNoticeByNurseMs, result.cancelledShiftsByNurse);
			addAllStatistics(result.cancellationNoticeByOrgMs, result.cancelledShiftsByOrg);
			addAllStatistics(result.timeTillFirstApplyMs);
			addAllStatistics(result.timeToApplyMs);
			addAllStatistics(result.timeToConfirmMs);
			addAllStatistics(result.jobsWithApplications);
			addAllStatistics(result.jobsWithApplicationsIndividual);
			addAllStatistics(result.jobsWithConfirmations);
			addAllStatistics(result.jobsWithConfirmationsIndividual);

			setTableData(result);
			setPostedStatistics(result.created);
			setCancelledStatistics(result.cancelled);
			setTimeTillCancelledStatistics(result.timeTillCancelledMs);
			setApplicationsStatistics(result.applications);
			setConfirmedStatistics(result.confirmed);
			setCancelledShiftsByClinicianStatistics(result.cancelledShiftsByNurse);
			setCancelledShiftsByOrgStatistics(result.cancelledShiftsByOrg);
			setCancellationNoticeByClinicianStatistics(result.cancellationNoticeByNurseMs);
			setCancellationNoticeByOrgStatistics(result.cancellationNoticeByOrgMs);
			setTimeToFirstApplyStatistics(result.timeTillFirstApplyMs);
			setTimeToApplyStatistics(result.timeToApplyMs);
			setTimeToConfirmStatistics(result.timeToConfirmMs);

			const applicationRateStat: { [jobType: string]: StatisticsSummaryData } = {};
			const confirmationRateStat: { [jobType: string]: StatisticsSummaryData } = {};

			for (const key in result.created) {
				applicationRateStat[key] = {
					thisWeek: result.created[key].thisWeek
						? (result.jobsWithApplications[key]?.thisWeek || 0) /
							result.created[key].thisWeek
						: 0,
					lastWeek: result.created[key].lastWeek
						? (result.jobsWithApplications[key]?.lastWeek || 0) /
							result.created[key].lastWeek
						: 0,
					thisMonth: result.created[key].thisMonth
						? (result.jobsWithApplications[key]?.thisMonth || 0) /
							result.created[key].thisMonth
						: 0,
					lastMonth: result.created[key].lastMonth
						? (result.jobsWithApplications[key]?.lastMonth || 0) /
							result.created[key].lastMonth
						: 0,
					allTime: result.created[key].allTime
						? (result.jobsWithApplications[key]?.allTime || 0) /
							result.created[key].allTime
						: 0
				};

				confirmationRateStat[key] = {
					thisWeek: result.created[key].thisWeek
						? (result.jobsWithConfirmations[key]?.thisWeek || 0) /
							result.created[key].thisWeek
						: 0,
					lastWeek: result.created[key].lastWeek
						? (result.jobsWithConfirmations[key]?.lastWeek || 0) /
							result.created[key].lastWeek
						: 0,
					thisMonth: result.created[key].thisMonth
						? (result.jobsWithConfirmations[key]?.thisMonth || 0) /
							result.created[key].thisMonth
						: 0,
					lastMonth: result.created[key].lastMonth
						? (result.jobsWithConfirmations[key]?.lastMonth || 0) /
							result.created[key].lastMonth
						: 0,
					allTime: result.created[key].allTime
						? (result.jobsWithConfirmations[key]?.allTime || 0) /
							result.created[key].allTime
						: 0
				};
			}

			setApplicationRateStatistics(applicationRateStat);
			setConfirmationRateStatistics(confirmationRateStat);
		}

		setIsStatsLoading(true);

		loadStatistics()
			.catch((e) => {
				toast(e, 'error');
			})
			.finally(() => {
				setIsStatsLoading(false);
			});
	}, [api, auth, organizationId, toast]);

	const handleChange = async (e: SelectChangeEvent<string>) => {
		setOrganizationId(e.target.value as string);
	};

	return (
		<div>
			<Grid container spacing={2}>
				{!auth.session?.organization && (
					<Grid item xs={12}>
						<Paper>
							<FormControl variant="standard" sx={(theme) => ({
								margin: theme.spacing(1),
								minWidth: 120
							})}>
								<InputLabel>{t('Organization')}</InputLabel>
								<Select
									variant="standard"
									native
									value={organizationId}
									onChange={handleChange}
									inputProps={{
										name: 'organization'
									}}
								>
									<option aria-label="None" value="all">
										{t('All')}
									</option>
									<option aria-label="PraosHealth" value="praos">
										Praos Health
									</option>
									{organizations.map((value) => {
										return (
											<option key={value._id} value={value._id}>
												{value.name}
											</option>
										);
									})}
								</Select>
							</FormControl>
						</Paper>
					</Grid>
				)}
				<Grid item xs={12} md={12}>
					<JobMetricsTable data={tableData} loading={isStatsLoading} organizationId={organizationId} />
				</Grid>
				<Grid item xs={12} md={6}>
					<Paper>
						<StatisticsSummary
							title={t('JobShiftPostings')}
							data={postedStatistics}
							loading={isStatsLoading}
							categoryTitle={t('JobType')}
							category="All"
						/>
					</Paper>
				</Grid>
				<Grid item xs={12} md={6}>
					<Paper>
						<StatisticsSummary
							title={t('CancellationOfOpenJobsShifts')}
							description={t('CancellationOfOpenJobsShiftsDescription')}
							data={cancelledStatistics}
							loading={isStatsLoading}
							categoryTitle={t('JobType')}
							category="All"
						/>
					</Paper>
				</Grid>
				<Grid item xs={12} md={6}>
					<Paper>
						<StatisticsSummary
							title={t('ApplicationRate')}
							description={t('ApplicationRateDescription')}
							data={applicationRateStatistics}
							loading={isStatsLoading}
							formatter={(i) => `${(i * 100).toFixed(2)}%`}
							categoryTitle={t('JobType')}
							category="All"
						/>
					</Paper>
				</Grid>
				<Grid item xs={12} md={6}>
					<Paper>
						<StatisticsSummary
							title={t('TimeTillFirstApplication')}
							description={t('TimeTillFirstApplicationDescription')}
							data={timeToFirstApplyStatistics}
							loading={isStatsLoading}
							formatter={formatTime}
							categoryTitle={t('JobType')}
							category="All"
						/>
					</Paper>
				</Grid>
				<Grid item xs={12} md={6}>
					<Paper>
						<StatisticsSummary
							title={t('AverageApplyTime')}
							description={t('AverageApplyTimeDescription')}
							data={timeToApplyStatistics}
							loading={isStatsLoading}
							formatter={formatTime}
							categoryTitle={t('JobType')}
							category="All"
						/>
					</Paper>
				</Grid>
				<Grid item xs={12} md={6}>
					<Paper>
						<StatisticsSummary
							title={t('FillRate')}
							description={t('FillRateDescription')}
							data={confirmationRateStatistics}
							loading={isStatsLoading}
							formatter={(i) => `${(i * 100).toFixed(2)}%`}
							categoryTitle={t('JobType')}
							category="All"
						/>
					</Paper>
				</Grid>
				<Grid item xs={12} md={6}>
					<Paper>
						<StatisticsSummary
							title={t('AverageFillTime')}
							description={t('AverageFillTimeDescription')}
							data={timeToConfirmStatistics}
							loading={isStatsLoading}
							formatter={formatTime}
							categoryTitle={t('JobType')}
							category="All"
						/>
					</Paper>
				</Grid>
				<Grid item xs={12} md={6}>
					<Paper>
						<StatisticsSummary
							title={t('TimeToCancelOpenJobsShifts')}
							description={t('TimeToCancelOpenJobsShiftsDescription')}
							data={timeTillCancelledStatistics}
							loading={isStatsLoading}
							formatter={formatTime}
							categoryTitle={t('JobType')}
							category="All"
						/>
					</Paper>
				</Grid>
				<Grid item xs={12} md={6}>
					<Paper>
						<StatisticsSummary
							title={t('JobShiftApplications')}
							data={applicationsStatistics}
							loading={isStatsLoading}
							categoryTitle={t('JobType')}
							category="All"
						/>
					</Paper>
				</Grid>
				<Grid item xs={12} md={6}>
					<Paper>
						<StatisticsSummary
							title={t('ConfirmedJobsScheduledShifts')}
							description={t('ConfirmedJobsScheduledShiftsDescription')}
							data={confirmedStatistics}
							loading={isStatsLoading}
							categoryTitle={t('JobType')}
							category="All"
						/>
					</Paper>
				</Grid>
				<Grid item xs={12} md={6}>
					<Paper>
						<StatisticsSummary
							title={t('CancellationByProfessionalOfScheduledJobsShifts')}
							description={t(
								'CancellationByProfessionalOfScheduledJobsShiftsDescription'
							)}
							data={cancelledShiftsByClinicianStatistics}
							loading={isStatsLoading}
							categoryTitle={t('JobType')}
							category="All"
						/>
					</Paper>
				</Grid>
				<Grid item xs={12} md={6}>
					<Paper>
						<StatisticsSummary
							title={t('CancellationNoticeByProfessionalForScheduledJobsShifts')}
							description={t(
								'CancellationNoticeByProfessionalForScheduledJobsShiftsDescription'
							)}
							data={cancellationNoticeByClinicianStatistics}
							loading={isStatsLoading}
							formatter={formatTime}
							categoryTitle={t('JobType')}
							category="All"
						/>
					</Paper>
				</Grid>
				<Grid item xs={12} md={6}>
					<Paper>
						<StatisticsSummary
							title={t('CancellationByOrganizationOfScheduledJobsShifts')}
							description={t(
								'CancellationByOrganizationOfScheduledJobsShiftsDescription'
							)}
							data={cancelledShiftsByOrgStatistics}
							loading={isStatsLoading}
							categoryTitle={t('JobType')}
							category="All"
						/>
					</Paper>
				</Grid>
				<Grid item xs={12} md={6}>
					<Paper>
						<StatisticsSummary
							title={t('CancellationNoticeByOrganizationForScheduledJobsShifts')}
							description={t(
								'CancellationNoticeByOrganizationForScheduledJobsShiftsDescription'
							)}
							data={cancellationNoticeByOrgStatistics}
							loading={isStatsLoading}
							formatter={formatTime}
							categoryTitle={t('JobType')}
							category="All"
						/>
					</Paper>
				</Grid>
				{/*<Grid item xs={12} md={6}>
											<Paper>
													<JobBreakdownSunburst title={t('OpenJobs')} description={t('OpenJobsDescription')} organization={organizationId} height={500} />
								</Paper>
									</Grid>*/}
				<Grid item xs={12}>
					<Paper>
						<JobBar
							title={t('Jobs')}
							description={t('JobsDescription')}
							organization={organizationId}
							height={500}
						/>
					</Paper>
				</Grid>
				<Grid item xs={12}>
					<Paper>
						<JobApplicationLine
							title={t('JobApplicationStatistics')}
							description={t('JobApplicationStatisticsDescription')}
							organization={organizationId}
							height={500}
						/>
					</Paper>
				</Grid>
				<Grid item xs={12}>
					<Paper>
						<JobMap
							title={t('OpenJobs')}
							description={t('OpenJobsDescription')}
							organization={organizationId}
							height={500}
						/>
					</Paper>
				</Grid>
				<Grid item xs={12}>
					<Paper>
						<JobApplicationMap
							title={t('JobApplications')}
							description={t('JobApplicationsDescription')}
							organization={organizationId}
							height={500}
						/>
					</Paper>
				</Grid>
			</Grid>
		</div>
	);
}
