import { Task } from "../task";
import { TaskType } from "../task-type";
import { LicenseVerificationApplicant } from "./license-verification-applicant";

export enum LicenseVerificationStatus {
	IN_PROGRESS = "IN_PROGRESS",
	VERIFIED = "VERIFIED",
	UNABLE_TO_VERIFY = "UNABLE_TO_VERIFY"
}

export interface LicenseVerificationTask extends Task {
	type?: TaskType.LicenseVerification,
	applicant?: LicenseVerificationApplicant,
	status?: LicenseVerificationStatus,
	license?: LicenseVerificationResultLicense,
	note?: string,
	psvFileId?: string
}

export interface LicenseVerificationResultLicense {
	licenseBody?: string,
	issueDate?: Date,
	expirationDate?: Date,
	licenseNumber?: string
}

export function isLicenseVerificationTask(task: Task): task is LicenseVerificationTask {
	return task.type === TaskType.LicenseVerification;
}

export function parseLicenseVerificationTask(task: LicenseVerificationTask): LicenseVerificationTask {
	if (task.license) {
		if (task.license.issueDate) {
			task.license.issueDate = new Date(task.license.issueDate);
		}

		if (task.license.expirationDate) {
			task.license.expirationDate = new Date(task.license.expirationDate);
		}
	}

	if (task.applicant) {
		if (task.applicant.dateOfBirth) {
			task.applicant.dateOfBirth = new Date(task.applicant.dateOfBirth);
		}

		if (task.applicant.licenses) {
			for (const license of task.applicant.licenses) {
				if (license.issueDate) {
					license.issueDate = new Date(license.issueDate);
				}

				if (license.expirationDate) {
					license.expirationDate = new Date(license.expirationDate);
				}

				if (license.verifiedAt) {
					license.verifiedAt = new Date(license.verifiedAt);
				}
			}
		}
	}

	return task;
}