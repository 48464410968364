import React from 'react';
import { FormControl, Grid, InputLabel, Paper, Select, SelectChangeEvent } from '@mui/material';
import { useApi, useToaster } from '@praos-health/ui';
import { useAuth } from '@praos-health/ui-security';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OptOutSummary, OptOutSummaryData } from '../../components';
import { StatisticsType, StatisticsOptions } from '@praos-health/professional-client';
import { Organization } from '@praos-health/organizations-client';
import { downloadFile } from '../../common/download';
import { AppApi } from '../../app-api';
export * from './user-engagement-page-toolbar';

export function UserEngagementPage(): JSX.Element {
	const { t } = useTranslation();
	const auth = useAuth();
	const api = useApi<AppApi>();
	const toast = useToaster();
	const [isLoading, setIsLoading] = useState(false);
	const [organizationId, setOrganizationId] = useState('all');
	const [organizations, setOrganizations] = useState<Organization[]>([]);
	const [data, setData] = useState<OptOutSummaryData>({ count: 0, email: 0, sms: 0 });

	useEffect(() => {
		async function loadOrganizations(): Promise<void> {
			if (auth.session?.organization) {
				setOrganizations([
					{
						_id: auth.session.organization._id,
						name: auth.session.organization.name
					}
				]);
				return;
			}

			const result = await api.organizationService.list(auth.session?.auth || '', {
				select: { name: 1 },
				status: 'APPROVED'
			});

			setOrganizations(result.list);
		}

		loadOrganizations().catch(async (e) => {
			if (e instanceof Error && (e.name === 'UnauthorizedError' || e.message === 'Unauthorized')) {
				toast('Your session has expired', 'error');
				return await auth.signout();
			}
			toast(e, 'error');
		});
	}, [api, auth, toast]);

	useEffect(() => {
		const options: StatisticsOptions = {};

		setIsLoading(true);

		if (organizationId === 'praos') {
			options.isMarketplace = true;
		} else if (organizationId !== 'all') {
			options.organization = organizationId;
		}

		api.professionalService
			.statistics(auth.session?.auth || '', StatisticsType.UserEngagement, options)
			.then((stats:any) => {
				setData({
					count: stats.count,
					email: stats.optOuts.email,
					sms: stats.optOuts.sms
				});
			})
			.catch((e:Error) => {
				toast(e, 'error');
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [auth, api, toast, organizationId]);

	const handleChange = async (e: SelectChangeEvent<string>) => {
		setOrganizationId(e.target.value as string);
	};

	const handleDownload = async () => {
		const options: StatisticsOptions = {
			download: true
		};

		if (organizationId === 'praos') {
			options.isMarketplace = true;
		} else if (organizationId !== 'all') {
			options.organization = organizationId;
		}
		api.professionalService.statistics(auth.session?.auth || '', StatisticsType.UserEngagement, options)
			.then((stats) => {
				downloadFile(stats.fileName, 'text/csv;charset=utf-8;', stats.csv);
			})
			.catch((e:Error) => {
				toast(e, 'error');
			});
	};

	return (
		<div>
			<Grid container spacing={2}>
				{!auth.session?.organization && (
					<Grid item xs={12}>
						<Paper>
							<FormControl variant="standard" sx={(theme) => ({
								margin: theme.spacing(1),
								minWidth: 120
							})}>
								<InputLabel>{t('Organization')}</InputLabel>
								<Select
									variant="standard"
									native
									value={organizationId}
									onChange={handleChange}
									inputProps={{
										name: 'organization'
									}}
								>
									<option aria-label="None" value="all">
										{t('All')}
									</option>
									<option aria-label="PraosHealth" value="praos">
										Praos Health
									</option>
									{organizations.map((value) => {
										return (
											<option key={value._id} value={value._id}>
												{value.name}
											</option>
										);
									})}
								</Select>
							</FormControl>
						</Paper>
					</Grid>
				)}
				<Grid item xs={12} md={6}>
					<Paper>
						<OptOutSummary title={t('OptOuts')} loading={isLoading} data={data} handleDownload={handleDownload} />
					</Paper>
				</Grid>
			</Grid>
		</div>
	);
}
