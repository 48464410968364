import { User } from "./user"
import { Department } from "./department"
import { Organization } from "./organization"

export interface Affiliation {
	thirdPartyId?: string,
	organization?: Organization,
	createdAt?: Date,
	createdBy?: User,
	acceptedAt?: Date,
	acceptedBy?: User,
	rejectedAt?: Date,
	rejectedBy?: User,
	department?: Department,
	recruiter?: User,
	notes?: AffiliationNote[],
	clients?: AffiliationClient[]
	referredBy?: string,
	interestedInJob?: string,
}

export interface AffiliationNote {
	text?: string,
	createdAt?: Date,
	createdBy?: User
}

export interface AffiliationClient {
	_id?: string,
	name?: string
}

export function parseAffiliation(json: any): Affiliation {
	if (json.createdAt) {
		json.createdAt = new Date(json.createdAt);
	}

	if (json.acceptedAt) {
		json.acceptedAt = new Date(json.acceptedAt);
	}

	if (json.rejectedAt) {
		json.rejectedAt = new Date(json.rejectedAt);
	}

	if (json.notes) {
		for (let i = 0; i < json.notes.length; i++) {
			if (json.notes[i].createdAt) {
				json.notes[i].createdAt	= new Date(json.notes[i].createdAt);
			}
		}
	}

	return json;
}