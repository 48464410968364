//import fetch from "node-fetch";
//import FormData from "form-data";
import { isObject, toJson } from "@praos-health/core/utilities/object";
import { RecordError } from "./errors";

export async function ajax(url: RequestInfo, method: string, auth?: string, body?: any, qs?: any, signal?: AbortSignal | null): Promise<any> {
	if (qs) {
		url += '?' + Object.keys(qs).reduce((i, j) => {
			let v = toJson(qs[j]);

			if (isObject(v)) {
				v = JSON.stringify(v);
			}

			i.push(`${j}=${encodeURIComponent(v)}`);

			return i;
		}, []).join('&');
	}

	const headers: any = {};

	if (body && !(body instanceof FormData)) {
		headers['Content-Type'] = 'application/json';
		body = JSON.stringify(toJson(body));
	}

	if (auth) {
		headers.Authorization = auth;
	}

	const response: Response = await fetch(
		url,
		{
			method,
			mode: 'cors',
			cache: 'no-cache',
			credentials: 'same-origin',
			headers,
			redirect: 'manual',
			referrerPolicy: 'no-referrer',
			body,
			signal
		}
	);

	return await handleResponse(response);			
}

async function handleResponse(response: Response): Promise<any> {
	if (!response) {
		throw new RecordError('RecordError', 'Request timed out.');
	}

	let result: any = await response.text();

	try {
		result = JSON.parse(result);
	} catch (err) {}

	if (response.ok) {
		return result;
	}

	const location = response.headers.get('location');
	const type = (location || !result?.type) ? 'RecordError' : result.type;
	const err = new RecordError(type, result?.message || response.statusText, response.status, location);

	if (result.stack) {
		err.stack = result.stack;
	}

	throw err;
}