import React from 'react';
import { useTranslation } from 'react-i18next';
import { LimitedBackdrop } from '@praos-health/web/components/limited-backdrop';
import { InfoButtonPopper } from '@praos-health/web/components';
import { IconButton, styled } from '@mui/material';
import { Title } from '@praos-health/web/components';
import DownloadIcon from '@mui/icons-material/GetApp';

interface Props {
	title: string,
	description?: string,
	loading?: boolean,
	data: OptOutSummaryData,
	handleDownload?: () => void
}

export interface OptOutSummaryData {
	count: number,
	email: number,
	sms: number
}

export interface OptOutData {
	json: string,
	csv: string
}

const Main = styled('div')(({ theme }) => ({
	position: 'relative',
	padding: theme.spacing(2)
}));

const Section = styled('div')(() => ({
	display: 'flex',
	maxWidth: '500px',
	justifyContent: 'center'
}));

const SectionItem = styled('div')(() => ({
	flex: '1 1 0'
}));

const Value = styled('div')(() => ({
	whiteSpace: 'nowrap',
	textAlign: 'center',
	fontWeight: 'bold',
	fontSize: '48px'
}));

const SubTitle = styled('div')(({ theme }) => ({
	textAlign: 'center',
	color: theme.palette.text.disabled
}));

const PctValue = styled('div')(() => ({
	flex: '1 1 0',
	fontSize: '36px',
	textAlign: 'center'
}));

export function OptOutSummary({ title, description, loading, data, handleDownload } : Props): JSX.Element {
	const { t } = useTranslation();

	return (
		<Main>
			<Title>{title}</Title>
			{ description && <InfoButtonPopper text={description} /> }
			<IconButton color="inherit" edge="end" onClick={handleDownload} size="large">
				<DownloadIcon />
			</IconButton>
			<Section>
				<SectionItem>
					<Value>{data.sms}</Value>
					<SubTitle>{t('Sms')}</SubTitle>
				</SectionItem>
				<SectionItem>
					<Value>{data.email}</Value>
					<SubTitle>{t('Email')}</SubTitle>
				</SectionItem>
			</Section>
			<Section>
				<PctValue>
					{ data.count ? (data.sms / data.count * 100).toFixed(2) : 0}%
				</PctValue>
				<PctValue>
					{ data.count ? (data.email / data.count * 100).toFixed(2) : 0}%
				</PctValue>
			</Section>
			<LimitedBackdrop open={!!loading} />
		</Main>
	);
}