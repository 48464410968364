import React, { ReactNode } from 'react';
import { Paper, Typography, Box } from '@mui/material';
import { InfoButtonPopper } from '@praos-health/web';

interface MetricDisplayProps {
	title: string,
	description: string,
	timePeriod?: {
	  startDate: Date,
	  endDate: Date
	},
	value?: number | string,
	children?: ReactNode,
	loading: boolean
}

const MetricDisplay: React.FC<MetricDisplayProps> = ({ title, value, description, children }) => {

	return (
		<Paper
			elevation={1}
			sx={{
				p: 2,
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				borderRadius: '4px',
				boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)'
			}}
		>
			<Box display="flex" alignItems="center" justifyContent="space-between">
				<Typography variant="h6" component="h3" sx={{ fontWeight: 'bold' }}>
					{title}
				</Typography>
				<InfoButtonPopper text={description} />
			</Box>
			{ value ?
				<Typography variant="h3" component="p" align="center" sx={{ fontWeight: 400, my: 2 }}>
					{value}
				</Typography>
				: children && (
					<Box mt={2}>
						{children}
					</Box>
				)
			}
		</Paper>
	);
};

export default MetricDisplay;