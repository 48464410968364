export type WorkExperience = {
	resumeFileId?: string,
	resumeFileUrl?: string,
	downloadUrl?: string,
	linkedInUrl?: string,
	uploadedAt?: Date
}

export function prepWorkExperience(experience: WorkExperience): WorkExperience {
	delete experience.resumeFileId;
	delete experience.resumeFileUrl;
	delete experience.downloadUrl;
	delete experience.uploadedAt;

	return experience;
}

export function parseWorkExperience(json: any): WorkExperience {
	if (json.uploadedAt) {
		json.uploadedAt = new Date(json.uploadedAt);
	}

	return json;
}