import { Task } from "../task";
import { TaskType } from "../task-type";
import { VettyApplicant } from "./vetty-applicant";
import { VettyPackage } from "./vetty-package";

export enum VettyStatus {
	APPLICANT_CREATED = "APPLICANT_CREATED",
	APPLICANT_PENDING = "APPLICANT_PENDING",
	IN_PROGRESS = "IN_PROGRESS",
	CLEAR = "CLEAR",
	REVIEW_REQUIRED = "REVIEW_REQUIRED",
	ACCEPTED = "ACCEPTED",
	PRE_ADVERSE = "PRE_ADVERSE",
	ADVERSE = "ADVERSE",
	DISPUTE_UNDER_REVIEW = "DISPUTE_UNDER_REVIEW",
	ARCHIVED = "ARCHIVED",
	APPROVAL_NEEDED = "APPROVAL_NEEDED",
	NOT_MEET_CRITERIA = "NOT_MEET_CRITERIA",
	WITHDRAWN = "WITHDRAWN",
	COI_EXPIRED = "COI_EXPIRED",
	IDV_FAIL = "IDV_FAIL",
	INCONCLUSIVE = "INCONCLUSIVE",
	ERROR = "ERROR"
}

export interface VettyTask extends Task {
	type?: TaskType.Vetty,
	applicantId?: string,
	applicant?: VettyApplicant,
	screeningId?: string,
	jobStartDate?: Date,
	package?: VettyPackage,
	additionalChecks?: string[],
	status?: VettyStatus,
	screeningFileId?: string
}

export function isVettyTask(task: Task): task is VettyTask {
	return task.type === TaskType.Vetty;
}

export function parseVettyTask(task: VettyTask): VettyTask {
	if (task.jobStartDate) {
		task.jobStartDate = new Date(task.jobStartDate);
	}

	if (task.applicant) {
		if (task.applicant.dateOfBirth) {
			task.applicant.dateOfBirth = new Date(task.applicant.dateOfBirth);
		}
	}

	return task;
}